import moment from 'moment';

import { PidTypeCode } from '@/enum/Nomenclature/PidTypeCode';
import { i18n } from '@/plugin/I18n';
import { IValidator } from '@/plugin/Validator/IValidator';
import { identifierUtil } from '@/util/IdentifierUtil';

const validatorConfig = {
    minYear: 1900,
    maxYear: 2100,
    digitsOnlyPattern: /^\d+$/u
};

const dateRange = {
    min: moment(new Date(validatorConfig.minYear, 0, 1)),
    max: moment(new Date(validatorConfig.maxYear, 0, 1))
};

export const validatorService: IValidator = {
    required(value: string | number | boolean | null | undefined) {
        // Забележка: false се счита за попълнена стойност. Това позволява да се валидират и полета от тип boolean | null.
        if (typeof value === 'string') {
            if (value.trim().length === 0) {
                return i18n.tc('validation.requiredField', 1);
            }
        }
        return Boolean(value) || typeof value === 'boolean' || i18n.tc('validation.requiredField', 1);
    },

    minLength(len: number) {
        return (value: string) => !value || value.length >= len || i18n.t('validation.minLength', { count: len });
    },

    maxLength(len: number) {
        return (value: string) => !value || value.length <= len || i18n.t('validation.maxLength', { count: len });
    },

    exactLength(length: number) {
        return (value: string) =>
            (value || '').length === length || i18n.t('validation.exactLength', { count: length });
    },

    minValue(val: number) {
        return (value: number) => value >= val || i18n.t('validation.minValue', { value: val });
    },

    maxValue(val: number) {
        return (value: number) => value <= val || i18n.t('validation.maxValue', { value: val });
    },

    dateFormat(format: string) {
        return (value: string | null) => {
            // Празната стойност се счита за валидна. Ако трябва да има въведена дата, се изпозва и required.
            if (!value) {
                return true;
            }
            const momentDate = moment(value, format, true);
            if (!momentDate.isValid()) {
                return i18n.t('validation.invalidDateFormat', { format });
            }
            if (momentDate < dateRange.min) {
                return i18n.t('validation.dateTooPast');
            }
            if (momentDate > dateRange.max) {
                return i18n.t('validation.dateTooFuture');
            }
            return true;
        };
    },

    digitsOnly(value: string) {
        return !value || validatorConfig.digitsOnlyPattern.test(value) || i18n.t('validation.digitsOnly');
    },

    // Забележка: Обикновено се връща функция по този начин:
    // public identifier(pidTypeCode: string) {
    //     return(identifier: string) => { ... }
    // }
    // Горното не върши работа, защото при промяна само в pidTypeCode грешката не се опреснява.
    identifier(pidTypeCode: string, identifier: string) {
        if (pidTypeCode !== PidTypeCode.Foreigner) {
            if (!validatorConfig.digitsOnlyPattern.test(identifier)) {
                return i18n.t('validation.digitsOnly');
            }
            const bgIdentifierLength = 10;
            if (identifier.length !== bgIdentifierLength) {
                return i18n.t('validation.digitCount', { count: 10 });
            }
        }
        switch (pidTypeCode) {
            case PidTypeCode.Egn:
                if (!identifierUtil.isEgnValid(identifier)) {
                    return i18n.t('validation.invalidEgn');
                }
                break;
            case PidTypeCode.Lnch:
                if (!identifierUtil.isLnchValid(identifier)) {
                    return i18n.t('validation.invalidLnch');
                }
                break;
            case PidTypeCode.Baby:
                if (!identifierUtil.isBabyValid(identifier)) {
                    return i18n.t('validation.invalidIdentifier');
                }
                break;
            default:
                return true;
        }
        return true;
    },

    email(value: string) {
        // Оригиналният израз беше без named capture groups и изглеждаше така:
        // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u;
        // Той е взет от някъде наготово. Поддържа също формат user@[IP], който се оказа по стандарт.
        const pattern =
            /^(?<userName>(?<namePart1>[^<>()[\]\\.,;:\s@"]+(?<namePartN>\.[^<>()[\]\\.,;:\s@"]+)*)|(?<quotedName>".+"))@(?<domainOrIp>(?<ipAddress>\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(?<domainPath>(?<domainPart>[a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u;
        return !value || pattern.test(value) || i18n.t('validation.invalidEmail');
    },

    postalCode(value: string) {
        const pattern = /^\d{4}$/u;
        return !value || pattern.test(value) || i18n.t('validation.postalCodeLength');
    },

    practiceNumber(value: string) {
        const pattern = /^\d{10}$/u;
        return !value || pattern.test(value) || i18n.t('validation.practiceNumberLength');
    },

    uin(value: string) {
        const pattern = /^\d{10}$/u;
        return !value || pattern.test(value) || i18n.t('validation.uinLength');
    },

    nrn(value: string) {
        const pattern = /^[0-9]{5}[A-F0-9]{7}/u;
        return !value || pattern.test(value) || i18n.t('validation.invalidNrn');
    },

    personName(value: string) {
        const pattern = /^[а-яА-Я-\s']+$|^[a-zA-Z-\s']+$/u;
        return !value || pattern.test(value) || i18n.t('validation.invalidName');
    },

    exactlyOneIsRequired(fieldValue: string | number | null, otherValue: string | number | null, fieldNames: string) {
        return (
            Boolean((fieldValue && !otherValue) || (!fieldValue && otherValue)) ||
            `Попълнете точно едно от полетата ${fieldNames}.`
        );
    }
};
