<template>
    <ExamLayout
        :can-delete="document.id > 0"
        :can-print="false"
        title="Антропометрия"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card>
            <main-title>Антропометрия</main-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6" md="3">
                        <date-picker v-model="document.issueDate" label="Дата на измерването" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <text-field v-model="document.age" type="number" label="Възраст: (г)" disabled />
                    </v-col>
                    <v-col cols="6" md="3">
                        <dropdown v-model="patientGender" :items="genders" item-value="code" label="Пол" disabled />
                    </v-col>
                    <v-col cols="6" md="3">
                        <text-field
                            v-model="document.headCircumference"
                            :hint="headHint"
                            type="number"
                            label="Обиколка глава: (см)"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="3">
                        <text-field
                            v-model="document.height"
                            :hint="heightHint"
                            type="number"
                            label="Ръст (см)"
                            @blur="calculateItm"
                        />
                    </v-col>
                    <v-col cols="6" md="3">
                        <text-field
                            v-model="document.weight"
                            :hint="weightHint"
                            type="number"
                            label="Телесна маса: (кг)"
                            @blur="calculateItm"
                        />
                    </v-col>
                    <v-col cols="6" md="3">
                        <text-field v-model="document.waist" type="number" label="Обиколка талия: (см)" />
                    </v-col>
                    <v-col v-if="document.age > 18" cols="6" md="3">
                        <text-field v-model="document.indexTm" type="number" label="ИТМ: " disabled />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="3">
                        <text-field v-model="document.fontanelle1" type="number" label="Фонтанела: (мм)" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <text-field v-model="document.fontanelle2" type="number" label="(мм)" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="3">
                        <text-field
                            v-model="document.chestRelax"
                            :hint="chestPauseHint"
                            type="number"
                            label="Гръдна обиколка в спокойно съст: (см)"
                        />
                    </v-col>
                    <v-col cols="6" md="3">
                        <text-field
                            v-model="document.chestInhalation"
                            :hint="chestInhalationHint"
                            type="number"
                            label="Гръдна обиколка при вдишв: (см)"
                        />
                    </v-col>
                    <v-col cols="6" md="3">
                        <text-field
                            v-model="document.chestExhalation"
                            :hint="chestExhalationHint"
                            type="number"
                            label="Гръдна обиколка при издишв:  (см)"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field :value="groupsLegend" dense outlined no-resize disabled rounded></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="12">
                        <btn action="New" @click="addToAmbulatorySheet(false)">Добави в обективно състояние в АЛ</btn>
                        <btn style="margin-left: 5px" action="New" @click="addToAmbulatorySheet(true)"
                            >Добави в изследвания в АЛ</btn
                        >
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <data-table class="mt-3" :items="tableItems" :headers="tableHeaders" disable-pagination />
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { AntrhropometryDto } from '@/model/Exam/Anthropometry/AntrhropometryDto';
    import { ExamDocumentType } from '@/model/Exam/ExamDocumentType';
    import { AnthropometricNormDto } from '@/model/Nomenclature/AnthropometricNormDto';
    import { anthropometryService } from '@/service/Exam/AnthropometryService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { anthropometricNormService } from '@/service/Nomenclature/AnthropometricNormService';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { genderCache } from '@/store/Nomenclature/GenderCache';
    import { dateUtil } from '@/util/DateUtil';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: { UnsavedChanges, ExamLayout },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class Anthropometry extends Vue {
        @Ref() readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private documentId!: number;

        @Prop()
        private examId!: number;

        private document: AntrhropometryDto = new AntrhropometryDto();
        private initialValue: AntrhropometryDto = new AntrhropometryDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();
        private tableItems: AnthropometricNormDto[] = [];
        private headHint: string = '';
        private heightHint: string = '';
        private weightHint: string = '';
        private chestPauseHint: string = '';
        private chestInhalationHint: string = '';
        private chestExhalationHint: string = '';

        private get genders() {
            return genderCache.items;
        }

        private get birthDate() {
            return currentPatientCache.value.personIdentification.birthDate;
        }

        private get patientGender() {
            return currentPatientCache.value.personIdentification.genderCode;
        }

        private get groupsLegend() {
            const legendText =
                'Легенда: Група 1 – малко отклонение от нормата; Група 2 – до два пъти отклонение от нормата; Група 3 – отклонение по-голямо от два пъти';
            return legendText;
        }

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Възраст', value: 'age' },
                { text: 'Об. глава - норма', value: 'headNorm' },
                { text: 'Об. глава - отклонение', value: 'headDeviation' },
                { text: 'Ръст - норма', value: 'heightNorm' },
                { text: 'Ръст - отклонение', value: 'heightDeviation' },
                { text: 'Тегло - норма', value: 'weightNorm' },
                { text: 'Тегло - отклонение', value: 'weightDeviation' },
                { text: 'Об. гърди(в пауза) - норма', value: 'chestPauseNorm' },
                { text: 'Об. гърди(в пауза) - отклонение', value: 'chestPauseDeviation' },
                { text: 'Об. гърди(вдишване) - норма', value: 'chestInhalationNorm' },
                { text: 'Об. гърди(вдишване) - отклонение', value: 'chestInhalationDeviation' },
                { text: 'Об. гърди(издишване) - норма', value: 'chestExhalationNorm' },
                { text: 'Об. гърди(издишване) - отклонение', value: 'chestExhalationDeviation' }
            ];
        }

        private get patientAgeText() {
            if (this.document.age === 0 && this.birthDate) {
                const months = dateUtil.monthDifferenceBetweenTwoDates(this.birthDate ?? new Date(), new Date());
                return `${months} м.`;
            }
            const maxYearsToShowMonths = 7;
            const maxMonths = 12;
            if (this.document.age && this.document.age < maxYearsToShowMonths && this.birthDate) {
                const currentDate = new Date();
                const birthDate = new Date(this.birthDate);
                let years = currentDate.getFullYear() - birthDate.getFullYear();
                let months = 0;
                if (currentDate.getMonth() > birthDate.getMonth()) {
                    months = this.calcMonthsNewerCurrentDate(currentDate, birthDate);
                } else if (currentDate.getMonth() === birthDate.getMonth()) {
                    if (currentDate.getDate() >= birthDate.getDate()) {
                        months = currentDate.getMonth() - birthDate.getMonth();
                    } else {
                        months = maxMonths - 1;
                        years = years - 1;
                    }
                } else {
                    months = this.calcMonths(currentDate, birthDate);
                    years = years - 1;
                }

                return !months ? `${years} г.` : this.getAgeString(months + years * maxMonths);
            }
            return `${this.document.age} г.`;
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = 0;
                if (this.document.id === 0) {
                    this.document.examId = this.examId;
                    documentId = await anthropometryService.create(this.document);
                    this.copyObject();
                    eventBus.$emit(
                        'create-referral-event',
                        documentId,
                        'Anthropometry',
                        ExamDocumentType.Anthropometry
                    );
                    this.$router.push(`/Exam/Anthropometry/Edit/${documentId}`);
                } else {
                    await anthropometryService.updateAnthropometry(this.document);
                    this.copyObject();
                    eventBus.$emit('update-referral-event', ExamDocumentType.Anthropometry);
                }
            } finally {
                this.$loading.hide();
            }
        }

        public getAgeString(months: number): string {
            const ageRanges = [
                { limit: 15, age: '1 г. 3 м.' },
                { limit: 18, age: '1 г. 6 м.' },
                { limit: 21, age: '1 г. 9 м.' },
                { limit: 27, age: '2 г. 3 м.' },
                { limit: 30, age: '2 г. 6 м.' },
                { limit: 33, age: '2 г. 9 м.' },
                { limit: 42, age: '3 г. 6 м.' },
                { limit: 54, age: '4 г. 6 м.' },
                { limit: 66, age: '5 г. 6 м.' },
                { limit: 78, age: '6 г. 6 м.' }
            ];
            const matchingRange = ageRanges.find((range) => months <= range.limit);
            return matchingRange ? matchingRange.age : '';
        }

        private async addToAmbulatorySheet(isForExamTests: boolean) {
            this.$loading.show();
            try {
                const result = ` Измерване: ${
                    this.document?.headCircumference ? `обиколка глава: ${this.document.headCircumference} см.; ` : ''
                }${this.document?.height ? `ръст: ${this.document.height} см.; ` : ''}${
                    this.document?.weight ? `тегло: ${this.document.weight} кг.; ` : ''
                }${this.document?.chestRelax ? `гр. об. в спок. съст.: ${this.document.chestRelax} см.; ` : ''}${
                    this.document?.chestInhalation ? `гр. об. при вдишване: ${this.document.chestInhalation} см.; ` : ''
                }${
                    this.document?.chestExhalation
                        ? `гр. об. при издишване: ${this.document.chestExhalation} см.; `
                        : ''
                }${this.document?.waist ? `об. талия: ${this.document.waist} см.; ` : ''}${
                    this.document?.fontanelle1 || this.document?.fontanelle2
                        ? `фонтанела: ${this.document.fontanelle1} мм., ${this.document.fontanelle2} мм.; `
                        : ''
                }${this.document?.indexTm ? `ИТМ: ${this.document.indexTm} ; ` : ''};`;

                if (isForExamTests) {
                    await anthropometryService.updateExamTest(result);
                } else {
                    await anthropometryService.updateExamCondition(result);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const documentId = this.document.id;
                await anthropometryService.deleteAnthropometry(documentId);
                this.copyObject();
                this.$router.push(`/Exam/Anthropometry/Create/${currentVisitCache.value.id}`);
                eventBus.$emit('delete-referral-event', documentId, 'Anthropometry', ExamDocumentType.Anthropometry);
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.document.id) {
                this.$router.push(`/Print/Exam.Anthropometry/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Anthropometry';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                const norms = await anthropometricNormService.getNorms();
                this.tableItems = norms.filter(
                    (norm) => norm.genderCode === currentPatientCache.value.personIdentification.genderCode
                );
                if (this.documentId) {
                    this.document = await anthropometryService.getAnthropometry(this.documentId);
                } else {
                    this.document.age = currentPatientCache.value?.age ?? 0;
                    this.document.issueDate = new Date(currentVisitCache.value.startDateTime as Date);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private calculateItm() {
            const over18 = 18;
            const hundred: number = 100;
            if (
                this.document?.height &&
                this.document.height > 0 &&
                this.document?.weight &&
                this.document.weight > 0 &&
                this.document.age > over18
            ) {
                const heightMeters = this.document.height / hundred;
                this.document.indexTm =
                    Math.round((this.document.weight / (heightMeters * heightMeters)) * hundred) / hundred;
            }
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }

        private getGroup(norm: number | null, deviation: number | null, value: number) {
            if (!norm || !deviation) {
                return 0;
            }
            const group1 = 1;
            const group2 = 2;
            const group3 = 3;
            const diffrence = Math.abs(norm - value);
            if (diffrence <= deviation) {
                return group1;
            } else if (diffrence <= deviation * group2) {
                return group2;
            }
            return group3;
        }

        private getAntropoGroup(measurePart: string, value: number) {
            const ageAt3 = 3;
            if (
                (this.document.age > 1 && measurePart === 'head') ||
                (this.document.age < ageAt3 && (measurePart === 'inhalation' || measurePart === 'exhalation'))
            ) {
                return 0;
            }
            const { patientAgeText } = this;
            const norm = this.tableItems.find((item) => item.age === patientAgeText);
            if (!norm) {
                return 0;
            }
            switch (measurePart) {
                case 'head':
                    return this.getHeadGroup(norm, value);
                case 'height':
                    return this.getHeightGroup(norm, value);
                case 'weight':
                    return this.getWeightGroup(norm, value);
                case 'chestPause':
                    return this.getChestPauseGroup(norm, value);
                case 'inhalation':
                    return this.getInhalationGroup(norm, value);
                case 'exhalation':
                    return this.getExhalationGroup(norm, value);
                default:
                    return 0;
            }
        }

        private getHeadGroup(norm: AnthropometricNormDto, value: number) {
            const { headNorm } = norm;
            const { headDeviation } = norm;
            return this.getGroup(headNorm, headDeviation, value);
        }

        private getHeightGroup(norm: AnthropometricNormDto, value: number) {
            const { heightNorm } = norm;
            const { heightDeviation } = norm;
            return this.getGroup(heightNorm, heightDeviation, value);
        }

        private getWeightGroup(norm: AnthropometricNormDto, value: number) {
            const { weightNorm } = norm;
            const { weightDeviation } = norm;
            return this.getGroup(weightNorm, weightDeviation, value);
        }

        private getChestPauseGroup(norm: AnthropometricNormDto, value: number) {
            const { chestPauseNorm } = norm;
            const { chestPauseDeviation } = norm;
            return this.getGroup(chestPauseNorm, chestPauseDeviation, value);
        }

        private getInhalationGroup(norm: AnthropometricNormDto, value: number) {
            const { chestInhalationNorm } = norm;
            const { chestInhalationDeviation } = norm;
            return this.getGroup(chestInhalationNorm, chestInhalationDeviation, value);
        }

        private getExhalationGroup(norm: AnthropometricNormDto, value: number) {
            const { chestExhalationNorm } = norm;
            const { chestExhalationDeviation } = norm;
            return this.getGroup(chestExhalationNorm, chestExhalationDeviation, value);
        }

        private calcMonthsNewerCurrentDate(currentDate: Date, birthDate: Date): number {
            let months = 0;
            if (currentDate.getDate() > birthDate.getDate()) {
                months = currentDate.getMonth() - birthDate.getMonth();
            } else {
                months = currentDate.getMonth() - birthDate.getMonth() - 1;
            }
            return months;
        }

        private calcMonths(currentDate: Date, birthDate: Date): number {
            let months = 0;
            const maxMonths = 12;
            if (currentDate.getDate() >= birthDate.getDate()) {
                months = maxMonths - birthDate.getMonth() + currentDate.getMonth();
            } else {
                months = maxMonths - birthDate.getMonth() + currentDate.getMonth() - 1;
            }
            return months;
        }

        @Watch('document.headCircumference')
        private onHeadCircumferenceChange() {
            if (!this.document.headCircumference) {
                this.headHint = '';
                return;
            }
            const group = this.getAntropoGroup('head', this.document.headCircumference);
            this.headHint = group > 0 ? `Група: ${group.toString()}` : '';
        }

        @Watch('document.height')
        private onHeightChange() {
            if (!this.document.height) {
                this.heightHint = '';
                return;
            }
            const group = this.getAntropoGroup('height', this.document.height);
            this.heightHint = group > 0 ? `Група: ${group.toString()}` : '';
        }

        @Watch('document.weight')
        private onWeightChange() {
            if (!this.document.weight) {
                this.weightHint = '';
                return;
            }
            const group = this.getAntropoGroup('weight', this.document.weight);
            this.weightHint = group > 0 ? `Група: ${group.toString()}` : '';
        }

        @Watch('document.chestRelax')
        private onChestRelaxChange() {
            if (!this.document.chestRelax) {
                this.chestPauseHint = '';
                return;
            }
            const group = this.getAntropoGroup('chestPause', this.document.chestRelax);
            this.chestPauseHint = group > 0 ? `Група: ${group.toString()}` : '';
        }

        @Watch('document.chestInhalation')
        private onChestInhalationChange() {
            if (!this.document.chestInhalation) {
                this.chestInhalationHint = '';
                return;
            }
            const group = this.getAntropoGroup('inhalation', this.document.chestInhalation);
            this.chestInhalationHint = group > 0 ? `Група: ${group.toString()}` : '';
        }

        @Watch('document.chestExhalation')
        private onChestExhalationChange() {
            if (!this.document.chestExhalation) {
                this.chestExhalationHint = '';
                return;
            }
            const group = this.getAntropoGroup('exhalation', this.document.chestExhalation);
            this.chestExhalationHint = group > 0 ? `Група: ${group.toString()}` : '';
        }
    }
</script>
