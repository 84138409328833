<template>
    <v-row>
        <v-card-title>Лекарство №{{ position }}</v-card-title>
        <v-col cols="12">
            <NhifDrugPicker
                v-if="examDiagnosisInternal.icdCode"
                v-model="nhifDrug"
                :icd-code="examDiagnosisInternal.icdCode"
                :protocol-type-code="protocolTypeCode"
                :is-generic="nhifDrug.isGeneric"
                :requirement-id="requirementId"
                form-type="Protocol"
                label="Код на лекарствен продукт:"
                required
                @input="onChangeNhifDrug"
            />
        </v-col>
        <v-col cols="12">
            <v-row>
                <v-col md="3" cols="6">
                    <div v-if="nhifDrug.isGeneric">
                        <v-tooltip top :disabled="!nhifDrug.internationalName">
                            <template #activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <text-field
                                        v-model="nhifDrug.internationalName"
                                        disabled
                                        label="Международно наименование"
                                    />
                                </span>
                            </template>
                            <span>{{ nhifDrug.internationalName }}</span>
                        </v-tooltip>
                    </div>
                    <div v-else>
                        <v-tooltip top :disabled="!nhifDrug.tradeName">
                            <template #activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <text-field v-model="nhifDrug.tradeName" disabled label="Търговско наименование" />
                                </span>
                            </template>
                            <span>{{ nhifDrug.tradeName }}</span>
                        </v-tooltip>
                    </div>
                </v-col>

                <v-col md="3" cols="6">
                    <text-field v-model="nhifDrug.quantity" disabled label="Количество на лек. продукт" />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model="nhifDrug.medicineFormat" disabled label="Формата на лек. продукт" />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field :value="nhifDrug.isDivisible ? 'Да' : 'Не'" disabled label="Делимо" />
                </v-col>

                <v-col md="3" cols="6">
                    <text-field
                        v-model.number="nhifDrug.singlePackage"
                        label="Количество в една опаковка"
                        disabled
                        type="number"
                    />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field
                        v-model="totalQuantity"
                        label="Общо количество за целия период"
                        disabled
                        type="number"
                    />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field
                        v-model="totalDays"
                        label="Общо брой дни за изчерпване на медикамента"
                        disabled
                        type="number"
                    />
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <v-row v-for="(dosageInstruction, index) in value.dosageInstruction" :key="index">
                <v-col cols="12">
                    <h3>Дозировка {{ index + 1 }}</h3>
                </v-col>
                <v-col>
                    <drug-calculator
                        v-model="value.dosageInstruction[index]"
                        :nhif-drug="nhifDrug"
                        is-drug-protocol
                        :calculate-dosage="calcDosage"
                        :check-strips="checkStrips"
                        :is-prescription-type5="isPrescriptionType5"
                    />
                </v-col>
                <v-col cols="auto">
                    <btn v-if="$allowWrite" icon action="Delete" @click="removeDosageInstruction(index)" />
                </v-col>
            </v-row>
            <template v-if="$allowWrite">
                <v-card-actions>
                    <btn action="CheckDocument" @click="addDosageInstruction">Добавяне на дозировка</btn>
                </v-card-actions>
            </template>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { NhifPrescriptionSegmentCode } from '@/enum/Exam/NhifPrescriptionSegmentCode';
    import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
    import { NhifProtocolDrugDto } from '@/model/Exam/DrugProtocol/NhifProtocolDrugDto';
    import { DosageInstructionDto } from '@/model/Exam/Prescription/DosageInstructionDto';
    import { PrescriptionDrugConfig } from '@/model/Exam/Prescription/PrescriptionDrugConfig';
    import { PrescriptionDiagnosisDto } from '@/model/Exam/PrescriptionDiagnosisDto';
    import { externalVisitService } from '@/service/Exam/ExternalVisitService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { arrayUtil } from '@/util/ArrayUtil';
    import DrugCalculator from '@/view/Exam/PrescriptionNhif/DrugCalculator.vue';
    import NhifDrugPicker from '@/view/Exam/PrescriptionNhif/NhifDrugPicker.vue';

    const drugConfig = {
        maxPackageCount: 999,
        maxDays: 30,
        daysInYear: 365,
        daysInMonth: 30,
        daysInWeek: 7,
        hoursPerDay: 24,
        minutesPerHour: 60,
        secondsPerMinute: 60,
        // Средна стойност на една капка в мл (20 капки = 1 мл)
        oneDropInMl: 0.05,
        oneDropOfGel: 1,
        defaultQuantityInMl: 5,
        maxDaysTakingDrug: 30,
        maxDosageChars: 100,
        checkStripsName: 'ТЕСТ ЛЕНТИ'
    };

    @Component({
        components: { NhifDrugPicker, DrugCalculator }
    })
    export default class NhifProtocolDrugComponent extends Vue {
        @Prop()
        private position!: number;

        @Prop()
        private protocolTypeCode!: string;

        @Prop()
        private value!: NhifProtocolDrugDto;

        @Prop()
        private examDiagnosis!: PrescriptionDiagnosisDto;

        @Prop({ default: NhifPrescriptionSegmentCode.A })
        private segmentCode!: NhifPrescriptionSegmentCode;

        @Prop({ default: false })
        private isDrugProtocol!: boolean;

        @Prop()
        private formType!: string;

        @Prop()
        private requirementId!: number;

        private nhifDrug: NhifProtocolDrugDto = new NhifProtocolDrugDto();
        private examDiagnosisInternal: PrescriptionDiagnosisDto = new PrescriptionDiagnosisDto();
        private checkStrips: boolean = false;
        private calcDosage: boolean = false;

        private get isPrescriptionType5() {
            return this.formType !== PrescriptionFormTypeCode.White;
        }

        private get totalQuantity() {
            const totalQuantity = this.value?.dosageInstruction?.reduce(
                (partialSum, instruction) => partialSum + instruction.packageCount,
                0
            );
            this.nhifDrug.totalQuantity = totalQuantity;
            return totalQuantity;
        }

        private get totalDays() {
            const totalDays = this.value?.dosageInstruction?.reduce(
                (partialSum, instruction) => partialSum + (instruction?.therapyDuration ?? 0),
                0
            );
            this.nhifDrug.prescribedDays = totalDays;
            return totalDays;
        }

        @Watch('examDiagnosisInternal')
        private onExamDiagnosisChanged(newExamDiagnosis: PrescriptionDiagnosisDto) {
            if (newExamDiagnosis) {
                this.$emit('exam-diagnosis-selected', {
                    examDiagnosisId: newExamDiagnosis.id,
                    position: this.position,
                    isAdd: newExamDiagnosis.isAdd
                });
            }
        }

        @Watch('examDiagnosis', { immediate: true, deep: true })
        private onExamDiagStart(val: PrescriptionDiagnosisDto) {
            if (val && val.id) {
                this.examDiagnosisInternal = val;
            }
        }

        @Watch('value', { immediate: true })
        private onValueChange(drug: NhifProtocolDrugDto | null) {
            if (!drug) {
                this.nhifDrug = new NhifProtocolDrugDto();
                this.nhifDrug.dosageInstruction.push(new DosageInstructionDto());
            } else {
                this.nhifDrug = drug;
            }
            this.setDrugType(this.nhifDrug);
        }

        private addDosageInstruction() {
            this.nhifDrug.dosageInstruction.push(new DosageInstructionDto());
            this.emitNhifDrug();
        }

        private removeDosageInstruction(index: number) {
            arrayUtil.removeAt(this.value.dosageInstruction, index);
        }

        private async onChangeNhifDrug(value: NhifProtocolDrugDto | null) {
            if (!value) {
                this.nhifDrug = new NhifProtocolDrugDto();
            } else if (value.tradeName !== '') {
                await this.checkForCoronary(value.nhifCode);
            }
            this.value.dosageInstruction = [new DosageInstructionDto()];
            this.nhifDrug.isQuantityByForm = value?.isDivisible ?? false;
            //this.isItCalculating = true;
            this.emitNhifDrug();
        }

        private async checkForCoronary(nhifCode: string) {
            if (PrescriptionDrugConfig.coronaryDrugs.indexOf(nhifCode) >= 0) {
                const { practiceId, patientId } = currentPatientCache.value.key;
                const eventExists = await externalVisitService.patientHasCoronaryEvent(practiceId, patientId);
                if (!eventExists) {
                    this.$notifier.showWarning(
                        'Внимание',
                        'Не е открит запис в регистъра на пациента за коронарен инциидент. Моля въведете в регистъра или сменете лекарството'
                    );
                }
            }
        }

        private emitNhifDrug() {
            this.nhifDrug.position = this.position;
            this.nhifDrug.dosageInstruction = this.value.dosageInstruction;
            this.setDrugType(this.nhifDrug);
            //this.calculateDaysTakingDrug(this.nhifDrug);
            this.$emit('update-nhif', {
                nhifDrug: this.nhifDrug,
                segmentCode: this.segmentCode,
                position: this.position
            });
        }

        private configureForCheckStrips() {
            this.checkStrips = true;
            this.calcDosage = false;
            this.nhifDrug.isGeneric = true;
        }

        private setDrugType(nhifDrug: NhifProtocolDrugDto) {
            const medFormat = nhifDrug.medicineFormat;
            const { internationalName } = nhifDrug;
            if (medFormat !== drugConfig.checkStripsName) {
                this.checkStrips = false;
                this.calcDosage = false;
            }
            if (medFormat === drugConfig.checkStripsName) {
                // Изключение има за Тест-лентите за глюкомери те се предписват за цяла година или за 4 месеца
                this.configureForCheckStrips();
            } else if (internationalName?.includes(PrescriptionDrugConfig.insulin)) {
                this.calcDosage = true;
            }
        }
    }
</script>
