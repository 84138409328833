<template>
    <v-menu open-on-hover bottom offset-y pointer>
        <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <btn action="Print" block>Печат за книжка</btn>
            </div>
        </template>

        <v-list>
            <v-list-item @click="printForPrescriptionBookWithoutPreview">
                <v-list-item-title class="pointer">Печат</v-list-item-title>
            </v-list-item>
            <v-list-item @click="printForPrescriptionBook">
                <v-list-item-title class="pointer">Печат - преглед</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
    import { printService } from '@/service/PrintService';

    @Component
    export default class PrintPrescriptionBook extends Vue {
        @Prop({ required: true })
        private prescriptionKey!: PrescriptionKey;

        private printForPrescriptionBook() {
            if (this.prescriptionKey?.prescriptionId) {
                this.$router.push(
                    `/Print/Exam.Prescription.PrescriptionForBook/Prescription/${this.prescriptionKey.examId}/${this.prescriptionKey.prescriptionId}`
                );
            }
        }

        private async printForPrescriptionBookWithoutPreview() {
            if (this.prescriptionKey?.prescriptionId) {
                this.$loading.show();
                try {
                    const report = 'Exam.Prescription.PrescriptionForBook';
                    const parameters: { [key: string]: string } = {};
                    parameters.examId = this.prescriptionKey.examId.toString();
                    parameters.prescriptionId = this.prescriptionKey.prescriptionId.toString();
                    await printService.printReport(report, parameters);
                } finally {
                    this.$loading.hide();
                }
            }
        }
    }
</script>

<style scoped></style>
