import { render, staticRenderFns } from "./Btn.vue?vue&type=template&id=3b0f9096&scoped=true"
import script from "./Btn.vue?vue&type=script&lang=ts"
export * from "./Btn.vue?vue&type=script&lang=ts"
import style0 from "./Btn.vue?vue&type=style&index=0&id=3b0f9096&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b0f9096",
  null
  
)

export default component.exports