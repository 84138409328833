import { NhisLongtermCareBaseCommand } from '@/model/Nhis/LongtermCare/NhisLongtermCareBaseCommand';
import { NhisLongtermCareDto } from '@/model/Nhis/LongtermCare/NhisLongtermCareDto';
import { NhisLongtermCareXmlRequestDto } from '@/model/Nhis/LongtermCare/NhisLongtermCareXmlRequestDto';
import { NhisPreventiveExamCommand } from '@/model/Nhis/LongtermCare/NhisPreventiveExamCommand';
import { NhisPreventiveExamDetailsCommand } from '@/model/Nhis/LongtermCare/NhisPreventiveExamDetailsCommand';
import { NhisPreventiveExamDetailsResultDto } from '@/model/Nhis/LongtermCare/NhisPreventiveExamDetailsResultDto';
import { NhisPreventiveExamResultDto } from '@/model/Nhis/LongtermCare/NhisPreventiveExamResultDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { nhisService } from '@/service/Nhis/NhisService';
import { signXmlService } from '@/service/Report/SignXmlService';
import { loadingState } from '@/store/LoadingState';
import { accessTokenCache, nhisKey } from '@/store/NhisNhifAccessTokenCache';

const preventiveExamSearchXml = async function preventiveExamSearchXml(
    requestData: NhisPreventiveExamCommand
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/PreventiveExamSearchXml`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const preventiveExamDetailsXml = async function preventiveExamDetailsXml(
    requestData: NhisPreventiveExamDetailsCommand
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/PreventiveExamDetailsXml`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const postXmlToNhis = async function postXmlToNhis<NhisLongtermCareDto>(
    action: string,
    command: NhisLongtermCareBaseCommand
): Promise<NhisLongtermCareDto> {
    const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
    command.accessTokenValue = tokenInfo?.accessToken ?? '';
    const response = await httpService.post<NhisLongtermCareDto>(`${nhisKey}/${action}`, command);
    return response.data;
};

const createLongtermCareXml = async function createLongtermCareXml(
    requestData: NhisLongtermCareXmlRequestDto
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/CreateLongtermCareXml`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const cancelLongtermCareXml = async function cancelLongtermCareXml(
    requestData: NhisLongtermCareXmlRequestDto
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/CancelLongtermCareXml`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const updateLongtermCareXml = async function updateLongtermCareXml(
    requestData: NhisLongtermCareXmlRequestDto
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/UpdateLongtermCareXml`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const fetchLongtermCarehXml = async function fetchLongtermCarehXml(
    requestData: NhisLongtermCareXmlRequestDto
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/FetchLongtermCarehXml`, requestData);
    console.log(response);
    return response?.data ?? '';
};

export const nhisLongtermCareService = {
    async postPreventiveExamSearch(requestData: NhisPreventiveExamCommand) {
        loadingState.show();
        try {
            const xmlString = await preventiveExamSearchXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const examResult = await nhisService.postXmlToNhis<NhisPreventiveExamResultDto>(
                    'PostNhisPreventiveExamSearchRequest',
                    signResponse.contents
                );
                console.log(examResult?.response);
                if (examResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${examResult.error}`);
                } else {
                    if (examResult?.warnings?.length > 0) {
                        nhisService.showWarnings(examResult.warnings);
                    }
                    return examResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postPreventiveExamDetails(requestData: NhisPreventiveExamDetailsCommand) {
        loadingState.show();
        try {
            const xmlString = await preventiveExamDetailsXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const examResult = await nhisService.postXmlToNhis<NhisPreventiveExamDetailsResultDto>(
                    'PostNhisPreventiveExamDetailsRequest',
                    signResponse.contents
                );
                console.log(examResult?.response);
                if (examResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${examResult.error}`);
                } else {
                    if (examResult?.warnings?.length > 0) {
                        nhisService.showWarnings(examResult.warnings);
                    }
                    return examResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postCreateLongtermCare(requestData: NhisLongtermCareXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await createLongtermCareXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                // eslint-disable-next-line require-atomic-updates
                requestData.xmlString = signResponse.contents;
                const longtermCareResult = await postXmlToNhis<NhisLongtermCareDto>(
                    'PostCreateLongtermCareRequest',
                    requestData
                );
                console.log(longtermCareResult?.response);
                if (longtermCareResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${longtermCareResult.error}`);
                } else {
                    if (longtermCareResult?.warnings?.length > 0) {
                        nhisService.showWarnings(longtermCareResult.warnings);
                    }
                    return longtermCareResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postCancelLongtermCare(requestData: NhisLongtermCareXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await cancelLongtermCareXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                // eslint-disable-next-line require-atomic-updates
                requestData.xmlString = signResponse.contents;
                const longtermCareResult = await postXmlToNhis<NhisLongtermCareDto>(
                    'PostCancelLongtermCareRequest',
                    requestData
                );
                console.log(longtermCareResult?.response);
                if (longtermCareResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${longtermCareResult.error}`);
                } else {
                    if (longtermCareResult?.warnings?.length > 0) {
                        nhisService.showWarnings(longtermCareResult.warnings);
                    }
                    return longtermCareResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postUpdateLongtermCare(requestData: NhisLongtermCareXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await updateLongtermCareXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                // eslint-disable-next-line require-atomic-updates
                requestData.xmlString = signResponse.contents;
                const longtermCareResult = await postXmlToNhis<NhisLongtermCareDto>(
                    'PostUpdateLongtermCareRequest',
                    requestData
                );
                console.log(longtermCareResult?.response);
                if (longtermCareResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${longtermCareResult.error}`);
                } else {
                    if (longtermCareResult?.warnings?.length > 0) {
                        nhisService.showWarnings(longtermCareResult.warnings);
                    }
                    return longtermCareResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postFetchLongtermCare(requestData: NhisLongtermCareXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await fetchLongtermCarehXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                // eslint-disable-next-line require-atomic-updates
                requestData.xmlString = signResponse.contents;
                const longtermCareResult = await postXmlToNhis<NhisLongtermCareDto>(
                    'PostFetchLongtermCareRequest',
                    requestData
                );
                console.log(longtermCareResult?.response);
                if (longtermCareResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${longtermCareResult.error}`);
                } else {
                    if (longtermCareResult?.warnings?.length > 0) {
                        nhisService.showWarnings(longtermCareResult.warnings);
                    }
                    return longtermCareResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    }
};
