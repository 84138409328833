<template>
    <v-card>
        <v-card-title v-if="isDoctor">Добавяне на служител, който е лекар</v-card-title>
        <v-card-title v-else>Добавяне на служител, който не е лекар</v-card-title>
        <v-card-actions>
            <btn action="Back" :to="backRoute" />
            <btn v-if="isGlobalAdminOrPracticeOwner" action="Save" @click="createEmployee" />
            <v-spacer></v-spacer>
            <v-checkbox v-model="isDoctor" dense label="Служителят е лекар"></v-checkbox>
        </v-card-actions>
        <v-card-text>
            <v-form ref="form" v-model="isFormValid">
                <template v-if="isDoctor">
                    <v-row>
                        <v-col cols="12" md="6">
                            <text-field
                                v-model="command.doctorUin"
                                :rules="[$validator.uin]"
                                required
                                label="УИН"
                                @input="searchDoctor"
                            ></text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <text-field
                                v-model="command.doctorName"
                                :disabled="Boolean(existing.doctorId)"
                                required
                                label="Име"
                            ></text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="doctorIsRegisteredInPractice" cols="12">
                            <div class="text--disabled">
                                Лекарят вече е регистриран в практиката със следните специалности
                            </div>
                            <div>
                                <v-chip v-for="de in existing.doctorEmployees" :key="de.specialtyCode">
                                    {{ de.specialtyName }}
                                </v-chip>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <dropdown
                                v-model="command.doctorEmployeeSpecialtyCode"
                                :items="freeSpecialties"
                                item-value="code"
                                :label="
                                    doctorIsRegisteredInPractice
                                        ? 'Добавяне на още една специалност'
                                        : 'Изберете специалност'
                                "
                                required
                            ></dropdown>
                        </v-col>
                    </v-row>
                </template>
                <!-- Служител, който не е лекар. -->
                <v-row v-else>
                    <v-col>
                        <text-field v-model="command.doctorName" required label="Име"></text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import { EmployeeCreateCommand } from '@/model/Employee/EmployeeCreateCommand';
    import { ExistingDoctorEmployeesDto } from '@/model/Employee/ExistingDoctorEmployeesDto';
    import { SpecialtyDto } from '@/model/Nomenclature/SpecialtyDto';
    import { employeeService } from '@/service/Employee/EmployeeService';
    import { registerDoctorService } from '@/service/Employee/RegisterDoctorService';

    import { EmployeeBase } from './EmployeeBase';

    @Component
    export default class EmployeeCreate extends EmployeeBase {
        @Prop({ required: true })
        private practiceId!: number;

        // Страницата за добавяне на нает лекар пренасочва тук, когато желаният нает лекар не присъства в персонала.
        // След създаването на лекаря и служителя, следният prop помага да се върнем към добавянето на нает лекар.
        @Prop()
        private backToDoctorEmployeeSeqNumber!: number | null;

        private command: EmployeeCreateCommand = new EmployeeCreateCommand();
        private existing: ExistingDoctorEmployeesDto = new ExistingDoctorEmployeesDto();
        private isDoctor: boolean = true;
        private freeSpecialties: SpecialtyDto[] = [];
        private isFormValid: boolean = true;

        @Ref()
        private form!: Vue & { validate: () => boolean };

        private async createEmployee() {
            this.form.validate();
            if (this.isFormValid) {
                this.$loading.show();
                try {
                    this.command.practiceId = this.practiceId;
                    if (!this.isDoctor) {
                        this.command.doctorUin = '';
                    }
                    const employee = await employeeService.createEmployee(this.command);

                    const route = this.backToDoctorEmployeeSeqNumber
                        ? `/Employee/Hired/Create/${this.practiceId}/${this.backToDoctorEmployeeSeqNumber}`
                        : employee.employeeUserCount > 0
                        ? `/Employee/Edit/${this.practiceId}/${employee.seqNumber}`
                        : `/Employee/EmployeeUser/Create/${this.practiceId}/${employee.seqNumber}`;
                    this.$router.push(route);
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private async searchDoctor() {
            const doctorUinLength = 10;
            const dentistLpkLength = 9;
            if (
                this.command.doctorUin.length === dentistLpkLength ||
                this.command.doctorUin.length === doctorUinLength
            ) {
                this.$loading.show();
                try {
                    const doctorRegistration = await registerDoctorService.getDoctorRegistration(
                        this.practiceId,
                        this.command.doctorUin
                    );
                    if (doctorRegistration) {
                        this.existing = doctorRegistration;
                        this.command.doctorName = doctorRegistration.doctorName;
                    } else {
                        this.existing = new ExistingDoctorEmployeesDto();
                    }
                    await this.loadFreeSpecialties();
                } finally {
                    this.$loading.hide();
                }
            } else {
                this.existing = new ExistingDoctorEmployeesDto();
            }
        }

        private get doctorIsRegisteredInPractice() {
            return this.existing.doctorEmployees && this.existing.doctorEmployees.length > 0;
        }

        private async loadFreeSpecialties() {
            this.freeSpecialties = await registerDoctorService.getFreeSpecialties(
                this.practiceId,
                this.existing.doctorId
            );
        }

        private mounted() {
            this.loadFreeSpecialties();
        }

        private get backRoute() {
            return `/Employee/List/${this.practiceId}`;
        }
    }
</script>
