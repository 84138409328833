export class VeteranPrescriptionBookDto {
    public practiceId: number = 0;
    public doctorEmployeeSeqNumber: number = 0;
    public patientId: number = 0;
    public id: number = 0;
    public number: string | null = null;
    public pagesCount: number = 0;
    public releaseDate: Date | null = null;
    public certificateNumber: string = '';
    public certificateDate: Date | null = null;
    public cancelationDate: Date | null = null;
    public cancelationReason: string = '';
}
