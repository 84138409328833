<template>
    <div>
        <text-field
            v-model="practiceNumber"
            :required="required"
            :rules="[$validator.practiceNumber]"
            :error-messages="showValidationErrorMessage ? validationErrorMessage : ''"
            :disabled="disabled"
            label="Номер на практика"
            @input="inputChanged"
        ></text-field>
        <practice-type-label :practice-number="practiceNumber"></practice-type-label>
        <div class="caption text--disabled">{{ districtAndMunicipalityAreaMessage }}</div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { practiceService } from '@/service/Practice/PracticeService';
    import { practiceTypeCache } from '@/store/Nomenclature/PracticeTypeCache';

    import PracticeTypeLabel from './PracticeTypeLabel.vue';

    @Component({
        components: { PracticeTypeLabel }
    })
    export default class PracticeNumber extends Vue {
        @Prop()
        value!: string;

        @Prop()
        required!: boolean;

        @Prop()
        disabled!: boolean;

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        readonly PRACTICE_NUMBER_LENGTH: number = 10;

        practiceNumber: string = '';
        lastCheckedForValidCodesCombination: string = '';
        districtAndMunicipalityAreaMessage: string = '';
        showValidationErrorMessage: boolean = false;
        validationErrorMessage: string = '';

        mounted(): void {
            this.practiceNumber = this.value;
        }

        async inputChanged(): Promise<void> {
            this.showValidationErrorMessage = false;
            await this.validateDistrictAndMunicipality();
            await this.validatePracticeType();
            this.$emit('input', this.practiceNumber);
        }

        async validatePracticeType(): Promise<void> {
            if (!this.practiceNumber) {
                return;
            }

            const practiceTypeStartPos = 4;
            const practiceTypeEndPos = 7;

            if (this.practiceNumber.length < practiceTypeEndPos) {
                return;
            }

            const code = this.practiceNumber.slice(practiceTypeStartPos, practiceTypeEndPos);
            const isValidPracticeType = await practiceTypeCache.isValidPracticeTypeByCodeAsync(code);

            if (!isValidPracticeType && !this.showValidationErrorMessage) {
                this.validationErrorMessage = 'Невалиден тип практика (цифри 5-7)';
                this.showValidationErrorMessage = true;
            }
        }

        // Твърде ограничително правило а и редовете са много защото има правило за No magic numbers
        // с което се правят излишни понкога редове а и форматирането понякога прави излишни редове
        // eslint-disable-next-line max-statements
        async validateDistrictAndMunicipality(): Promise<void> {
            const districtCodesEndPosition = 3;
            if (this.practiceNumber.length > districtCodesEndPosition) {
                const districtCodeStartPos = 0;
                const districtCodeEndPosition = 2;
                const municipalityCodeStartPos = 2;
                const municipalityCodeEndPos = 4;
                const districtCode = this.practiceNumber.slice(districtCodeStartPos, districtCodeEndPosition);
                const municipalityCode = this.practiceNumber.slice(municipalityCodeStartPos, municipalityCodeEndPos);

                // https://dev.azure.com/Hippocrates/Hippocrates/_workitems/edit/1915
                // София е изключение и затова се пропуска валидацята за код 22 (София)
                // Може би трябва и за Пловдив и Варна да се пропуска?
                const sofiaDistrictCode = '22';
                if (districtCode !== sofiaDistrictCode) {
                    if (this.lastCheckedForValidCodesCombination !== `${districtCode}${municipalityCode}`) {
                        this.lastCheckedForValidCodesCombination = `${districtCode}${municipalityCode}`;
                        const result = (
                            await practiceService.checkForValidDistrictAndMunicipalityCombination(
                                districtCode,
                                municipalityCode
                            )
                        ).data;

                        if (result.isValidCombination) {
                            this.districtAndMunicipalityAreaMessage = `Област: ${result.districtName}, Община: ${result.municipalityName}`;
                            this.validationErrorMessage = '';
                        } else {
                            this.districtAndMunicipalityAreaMessage = '';
                            this.validationErrorMessage = `Невалидна комбинация на област и община`;
                            this.showValidationErrorMessage = true;
                        }
                    }
                } else {
                    this.districtAndMunicipalityAreaMessage = '';
                    this.validationErrorMessage = '';
                }
            } else {
                this.lastCheckedForValidCodesCombination = '';
                this.districtAndMunicipalityAreaMessage = '';
            }
        }

        @Watch('value')
        private onValueChanged() {
            if (this.practiceNumber !== this.value) {
                this.practiceNumber = this.value;
            }
        }
    }
</script>
