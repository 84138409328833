<template>
    <div>
        <data-table :items="externalVisits" :headers="tableHeaders" title="История на заболяванията">
            <template #actions>
                <btn action="New" :to="`/Patient/ExternalVisit/Create/${id}`" />
            </template>
            <template #rowActions="{ item }">
                <btn
                    icon
                    action="Edit"
                    :to="`/Patient/ExternalVisit/Edit/${item.practiceId}/${item.patientId}/${item.seqNumber}`"
                />
                <btn v-if="item.visitId" icon action="Visit" :to="`/Exam/AmbulatorySheet/Edit/${item.visitId}`"
                    >Посещение</btn
                >
            </template>
        </data-table>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ExternalVisitTableDto } from '@/model/Exam/ExternalVisit/ExternalVisitTableDto';
    import { externalVisitService } from '@/service/Exam/ExternalVisitService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class PatientExternalVisit extends Vue {
        @Prop()
        public id!: number;

        private isFormValid: boolean = false;
        private externalVisits: ExternalVisitTableDto[] = [];

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Дата', value: 'date', formatter: formatters.date },
                { text: 'Вид на документа', value: 'documentType' },
                { text: 'Номер на документа', value: 'documentNumber' },
                { text: 'Лечебно заведение', value: 'practiceName' },
                { text: 'Лекар', value: 'doctorName' },
                { text: 'Специалност/Отделение', value: 'specialty' },
                { text: 'АЛ', value: 'visitNumberAndDate' }
            ];
        }

        private async getTableItems() {
            this.$loading.show();
            try {
                this.externalVisits = await externalVisitService.getExternalVisits(
                    userContextCache.currentPracticeId ?? 0,
                    this.id,
                    null
                );
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            if (this.id) {
                await this.getTableItems();
            }
        }
    }
</script>
