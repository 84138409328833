<template>
    <v-container>
        <v-card>
            <v-card-title>Амбулаторен журнал</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="9">
                        <report-date-picker v-model="command" />
                    </v-col>
                    <v-col cols="3">
                        <fund-selection v-model="command.fundCode" :is-required="false" />
                    </v-col>
                    <v-col cols="3">
                        <dropdown
                            v-model="command.documentTypeCode"
                            label="Бланка"
                            :items="journalDropdownItems"
                            item-value="code"
                            required
                        />
                    </v-col>
                    <v-col cols="3">
                        <text-field v-if="showIcdFilter" v-model="command.icdCode" label="МКБ:" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="3">
                        <btn action="Refresh" block @click="loadDocuments">Зареждане</btn>
                    </v-col>
                    <v-col cols="6" md="3">
                        <btn action="Export" block @click="exportExcel" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <btn action="Print" block @click="printEventHandler" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <data-table class="mt-2" :items="tableItems" :headers="tableHeaders" sort-by="issueDate">
            <template #rowActions="{ item }">
                <btn icon action="Edit" @click="goToPage(item)" />
            </template>
        </data-table>
    </v-container>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import FundSelection from '@/component/Picker/FundPicker.vue';
    import { JournalDocumentTypeCode } from '@/enum/VisitList/JournalDocumentTypeCode';
    import { JournalDto } from '@/model/VisitList/Journal/JournalDto';
    import { JournalFilterCommand } from '@/model/VisitList/Journal/JournalFilterCommand';
    import { journalService } from '@/service/VisitList/JournalService';
    import { loadingState } from '@/store/LoadingState';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { dateUtil } from '@/util/DateUtil';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: {
            ReportDatePicker,
            FundSelection
        }
    })
    export default class Journal extends Vue {
        private command: JournalFilterCommand = new JournalFilterCommand();
        private tableItems: JournalDto[] = [];

        private get journalDropdownItems() {
            return [
                { code: JournalDocumentTypeCode.AmbulatorySheet, name: 'Амбулаторен лист' },
                { code: JournalDocumentTypeCode.Referral3, name: 'Бланка 3 - Напр. за консултация' },
                { code: JournalDocumentTypeCode.Referral3a, name: 'Бланка 3а - Напр. за консултация и ВСД' },
                { code: JournalDocumentTypeCode.Referral4, name: 'Бланка 4 - Направление за изследвания' },
                { code: JournalDocumentTypeCode.Referral6, name: 'Бланка 6 - Талон за ЛКК' },
                { code: JournalDocumentTypeCode.Referral7, name: 'Бланка 7 - Хоспитализация' },
                { code: JournalDocumentTypeCode.Referral8, name: 'Бланка 8 - Напр. за кл./амб. процедура' },
                { code: JournalDocumentTypeCode.ReferralRequestFor, name: 'Медицинско направление/Искане за' },
                { code: JournalDocumentTypeCode.PrescriptionNhif, name: 'Бланка 5 - рецепта НЗОК за 1 мес.' },
                { code: JournalDocumentTypeCode.PrescriptionNhifTypeA, name: 'Бланка 5a - рецепта НЗОК за 3 мес.' },
                { code: JournalDocumentTypeCode.PrescriptionNhifTypeB, name: 'Бланка 5б - рецепта НЗОК военноинвалид' },
                { code: JournalDocumentTypeCode.PrescriptionNhifTypeC, name: 'Бланка 5в - рецепта НЗОК ветеран' },
                { code: JournalDocumentTypeCode.PrescriptionNhifWhite, name: 'Бяла рецепта' },
                { code: JournalDocumentTypeCode.PrescriptionNhifGreen, name: 'Зелена рецепта' },
                { code: JournalDocumentTypeCode.PrescriptionNhifYellow, name: 'Жълта рецепта' },
                { code: JournalDocumentTypeCode.SickSheet, name: 'Болничен лист' },
                { code: JournalDocumentTypeCode.StagedEpicrisis, name: 'Етапна епикриза' },
                { code: JournalDocumentTypeCode.RiskAssessmentCard, name: 'Карта за оценка на рискови фактори' },
                { code: JournalDocumentTypeCode.ReferralDispObserv, name: 'Мед. направление за дисп. набл.' },
                { code: JournalDocumentTypeCode.ReferralTelk, name: 'Направление Телк' },
                { code: JournalDocumentTypeCode.ChildHealthCard, name: 'Лична здравно-профилактична карта' }
            ];
        }

        private get tableHeaders() {
            return [
                { text: 'Фонд:', value: 'fundName' },
                { text: 'Амбулаторен лист-НРН:', value: 'ambulatorySheetNrn' },
                { text: 'Амбулаторен лист-номер:', value: 'ambulatorySheetNumber' },
                { text: 'Описание:', value: 'description' },
                { text: 'Бланка-НРН:', value: 'formNrn' },
                { text: 'Бланка-номер:', value: 'formNumber' },
                { text: 'МКБ:', value: 'icdCode' },
                { text: 'Дата:', value: 'issueDate', formatter: formatters.date },
                { text: 'Идентификатор:', value: 'patientIdentifier' },
                { text: 'Име:', value: 'patientName' },
                { text: 'Адрес:', value: 'patientAddress' },
                { text: 'Телефон:', value: 'patientPhone' },
                { text: 'Лекар:', value: 'doctorName' },
                { text: '', value: 'actionButtons' }
            ];
        }

        private get showIcdFilter() {
            const formsWithoutIcd = [
                JournalDocumentTypeCode.ReferralRequestFor,
                JournalDocumentTypeCode.RiskAssessmentCard,
                JournalDocumentTypeCode.Referral8
            ];
            const showFilter = !formsWithoutIcd.includes(this.command.documentTypeCode);
            if (!showFilter) {
                this.command.icdCode = null;
            }
            return showFilter;
        }

        private goToPage(item: JournalDto) {
            const prescriptionTypes = [
                JournalDocumentTypeCode.PrescriptionNhif,
                JournalDocumentTypeCode.PrescriptionNhifTypeA,
                JournalDocumentTypeCode.PrescriptionNhifTypeB,
                JournalDocumentTypeCode.PrescriptionNhifTypeC,
                JournalDocumentTypeCode.PrescriptionNhifWhite,
                JournalDocumentTypeCode.PrescriptionNhifGreen,
                JournalDocumentTypeCode.PrescriptionNhifYellow
            ];

            if (prescriptionTypes.includes(item.documentType ?? JournalDocumentTypeCode.AmbulatorySheet)) {
                this.$router.push(`/Exam/${item.documentType}/Edit/${item.examId}/${item.id}`);
            } else {
                this.$router.push(`/Exam/${item.documentType}/Edit/${item.id}`);
            }
        }

        private async loadDocuments() {
            if (this.command.documentTypeCode === null) {
                this.$notifier.showWarning(
                    this.$t('Изберете вид бланка').toString(),
                    this.$t('Попълнете задължителните полета').toString()
                );

                return;
            }

            loadingState.show();
            try {
                this.command.practiceId = userContextCache.currentPracticeId ?? 0;
                this.command.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
                this.tableItems = await journalService.getDocumentsByType(this.command);
            } finally {
                loadingState.hide();
            }
        }

        private async exportExcel() {
            loadingState.show();
            try {
                await journalService.exportExcel(this.command);
            } finally {
                loadingState.hide();
            }
        }

        private printEventHandler() {
            if (this.command.practiceId) {
                const parameters: { [key: string]: string | null } = {};
                parameters.practiceId = this.command.practiceId.toString();
                parameters.doctorEmployeeSeqNumber = (userContextCache.currentDoctorEmployeeSeqNumber ?? 0).toString();
                parameters.documentTypeCode = this.command.documentTypeCode;
                parameters.fundCode = this.command.fundCode;
                parameters.icdCode = this.command.icdCode;
                parameters.startDate = dateUtil.formatBgDate(this.command.startDate);
                parameters.endDate = dateUtil.formatBgDate(this.command.endDate);
                this.$router.push(
                    `/Print/VisitList.Journal/Journal/${parameters.practiceId}/${parameters.doctorEmployeeSeqNumber}/${parameters.documentTypeCode}/${parameters.fundCode}/${parameters.icdCode}/${parameters.startDate}/${parameters.endDate}`
                );
            }
        }

        private async mounted() {
            this.command.fillCurrentMonth();
            this.command.fundCode = 'Nhif';
            await this.loadDocuments();
        }
    }
</script>
