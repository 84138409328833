import { PatientKey } from '@/model/Patient/PatientKey';

import { StagedEpicrisisExamFilterTypeCode } from './StagedEpicrisisExamFilterType';

export class StagedEpicrisisBuildCommand {
    public currentExamId: number | null = null;
    public patientKey: PatientKey = new PatientKey();
    public examFilterTypeCode: StagedEpicrisisExamFilterTypeCode | null = null;
    public fromDate: Date | null = null;
    public toDate: Date | null = null;
    public examIds: number[] = [];
}
