var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('editor',{attrs:{"api-key":"ff8zr986935993ejcjoeh26pbkczd20klg8tvlxh8pmx48mz","value":_vm.value,"init":{
        height: 300,
        menubar: true,
        readonly: 1,
        plugins: [
            'advlist',
            'autolink',
            'lists',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'insertdatetime',
            'wordcount'
        ],

        toolbar:
            'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat'
    }},on:{"input":function($event){return _vm.$emit('input', ...arguments)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }