<template>
    <div>
        <v-card>
            <v-card-title>Търсене на посещения</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="3">
                        <date-picker v-model="command.fromDate" label="От дата:" @keyup.enter="search" />
                    </v-col>
                    <v-col cols="12" sm="3">
                        <date-picker v-model="command.toDate" label="До дата:" @keyup.enter="search" />
                    </v-col>
                    <v-col cols="12" sm="3">
                        <text-field v-model="command.identifier" label="Пациент идентификатор:" @keyup.enter="search" />
                    </v-col>
                    <v-col cols="12" sm="3">
                        <dropdown
                            v-model="command.nhifVisitReasonCode"
                            :items="nhifVisitReasons"
                            item-value="code"
                            label="Посещение за"
                        ></dropdown>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" lg="3">
                        <text-field
                            v-model="command.ambulatorySheetNumber"
                            label="Номер на АЛ/НРН:"
                            @keyup.enter="search"
                        />
                    </v-col>
                    <v-col cols="12" sm="6" lg="3">
                        <text-field v-model="command.icdCode" label="МКБ:" @keyup.enter="search" />
                    </v-col>
                    <v-col cols="12" sm="6" lg="3">
                        <text-field
                            v-model.number="command.referralNumber"
                            type="number"
                            label="Направление:"
                            @keyup.enter="search"
                        />
                    </v-col>
                    <v-col cols="12" sm="6" lg="3">
                        <v-checkbox
                            v-model="command.hasIncompletedRiskAssessmentCard"
                            label="С незавършени/липсващи карти за оценка на риска"
                            dense
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" sm="6" md="3" xl="2">
                        <btn action="Search" block :disabled="disableButtons" @click="search" />
                    </v-col>
                    <v-col cols="12" sm="6" md="3" xl="2">
                        <btn action="Export" block :disabled="disableButtons" @click="exportVisits" />
                    </v-col>
                    <v-col v-if="currentPatientIsLoaded" cols="12" sm="6" md="3" xl="2">
                        <btn action="New" block :disabled="disableButtons" to="/Exam/AmbulatorySheet/Create">
                            Ново посещение
                        </btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card>
            <VisitDataTable v-if="!emptyResult" :items="visits" show-patient-data :bottom-anchor="48" />
            <v-card-text v-else>Няма намерени резултати</v-card-text>
        </v-card>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator';

    import { NhifVisitReasonDto } from '@/model/Nomenclature/NhifVisitReasonDto';
    import { VisitResultDto } from '@/model/VisitList/VisitSearch/VisitResultDto';
    import { VisitSearchCommand } from '@/model/VisitList/VisitSearch/VisitSearchCommand';
    import { nhifVisitReasonService } from '@/service/Nomenclature/NhifVisitReasonService';
    import { visitSearchService } from '@/service/VisitList/VisitSearchService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { loadingState } from '@/store/LoadingState';

    import VisitDataTable from './VisitDataTable.vue';

    @Component({
        components: { VisitDataTable }
    })
    export default class VisitSearch extends Vue {
        private emptyResult: boolean = false;
        private menuFrom: boolean = false;
        private menuTo: boolean = false;
        private visits: VisitResultDto[] = [];
        private command: VisitSearchCommand = new VisitSearchCommand();
        private nhifVisitReasons: NhifVisitReasonDto[] = [];

        private get currentPatientIsLoaded() {
            return currentPatientCache.isLoaded;
        }

        private get disableButtons() {
            return loadingState.isVisible;
        }

        private async loadVisitReasons() {
            const responseVisitReasons = await nhifVisitReasonService.getNhifVisitReasons();
            this.nhifVisitReasons = responseVisitReasons.data;
        }

        //@Watch('command.ambulatorySheetNumber')
        //private onAmbulatorySheetNumberChanged() {
        //    if (this.command.ambulatorySheetNumber?.toString() === '') {
        //        this.command.ambulatorySheetNumber = null;
        //    }
        //}

        @Watch('command.referralNumber')
        private onReferralNumberChanged() {
            if (this.command.referralNumber?.toString() === '') {
                this.command.referralNumber = null;
            }
        }

        private mounted() {
            this.loadVisitReasons();
            this.command.fillThisMonth();
        }

        private async search() {
            this.$loading.show();
            try {
                this.emptyResult = false;
                this.visits = await visitSearchService.searchVisit(this.command);
                this.emptyResult = this.visits.length === 0;
            } finally {
                this.$loading.hide();
            }
        }

        private async exportVisits() {
            this.$loading.show();
            try {
                this.emptyResult = false;
                await visitSearchService.exportVisit(this.command);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
