<template>
    <v-card class="mb-md-4" :style="`background-color: ${backgroundColor}`">
        <v-card-text>
            <v-row>
                <v-col md="3" sx="6" cols="6">
                    <date-picker
                        v-model="value.issueDate"
                        label="Дата на изпъл. на рецептата"
                        required
                        :disabled="disableEdit"
                    ></date-picker>
                </v-col>

                <v-col md="3" sx="6" cols="6">
                    <text-field
                        id="prescriptionBookNumber"
                        v-model="value.prescriptionBookNumber"
                        label="№ на рецептурна книжка"
                        readonly
                        :disabled="disableEdit"
                        :required="isRequiredPrescriptionBook"
                        :rules="isRequiredPrescriptionBook ? [$validator.exactLength(8)] : []"
                    />
                </v-col>
                <v-col>
                    <text-field v-model="currentExamNrn" disabled label="Амбулаторен лист №" />
                </v-col>

                <v-col>
                    <text-field v-model.number="value.prescriptionNumber" type="number" disabled label="Рецепта №" />
                </v-col>
            </v-row>
            <alert v-if="icdWarning" type="warning"> {{ icdWarning }}</alert>
            <!-- Следват бутони, които отварят диалози. -->
            <v-row>
                <v-col cols="12">
                    <patient-prescription-drug
                        :form-type="value.formTypeCode"
                        :disabled="disableEdit"
                        @input="prescriptionChange"
                    />
                    <nhif-drug-search-dialog />
                    <!-- В случая v-model само прихваща @input. Компонентът няма value prop. -->
                    <nhif-prescription-book-check-button
                        v-model="value.prescriptionBookNumber"
                        :disabled="disableEdit"
                    />
                    <nhif-prescription-history :disabled="disableEdit" />
                </v-col>
                <v-col md="3" class="pr-0">
                    <patient-protocol-status
                        :document="value"
                        :disabled="disableEdit"
                        @input="prescriptionChange"
                        @input-drug="drugChange"
                        @reload-diagnoses="reloadDiagnoses"
                    />
                </v-col>
                <v-col md="3" class="pl-0" :disabled="false">
                    <print-prescription-book v-if="value.key.prescriptionId > 0" :prescription-key="value.key" />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PrescriptionNhifDto } from '@/model/Exam/Prescription/PrescriptionNhifDto';
    import { PrescriptionNhifTypeADto } from '@/model/Exam/Prescription/PrescriptionNhifTypeADto';
    import { PrescriptionDiagnosisDto } from '@/model/Exam/PrescriptionDiagnosisDto';
    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
    import { prescriptionService } from '@/service/Exam/PrescriptionService';
    import { prescriptionBookService } from '@/service/Nhif/PrescriptionBookService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import PatientProtocolStatus from '@/view/Exam/DrugProtocol/PatientProtocolStatus.vue';
    import PrintPrescriptionBook from '@/view/Exam/PrescriptionNhif/PrintPrescriptionBook.vue';
    import NhifPrescriptionBookCheckButton from '@/view/Nhif/NhifPrescriptionBookCheckButton.vue';
    import NhifPrescriptionHistory from '@/view/Nhif/NhifPrescriptionHistory.vue';
    import NhifDrugSearchDialog from '@/view/Nomenclature/NhifDrugSearchDialog.vue';
    import PatientPrescriptionDrug from '@/view/Query/Patient/PatientPrescriptionDrug.vue';

    @Component({
        components: {
            PrintPrescriptionBook,
            PatientPrescriptionDrug,
            PatientProtocolStatus,
            NhifDrugSearchDialog,
            NhifPrescriptionBookCheckButton,
            NhifPrescriptionHistory
        }
    })
    export default class PrescriptionInfo extends Vue {
        @Prop()
        private value!: PrescriptionNhifDto | PrescriptionNhifTypeADto;

        @Prop()
        private examDiagnoses!: PrescriptionDiagnosisDto[];

        @Prop()
        private disableEdit!: boolean;

        @Prop({ default: 'transparent' })
        private backgroundColor!: string;

        private patientActiveIcds: string[] = [];
        private requiredIcdTypes: string[] = [];

        public getPrescriptionDiagnosis(
            examDiagnosisId: number | null,
            isAdd: boolean
        ): PrescriptionDiagnosisDto | null {
            return (
                this.examDiagnoses.find(
                    (examDiagnosis) => examDiagnosis.id === examDiagnosisId && examDiagnosis.isAdd === isAdd
                ) ?? null
            );
        }

        private reloadDiagnoses() {
            this.$emit('reload-diagnoses');
        }

        private get currentVisitPatientId() {
            return currentVisitCache.value?.patientVersionKey.patientId;
        }

        private get currentExamNrn() {
            return currentVisitCache.value?.exam?.nrn;
        }

        private prescriptionChange(item: PrescriptionNhifDto | PrescriptionNhifTypeADto) {
            item.key = this.value.key;
            item.prescriptionNumber = this.value.prescriptionNumber;
            this.$emit('input', item);
        }

        private drugChange(drug: NhifDrugDto) {
            const positionDrug1 = 1;
            const positionDrug2 = 2;
            const positionDrug3 = 3;

            if (drug?.position === positionDrug1 || !this.value.nhifDrug?.tradeName) {
                this.value.nhifDrug = drug;
            } else if (drug?.position === positionDrug2 || !this.value.nhifDrug2?.tradeName) {
                this.value.nhifDrug2 = drug;
            } else if (drug?.position === positionDrug3 || !this.value.nhifDrug3?.tradeName) {
                this.value.nhifDrug3 = drug;
            }
        }

        private get examDiagnosis1() {
            return this.getPrescriptionDiagnosis(this.value.examDiagnosis1Id, this.value.isDiagnosis1Add);
        }

        private get examDiagnosis2() {
            return this.getPrescriptionDiagnosis(this.value.examDiagnosis2Id, this.value.isDiagnosis2Add);
        }

        private get examDiagnosis3() {
            return this.getPrescriptionDiagnosis(this.value.examDiagnosis3Id, this.value.isDiagnosis3Add);
        }

        private get isRequiredPrescriptionBook(): boolean {
            if (this.requiredIcdTypes.length > 0) {
                if (
                    this.examDiagnosis1?.icdCode &&
                    this.requiredIcdTypes.some((icdCode) => icdCode === this.examDiagnosis1?.icdCode)
                ) {
                    return true;
                } else if (
                    this.examDiagnosis2?.icdCode &&
                    this.requiredIcdTypes.some((icdCode) => icdCode === this.examDiagnosis2?.icdCode)
                ) {
                    return true;
                } else if (
                    this.examDiagnosis3?.icdCode &&
                    this.requiredIcdTypes.some((icdCode) => icdCode === this.examDiagnosis3?.icdCode)
                ) {
                    return true;
                }
            }
            return false;
        }

        private get icdWarning() {
            let message = '';
            if (this.isRequiredPrescriptionBook) {
                if (this.examDiagnosis1 && !this.patientActiveIcds?.includes(this.examDiagnosis1.icdCode)) {
                    message += `Лекарство 1: МКБ ${this.examDiagnosis1.icdCode} липсва в рецептурната книжка на пациента! Моля добавете го преди изпращане на рецептата! \r\n`;
                }
                if (this.examDiagnosis2 && !this.patientActiveIcds?.includes(this.examDiagnosis2.icdCode)) {
                    message += `Лекарство 2: МКБ ${this.examDiagnosis2.icdCode} липсва в рецептурната книжка на пациента! Моля добавете го преди изпращане на рецептата! \r\n`;
                }
                if (this.examDiagnosis3 && !this.patientActiveIcds?.includes(this.examDiagnosis3.icdCode)) {
                    message += `Лекарство 3: МКБ ${this.examDiagnosis3.icdCode} липсва в рецептурната книжка на пациента! Моля добавете го преди изпращане на рецептата! `;
                }
            }

            return message;
        }

        private async loadActiveIcdsForPatient() {
            this.patientActiveIcds = await prescriptionBookService.getActiveDiagnosesForPatientAsync(
                currentPatientCache.value.key.patientId,
                this.value.issueDate ?? new Date()
            );
        }

        private async getRequiredTypes() {
            this.requiredIcdTypes = await prescriptionService.getRequiredIcdPrescriptionBook();
        }

        private async mounted() {
            await this.loadActiveIcdsForPatient();
            await this.getRequiredTypes();
        }
    }
</script>
