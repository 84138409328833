<template>
    <v-card>
        <v-card-title>Данни за е-медицинско за шофьор</v-card-title>
        <v-card-text>
            <v-row v-if="value" no-gutters>
                <v-col md="3" class="px-1">
                    <text-field v-model="value.nrn" :class="nrnBackground" label="НРН номер" readonly></text-field>
                </v-col>
                <v-col md="3" class="px-1">
                    <text-field v-model="value.statusName" label="Статус" readonly></text-field>
                </v-col>
                <v-col md="3" class="px-1">
                    <date-field v-model="value.statusDate" label="Дата на изпращане" disabled />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-row dense>
                <v-col v-if="canOpen" md="3">
                    <btn action="Send" block :disabled="disable || isLoading" @click="postOpenCertificateDrive()" />
                </v-col>
                <v-col v-if="canClose" md="3">
                    <btn action="Close" block :disabled="disable || isLoading" @click="postCloseCertificateDrive()" />
                </v-col>
                <v-col v-if="!id" md="3">
                    <btn action="Refresh" block :disabled="disable || isLoading" @click="dialogIsVisible = true">
                        Извличане на удостоверения
                    </btn>
                </v-col>
            </v-row>
        </v-card-actions>
        <nhis-certificate-drive-extraction
            :exam-id="examId"
            :extraction-dialog-is-visible="dialogIsVisible"
            @close="dialogIsVisible = false"
        />
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhisCertificateStatusCode } from '@/enum/Nhis/NhisCertificateStatusCode';
    import { NhisCertificateDriveDto } from '@/model/Nhis/CertificateDrive/NhisCertificateDriveDto';
    import { NhisXmlRequestDto } from '@/model/Nhis/NhisXmlRequestDto';
    import { nhisCertificateDriveService } from '@/service/Nhis/NhisCertificateDriveService';
    import { nhisService } from '@/service/Nhis/NhisService';
    import NhisCertificateDriveExtraction from '@/view/Nhis/CertificateDrive/NhisCertificateDriveExtraction.vue';

    @Component({
        components: { NhisCertificateDriveExtraction }
    })
    export default class NhisCertificateDrive extends Vue {
        @Prop()
        value!: NhisCertificateDriveDto;

        @Prop()
        private disable!: boolean;

        @Prop()
        private id!: number;

        @Prop()
        private examId!: number;

        private dialogIsVisible: boolean = false;

        private get isLoading() {
            return this.$loading.isVisible;
        }

        private get canOpen() {
            return this.id > 0 && !this.value?.statusCode;
        }

        private get canClose() {
            return this.id > 0 && this.value?.statusCode === NhisCertificateStatusCode.Open;
        }

        private get nrnBackground() {
            return this.value.statusCode === NhisCertificateStatusCode.Open
                ? 'text-field-info'
                : this.value.statusCode === NhisCertificateStatusCode.Closed
                ? 'text-field-success'
                : this.value.statusCode === NhisCertificateStatusCode.Canceled
                ? 'text-field-error'
                : '';
        }

        private change(data: NhisCertificateDriveDto) {
            this.$emit('input', data);
        }

        private requestData(): NhisXmlRequestDto {
            return nhisService.requestData(this.id, null);
        }

        private async postOpenCertificateDrive() {
            const response = await nhisCertificateDriveService.postOpenCertificateDrive(this.requestData());
            if (response) {
                this.change(response);
            }
        }

        private async postCloseCertificateDrive() {
            const response = await nhisCertificateDriveService.postCloseCertificateDrive(this.requestData());
            if (response) {
                this.change(response);
            }
        }
    }
</script>

<style scoped></style>
