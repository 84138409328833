<template>
    <div>
        <data-table
            v-if="$allowWrite && dispensaryExternals.length > 0"
            title="Диспансеризации при друг"
            :headers="dispensaryExternalHeaders"
            :items="dispensaryExternals"
        >
            <template #actions>
                <btn
                    v-if="$allowWrite"
                    action="New"
                    :to="`/Patient/DispensaryExternal/Create/${practiceId}/${patientId}`"
                >
                </btn>
            </template>
            <template #rowActions="{ item }">
                <btn
                    v-if="$allowWrite"
                    icon
                    action="Edit"
                    :to="`/Patient/DispensaryExternal/Edit/${practiceId}/${patientId}/${item.seqNumber}`"
                />
            </template>
        </data-table>

        <data-table
            title="Диспансеризации на пациента"
            :headers="headers"
            :items="dispensaryRegisters"
            :item-class="itemRowBackground"
        >
            <template #actions>
                <!-- Насочва към добавяне на диспансеризация по служител, не по пациент. Пациентът се избира в последствие или се взима от кеша? -->
                <btn
                    v-if="$allowWrite"
                    action="New"
                    :to="`/Register/DispensaryRegister/Create/${practiceId}/${currentDoctorEmployeeSeqNumber}`"
                >
                </btn>
                <!-- Когато няма дисп. при друг, таблицата не се вижда, а бутонът за добавяне на дисп. при друг се показва тук. -->
                <btn
                    v-if="$allowWrite && dispensaryExternals.length <= 0"
                    action="New"
                    :to="`/Patient/DispensaryExternal/Create/${practiceId}/${patientId}`"
                >
                    Дисп. при друг
                </btn>
            </template>
            <template #rowActions="{ item }">
                <btn
                    v-if="$allowWrite && item.doctorEmployeeSeqNumber === currentDoctorEmployeeSeqNumber"
                    icon
                    action="Edit"
                    :to="`/Register/DispensaryRegister/Edit/${practiceId}/${patientId}/${item.key.seqNumber}`"
                />
            </template>
        </data-table>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { DispensaryExternalRowDto } from '@/model/Patient/Dispensary/DispensaryExternalRowDto';
    import { PatientKey } from '@/model/Patient/PatientKey';
    import { DispensaryRegisterDto } from '@/model/Register/Dispensary/DispensaryRegisterDto';
    import { dispensaryExternalService } from '@/service/Register/DispensaryExternalService';
    import { dispensaryRegisterService } from '@/service/Register/DispensaryRegisterService';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class DispensaryRegister extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private patientId!: number;

        private dispensaryRegisters: DispensaryRegisterDto[] = [];
        private dispensaryExternals: DispensaryExternalRowDto[] = [];

        private dispensaryExternalHeaders: IDataTableHeader[] = [
            { text: 'Име на лекар', value: 'doctorName' },
            { text: 'МКБ код', value: 'icdCode' },
            { text: 'Дата на регистрация', value: 'registrationDate', formatter: formatters.date },
            { text: 'Дата на отписване', value: 'deregistrationDate', formatter: formatters.date }
        ];

        private headers: IDataTableHeader[] = [
            { text: 'Регистрация', value: 'registerDate', filterable: false, formatter: formatters.date },
            { text: 'Досие', value: 'dossierNumber' },
            { text: 'МКБ', value: 'icdCode' },
            { text: 'МКБ 2', value: 'icdCodeAdd' },
            { text: 'Възраст', value: 'ageBracket' },
            { text: 'Отписване', value: 'deregisterDate', filterable: false, formatter: formatters.date },
            { text: 'Предложения за предстоящи прегледи', value: 'suggestedFutureExams', filterable: false },
            { text: '', value: 'actionButtons', sortable: false }
        ];

        // Служител за линка в бутона "Добавяне" и за скриване на бутона "Редактиране" за чужди диспансеризации.
        private get currentDoctorEmployeeSeqNumber() {
            return userContextCache.currentDoctorEmployeeSeqNumber;
        }

        private async mounted() {
            this.$loading.show();
            try {
                const patientKey = new PatientKey();
                patientKey.practiceId = this.practiceId;
                patientKey.patientId = this.patientId;

                this.dispensaryRegisters = await dispensaryRegisterService.getByPatient(
                    patientKey,
                    userContextCache.currentDoctorEmployeeSeqNumber ?? 0
                );
                this.dispensaryExternals = await dispensaryExternalService.getDispensaryExternals(patientKey);
            } finally {
                this.$loading.hide();
            }
        }

        private itemRowBackground(item: DispensaryRegisterDto): string {
            if (item.deregisterDate !== null) {
                return 'style-deregistered';
            }
            return 'style-main';
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../asset/Css/Register.css';
    /*По някаква причина долният импорт на по-новия формат css не работи. Трябва да търсим причината.*/
    /*@import '../../asset/Css/Register.scss'; */
</style>
