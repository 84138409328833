export class EmployeeUserDto {
    public userId: number = 0;
    public userIsPending: boolean = false;
    // Потребителското име обикновено е e-mail адрес, но това не е задължително.
    public userName: string = '';
    public personName: string = '';

    public get personAndUserName() {
        return (
            (this.personName ? `${this.personName} (${this.userName})` : this.userName ?? '') +
            (this.userIsPending ? ` - очаква одобрение` : '')
        );
    }
}
