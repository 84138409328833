import { plainToInstance } from 'class-transformer';

import { StagedEpicrisisBodyDto } from '@/model/Exam/StagedEpicrisis/StagedEpicrisisBodyDto';
import { StagedEpicrisisBuildCommand } from '@/model/Exam/StagedEpicrisis/StagedEpicrisisBuildCommand';
import { StagedEpicrisisEditDto } from '@/model/Exam/StagedEpicrisis/StagedEpicrisisEditDto';
import { StagedEpicrisisExamDto } from '@/model/Exam/StagedEpicrisis/StagedEpicrisisExamDto';
import { StagedEpicrisisExamSearchModel } from '@/model/Exam/StagedEpicrisis/StagedEpicrisisExamSearchModel';
import { StagedEpicrisisViewDto } from '@/model/Exam/StagedEpicrisis/StagedEpicrisisViewDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { currentPatientCache } from '@/store/CurrentPatientCache';
import { currentVisitCache } from '@/store/CurrentVisitCache';
import { userContextCache } from '@/store/User/UserContextCache';

export const stagedEpicrisisService = {
    async getExamsForCurrentPatient(): Promise<StagedEpicrisisExamDto[]> {
        const response = await httpService.get<StagedEpicrisisExamDto[]>('StagedEpicrisis/GetExamsForPatient', {
            params: currentPatientCache.value.key
        });
        return response.data;
    },

    async buildStagedEpicrisisBody(filter: StagedEpicrisisExamSearchModel): Promise<StagedEpicrisisBodyDto> {
        const command = new StagedEpicrisisBuildCommand();
        command.currentExamId = currentVisitCache.value.exam ? currentVisitCache.value.id : null;
        command.patientKey = currentPatientCache.value.key;
        command.examFilterTypeCode = filter.examFilterTypeCode;
        command.fromDate = filter.fromDate;
        command.toDate = filter.toDate;
        command.examIds = filter.selectedExams.map((id) => id.examId);
        const response = await httpService.post<StagedEpicrisisBodyDto>(
            'StagedEpicrisis/BuildStagedEpicrisisBody/',
            command
        );
        return response.data;
    },

    async getStagedEpicrisesForVisit(visitId: number): Promise<StagedEpicrisisViewDto[]> {
        const response = await httpService.get<StagedEpicrisisViewDto[]>(`StagedEpicrisis/GetStagedEpicrisesForVisit`, {
            params: { visitId }
        });
        return plainToInstance(StagedEpicrisisViewDto, response.data);
    },

    async stagedEpicrisisIsPrimaryDocument(visitId: number): Promise<boolean> {
        const response = await httpService.get<boolean>('StagedEpicrisis/StagedEpicrisisIsPrimaryDocument', {
            params: { visitId }
        });
        return response.data;
    },

    async getStagedEpicrisisById(id: number): Promise<StagedEpicrisisEditDto> {
        const response = await httpService.get<StagedEpicrisisEditDto>(`StagedEpicrisis/GetStagedEpicrisisById/${id}`);
        return plainToInstance(StagedEpicrisisEditDto, response.data);
    },

    // Връща новото id на етапна епикриза.
    async createStagedEpicrisisVisit(dto: StagedEpicrisisBodyDto): Promise<number> {
        const doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber;
        if (!doctorEmployeeSeqNumber) {
            throw Error('Не е избрана месторабота.');
        }

        const response = await httpService.post<number>('StagedEpicrisis/CreateStagedEpicrisisVisit', dto, {
            params: {
                patientId: currentPatientCache.value.key.patientId,
                doctorEmployeeSeqNumber
            }
        });
        return response.data;
    },

    async createStagedEpicrisis(visitId: number, dto: StagedEpicrisisBodyDto): Promise<number> {
        const response = await httpService.post<number>('StagedEpicrisis/CreateStagedEpicrisis', dto, {
            params: { visitId }
        });
        return response.data;
    },

    async updateStagedEpicrisis(id: number, dto: StagedEpicrisisBodyDto): Promise<void> {
        await httpService.post<StagedEpicrisisEditDto>(`StagedEpicrisis/UpdateStagedEpicrisis/${id}`, dto);
    },

    // Връща дали посещението е било изтрито заедно с етапната епикриза.
    async deleteStagedEpicrisis(id: number): Promise<boolean> {
        return (await httpService.delete<boolean>(`/StagedEpicrisis/DeleteStagedEpicrisis/${id}`)).data;
    }
};
