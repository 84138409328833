<template>
    <ExamLayout
        :can-delete="document.id > 0 && !disableEdit"
        :can-print="document.id > 0"
        :can-save="!disableEdit"
        title="Направление за хоспитализация - Бланка 7"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <nhis-referral
            v-if="showNhisReferral"
            v-model="document.nhisReferral"
            :referral-id="document.id"
            :form-type="'Referral7'"
            @input="copyObject"
        />
        <v-card :disabled="disableEdit">
            <main-title>Направление за хоспитализация - Бланка 7</main-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6"><PatientInfo /></v-col>
                    <v-col cols="12" md="6"><DoctorInfo /></v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <text-field v-model="document.number" label="Номер:" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <date-picker v-model="document.issueDate" label="Дата:" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col><v-checkbox v-model="document.isEmergency" dense label="Спешен"></v-checkbox></v-col>
                    <v-col cols="12" md="4">
                        <dropdown
                            v-model="document.clinicalPathwayCode"
                            :items="clinicalAlgorithms"
                            item-value="code"
                            item-text="nhifCodeAndName"
                            clearable
                            label="КП:"
                        ></dropdown>
                    </v-col>
                    <v-col><v-checkbox v-model="showAllAlgorithms" dense label="Покажи всички"></v-checkbox></v-col>
                    <v-col cols="12" md="4" ml="4">
                        <dropdown
                            v-model="document.ambulatoryProcedureCode"
                            :items="algorithms.ambulatoryProcedures"
                            item-value="code"
                            item-text="nhifCodeAndName"
                            clearable
                            label="АПр:"
                        ></dropdown>
                    </v-col>
                </v-row>
                <ExamDiagnosisPicker v-model="examDiagnosis1" required />
                <ExamDiagnosisPicker v-model="examDiagnosis2" />
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import DoctorInfo from '@/component/Exam/DoctorInfo.vue';
    import ExamDiagnosisPicker from '@/component/Exam/ExamDiagnosisPicker.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { AlgorithmFormTypeCode } from '@/enum/Exam/AlgorithmFormTypeCode';
    import { ReferralTypeCode } from '@/enum/Exam/ReferralTypeCode';
    import { Referral7Dto } from '@/model/Exam/Referral/Referral7Dto';
    import { ReferralAlgorithmListsModel } from '@/model/Exam/Referral/ReferralAlgorithmListsModel';
    import { AlgorithmDto } from '@/model/Nomenclature/AlgorithmDto';
    import { referral7Service } from '@/service/Exam/Referral/Referral7Service';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { algorithmService } from '@/service/Nomenclature/AlgorithmService';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import NhisReferral from '@/view/Exam/Referral/NhisReferral.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: {
            NhisReferral,
            UnsavedChanges,
            ExamDiagnosisPicker,
            PatientInfo,
            DoctorInfo,
            ExamLayout
        },
        async beforeRouteLeave(to, from, next) {
            if (this?.$data?.leaveDialogRef) {
                await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
            } else {
                next();
            }
        }
    })
    export default class Referral7 extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private documentId!: number;

        @Prop()
        private examId!: number;

        private document: Referral7Dto = new Referral7Dto();
        private initialValue: Referral7Dto = new Referral7Dto();
        private algorithms: ReferralAlgorithmListsModel = new ReferralAlgorithmListsModel();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();
        private showAllAlgorithms: boolean = false;

        private get showNhisReferral() {
            return this.document.id > 0 && this.document.nhisReferral;
        }

        private get disableEdit() {
            return this.document?.nhisReferral?.nrn?.length > 0;
        }

        private get examDiagnosis1() {
            return currentVisitCache.value.exam?.getExamDiagnosis(this.document.diagnosis1Id);
        }

        private set examDiagnosis1(examDiagnosis) {
            this.document.diagnosis1Id = examDiagnosis ? examDiagnosis.id : null;
        }

        private get examDiagnosis2() {
            return currentVisitCache.value.exam?.getExamDiagnosis(this.document.diagnosis2Id);
        }

        private set examDiagnosis2(examDiagnosis) {
            this.document.diagnosis2Id = examDiagnosis ? examDiagnosis.id : null;
        }

        private get clinicalAlgorithms() {
            return this.showAllAlgorithms || this.disableEdit
                ? this.algorithms.clinicalAlgorithms
                : this.algorithms.clinicalAlgorithms.filter((alg) => this.diagnosesSupportAlgorithm(alg));
        }

        private diagnosesSupportAlgorithm(alg: AlgorithmDto) {
            return alg.icdCodes.some(
                (icdCode) => icdCode === this.examDiagnosis1?.icdCode || icdCode === this.examDiagnosis2?.icdCode
            );
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                this.algorithms = await algorithmService.getActiveNhifAlgorithmsByFormType(
                    AlgorithmFormTypeCode.Referral7
                );

                if (!this.documentId) {
                    const responseExam = await referral7Service.getReferral7InfoByExamId(this.examId);
                    this.document = responseExam.data;
                } else {
                    const responseReferral = await referral7Service.getReferral7(this.documentId);
                    this.document = responseReferral.data;
                    await currentVisitCache.load(this.document.examId);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                if (this.examId && (await referral7Service.haveExistingReferral7(this.examId)).data) {
                    this.$notifier.showError(
                        'Грешка',
                        'Към АЛ е разрешено издаването само на 1 бланка Направление за хоспитализация!'
                    );
                } else {
                    let documentId = this.document.id;
                    const title = `№ ${this.document.number}; ${
                        this.document.clinicalPathwayCode !== null
                            ? `КП: ${
                                  this.algorithms.clinicalAlgorithms.find(
                                      (alg) => alg.code === this.document.clinicalPathwayCode
                                  )?.code
                              }`
                            : this.document.ambulatoryProcedureCode !== null
                            ? `АП: ${
                                  this.algorithms.ambulatoryProcedures.find(
                                      (alg) => alg.code === this.document.ambulatoryProcedureCode
                                  )?.code
                              }`
                            : ''
                    }`;

                    if (documentId === 0) {
                        const refResponse = await referral7Service.createReferral7(this.document);
                        documentId = Number(refResponse.data);
                        this.copyObject();
                        eventBus.$emit('create-referral-event', documentId, ReferralTypeCode.Referral7, title);
                        this.$router.push(`/Exam/Referral7/Edit/${documentId}`);
                    } else {
                        await referral7Service.updateReferral7(this.document);
                        eventBus.$emit('update-referral-event', title);
                        this.copyObject();
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            const documentId = this.document.id;
            await referral7Service.deleteReferral7(documentId);
            this.copyObject();
            this.$router.push(`/Exam/Referral7/Create/${currentVisitCache.value.id}`);
            eventBus.$emit(
                'delete-referral-event',
                documentId,
                ReferralTypeCode.Referral7,
                'Бланка 7 - Хоспитализация'
            );
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.Referral.Referral7/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Referral.Referral7';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
