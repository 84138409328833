<template>
    <div>
        <v-card>
            <v-card-title>Частен фонд</v-card-title>
            <v-card-text v-for="(fund, index) in patientFunds" :key="index">
                <h4 v-if="!fund.isDeleted">Фонд: {{ index + 1 }}</h4>
                <v-row v-if="!fund.isDeleted" class="mt-1">
                    <v-col>
                        <v-form ref="fundForm">
                            <v-row>
                                <v-col cols="4">
                                    <dropdown
                                        v-model="fund.fundCode"
                                        :items="activeFunds"
                                        :item-value="'code'"
                                        required
                                        label="Фонд"
                                    ></dropdown>
                                </v-col>
                                <v-col>
                                    <text-field v-model="fund.cardNumber" label="Номер на карта" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <date-picker v-model="fund.validFrom" label="От дата:" />
                                </v-col>
                                <v-col cols="4">
                                    <date-picker v-model="fund.validTo" label="До дата:" />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                    <v-col cols="auto">
                        <btn icon action="Delete" @click="removeFund(index)" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <btn action="New" @click="addFund" />
            </v-card-actions>
        </v-card>
        <btn action="Save" @click="update" />
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { PatientFundDto } from '@/model/Patient/PatientFundDto';
    import { PatientFundUpdateDto } from '@/model/Patient/PatientFundUpdateDto';
    import { PracticeFundDto } from '@/model/Practice/PracticeFundDto';
    import { patientFundService } from '@/service/Patient/PatientFundService';
    import { practiceFundService } from '@/service/Practice/PracticeFundService';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component
    export default class PatientFund extends Vue {
        @Prop()
        public patientId!: number;

        private patientFunds: PatientFundDto[] = [];
        private activeFunds: PracticeFundDto[] = [];

        private addFund() {
            this.patientFunds.push(new PatientFundDto());
        }

        private removeFund(index: number) {
            if (this.patientFunds[index].id) {
                this.patientFunds[index].isDeleted = true;
            } else {
                this.patientFunds.splice(index, 1);
            }
        }

        private get fundForms() {
            return this.$refs.fundForm as [];
        }

        private async mounted() {
            this.getActiveFunds();
            const dto = await patientFundService.getPatientFund(this.patientId);
            if (dto?.patientFunds) {
                this.patientFunds = dto.patientFunds;
            }
        }

        private async getActiveFunds() {
            const response = await practiceFundService.getActiveFunds(
                userContextCache.current?.practiceId ?? 0,
                null,
                false
            );
            if (response?.data) {
                //NhisCode = 3 е за частни фондове
                this.activeFunds = response.data.filter((fund) => fund.nhisCode === '3');
            }
        }

        private async update() {
            this.$loading.show();
            try {
                if (this.beforeSave()) {
                    const dto = new PatientFundUpdateDto();
                    for (const fund of this.patientFunds) {
                        fund.patientId = this.patientId;
                        fund.practiceId = userContextCache.current?.practiceId ?? 0;
                    }
                    dto.patientFunds = this.patientFunds;
                    await patientFundService.updatePatientFund(dto);
                    this.$notifier.showSuccess('', 'Успешен запис');
                }
            } finally {
                this.$loading.hide();
            }
        }

        private validate(index: number) {
            const fund = this.patientFunds[index];
            if (!fund.isDeleted || !fund.id) {
                (this.fundForms[index] as Vue & { validate: () => boolean }).validate();
            } else {
                (this.fundForms[index] as Vue & { resetValidation: () => boolean }).resetValidation();
            }
        }

        private beforeSave() {
            const fundForValidation = this.patientFunds.filter((fund) => !fund.isDeleted);
            for (let index = 0; index < fundForValidation.length; index++) {
                this.patientFunds[index].isValid = (
                    this.fundForms[index] as Vue & { validate: () => boolean }
                ).validate();
            }
            if (
                this.patientFunds.some(
                    (fundForm) => (!fundForm.isValid && !fundForm.isDeleted) || (fundForm.isDeleted && !fundForm.id)
                )
            ) {
                return false;
            }
            return true;
        }
    }
</script>
