export class PracticeLicenseDto {
    public practiceId: number = 0;
    public seqNumber: number = 0;
    public validFrom: Date = new Date();
    public months: number = 0;
    public employeeCount: number = 0;
    public publicNotes: string = '';

    // TODO: Account for edge cases
    public get validTo(): Date {
        // TODO: Да се приложи и тук изключението "начало в последен ден от месеца = край в последен ден от месеца".
        const result = new Date(this.validFrom);
        const temp = result.getDate();
        result.setMonth(result.getMonth() + this.months);
        if (result.getDate() !== temp) {
            result.setDate(0);
        }
        result.setDate(result.getDate() - 1);
        return result;
    }
}
