<template>
    <v-form ref="form" v-model="isFormValid">
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>Нежелана реакция към Е-Имунизация</v-col>
                    <v-spacer />
                    <v-col cols="auto"><btn icon action="Close" @click="close" /></v-col>
                </v-row>
            </v-card-title>
            <v-card-text v-if="value">
                <v-row>
                    <v-col cols="3">
                        <date-picker v-model="value.startDate" label="Начална дата на проявата" required />
                    </v-col>
                    <v-col cols="3">
                        <date-picker v-model="value.endDate" label="Крайна дата(ако има такава)" />
                    </v-col>
                    <v-col cols="3">
                        <v-checkbox v-model="value.isSelfReported" label="Реакцията е забелязана от пациента" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <text-field v-model="value.nrn" label="НРН" disabled />
                    </v-col>
                    <v-col cols="3">
                        <date-field v-model="value.uploadDate" label="Изпратено на" disabled />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            v-model="value.description"
                            label="Описание"
                            class="required"
                            :rules="[$validator.required]"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions v-if="value">
                <btn v-if="!value.nrn" action="Save" type="button" @click="updateReaction" />
                <btn
                    v-if="value.key.reactionSeqNumber > 0 && !value.nrn"
                    action="Send"
                    type="button"
                    @click="postReaction"
                />
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhisImmunizationReactionDto } from '@/model/Nhis/Immunization/NhisImmunizationReactionDto';
    import { NhisPatientImmunizationKey } from '@/model/Nhis/Immunization/NhisPatientImmunizationKey';
    import { nhisImmunizationService } from '@/service/Nhis/NhisImmunizationService';

    @Component
    export default class NhisImmunizationReactionEditor extends Vue {
        @Prop({ type: Object })
        private value!: NhisImmunizationReactionDto;

        @Prop()
        immunizationKey!: NhisPatientImmunizationKey;

        private isFormValid: boolean = false;

        private async updateReaction() {
            this.$loading.show();
            try {
                (this.$refs.form as Vue & { validate: () => boolean }).validate();
                if (this.isFormValid) {
                    if (!this.value.key.reactionSeqNumber) {
                        this.value.key.immunizationSeqNumber = this.immunizationKey.immunizationSeqNumber;
                        this.value.key.patientKey = this.immunizationKey.patientKey;
                        const response = await nhisImmunizationService.createImmunizationReaction(this.value);
                        if (response?.data) {
                            this.value.key.reactionSeqNumber = response.data;
                        }
                    } else {
                        await nhisImmunizationService.updateImmunizationReaction(this.value);
                    }
                    this.$notifier.showSuccess('', `Успешен запис.`);
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async postReaction() {
            const response = await nhisImmunizationService.postReaction(this.value.key);
            if (response) {
                this.value.nrn = response.nrn;
                this.value.uploadDate = response.statusDate ?? new Date();
                this.close();
            }
        }

        private close() {
            this.$emit('change', this.value);
        }
    }
</script>
