<template>
    <left-menu-layout :menu-items="menuItems" router-name="practice" />
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import LeftMenuLayout from '@/component/Menu/LeftMenuLayout.vue';
    import { createPracticeMenuItems } from '@/model/Menu/PracticeMenuItems';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({
        components: { LeftMenuLayout }
    })
    export default class PracticeMenu extends Vue {
        @Prop()
        private practiceId!: number | null;

        @Prop()
        private doctorId!: number | null;

        private get menuItems() {
            return createPracticeMenuItems();
        }

        private mounted() {
            // При зареждане на конкретно view, свързано с практика или лекар, в AllPracticeRoutes.ts
            // се оформя prop с име practiceId. Само при редактиране на лекар е doctorId.
            // Тези props се прихващат и от самото меню(тук). Така менюто превключва към адекватeн UserContext
            // и показва линкове към валидна практика или лекар без да е нужно да се пипат всички view–та.
            // Тази логика обаче трябва да се копира и във view-тата, които НЕ приемат practiceId/doctorId,
            // а приемат друг вид id, например id на договор с НЗОК, id на служител, id на лиценз и т.н.
            if (this.practiceId) {
                userContextCache.trySwitchToPractice(this.practiceId);
            } else if (this.doctorId) {
                userContextCache.trySwitchToDoctor(this.doctorId);
            }
        }
    }
</script>
