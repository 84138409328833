import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { NhifProtocolDocumentCheckDto } from '@/model/Exam/DrugProtocol/NhifProtocolDocumentCheckDto';
import { NhifProtocolDropdownDto } from '@/model/Exam/DrugProtocol/NhifProtocolDropdownDto';
import { NhifProtocolDto } from '@/model/Exam/DrugProtocol/NhifProtocolDto';
import { NhifProtocolViewDto } from '@/model/Exam/DrugProtocol/NhifProtocolViewDto';
import { PatientKey } from '@/model/Patient/PatientKey';
import { httpService } from '@/service/Infrastructure/HttpService';
import { currentPatientCache } from '@/store/CurrentPatientCache';

export const drugProtocolService = {
    async getDrugProtocol(entityId: number, expireDate: Date | null): Promise<NhifProtocolDto> {
        const response = await httpService.get(`/DrugProtocol/GetDrugProtocol/`, {
            params: {
                entityId,
                expireDate
            }
        });
        return plainToInstance(NhifProtocolDto, response.data);
    },

    async getDrugProtocolInfoByExamId(id: number): Promise<NhifProtocolDto> {
        const response = await httpService.get(`/DrugProtocol/GetInfoByExamId/${id}`);
        return plainToInstance(NhifProtocolDto, response.data);
    },

    getPreviousProtocolsForPatient(patientKey: PatientKey): Promise<AxiosResponse<NhifProtocolDropdownDto[]>> {
        return httpService.post(`/DrugProtocol/GetPreviousProtocolsForPatient`, patientKey);
    },

    async getUploadedDocumentsInfo(id: number): Promise<NhifProtocolDocumentCheckDto[]> {
        const response = await httpService.get<NhifProtocolDocumentCheckDto[]>(
            `/DrugProtocol/GetUploadedDocumentsInfo/${id}`
        );
        return plainToInstance(NhifProtocolDocumentCheckDto, response.data);
    },

    getDrugProtocolByExam(examId: number): Promise<AxiosResponse<NhifProtocolViewDto[]>> {
        return httpService.get('/DrugProtocol/GetMenuViewsByExam/', {
            params: { examId }
        });
    },

    createDrugProtocol(data: NhifProtocolDto): Promise<AxiosResponse<number>> {
        return httpService.post('/DrugProtocol/CreateDrugProtocol/', data);
    },

    updateDrugProtocol(data: NhifProtocolDto): Promise<AxiosResponse<void>> {
        return httpService.put('/DrugProtocol/UpdateDrugProtocol/', data);
    },

    deleteDrugProtocol(id: number): Promise<AxiosResponse<void>> {
        return httpService.delete(`/DrugProtocol/DeleteDrugProtocol/${id}`);
    },

    checkForMissingPatientData(dataType: string): Promise<AxiosResponse<string>> {
        const { key } = currentPatientCache.value;

        return httpService.get('/DrugProtocol/CheckForMissingPatientData/', {
            params: {
                patientVersionSeqNumber: key.seqNumber,
                patientId: key.patientId,
                practiceId: key.practiceId,
                dataType
            }
        });
    }
};
