<template>
    <v-card>
        <v-card-title v-if="$slots.title" class="pb-2">
            <slot name="title"></slot>
        </v-card-title>
        <v-card-title v-else-if="title" class="pb-2">{{ title }}</v-card-title>

        <v-card-actions v-if="$slots.actions" class="py-0 pl-4">
            <slot name="actions"></slot>
        </v-card-actions>

        <v-card-text class="pt-0">
            <text-field
                v-if="showSearchBox"
                v-model="searchString"
                append-icon="mdi-magnify"
                label="Търсене"
                hide-details
            ></text-field>
            <!--
                - disable-pagination включва и hide-default-footer, защото иначе бутоните за странициране се виждат, а не работят.
                - fixed-header скролира само данните без хедъра. Има смисъл само когато таблицата има ограничен height и се появява scroller.
                - За да разберем дали даден Prop се използва реално: Ctrl+F, Use Regular Expressions, Current project:
                  <data-table([^>]*\r\n)*?[^>]*?sort-by([^>]*\r\n)*?[^>]*?>
            -->
            <v-data-table
                v-model="selected"
                :items="items"
                :item-class="itemClass"
                :headers="headersWithActionsColumn"
                :loading="loading"
                loading-text="Зареждане на данни..."
                no-data-text="Няма записи"
                :search="searchString"
                no-results-text="Няма намерени резултати"
                :footer-props="{ itemsPerPageText: 'Редове на страница', itemsPerPageAllText: 'Всички' }"
                :disable-pagination="disablePagination"
                :hide-default-footer="disablePagination"
                :height="height"
                :fixed-header="height ? true : false"
                :show-select="showSelect"
                :single-select="singleSelect"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                :show-expand="showExpand"
                :expanded.sync="expanded"
                :item-key="itemKey"
                :group-by="groupBy"
                :custom-sort="customSort"
            >
                <template
                    v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))"
                    #[`item.${header.value}`]="{ value }"
                >
                    <div :key="index">
                        <span> {{ header.formatter(value) }}</span>
                    </div>
                </template>
                <template #[`group.summary`]="{ items, group }">
                    <tr>
                        <td colspan="4">
                            <slot name="groupSummary" :items="items" :group="group"></slot>
                        </td>
                    </tr>
                </template>
                <template #[`item.${rowActionsColumnName}`]="{ item }">
                    <td class="rowActions">
                        <span class="float-right">
                            <slot name="rowActions" :item="item"></slot>
                        </span>
                    </td>
                </template>

                <template #expanded-item="{ headers, item }">
                    <td v-if="item.children && item.children.length > 0" class="col-12" :colspan="headers.length">
                        <v-data-table
                            :headers="childHeaders"
                            :items="item.children"
                            no-data-text="Няма записи"
                            disable-pagination
                            hide-default-footer
                        >
                            <template
                                v-for="(property, index) in childHeaders.filter((property) =>
                                    property.hasOwnProperty('formatter')
                                )"
                                #[`item.${property.value}`]="{ value }"
                            >
                                <div :key="index">
                                    <span> {{ property.formatter(value) }}</span>
                                </div>
                            </template></v-data-table
                        >
                    </td>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';

    @Component
    export default class DataTable extends Vue {
        @Prop()
        private value!: [];

        @Prop()
        private items!: [];

        @Prop()
        private headers!: IDataTableHeader[];

        private get rowActionsColumnName() {
            return 'rowActions';
        }

        private get headersWithActionsColumn() {
            return this.headers.find((header) => header.value === this.rowActionsColumnName)
                ? this.headers
                : [...this.headers, { text: '', value: this.rowActionsColumnName, sortable: false }];
        }

        @Prop()
        private childHeaders!: IDataTableHeader[];

        @Prop()
        private title!: string;

        @Prop()
        private loading!: boolean;

        @Prop()
        private itemClass!: string | (<T>(item: T) => string);

        @Prop()
        private showSearchBox!: boolean;

        // Включва също и v-data-table.hide-default-footer.
        @Prop()
        private disablePagination!: boolean;

        // Към 2022-12 height се използва само директно с Vuetify-ския v-data-table-и, но не и с този data-table.
        // Включва също и v-data-table.fixed-header, за да скролира само данните без хедъра.
        @Prop()
        private height!: string;

        @Prop()
        private showSelect!: boolean;

        @Prop()
        private singleSelect!: boolean;

        @Prop()
        private sortDesc!: boolean;

        @Prop({ default: '' })
        private sortBy!: string;

        @Prop()
        private showExpand!: boolean;

        @Prop()
        private itemKey!: string;

        @Prop()
        private groupBy!: string | [];

        @Prop(Function)
        customSort!: (items: []) => [] | void;

        private searchString: string = '';
        private selected: [] = [];
        private expanded: [] = [];

        @Watch('selected')
        onSelectedChange(): void {
            this.$emit('input', this.selected);
        }
    }
</script>

<style scoped>
    .rowActions {
        position: sticky;
        right: 0;
    }

    /* Dropdown-ът за брой елементи на страница е твърде висок по подразбиране - margin: 13px 0 13px 34px; */
    ::v-deep .v-data-footer__select .v-select {
        margin: 4px 0 4px 24px;
    }
</style>
