import { Type } from 'class-transformer';

import { formatters } from '@/util/Formatters';

import { PracticeFeaturesDto } from './PracticeFeaturesDto';

const pleasePayMessage = (amount: number) => ` Моля, преведете ${formatters.currency(amount)}`;

export class LicensingStatusDto {
    @Type(() => PracticeFeaturesDto)
    public practiceFeatures: PracticeFeaturesDto = new PracticeFeaturesDto();

    // Дали има текущ, валиден и платен лиценз ИЛИ текущ, валиден, но неплатен лиценз, който се ползва на вересия.
    public allowWrite: boolean = false;

    // Всички останали полета водят до предупрежданя към потребителя, но не заключват системата.

    public noLicenseForToday: boolean = false;
    public maxEmployeeCount: number = 0;
    public minAmountDueWithVat: number | null = null;
    // Дни до изтичане на последния платен лиценз - попълнва се само ако са до 30 дни.
    public fewRemainingDays: number | null = null;
    // Дни до изтичане на пробния период. Отрицателно число при изтекъл пробен период. Попълнва се само за първия лиценз на практиката.
    public remainingTrialDays: number | null = null;

    public get message() {
        if (this.noLicenseForToday) {
            return 'Няма лиценз към днешна дата. Моля, създайте лиценз.';
        }
        if (this.practiceFeatures.employeeCount > this.maxEmployeeCount) {
            const employeeWord = this.maxEmployeeCount === 1 ? 'лекар' : 'лекари';
            return `Лицензът е за ${this.maxEmployeeCount} ${employeeWord}, но в практиката има ${this.practiceFeatures.employeeCount}.`;
        }
        if (this.remainingTrialDays !== null) {
            const payMessage = this.minAmountDueWithVat !== null ? pleasePayMessage(this.minAmountDueWithVat) : null;
            return `Пробният период ${this.remainingTrialDays >= 0 ? 'изтича' : 'изтече'} ${formatters.remainingDays(
                this.remainingTrialDays
            )}.${payMessage}`;
        }
        if (this.minAmountDueWithVat) {
            return `Работите в просрочие!${pleasePayMessage(this.minAmountDueWithVat)}`;
        }
        if (this.fewRemainingDays !== null) {
            return `Лицензът изтича ${formatters.remainingDays(this.fewRemainingDays)}. Моля, подновете го.`;
        }
        return '';
    }

    public get severity() {
        if (this.noLicenseForToday || this.practiceFeatures.employeeCount > this.maxEmployeeCount) {
            return 'error';
        }
        if (this.remainingTrialDays !== null) {
            return this.remainingTrialDays >= 0 ? 'warning' : 'error';
        }
        if (this.minAmountDueWithVat) {
            return 'error';
        }
        if (this.fewRemainingDays !== null) {
            return 'warning';
        }
        return null;
    }
}
