import { Type } from 'class-transformer';

import { PatientKey } from '@/model/Patient/PatientKey';

export class NhisPatientImmunizationKey {
    @Type(() => PatientKey)
    public patientKey: PatientKey = new PatientKey();

    public immunizationSeqNumber: number = 0;
}
