<template>
    <v-card>
        <v-card-title>Данни за дългосрочна грижа</v-card-title>
        <v-card-text>
            <v-row v-if="value" dense>
                <v-col md="3">
                    <text-field v-model="value.nrn" :class="nrnBackground" label="НРН номер" readonly></text-field>
                </v-col>
                <v-col md="3">
                    <text-field v-model="value.statusName" label="Статус на грижа" readonly></text-field>
                </v-col>
            </v-row>
            <v-card-actions>
                <v-row dense>
                    <v-col v-if="canPost" md="3">
                        <btn action="Send" block :disabled="disable || isLoading" @click="postLongtermCare" />
                    </v-col>
                    <v-col v-if="canUpdateOrCancel" md="3">
                        <btn action="Send" block :disabled="disable || isLoading" @click="updateLongtermCare" />
                    </v-col>
                    <v-col v-if="canUpdateOrCancel" md="3">
                        <btn action="Delete" block :disabled="disable || isLoading" @click="cancelLongtermCare">
                            Анулиране
                        </btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhisPrescriptionStatusCode } from '@/enum/Nhis/NhisPrescriptionStatusCode';
    import { NhisLongtermCareDto } from '@/model/Nhis/LongtermCare/NhisLongtermCareDto';
    import { NhisLongtermCareXmlRequestDto } from '@/model/Nhis/LongtermCare/NhisLongtermCareXmlRequestDto';
    import { nhisLongtermCareService } from '@/service/Nhis/NhisLongtermCareService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { loadingState } from '@/store/LoadingState';
    import { userContextCache } from '@/store/User/UserContextCache';

    @Component({})
    export default class NhisLongtermCare extends Vue {
        @Prop()
        private value!: NhisLongtermCareDto;

        @Prop()
        private disable!: boolean;

        @Prop()
        private registerType!: string;

        @Prop()
        registerId!: number;

        @Prop()
        registerEntitySeqNumber!: number;

        private get isLoading() {
            return loadingState.isVisible;
        }

        private get canPost() {
            return !this.value.nrn && !this.value.statusCode;
        }

        private get canUpdateOrCancel() {
            return this.value.nrn && this.value.statusCode !== NhisPrescriptionStatusCode.Canceled;
        }

        private change(data: NhisLongtermCareDto) {
            this.$emit('input', data);
        }

        private async postLongtermCare() {
            const response = await nhisLongtermCareService.postCreateLongtermCare(this.createCommand());
            if (response) {
                this.change(response);
            }
        }

        private async updateLongtermCare() {
            const response = await nhisLongtermCareService.postUpdateLongtermCare(this.createCommand());
            if (response) {
                this.change(response);
            }
        }

        private async cancelLongtermCare() {
            const response = await nhisLongtermCareService.postCancelLongtermCare(this.createCommand());
            if (response) {
                this.change(response);
            }
        }

        private get nrnBackground() {
            return this.value.statusCode === NhisPrescriptionStatusCode.Active ||
                this.value.statusCode === NhisPrescriptionStatusCode.PartiallyComplete ||
                this.value.statusCode === NhisPrescriptionStatusCode.Complete
                ? 'text-field-success'
                : this.value.statusCode === NhisPrescriptionStatusCode.Canceled ||
                  this.value.statusCode === NhisPrescriptionStatusCode.PartiallyCanceled ||
                  this.value.statusCode === NhisPrescriptionStatusCode.Rejected ||
                  this.value.statusCode === NhisPrescriptionStatusCode.Expired
                ? 'text-field-error'
                : '';
        }

        private createCommand() {
            const command = new NhisLongtermCareXmlRequestDto();
            command.registerId = this.registerId;
            command.registerEntitySeqNumber = this.registerEntitySeqNumber;
            command.deputyDoctorIsHired = userContextCache.current?.deputyDoctorIsHired ?? null;
            command.nrn = this.value.nrn;
            command.deputyDoctorUin = userContextCache.current?.deputyDoctorUin ?? null;
            command.doctorUin = userContextCache.current?.doctorUin ?? '';
            command.doctorSpecialtyCode = userContextCache.current?.specialtyCode ?? null;
            command.doctorEmployeeId = userContextCache.currentDoctorEmployeeId;
            command.doctorEmployeeSeqNumber = userContextCache.current?.employeeSeqNumber ?? 0;
            command.patientId = currentPatientCache.value.key.patientId;
            command.practiceId = userContextCache.currentPracticeId ?? 0;
            command.registerType = this.registerType;

            return command;
        }
    }
</script>

<style scoped></style>
