<template>
    <div>
        <v-card>
            <v-card-title>Данни за практиката</v-card-title>
            <v-card-actions>
                <btn
                    v-if="isGlobalAdminOrPracticeOwner"
                    action="Save"
                    :disabled="$loading.isVisible"
                    @click="createUpdatePractice"
                />
            </v-card-actions>

            <v-card v-if="practice.dealerContact" class="mt-8">
                <dealer-contact v-model="practice.dealerContact"></dealer-contact>
            </v-card>

            <v-form ref="practiceEditForm" v-model="isValid" :disabled="!isGlobalAdminOrPracticeOwner">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <text-field v-model="practice.name" required label="Наименование на лечебното заведение" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4">
                            <text-field v-model="practice.eik" label="ЕИК:" />
                        </v-col>
                        <v-col cols="8">
                            <practice-number v-model="practice.practiceNumber" required></practice-number>
                        </v-col>
                        <v-col cols="6" md="4">
                            <dropdown v-model="practice.lookupRhifId" :items="rhifs" label="Изберете РЗОК" />
                        </v-col>
                        <v-col cols="6" md="4">
                            <dropdown
                                v-model="practice.rhifAreaId"
                                :items="rhifAreas"
                                label="Изберете здравен район"
                            ></dropdown>
                        </v-col>
                        <v-col cols="12" md="4">
                            <text-field v-model="practice.nhifCode" label="НЗОК №:" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <text-field v-model="practice.vatNumber" label="ДДС номер:" />
                        </v-col>
                        <v-col cols="12" md="8">
                            <dropdown
                                v-model="practice.companyTypeId"
                                :items="companyTypes"
                                label="Правна форма на фирмата/лицето"
                            ></dropdown>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Управител</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <text-field v-model="practice.managerName" label="Име" />
                        </v-col>
                        <v-col cols="6">
                            <text-field v-model="practice.managerIdentifier" label="ЕГН" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Банка</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" lg="6">
                            <text-field v-model="practice.bankInformation.name" label="Наименование" />
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <text-field v-model="practice.bankInformation.iban" label="IBAN" />
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <text-field v-model="practice.bankInformation.bic" label="BIC" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Адрес</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="4"><CityPicker v-model="practice.cityId" /></v-col>
                        <v-col cols="12" md="8"><text-field v-model="practice.address" label="Адрес" /></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="4">
                            <v-card-title class="pa-0">Географски координати</v-card-title>
                        </v-col>
                        <v-col cols="6" md="2">
                            <text-field v-model="locationLatitude" label="Ширина" />
                        </v-col>
                        <v-col cols="6" md="2">
                            <text-field v-model="locationLongitude" label="Дължина" />
                        </v-col>
                        <v-col cols="6" md="4">
                            <btn action="Location" @click="getPosition">Извличане на координати</btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-title>Настройки</v-card-title>
                <v-card-text>
                    <multi-select-dropdown
                        v-model="selectedFundCodes"
                        :items="funds"
                        item-value="code"
                        label="Здравни фондове и осигурители"
                    />
                    <v-checkbox v-model="practice.hasReception" label="Практиката работи с Регистратура"></v-checkbox>
                    <v-checkbox
                        v-if="isDevOrTest"
                        v-model="practice.isInDisadvantagedRegion"
                        label="Необлагодетелстван район (DevTest)"
                    ></v-checkbox>
                </v-card-text>
            </v-form>
        </v-card>
        <v-card v-if="practice.dealerContact" class="mt-8">
            <dealer-contact v-model="practice.dealerContact"></dealer-contact>
        </v-card>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import MultiSelectDropdown from '@/component/Dropdown/MultiSelectDropdown.vue';
    import CityPicker from '@/component/Picker/CityPicker.vue';
    import PracticeNumber from '@/component/Practice/PracticeNumber.vue';
    import { config } from '@/Config';
    import { CompanyTypeDto } from '@/model/Nomenclature/CompanyTypeDto';
    import { FundDto } from '@/model/Nomenclature/FundDto';
    import { RhifAreaDto } from '@/model/Nomenclature/RhifAreaDto';
    import { PracticeEditDto } from '@/model/Practice/PracticeEditDto';
    import { companyTypeService } from '@/service/Nomenclature/CompanyTypeService';
    import { fundService } from '@/service/Nomenclature/FundService';
    import { rhifAreaService } from '@/service/Nomenclature/RhifAreaService';
    import { practiceService } from '@/service/Practice/PracticeService';
    import { rhifCache } from '@/store/Nomenclature/RhifCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import DealerContact from '@/view/Licensing/DealerContact.vue';

    @Component({
        components: { PracticeNumber, CityPicker, MultiSelectDropdown, DealerContact }
    })
    export default class PracticeEdit extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private selectedFundCodes: string[] = [];
        private funds: FundDto[] = [];
        private isValid: boolean = false;

        private practice: PracticeEditDto = new PracticeEditDto();
        private rhifAreas: RhifAreaDto[] = [];
        private companyTypes: CompanyTypeDto[] = [];
        private locationLongitude: number | null = null;
        private locationLatitude: number | null = null;

        private get isDevOrTest() {
            return config.isDevOrTest;
        }

        private get isGlobalAdminOrPracticeOwner() {
            return userContextCache.currentIsGlobalAdminOrPracticeOwner;
        }

        private get rhifs() {
            return rhifCache.items;
        }

        private async getPractice(id: number) {
            const response = await practiceService.getPractice(id);
            this.practice = response.data;
            if (this.practice.geoLocation) {
                this.parseGeoLocation(this.practice.geoLocation);
            }
            this.selectedFundCodes = this.practice.supportedFundCodes;
        }

        private async getFunds() {
            const response = await fundService.getFunds();
            this.funds = response.data;
        }

        @Ref()
        private practiceEditForm!: { validate: () => boolean };

        private async createUpdatePractice() {
            this.practiceEditForm.validate();
            if (this.isValid) {
                this.$loading.show();
                try {
                    this.getLocationJson();
                    this.practice.id = this.practiceId;
                    this.practice.supportedFundCodes = this.selectedFundCodes;

                    const newCategoryCode = await practiceService.updatePractice(this.practice);

                    // UserContext-ите се обновяват без да се презареждат от сървъра.
                    for (const userContext of userContextCache.userContexts.filter(
                        (uc) => uc.practiceId === this.practiceId
                    )) {
                        userContext.practiceCategoryCode = newCategoryCode;
                        userContext.practiceNumber = this.practice.practiceNumber;
                        userContext.practiceName = this.practice.name;
                        userContext.hasReception = this.practice.hasReception;
                    }
                    this.$notifier.showSuccess('Данните са променени успешно.', '');
                } finally {
                    this.$loading.hide();
                }
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        private getPosition() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.locationLatitude = position.coords.latitude;
                    this.locationLongitude = position.coords.longitude;
                });
            }
        }

        private parseGeoLocation(locationJson: string) {
            const location = JSON.parse(locationJson);
            if (location) {
                this.locationLongitude = location.longitude;
                this.locationLatitude = location.latitude;
            }
        }

        private getLocationJson() {
            this.practice.geoLocation = JSON.stringify({
                longitude: this.locationLongitude,
                latitude: this.locationLatitude
            });
        }

        @Watch('practice.lookupRhifId')
        private async getRhifAreasByRhifId(rhifId: number | null) {
            if (rhifId) {
                const response = await rhifAreaService.getRhifAreasByRhifId(rhifId);
                this.rhifAreas = response.data;
            } else {
                this.rhifAreas = [];
            }
        }

        private async mounted() {
            this.$loading.show();

            this.companyTypes = await (await companyTypeService.getCompanyTypes()).data;
            if (this.practiceId) {
                await this.getPractice(this.practiceId);
            }

            await this.getFunds();

            this.$loading.hide();
        }
    }
</script>
