import { Route, RouteConfig } from 'vue-router';

export const examResultRoutes: RouteConfig[] = [
    {
        path: 'Results/:visitId',
        props: {
            exam: (route: Route) => ({ visitId: Number(route.params.visitId) })
        },
        components: {
            exam: () => import('@/view/Exam/Results/ResultsView.vue')
        }
    },
    {
        path: 'Results/Create/Visit',
        components: {
            exam: () => import('@/view/Exam/Results/ExternalVisit.vue')
        }
    },
    {
        // Create връща към списъка, защото потребителят трябва да избере с кой от двата grid-а иска да работи.
        path: 'Results/Create/:visitId',
        redirect: 'Results/:visitId'
    },
    {
        path: 'ReferralResult/:nrn/:examId',
        props: {
            exam: (route: Route) => ({ nrn: route.params.nrn, examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/ReferralResult/ReferralResult.vue')
        }
    },
    {
        path: 'ReferralResult4/:nrn/:examId',
        props: {
            exam: (route: Route) => ({ nrn: route.params.nrn, examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/ReferralResult/ReferralResult4.vue')
        }
    },
    {
        path: 'ReferralResult7/:nrn/:examId',
        props: {
            exam: (route: Route) => ({ nrn: route.params.nrn, examId: Number(route.params.examId) })
        },
        components: {
            exam: () => import('@/view/Exam/Referral/ReferralResult/ReferralResult7.vue')
        }
    },
    {
        path: 'ExternalVisit/Create/:visitId',
        props: {
            exam: (route: Route) => ({ visitId: Number(route.params.visitId) })
        },
        components: {
            exam: () => import('@/view/Exam/Results/ExternalVisit.vue')
        }
    },
    {
        path: 'ExternalVisit/Edit/:practiceId/:patientId/:seqNumber',
        props: {
            exam: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                patientId: Number(route.params.patientId),
                seqNumber: Number(route.params.seqNumber)
            })
        },
        components: {
            exam: () => import('@/view/Exam/Results/ExternalVisit.vue')
        }
    }
];
