<template>
    <!-- Вариант 1: Бутон-иконка с текст в tooltip. -->
    <v-tooltip v-if="icon" bottom>
        <template #activator="{ on, attrs }">
            <v-btn
                icon
                :block="block"
                :color="safeColor"
                :disabled="disabled"
                :to="relativeTo"
                :href="absoluteTo"
                class="action-icon-button"
                v-bind="attrs"
                v-on="on"
                @click="$emit('click', ...arguments)"
            >
                <!-- При подаден невалиден action или липса на иконка, бутонът се рендира с иконка-бъг. -->
                <v-icon v-if="actionObj && actionObj.icon">{{ actionObj.icon }}</v-icon>
                <v-icon v-else>mdi-bug-outline</v-icon>
            </v-btn>
        </template>

        <!-- При подаден невалиден action, бутонът се рендира без стил и с предупреждение. -->
        <template v-if="actionObj">
            <slot>{{ actionObj.defaultTitle }}</slot>
        </template>
        <template v-else>
            <slot></slot>
            <small>{{ invalidActionWarning }}</small>
        </template>
    </v-tooltip>

    <!-- Вариант 2: Нормален бутон с текст и иконка. -->
    <v-btn
        v-else
        :outlined="buttonsAreOutlined"
        :block="block"
        :color="safeColor"
        :disabled="disabled"
        :to="relativeTo"
        :href="absoluteTo"
        @click="$emit('click', ...arguments)"
    >
        <!-- При подаден невалиден action, бутонът се рендира без стил, с иконка-бъг и с предупреждение. -->
        <template v-if="actionObj">
            <v-icon v-if="actionObj.icon && !appendIcon">{{ actionObj.icon }}</v-icon>
            <slot>{{ actionObj.defaultTitle }}</slot>
            <v-icon v-if="actionObj.icon && appendIcon">{{ actionObj.icon }}</v-icon>
        </template>
        <template v-else>
            <slot></slot>
            <v-icon>mdi-bug-outline</v-icon>
            <small>{{ invalidActionWarning }}</small>
        </template>
    </v-btn>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ActionCode, actions } from '@/model/Common/Action';
    import { userSettingsState } from '@/store/User/UserSettingsState';

    @Component
    export default class Btn extends Vue {
        // Комбинация от цвят, иконка и заглавие по подразбиране.
        @Prop({ required: true })
        private action!: ActionCode;

        // Дали да запълва цялата налична ширина в parent елемента.
        @Prop()
        private block!: boolean;

        @Prop()
        private disabled!: boolean;

        @Prop()
        private icon!: boolean;

        @Prop()
        private to!: string;

        @Prop({ default: false })
        private appendIcon!: boolean;

        private get relativeTo() {
            return this.to && !this.isAbsoluteUrl() ? this.to : null;
        }

        private get absoluteTo() {
            return this.isAbsoluteUrl() ? this.to : null;
        }

        private isAbsoluteUrl() {
            try {
                const url = new URL(this.to);
                return url !== null;
            } catch {
                return false;
            }
        }

        private get actionObj() {
            return actions[this.action];
        }

        private get safeColor() {
            const actionObj = actions[this.action];
            return actionObj ? actionObj.color : 'error';
        }

        private get invalidActionWarning() {
            return ` невалиден action ${this.action}`;
        }

        private get buttonsAreOutlined() {
            return userSettingsState.userSettings.ui.buttonsAreOutlined;
        }
    }
</script>

<style scoped>
    /* Фонът на бутоните контрастира при hover върху ред от v-data-table. Използва се custom цвят rowButton от Vuetify темата. */
    tr:hover .action-icon-button {
        background-color: var(--v-contrast2-base);
    }
</style>
