import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { ReceptionServiceCreateCommand } from '@/model/Reception/Service/ReceptionServiceCreateCommand';
import { ReceptionVisitDto } from '@/model/Reception/Service/ReceptionVisitDto';
import { ServiceOfferDto } from '@/model/Reception/Service/ServiceOfferDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const receptionService = {
    async getPracticeServiceOffers(practiceId: number): Promise<ServiceOfferDto[]> {
        const response = await httpService.get<ServiceOfferDto[]>('Reception/GetPracticeServiceOffers', {
            params: { practiceId }
        });
        return plainToInstance(ServiceOfferDto, response.data);
    },

    async create(command: ReceptionServiceCreateCommand): Promise<void> {
        await httpService.post('Reception/Create', command);
    },

    async getReceptionVisitByPatient(patientId: number, practiceId: number): Promise<ReceptionVisitDto[]> {
        const response = await httpService.get<ReceptionVisitDto[]>('Reception/GetReceptionVisitByPatient', {
            params: { patientId, practiceId }
        });
        return plainToInstance(ReceptionVisitDto, response.data);
    },

    payOrders(orderIds: number[]): Promise<AxiosResponse<number[]>> {
        return httpService.get('Reception/PayOrder', {
            params: {
                orderIds
            }
        });
    }
};
