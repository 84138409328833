import { Type } from 'class-transformer';

import { PatientRegisterKey } from '@/model/Register/PatientRegisterKey';

export class DispensaryRuleDto {
    public id: number = 0;
    public name: string = '';
    public icdCode: string = '';
    public icdCodeAdd: string = '';
    public ageBracketName: string = '';
    public totalDuration: string = '';
    public activities: string = '';
    public totalActivitiesForYear: number = 0;
    public totalActivitiesForYearDescription: string = '';
    public validFrom: Date | null = null;
    public validTo: Date | null = null;
    public dispensaryOrdinanceText: string = '';

    @Type(() => PatientRegisterKey)
    public dispensaryRegisterKey: PatientRegisterKey = new PatientRegisterKey();
}
