import { plainToInstance } from 'class-transformer';

import { FamilyMemberDto } from '@/model/Exam/SickSheet/FamilyMemberDto';
import { GenerateGroupSickSheetCommand } from '@/model/Exam/SickSheet/GenerateGroupSickSheetCommand';
import { SickSheetDto } from '@/model/Exam/SickSheet/SickSheetDto';
import { SickSheetFilterDto } from '@/model/Exam/SickSheet/SickSheetFilterDto';
import { SickSheetMenuViewDto } from '@/model/Exam/SickSheet/SickSheetMenuViewDto';
import { SickSheetPatientReportDto } from '@/model/Exam/SickSheet/SickSheetPatientReportDto';
import { SickSheetViewDto } from '@/model/Exam/SickSheet/SickSheetViewDto';
import { NssiUploadResponseDto } from '@/model/Integration/Nssi/NssiUploadResponseDto';
import { ReasonOfDiseaseDto } from '@/model/Nomenclature/ReasonOfDiseaseDto';
import { RegimenDaysSubtypeDto } from '@/model/Nomenclature/RegimenDaysSubtypeDto';
import { SickFamilyMemberTypeDto } from '@/model/Nomenclature/SickFamilyMemberTypeDto';
import { PatientKey } from '@/model/Patient/PatientKey';
import { httpService } from '@/service/Infrastructure/HttpService';

export const sickSheetService = {
    async getReasonOfDiseaseTypes(): Promise<ReasonOfDiseaseDto[]> {
        const response = await httpService.get<ReasonOfDiseaseDto[]>('SickSheet/GetReasonOfDiseaseTypes');
        return plainToInstance(ReasonOfDiseaseDto, response.data);
    },

    async getRegimenDaysSubtypes(): Promise<RegimenDaysSubtypeDto[]> {
        const response = await httpService.get<RegimenDaysSubtypeDto[]>('SickSheet/GetRegimenDaysSubtypes');
        return plainToInstance(RegimenDaysSubtypeDto, response.data);
    },

    async getSickFamilyMemberType(): Promise<SickFamilyMemberTypeDto[]> {
        const response = await httpService.get<SickFamilyMemberTypeDto[]>('SickSheet/GetFamilyMemberTypes');
        return plainToInstance(SickFamilyMemberTypeDto, response.data);
    },

    async getSickSheetInfoByPatientAndDoctor(
        patientId: number,
        doctorEmployeeSeqNumber: number
    ): Promise<SickSheetDto> {
        const response = await httpService.get<SickSheetDto>('SickSheet/GetSickSheetInfoByPatientAndDoctor', {
            params: { patientId, doctorEmployeeSeqNumber }
        });
        return response.data;
    },

    async getSickSheetInfoByVisit(visitId: number): Promise<SickSheetDto> {
        const response = await httpService.get<SickSheetDto>('SickSheet/GetSickSheetInfoByVisit', {
            params: { visitId }
        });
        return plainToInstance(SickSheetDto, response.data);
    },

    async getSickSheetById(id: number): Promise<SickSheetDto> {
        const response = await httpService.get<SickSheetDto>(`SickSheet/GetSickSheetById/${id}`);
        return plainToInstance(SickSheetDto, response.data);
    },

    async getCopiedSickSheetById(id: number): Promise<SickSheetDto> {
        const response = await httpService.get<SickSheetDto>(`SickSheet/GetCopiedSickSheetById/${id}`);
        return plainToInstance(SickSheetDto, response.data);
    },

    async createSickSheetVisit(
        patientId: number,
        doctorEmployeeSeqNumber: number,
        data: SickSheetDto
    ): Promise<number> {
        const response = await httpService.post<number>('SickSheet/CreateSickSheetVisit', data, {
            params: { patientId, doctorEmployeeSeqNumber }
        });
        return response.data;
    },

    async createSickSheet(data: SickSheetDto): Promise<number> {
        const response = await httpService.post<number>('SickSheet/CreateSickSheet', data);
        return response.data;
    },

    async updateSickSheet(data: SickSheetDto): Promise<void> {
        await httpService.post('SickSheet/UpdateSickSheet/', data);
    },

    // Връща дали посещението е било изтрито заедно с БЛ.
    async deleteSickSheet(id: number): Promise<boolean> {
        const response = await httpService.delete<boolean>(`SickSheet/DeleteSickSheet/${id}`);
        return response.data;
    },

    async generateActiveSickSheetXml(sickSheetId: number, isFileCreate: boolean): Promise<string> {
        const response = await httpService.get<string>(`Nssi/CreateESickRequest`, {
            params: { sickSheetId, isFileCreate }
        });
        return response.data;
    },

    async generateActualCanceledXml(sickSheetId: number, isFileCreate: boolean): Promise<string> {
        const response = await httpService.get<string>(`Nssi/CreateActualCanceledById`, {
            params: { sickSheetId, isFileCreate }
        });
        return response.data;
    },

    async changeSickSheetToActualDeleteById(id: number): Promise<void> {
        await httpService.get(`SickSheet/ChangeSickSheetCanceledStatus/${id}`);
    },

    async changeSickSheetToActiveById(id: number): Promise<void> {
        await httpService.get(`SickSheet/ChangeSickSheetCanceledStatusToActive/${id}`);
    },

    async generateCanceledXml(
        updatedSickSheetId: number,
        canceledSickSheetId: number,
        isFileCreate: boolean
    ): Promise<string> {
        const response = await httpService.get<string>('Nssi/CreateCanceledESickRequest/', {
            params: { updatedSickSheetId, canceledSickSheetId, isFileCreate }
        });
        return response.data;
    },

    async updateSickSheetStatus(practiceId: number, data: NssiUploadResponseDto): Promise<string[]> {
        const response = await httpService.post<string[]>(`Nssi/UpdateSickSheetStatus`, data, {
            params: { practiceId }
        });
        return response.data;
    },

    async getSickSheetsPerPeriod(filter: SickSheetFilterDto): Promise<SickSheetViewDto[]> {
        const response = await httpService.post<SickSheetViewDto[]>('Nssi/GetSickSheetsByPeriod', filter);
        return plainToInstance(SickSheetViewDto, response.data);
    },

    async generateGroupSickSheet(data: SickSheetViewDto[]): Promise<string> {
        const response = await httpService.post<string>(
            'Nssi/GenerateGroupSickSheet',
            new GenerateGroupSickSheetCommand(data)
        );
        return response.data;
    },

    async getSickSheetCurrentStatus(id: number): Promise<string> {
        const response = await httpService.get<string>(`Nssi/GetSickSheetCurrentStatus/${id}`);
        return response.data;
    },

    async getSickSheetsForVisit(visitId: number): Promise<SickSheetMenuViewDto[]> {
        const response = await httpService.get<SickSheetMenuViewDto[]>(`SickSheet/GetSickSheetsForVisit`, {
            params: { visitId }
        });
        return plainToInstance(SickSheetMenuViewDto, response.data);
    },

    async getPatientSickSheets(patientKey: PatientKey): Promise<SickSheetPatientReportDto[]> {
        const response = await httpService.get<SickSheetPatientReportDto[]>('SickSheet/GetPatientSickSheets', {
            params: patientKey
        });
        return plainToInstance(SickSheetPatientReportDto, response.data);
    },

    async getPatientFamilyMembers(patientId: number): Promise<FamilyMemberDto[]> {
        const response = await httpService.get<FamilyMemberDto[]>(
            `SickSheet/GetPatientFamilyMembers?patientId=${patientId}`
        );
        return plainToInstance(FamilyMemberDto, response.data);
    },

    exportPatientSickSheets(patientKey: PatientKey): Promise<void> {
        return httpService.download('SickSheet/ExportPatientSickSheets', { params: patientKey });
    }
};
