import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';

enum ExamDocumentType {
    Immunization = 'Имунизации',
    EImmunization = 'Е-Имунизации',
    Referral3 = 'Бланка 3 - Напр. за консултация',
    Referral3a = 'Бланка 3а - Напр. за консултация и ВСД',
    Referral4 = 'Бланка 4 - Направление за изследвания',
    PrescriptionNhif = 'Бланка 5 - рецепта НЗОК за 1 мес.',
    PrescriptionNhifTypeA = 'Бланка 5a - рецепта НЗОК за 3 мес.',
    PrescriptionNhifTypeB = 'Бланка 5б - рецепта НЗОК военноинвалид',
    PrescriptionNhifTypeC = 'Бланка 5в - рецепта НЗОК ветеран',
    PrescriptionNhifWhite = 'Бяла рецепта',
    PrescriptionNhifGreen = 'Зелена рецепта',
    PrescriptionNhifYellow = 'Жълта рецепта',
    Referral6 = 'Бланка 6 - Талон за ЛКК',
    Referral7 = 'Бланка 7 - Хоспитализация',
    Referral8 = 'Бланка 8 - Напр. за кл./амб. процедура',
    Referral8a = 'Бланка 8а - Напр. за кл./амб. процедура',
    ReferralRequestFor = 'Медицинско направление/Искане за',
    Manipulation = 'Манипулации',
    SickSheet = 'Болничен лист',
    StagedEpicrisis = 'Етапна епикриза',
    CertificateWork = 'Медицинско за работа',
    CertificateDrive = 'Медицинско за шофьор',
    ECertificateDrive = 'Е-Медицинско за шофьор',
    CertificateMariage = 'Медицинско за встъпване в брак',
    CertificatePhysicalExam = 'Удостоверение за физикален преглед',
    MedicalNote = 'Медицинска бележка',
    ReferralDispObserv = 'Мед. направление за дисп. набл.',
    ReferralTelk = 'Напр. за ТЕЛК',
    CertificateSocialServices = 'Удостоверение',
    QuickNotice = 'Бързо известие',
    InformedConsentTreatment = 'Информирано съгласие за лечение',
    InformedConsentDiagnostics = 'Информирано съгласие за диагностика',
    PhysiotherapyCard = 'Физиопроцедурна карта',
    ConsultationTelk = 'Бланка за консултация при ТЕЛК',
    RequestForHistopathologicalTest = 'Искане за хистопатологично изследване',
    RequestForCytosmear = 'Цитонамазка',
    RequestForImagingTest = 'Искане за образно изследване',
    DrugProtocol = 'Протокол за НЗОК лекарства(хартиен)',
    NhifProtocol = 'Протокол за НЗОК лекарства',
    OfficialNotice = 'Служебна бележка',
    ChildHealthCard = 'Лична здравно-профилактична карта',
    Anthropometry = 'Антропометрия',
    FileUpload = 'Прикачени документи',
    Results = 'Резултати от лечение'
}

enum NoExamDocumentType {
    SickSheet = 'Болничен лист',
    StagedEpicrisis = 'Етапна епикриза',
    FileUpload = 'Прикачени документи',
    // Когато няма преглед, се поддържа само ръчното добавяне на документи, без резултатите от е-направление.
    Results = 'Резултати от лечение'
}

const MegaPrescriptions: Record<string, string> = {
    PrescriptionV2Nhif5: 'Бланка 5 - V2',
    PrescriptionV2Nhif5a: 'Бланка 5a - V2',
    PrescriptionV2Nhif5b: 'Бланка 5б - V2',
    PrescriptionV2Nhif5c: 'Бланка 5в - V2'
};

// Менюто е направено така че да ходи до /VisitDocumentMenu/GetExamDocumentCount
// За да се генерира и покаже елемент от зададения enum, трябва да го има като елемент в GetExamDocumentCount() (VisitDocumentMenuController)
const VisitMenuSpecialtyAdditions: Record<SpecialtyCode, Record<string, string>> = {
    Gp: {
        RiskAssessmentCard: 'Карта за оценка на рискови фактори'
    },
    Gynecology: {},
    Rehabilitation: {},
    Anesthesiology: {}
};

// За всяка специалност се задават елементи от ExamDocumentType, които да не се генерират в менюто за посещението.
// Пример: Gp: [ExamDocumentType.Referral8a, ExamDocumentType.ConsultationTelk, ExamDocumentType.NhifProtocol]
// Ако масивът за съответната специалност е празен, ще заредят всички елементи от ExamDocumentType.
const VisitMenuSpecialtyExclusions: Record<SpecialtyCode, ExamDocumentType[]> = {
    Gp: [
        ExamDocumentType.Referral8a,
        ExamDocumentType.InformedConsentTreatment,
        ExamDocumentType.InformedConsentDiagnostics,
        ExamDocumentType.PhysiotherapyCard,
        ExamDocumentType.ConsultationTelk,
        ExamDocumentType.RequestForHistopathologicalTest,
        ExamDocumentType.DrugProtocol,
        ExamDocumentType.NhifProtocol
    ],
    Gynecology: [],
    Rehabilitation: [],
    Anesthesiology: []
};

export {
    ExamDocumentType,
    MegaPrescriptions,
    NoExamDocumentType,
    VisitMenuSpecialtyAdditions,
    VisitMenuSpecialtyExclusions
};
