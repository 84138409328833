import { dateUtil } from '@/util/DateUtil';

export class VisitSearchCommand {
    public practiceId: number = 0;
    public doctorEmployeeSeqNumber: number = 0;
    public identifier: string = '';
    public ambulatorySheetNumber: string = '';
    public fromDate: Date | null = null;
    public toDate: Date | null = null;
    public icdCode: string = '';
    public referralNumber: number | null = null;
    public nhifVisitReasonCode: string = '';
    public hasIncompletedRiskAssessmentCard: boolean = false;

    public fillThisMonth(): void {
        this.fromDate = dateUtil.startOfThisMonth();
        this.toDate = dateUtil.endOfThisMonth();
    }
}
