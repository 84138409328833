export class CertificateDriveConsultationDto {
    constructor(public specialtyCode: string | null, public isNecessary: boolean) {}
    public examDate: Date | null = new Date();
    public examinationByGp: boolean = false;
    public consultationNrn: string | null = null;
    public condition: string | null = null;
    public notes: string | null = null;
    public icdCode: string | null = null;
    public icdCodeAdd: string | null = null;
}
