<template>
    <data-table class="mt-2" :items="items" :headers="tableHeaders" title="Справка за извършени имунизации">
        <template #actions>
            <v-row>
                <v-col cols="12" lg="6">
                    <report-date-picker v-model="command" :disabled="$loading.isVisible" @input="getTableItems" />
                </v-col>
                <v-col cols="12" lg="6">
                    <!-- Не се използва <immunization-picker>, защото е ориентиран към добавяне на имунизации.
                    <immunization-picker v-model="command.immunizationId" @input="getTableItems" />-->
                    <dropdown
                        v-model="command.immunizationId"
                        :items="immunizations"
                        item-text="title"
                        clearable
                        label="Избор на имунизация"
                        @input="getTableItems"
                    ></dropdown>
                </v-col>
                <v-col cols="6" md="3">
                    <btn action="Refresh" block @click="getTableItems">Зареждане</btn>
                </v-col>
                <v-col cols="6" md="3">
                    <btn action="Export" block @click="exportExcel" />
                </v-col>
            </v-row>
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { ImmunizationDto } from '@/model/Nomenclature/ImmunizationDto';
    import { ImmunizationReportCommand } from '@/model/Patient/Immunization/Report/ImmunizationReportCommand';
    import { ImmunizationReportDto } from '@/model/Patient/Immunization/Report/ImmunizationReportDto';
    import { immunizationService } from '@/service/Nomenclature/ImmunizationService';
    import { immunizationReportService } from '@/service/Query/Patient/ImmunizationReportService';
    import { loadingState } from '@/store/LoadingState';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { ReportDatePicker }
    })
    export default class ImmunizationReport extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private items: ImmunizationReportDto[] = [];
        private command: ImmunizationReportCommand = new ImmunizationReportCommand();
        private immunizations: ImmunizationDto[] = [];

        private get tableHeaders() {
            return [
                { text: 'Тип идентификатор:', value: 'pidType' },
                { text: 'Идентификатор:', value: 'identifier' },
                { text: 'Пациент:', value: 'patientName' },
                { text: 'Възраст:', value: 'age' },
                { text: 'НЗОК код:', value: 'immunizationNhifCode' },
                { text: 'РЗИ код:', value: 'immunizationRhiCode' },
                { text: 'Имунизация:', value: 'immunizationName' },
                { text: 'Дата:', value: 'occuranceDate', formatter: formatters.date },
                { text: 'АЛ номер:', value: 'ambulatorySheetNumber' },
                { text: 'Извършил имунизация', value: 'doctorName' }
            ];
        }

        private async getImmunizations() {
            const filterByNhis = false;
            this.immunizations = await immunizationService.getActiveImmunizations(filterByNhis);
        }

        private async getTableItems() {
            loadingState.show();
            try {
                this.fillDoctorEmployee();
                this.items = await immunizationReportService.getImmunizationReport(this.command);
            } finally {
                loadingState.hide();
            }
        }

        private async exportExcel() {
            loadingState.show();
            try {
                this.fillDoctorEmployee();
                await immunizationReportService.exportImmunizationReport(this.command);
            } finally {
                loadingState.hide();
            }
        }

        private fillDoctorEmployee() {
            this.command.practiceId = this.practiceId;
            this.command.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private async mounted() {
            this.command.fillCurrentMonth();
            await this.getImmunizations();
        }
    }
</script>
