// Този клас предотвратява цикличните зависимости между router, oidcClient, currentUser, userContextCache, userSettingsState и т.н.,
// като използва всички тях и оркестрира възстановяването на състоянието след refresh на browser-а или вход на потребител.

import { User } from 'oidc-client-ts';

import { router } from '@/router';
import { oidcClient, oidcRoute } from '@/service/Infrastructure/OidcClient';
import { pushNotificationService } from '@/service/User/PushNotificationService';
import { localServerCertificateCache } from '@/store/LocalServerCertificateCache';

import { currentPatientCache } from './CurrentPatientCache';
import { currentVisitCache } from './CurrentVisitCache';
import { accessTokenCache } from './NhisNhifAccessTokenCache';
import { notifications } from './Notifications';
import { currentUser } from './User/CurrentUser';
import { userContextCache } from './User/UserContextCache';
import { userSettingsState } from './User/UserSettingsState';

// Новите потребители се насочват към страницата за добавяне на месторабота.
// Ако е заредена директно тя, не пренасочва, за да не излиза грешка в конзолата 'NavigationDuplicated'.
const redirectNewUserToRegistration = () => {
    const path = '/Registration';
    if (userContextCache.userContexts.length === 0 && router.currentRoute.path !== path) {
        router.push(path);
    }
};

// След вход на потребител, user context-ите(местоработите) му винаги се презареждат от сървъра,
// а текущите понятия се нулират. Така, изход/вход може се използва за решаване на проблеми с кеша.
const onInitialSignin = async (user: User) => {
    currentUser.user = user;

    if (currentUser.isAuthenticated) {
        await userContextCache.loadFromServer();
        // Настройките и известията се зареждат асинхронно, за скорост. Темата може да се промени с леко закъснение.
        userSettingsState.loadFromServer();
        pushNotificationService.showPushNotifications();
        redirectNewUserToRegistration();
    } else {
        // Не би трябвало да е възможно, но ако access token-ът е изтекъл, не трябва да се правят обръщения към API-то.
    }

    currentPatientCache.clear();
    currentVisitCache.clear();
    accessTokenCache.clear();
    localServerCertificateCache.clear();
    notifications.loadFromLocalStorage();
};

// Обновява реактивния wrapper на текущия потребител с данните за подновената сесия.
const onSigninSilent = (user: User) => {
    currentUser.user = user;
};

// При презареждане на страницата с логнат потребител, зарежда от local storage последно използваните тема, user context, пациент, преглед, нотификации и др.
// Не прави обръщения към API-то, защото браузърът може да е бил стартиран след като access token–ът е изтекъл.
// В този слуай OidcClient.ts прави опит за подновяване на сесията, който завършва с нов access token или с изход от системата.
export const restoreState = async () => {
    oidcClient.onInitialSignin = onInitialSignin;
    oidcClient.onSigninSilent = onSigninSilent;
    oidcClient.onReturnUrlNeeded = () => router.currentRoute.fullPath;

    // В този момент router.currentRoute още не е попълнено.
    const user = await oidcClient.loadUserFromLocalStorage();
    // В този момент router.currentRoute вече е попълнено, защото готният await дава време на Vue router да се инициализира.

    // Restore-ването се пропуска ако SPA е зареден в невидим iframe чрез redirect от IdentityServer обратно към SPA.
    if (router.currentRoute.path === oidcRoute.signinSilentCallback) {
        return;
    }

    if (user) {
        // Ускорява прехода към числови id-та в спринт 63. Когато от local storage се зареди потребител с текстово id,
        // значи сесията е от стария вид, currentUser НЕ се възстановява и потребителят трябва да влезе отново.
        if (!/^\d+$/u.test(user.profile?.sub)) {
            console.log(`Невалидно id на потребител ${user.profile?.sub}. Моля, влезте отново.`);
            return;
        }

        currentUser.user = user;

        userSettingsState.loadFromLocalStorage();
        userContextCache.loadFromLocalStorage();
        redirectNewUserToRegistration();

        currentPatientCache.loadFromLocalStorage();
        currentVisitCache.loadFromLocalStorage();
        notifications.loadFromLocalStorage();
        localServerCertificateCache.loadFromLocalStorage();

        // TODO: Преди да е станало ясно дали сесията е подновена, не трябва да се правят обръщения към API-то.
        // За по-лесно, цялото mount-ване на SPA се отлага (не само обръщенията към API-то), но така страницата стои празна дълго време.
        // Изчакването да се премести в httpService, но след като се маркират публичните action-и (за които не трябва потребител).
        await currentUser.waitUntilAuthenticated();
    }

    pushNotificationService.startPolling();
};
