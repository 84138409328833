<template>
    <v-form ref="form" v-model="isFormValid" :disabled="!$allowWrite">
        <v-card>
            <patient-version-picker
                v-if="id"
                v-model="selectedVersion"
                :versions="versions"
                @input="navigateToSelectedVersion"
            />
            <v-card-text v-if="id && $allowWrite">
                <alert v-if="canTransferPatientFromOtherDoctor" type="info">
                    Пациентът е записан при друг лекар от тази практика. Желаете ли да го прехвърлите към себе си?
                </alert>
                <v-row>
                    <v-col>
                        <h2>{{ id > 0 ? 'Редактиране' : 'Добавяне' }} на пациент</h2>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <btn
                            v-if="canTransferPatientFromOtherDoctor"
                            action="Copy"
                            @click="transferPatientFromOtherDoctor"
                        >
                            Прехвърляне към мен
                        </btn>
                    </v-col>
                    <v-col cols="auto">
                        <btn v-if="!isInEditMode" action="Edit" @click="editMode" />
                    </v-col>
                    <v-col cols="auto">
                        <btn v-if="!isInEditMode" action="Print" @click="printGdpr">GDPR декларация</btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-title>Основни данни</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4" md="3">
                        <text-field
                            v-model.trim="patientVersion.firstName"
                            required
                            label="Име"
                            :disabled="!isInEditMode"
                            :rules="[$validator.personName]"
                        />
                    </v-col>
                    <v-col cols="4" md="3">
                        <text-field
                            v-model.trim="patientVersion.middleName"
                            label="Презиме"
                            :disabled="!isInEditMode"
                            :rules="[$validator.personName]"
                        />
                    </v-col>
                    <v-col cols="4" md="3">
                        <text-field
                            v-model.trim="patientVersion.lastName"
                            required
                            label="Фамилия"
                            :disabled="!isInEditMode"
                            :rules="[$validator.personName]"
                        />
                    </v-col>
                </v-row>
                <person-identification
                    :person-identification="patientVersion.personIdentification"
                    :disabled="!isInEditMode"
                    @reset-statuses="resetStatuses"
                    @check-identifier="doesPatientExist"
                    @birth-date-change="calculateAge"
                />
                <v-row>
                    <v-col cols="12" md="3">
                        <text-field
                            v-model="patientVersion.idCardNumber"
                            :disabled="!isInEditMode"
                            label="Лична карта номер"
                        />
                    </v-col>
                    <v-col md="3">
                        <text-field
                            v-model="patientVersion.idCardAuthority"
                            :disabled="!isInEditMode"
                            label="Издадена от"
                        />
                    </v-col>
                    <v-col md="3">
                        <date-picker
                            v-model="patientVersion.idCardIssueDate"
                            :disabled="!isInEditMode"
                            label="Дата на издаване"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-title>Адресна информация</v-card-title>
            <v-row>
                <v-col cols="12">
                    <v-card-text>
                        <Address
                            v-model="patientVersion.address"
                            show-mailbox
                            show-post-code
                            :disabled="!isInEditMode"
                        />
                    </v-card-text>
                </v-col>
            </v-row>
            <v-card-title>Контактна информация</v-card-title>
            <v-row>
                <v-col cols="12">
                    <v-card-text>
                        <Contact v-model="patientVersion.contact" :disabled="!isInEditMode" show-fax />
                    </v-card-text>
                </v-col>
            </v-row>
            <v-card-title>Данни за регистрацията</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3">
                        <dropdown
                            v-model="patientVersion.patientRegistrationTypeCode"
                            :items="patientRegistrationTypes"
                            item-value="code"
                            label="Вид регистрация"
                            :disabled="!isInEditMode"
                            required
                            @input="onRegistrationTypeChange"
                        />
                    </v-col>
                    <v-col cols="6" md="3">
                        <date-picker
                            v-model="patientVersion.registrationDate"
                            required
                            label="Дата на записване"
                            :disabled="!isInEditMode"
                        />
                    </v-col>
                    <v-col cols="6" md="3">
                        <date-picker
                            v-model="patientVersion.deregistrationDate"
                            label="Дата на отписване"
                            :disabled="!isInEditMode"
                            :required="isDeregistered"
                        />
                    </v-col>
                    <v-col v-if="hiredEmployees && hiredEmployees.length > 0" cols="6" md="3">
                        <dropdown
                            v-model="patientVersion.creditToEmployeeSeqNumber"
                            :items="hiredEmployees"
                            item-value="employeeSeqNumber"
                            item-text="uinNameAndSpecialty"
                            label="Пациентът се обслужва от:"
                            :disabled="!isInEditMode"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-title>Здравноосигурителна информация</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6" md="3">
                        <label>Осигурен:</label>
                        <v-switch
                            v-model="patientVersion.insuranceStatus.isInsured"
                            :label="patientVersion.insuranceStatus.isInsured ? 'Да' : 'Не'"
                            :disabled="!isInEditMode"
                            dense
                            @change="patientVersion.insuranceStatus.checkDate = null"
                        ></v-switch>
                        <span v-if="isInsuranceStatusManipulated" class="red--text">
                            Този статус е поставен ръчно
                        </span>
                    </v-col>

                    <v-col cols="12" md="3">
                        <label>Проверено на:</label>
                        <div class="mt-3">
                            <date-field
                                v-model="patientVersion.insuranceStatus.checkDate"
                                label="Дата на проверка"
                                show-time
                            />
                        </div>
                    </v-col>
                    <v-col v-if="!isInEditMode" cols="12" md="3">
                        <btn action="Refresh" block @click="checkInsuranceStatus">
                            Проверка на осигурителен статус
                        </btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="3">
                        <label>Пенсионер:</label>
                        <v-switch
                            v-model="patientVersion.isRetired"
                            :label="patientVersion.isRetired ? 'Да' : 'Не'"
                            :disabled="!isInEditMode"
                            @change="patientVersion.pensionStatusCheckDate = null"
                        ></v-switch>
                        <span v-if="isPensionStatusManipulated" class="red--text">Този статус е поставен ръчно</span>
                    </v-col>
                    <v-col cols="12" md="3">
                        <label>Проверено на:</label>
                        <div class="mt-3">
                            <date-field
                                v-model="patientVersion.pensionStatusCheckDate"
                                label="Дата на проверка"
                                show-time
                            />
                        </div>
                    </v-col>
                    <v-col v-if="!isInEditMode && showPensionStatusBtn" cols="12" md="3">
                        <btn action="Refresh" block @click="checkPensionStatus">Проверка на социален статус</btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="3">
                        <div>
                            <text-field
                                v-model="patientVersion.healthInsuranceBook"
                                :disabled="!isInEditMode"
                                label="Здравноосигурителна книжка"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" md="3">
                        <div>
                            <text-field
                                v-model="patientVersion.prescriptionBook"
                                label="Рецептурна книжка"
                                :disabled="!isInEditMode"
                                counter
                                :rules="[$validator.digitsOnly, $validator.maxLength(8)]"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" md="3">
                        <div>
                            <text-field
                                v-model="patientVersion.lakNumber"
                                label="Лична Амбулаторна Карта"
                                :disabled="!isInEditMode"
                                :rules="[$validator.digitsOnly]"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-row>
                <v-col cols="12" md="6"><v-card-title>Направен избор на регистрация от</v-card-title></v-col>
                <v-col cols="12" md="3">
                    <dropdown
                        v-model="patientVersion.choiceMakerTypeCode"
                        :items="choiceMakerTypes"
                        item-value="code"
                        :label="patientVersion.choiceMakerTypeCode ? 'Тип избор' : 'Личен избор'"
                        :disabled="!isInEditMode"
                        :clearable="canChooseAlone"
                    />
                    <!-- Knows bug: Спирането на clearable не пречи на потребителя да изтрие думата (примерно 'Родител') и да запише дете с личен избор. -->
                </v-col>
                <v-col v-if="!isInEditMode" cols="12" md="3" class="mt-2">
                    <!--<v-btn class="primary" @click="printChoiceForm">Печат на бланка за избор</v-btn>-->
                    <v-menu transition="slide-y-transition" top>
                        <template #activator="{ on, attrs }">
                            <btn action="Print" v-bind="attrs" v-on="on" />
                        </template>
                        <v-list>
                            <v-list-item @click="printWithoutPreview('Patient.GpChoice.FirstChoiceForm')">
                                <v-list-item-title>Бланка за първоначален избор на ОПЛ</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printWithoutPreview('Patient.GpChoice.TemporaryChoiceForm')">
                                <v-list-item-title>Бланка за временен избор на ОПЛ</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printWithoutPreview('Patient.GpChoice.PermanentChoiceForm')">
                                <v-list-item-title>Бланка за постоянен избор на ОПЛ</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printChoiceForm('Patient.GpChoice.FirstChoiceForm')">
                                <v-list-item-title>Бланка за първоначален избор на ОПЛ - Преглед</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printChoiceForm('Patient.GpChoice.TemporaryChoiceForm')">
                                <v-list-item-title>Бланка за временен избор на ОПЛ - Преглед</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="printChoiceForm('Patient.GpChoice.PermanentChoiceForm')">
                                <v-list-item-title>Бланка за постоянен избор на ОПЛ - Преглед</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <choice-maker
                v-if="patientVersion.choiceMakerTypeCode"
                v-model="patientVersion.choiceMaker"
                :show-clear-btn="patientVersion.id !== 0 ? false : true"
                :disabled="!isInEditMode"
            />
            <v-card-title v-if="isInEditMode && id">Вид редактиране</v-card-title>
            <v-card-text v-if="isInEditMode && id">
                <v-radio-group
                    v-model="patientVersion.isNewVersion"
                    column
                    :rules="[$validator.required]"
                    :disabled="!selectedVersion.isLast"
                >
                    <v-row>
                        <v-col md="5">
                            <v-radio label="Промяна в обстоятелства, само за бъдещите прегледи" :value="true"></v-radio>
                        </v-col>
                        <v-col md="7">
                            <div>* старите прегледи ще показват старите данни</div>
                        </v-col>
                        <v-col md="5">
                            <v-radio label="Допълване на данни или корекция на грешка" :value="false"></v-radio>
                        </v-col>
                        <v-col md="7">
                            <div>* данните в старите прегледи ще се променят</div>
                        </v-col>
                    </v-row>
                </v-radio-group>
            </v-card-text>
            <v-row>
                <v-col>
                    <v-card-text>
                        <v-textarea
                            v-model="patientVersion.notes"
                            label="Бележки"
                            :disabled="!isInEditMode"
                        ></v-textarea>
                    </v-card-text>
                </v-col>
            </v-row>
            <v-card-actions>
                <btn
                    v-if="$allowWrite && isInEditMode"
                    action="Cancel"
                    class="mx-2"
                    outlined
                    color="secondary"
                    :disabled="$loading.isVisible"
                    @click="previewMode"
                />
                <v-spacer />
                <btn
                    v-if="$allowWrite && isInEditMode"
                    action="Save"
                    :disabled="$loading.isVisible"
                    @click="savePatient()"
                />
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import Contact from '@/component/Common/Contact.vue';
    import { PidTypeCode } from '@/enum/Nomenclature/PidTypeCode';
    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { ChoiceMakerTypeCode } from '@/enum/Patient/ChoiceMakerTypeCode';
    import { PatientRegistrationTypeCode } from '@/enum/Patient/PatientRegistrationTypeCode';
    import { EmployeeTitleDto } from '@/model/Employee/EmployeeTitleDto';
    import { ChoiceMakerTypeDto } from '@/model/Nomenclature/ChoiceMakerTypeDto';
    import { PatientRegistrationTypeDto } from '@/model/Nomenclature/PatientRegistrationTypeDto';
    import { PatientExistenceDto } from '@/model/Patient/PatientExistenceDto';
    import { PatientVersionDropdownDto } from '@/model/Patient/PatientVersionDropdownDto';
    import { PatientVersionDto } from '@/model/Patient/PatientVersionDto';
    import { doctorDeputyService } from '@/service/Employee/Deputy/DoctorDeputyService';
    import { nssiService } from '@/service/Integration/NssiService';
    import { choiceMakerTypeService } from '@/service/Nomenclature/ChoiceMakerTypeService';
    import { patientRegistrationTypeService } from '@/service/Nomenclature/PatientRegistrationTypeService';
    import { insuranceStatusService } from '@/service/Patient/InsuranceStatusService';
    import { patientService } from '@/service/Patient/PatientService';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';
    import PersonIdentification from '@/view/Patient/PatientEdit/PersonIdentification.vue';

    import Address from './Address.vue';
    import ChoiceMaker from './ChoiceMaker.vue';
    import PatientVersionPicker from './PatientVersionPicker.vue';

    @Component({
        components: {
            PersonIdentification,
            PatientVersionPicker,
            ChoiceMaker,
            Address,
            Contact
        }
    })
    export default class PatientEdit extends Vue {
        @Prop()
        public id!: number;

        @Prop()
        public patientVersionSeqNumber!: number | null;

        private selectedVersion: PatientVersionDropdownDto = new PatientVersionDropdownDto();
        private versions: PatientVersionDropdownDto[] = [];
        private patientRegistrationTypes: PatientRegistrationTypeDto[] = [];
        private choiceMakerTypes: ChoiceMakerTypeDto[] = [];
        private patientVersion: PatientVersionDto = new PatientVersionDto();
        private hiredEmployees: EmployeeTitleDto[] = [];
        private isFormValid: boolean = false;
        private isInEditMode: boolean = false;
        private versionSeqNumber: number = 0;

        @Watch('patientVersion.firstName')
        private onFirstNameChanged() {
            this.patientVersion.firstName = formatters.capitalizeFirstLetter(this.patientVersion.firstName);
        }

        @Watch('patientVersion.middleName')
        private onMiddleNameChanged() {
            this.patientVersion.middleName = formatters.capitalizeFirstLetter(this.patientVersion.middleName);
        }

        @Watch('patientVersion.lastName')
        private onLastNameChanged() {
            this.patientVersion.lastName = formatters.capitalizeFirstLetter(this.patientVersion.lastName);
        }

        private navigateToPatient(patientId: number) {
            this.$router.push(`/Patient/PersonalData/${patientId}`);
        }

        private navigateToSelectedVersion(selectedVersion: PatientVersionDropdownDto) {
            this.navigateToPatientVersion(selectedVersion.seqNumber);
        }

        private navigateToPatientVersion(newSeqNumber: number) {
            this.$router.push(`/Patient/PersonalData/${this.id}/${newSeqNumber}`);
        }

        private get showPensionStatusBtn() {
            return (
                this.patientVersion.personIdentification.pidTypeCode !== PidTypeCode.Foreigner &&
                this.patientVersion.personIdentification.pidTypeCode !== PidTypeCode.Baby
            );
        }

        private async mounted() {
            this.$loading.show();
            try {
                await this.loadStaticDropdowns();

                if (this.id) {
                    await this.loadPatientVersions();
                    await this.loadPatientCacheForEdit();
                } else {
                    currentPatientCache.clear();
                    // Стойности по подразбиране за нов пациент.
                    this.patientVersion.key.practiceId = userContextCache.currentPracticeId ?? 0;
                    this.isInEditMode = true;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async loadStaticDropdowns() {
            this.choiceMakerTypes = (await choiceMakerTypeService.getChoiceMakerTypes()).data;
            this.patientRegistrationTypes = (await patientRegistrationTypeService.getPatientRegistrationTypes()).data;
            await this.loadHiredEmployees();
        }

        private async loadPatientVersions() {
            this.versions = await patientService.getPatientVersions(this.id);

            if (this.patientVersionSeqNumber) {
                this.selectedVersion =
                    this.versions.find((ver) => ver.seqNumber === this.patientVersionSeqNumber) ??
                    new PatientVersionDropdownDto();
            } else {
                this.selectedVersion =
                    this.versions.find(
                        (ver) => ver.gpEmployeeSeqNumber === userContextCache.currentDoctorEmployeeSeqNumber
                    ) ??
                    this.versions.find((ver) => ver.isLast) ??
                    new PatientVersionDropdownDto();
            }

            if (this.selectedVersion.seqNumber) {
                this.versionSeqNumber = this.selectedVersion.seqNumber;
            }
        }

        private async loadPatientCacheForEdit() {
            if (this.versionSeqNumber) {
                await currentPatientCache.loadSpecificVersion({
                    practiceId: userContextCache.currentPracticeId ?? 0,
                    patientId: this.id,
                    seqNumber: this.versionSeqNumber
                });
            } else {
                await currentPatientCache.loadLastVersion(this.id);
            }

            // Първо в кеша се зарежда желаната версия, след което се редактира директно стойността от кеша.
            // Видимият ефект е, че името и осиг. статус в заглавната лента се променят в реално време.
            // При отказ, кешът се презарежда от сървъра и така се показват оригиналните данни.
            this.patientVersion = currentPatientCache.value;
        }

        private async loadHiredEmployees() {
            this.hiredEmployees = await doctorDeputyService.getHiredDeputyDoctors(
                userContextCache.currentPracticeId ?? 0,
                userContextCache.currentDoctorEmployeeSeqNumber ?? 0
            );
            if (this.hiredEmployees?.length > 0) {
                this.hiredEmployees.push(this.getOwnerInfo());
                if (!this.patientVersion.creditToEmployeeSeqNumber) {
                    this.patientVersion.creditToEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber;
                }
            }
        }

        private get canTransferPatientFromOtherDoctor() {
            return (
                !this.isInEditMode &&
                this.patientVersion.key.patientId &&
                this.selectedVersion.isLast &&
                // Текущият служител е ОПЛ и е заредил чужд пациент.
                this.isGp &&
                this.patientVersion.gpEmployeeSeqNumber &&
                this.patientVersion.gpEmployeeSeqNumber !== userContextCache.currentDoctorEmployeeSeqNumber
            );
        }

        private async transferPatientFromOtherDoctor() {
            this.$loading.show();
            const oldGpEmployeeSeqNumber = this.patientVersion.gpEmployeeSeqNumber;
            try {
                this.patientVersion.gpEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber;
                const newSeqNumber = await patientService.transferPatientFromOtherDoctor(this.patientVersion);
                this.navigateToPatientVersion(newSeqNumber);
            } catch {
                this.patientVersion.gpEmployeeSeqNumber = oldGpEmployeeSeqNumber;
            } finally {
                this.$loading.hide();
            }
        }

        private printGdpr() {
            if (this.patientVersion.key.patientId) {
                this.$router.push(`/Print/Patient.GdprDeclaration/${this.patientVersion.key.patientId}`);
            }
        }

        private printChoiceForm(template: string) {
            if (this.patientVersion.key.patientId) {
                this.$router.push(
                    `/Print/${template}/GpChoice/${userContextCache.currentPracticeId}/${this.id}/${this.selectedVersion.seqNumber}`
                );
            } else {
                // Todo показване на грешка
            }
        }

        private async printWithoutPreview(template: string) {
            this.$loading.show();
            try {
                await printService.printReport(template, {
                    practiceId: userContextCache.currentPracticeId,
                    patientId: this.id,
                    patientVersionSeqNumber: this.selectedVersion.seqNumber
                });
            } finally {
                this.$loading.hide();
            }
        }

        private getOwnerInfo() {
            const ownerEmployee = new EmployeeTitleDto();
            ownerEmployee.employeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
            ownerEmployee.doctorName = userContextCache.current?.doctorName ?? '';
            ownerEmployee.doctorUin = userContextCache.current?.doctorUin ?? '';
            ownerEmployee.specialtyName = userContextCache.current?.specialtyName ?? '';
            return ownerEmployee;
        }

        private async savePatient() {
            this.$loading.show();
            try {
                if (!this.checkPatientRegistrationTypeCode()) {
                    return;
                }
                (this.$refs.form as Vue & { validate: () => boolean }).validate();
                if (this.isFormValid && this.$allowWrite) {
                    this.patientVersion.key.seqNumber = this.selectedVersion.seqNumber;
                    if (!this.id) {
                        this.patientVersion.gpEmployeeSeqNumber = this.isGp
                            ? userContextCache.currentDoctorEmployeeSeqNumber ?? 0
                            : null;
                        const newPatientId = await patientService.createPatient(this.patientVersion);
                        if (newPatientId) {
                            this.navigateToPatient(newPatientId);
                        }
                    } else {
                        const newSeqNumber = await patientService.updatePatient(this.patientVersion);
                        if (newSeqNumber) {
                            this.navigateToPatientVersion(newSeqNumber);
                        } else {
                            // Не е създадена нова версия.
                            this.isInEditMode = false;
                        }
                    }
                    currentPatientCache.changeAgeFromBirthDate(this.patientVersion.personIdentification.birthDate);
                    this.$notifier.showSuccess('', `Успешен запис.`);
                } else {
                    this.$notifier.showWarning(
                        this.$t('notifications.validationErrors').toString(),
                        this.$t('notifications.pleaseFixValidationErrors').toString()
                    );
                }
            } finally {
                this.$loading.hide();
            }
        }

        private checkPatientRegistrationTypeCode() {
            const patientRegistrationTypeCodesWithoutDeregistratinDate = [
                PatientRegistrationTypeCode.Registered,
                PatientRegistrationTypeCode.OneTimeVisit,
                PatientRegistrationTypeCode.PaidByPatient
            ];
            if (
                this.patientVersion.deregistrationDate &&
                patientRegistrationTypeCodesWithoutDeregistratinDate.includes(
                    this.patientVersion.patientRegistrationTypeCode
                )
            ) {
                this.$notifier.showWarning('', 'Не може да се добавя Дата на отписване за този Вид регистрация');
                return false;
            }
            return true;
        }

        private async checkInsuranceStatus() {
            const response = await insuranceStatusService.checkStatus(
                this.patientVersion.key.patientId,
                this.patientVersion?.personIdentification?.pidTypeCode ?? '',
                this.patientVersion?.personIdentification?.identifier ?? '',
                this.patientVersion.insuranceStatus
            );
            if (response) {
                this.patientVersion.insuranceStatus = response;
                // Тъй като this.patientVersion сочи към кеша, осигурителният статус в кеша също се обновява.
            }
        }

        private async checkPensionStatus() {
            const response = await nssiService.pensionStatusCheck(this.patientVersion);
            if (response && response.data) {
                const { data } = response;
                if (data.isRetired !== null) {
                    this.patientVersion.isRetired = data.isRetired;
                    this.patientVersion.pensionStatusCheckDate = data.checkDate;
                    // Тъй като this.patientVersion сочи към кеша, пенсионният статус в кеша също се обновява.
                    if (data.isRetired) {
                        this.$notifier.showSuccess('', data.message);
                    } else {
                        this.$notifier.showWarning('', data.message);
                    }
                }
            }
        }

        private async doesPatientExist() {
            if (
                this.patientVersion.key.practiceId &&
                this.patientVersion.personIdentification.pidTypeCode &&
                this.patientVersion.personIdentification.identifier
            ) {
                const dto = new PatientExistenceDto(
                    this.patientVersion.key.practiceId,
                    this.patientVersion.personIdentification.pidTypeCode,
                    this.patientVersion.personIdentification.identifier,
                    this.patientVersion.key.patientId
                );
                const patientId = await patientService.doesPatientExist(dto);
                if (patientId) {
                    this.navigateToPatient(patientId);
                }
            }
        }

        private editMode() {
            // При редактиране на последната версия, потребителят се задължава да избере дали иска нова версия.
            // При редактиране на стара версия, никога не се създава нова версия и потребителят няма избор.
            this.patientVersion.isNewVersion = this.selectedVersion.isLast ? null : false;
            this.isInEditMode = true;
        }

        private async previewMode() {
            if (this.id) {
                this.isInEditMode = false;
                // При отказ, кешът се презарежда от сървъра и така се показват оригиналните данни.
                currentPatientCache.clear();
                await this.loadPatientCacheForEdit();
            } else {
                this.$router.back();
            }
        }

        private calculateAge() {
            this.patientVersion.calculateAge();

            // Непълнолетно лице не може да прави личен избор. По подразбиране предлага 'Родител'.
            if (this.canChooseAlone) {
                if (!this.patientVersion.choiceMaker.firstName) {
                    this.patientVersion.choiceMakerTypeCode = null;
                }
            } else {
                this.patientVersion.choiceMakerTypeCode ??= ChoiceMakerTypeCode.Parent;
            }
        }

        private get isGp() {
            return userContextCache.current?.specialtyCode === SpecialtyCode.Gp;
        }

        // Непълнолетно лице не може да прави личен избор.
        private get canChooseAlone() {
            const adultAge = 18;
            return !this.isGp || this.patientVersion.age === null || this.patientVersion.age >= adultAge;
        }

        private onRegistrationTypeChange(newVal: PatientRegistrationTypeCode) {
            if (newVal === PatientRegistrationTypeCode.TemporaryRegistered) {
                const minPeriodMonths = 1;
                const registerDate = new Date(this.patientVersion.registrationDate);
                this.patientVersion.deregistrationDate = new Date(
                    registerDate.setMonth(registerDate.getMonth() + minPeriodMonths)
                );
            } else if (newVal === PatientRegistrationTypeCode.Deceased) {
                this.patientVersion.deregistrationDate = new Date();
            } else {
                this.patientVersion.deregistrationDate = null;
            }
        }

        private get isDeregistered() {
            return (
                this.patientVersion.patientRegistrationTypeCode === PatientRegistrationTypeCode.Deregistered ||
                this.patientVersion.patientRegistrationTypeCode === PatientRegistrationTypeCode.Deceased
            );
        }

        private get isInsuranceStatusManipulated() {
            return this.patientVersion?.insuranceStatus?.isInsured && !this.patientVersion?.insuranceStatus?.checkDate;
        }

        private get isPensionStatusManipulated() {
            return this.patientVersion.isRetired && !this.patientVersion.pensionStatusCheckDate;
        }

        private resetStatuses() {
            this.patientVersion.insuranceStatus.isInsured = false;
            this.patientVersion.isRetired = false;
            this.patientVersion.insuranceStatus.checkDate = null;
            this.patientVersion.pensionStatusCheckDate = null;
            this.calculateAge();
        }
    }
</script>
