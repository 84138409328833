<template>
    <v-card class="mb-md-4" :style="`background-color: ${backgroundColor}`">
        <v-card-text>
            <v-row>
                <v-col md="3" sx="6" cols="6">
                    <date-picker
                        v-model="value.issueDate"
                        label="Дата на изпъл. на рецептата"
                        required
                        :disabled="disableEdit"
                    ></date-picker>
                </v-col>

                <v-col md="3" sx="6" cols="6">
                    <text-field
                        id="prescriptionBookNumber"
                        v-model="value.prescriptionBookNumber"
                        label="№ на рецептурна книжка"
                        readonly
                        :disabled="disableEdit"
                        :required="isRequiredPrescriptionBook"
                        :rules="isRequiredPrescriptionBook ? [$validator.exactLength(8)] : []"
                    />
                </v-col>
                <v-col>
                    <text-field v-model="currentExamNrn" disabled label="Амбулаторен лист №" />
                </v-col>

                <v-col>
                    <text-field v-model.number="value.prescriptionNumber" type="number" disabled label="Рецепта №" />
                </v-col>
            </v-row>
            <alert v-if="icdWarning" type="warning"> {{ icdWarning }}</alert>
            <!-- Следват бутони, които отварят диалози. -->
            <v-row>
                <v-col cols="12">
                    <patient-prescription-drug
                        :form-type="value.formTypeCode"
                        :disabled="disableEdit"
                        is-mega-prescription
                        @input="prescriptionChange"
                    />
                    <nhif-drug-search-dialog />
                    <!-- В случая v-model само прихваща @input. Компонентът няма value prop. -->
                    <nhif-prescription-book-check-button
                        v-if="isFormType5"
                        v-model="value.prescriptionBookNumber"
                        :disabled="disableEdit"
                    />
                    <nhif-prescription-history v-if="isFormType5" :disabled="disableEdit" />
                </v-col>
                <v-col md="3" class="pr-0">
                    <patient-protocol-status
                        v-if="isFormType5"
                        :document="value"
                        :disabled="disableEdit"
                        @input="protocolPrescriptionChange"
                        @input-drug="drugChange"
                        @reload-diagnoses="reloadDiagnoses"
                    />
                </v-col>
                <v-col md="3" class="pl-0" :disabled="false">
                    <print-prescription-book v-if="value.key.prescriptionId > 0" :prescription-key="value.key" />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { plainToInstance } from 'class-transformer';
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhifPrescriptionSegmentCode } from '@/enum/Exam/NhifPrescriptionSegmentCode';
    import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
    import { PrescriptionNhifDto } from '@/model/Exam/Prescription/PrescriptionNhifDto';
    import { PrescriptionNhifV2Dto } from '@/model/Exam/Prescription/PrescriptionNhifV2Dto';
    import { PrescriptionDiagnosisDto } from '@/model/Exam/PrescriptionDiagnosisDto';
    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
    import { prescriptionService } from '@/service/Exam/PrescriptionService';
    import { prescriptionBookService } from '@/service/Nhif/PrescriptionBookService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import PatientProtocolStatus from '@/view/Exam/DrugProtocol/PatientProtocolStatus.vue';
    import PrintPrescriptionBook from '@/view/Exam/PrescriptionNhif/PrintPrescriptionBook.vue';
    import NhifPrescriptionBookCheckButton from '@/view/Nhif/NhifPrescriptionBookCheckButton.vue';
    import NhifPrescriptionHistory from '@/view/Nhif/NhifPrescriptionHistory.vue';
    import NhifDrugSearchDialog from '@/view/Nomenclature/NhifDrugSearchDialog.vue';
    import PatientPrescriptionDrug from '@/view/Query/Patient/PatientPrescriptionDrug.vue';

    @Component({
        components: {
            PrintPrescriptionBook,
            PatientPrescriptionDrug,
            PatientProtocolStatus,
            NhifDrugSearchDialog,
            NhifPrescriptionBookCheckButton,
            NhifPrescriptionHistory
        }
    })
    export default class PrescriptionInfoV2 extends Vue {
        @Prop()
        private value!: PrescriptionNhifV2Dto;

        @Prop()
        private examDiagnoses!: PrescriptionDiagnosisDto[];

        @Prop()
        private disableEdit!: boolean;

        @Prop({ default: 'transparent' })
        private backgroundColor!: string;

        private patientActiveIcds: string[] = [];
        private requiredIcdTypes: string[] = [];
        private examDiagnosisId: number | null = null;
        private examDiagnosisIsAdd: boolean | null = null;

        private get isFormType5() {
            return (
                this.value.formTypeCode === PrescriptionFormTypeCode.Nhif5a ||
                this.value.formTypeCode === PrescriptionFormTypeCode.Nhif5
            );
        }

        public getPrescriptionDiagnosis(
            examDiagnosisIdArg: number | null,
            isAdd: boolean
        ): PrescriptionDiagnosisDto | null {
            return (
                this.examDiagnoses.find(
                    (examDiagnosis) => examDiagnosis.id === examDiagnosisIdArg && examDiagnosis.isAdd === isAdd
                ) ?? null
            );
        }

        private get prescriptionDiagnoses() {
            const prDiagnoses: PrescriptionDiagnosisDto[] = [];
            this.value.nhifDrugs.forEach((drug) => {
                const diagnosis = this.getPrescriptionDiagnosis(drug.examDiagnosisId, drug.isExamDiagnosisAdd);
                if (diagnosis) {
                    prDiagnoses.push(diagnosis);
                }
            });

            return prDiagnoses;
        }

        private reloadDiagnoses() {
            this.$emit('reload-diagnoses');
        }

        private get currentExamNrn() {
            return currentVisitCache.value?.exam?.nrn;
        }

        private prescriptionChange(item: PrescriptionNhifV2Dto) {
            item.key = this.value.key;
            item.prescriptionNumber = this.value.prescriptionNumber;
            plainToInstance(PrescriptionNhifV2Dto, item);
            this.$emit('input', item);
        }

        private protocolPrescriptionChange(item: PrescriptionNhifDto) {
            this.examDiagnosisId = item.examDiagnosis1Id;
            const prescription = plainToInstance(PrescriptionNhifV2Dto, item);
            this.prescriptionChange(prescription);
        }

        private drugChange(drug: NhifDrugDto) {
            drug.segmentCode = NhifPrescriptionSegmentCode.A;
            drug.examDiagnosisId = this.examDiagnosisId;
            drug.isExamDiagnosisAdd = this.examDiagnosisIsAdd ?? false;
            if (!this.value.nhifDrugs.some((dr) => dr.nhifCode === drug.nhifCode)) {
                drug.position = this.value.nhifDrugs.length + 1;
                this.value.nhifDrugs.push(drug);
            }
        }

        private get isRequiredPrescriptionBook(): boolean {
            if (this.requiredIcdTypes.length > 0) {
                if (this.prescriptionDiagnoses.some((prDiag) => this.requiredIcdTypes.includes(prDiag.icdCode))) {
                    return true;
                }
            }
            return false;
        }

        private get icdWarning() {
            let message = '';
            if (this.isRequiredPrescriptionBook) {
                const missingDiagnoses = this.prescriptionDiagnoses.filter(
                    (prDiag) => !this.patientActiveIcds?.includes(prDiag.icdCode)
                );
                if (missingDiagnoses?.length > 0) {
                    missingDiagnoses.forEach(
                        (diagnosis) =>
                            (message += `МКБ ${diagnosis.icdCode} липсва в рецептурната книжка на пациента! Моля добавете го преди изпращане на рецептата! \r\n`)
                    );
                }
            }

            return message;
        }

        private async loadActiveIcdsForPatient() {
            this.patientActiveIcds = await prescriptionBookService.getActiveDiagnosesForPatientAsync(
                currentPatientCache.value.key.patientId,
                this.value.issueDate ?? new Date()
            );
        }

        private async getRequiredTypes() {
            this.requiredIcdTypes = await prescriptionService.getRequiredIcdPrescriptionBook();
        }

        private async mounted() {
            await this.loadActiveIcdsForPatient();
            await this.getRequiredTypes();
        }
    }
</script>
