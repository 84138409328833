<template>
    <v-card>
        <v-card-title>
            Резултат от направление
            <v-spacer />
            <btn action="Next" :to="routeToReferral">Към направлението...</btn>
            <btn
                v-if="referralResult && referralResult.status"
                :disabled="referralResult.status === 'Active'"
                class="ma-1"
                action="Print"
                @click="print"
                >Печат консултация</btn
            >
            <btn action="Download" class="ma-1" @click="checkReferral">Изтегляне на резултати</btn>
        </v-card-title>
        <v-col>
            <referral-result-main-info
                :nrn="referralResult.nrn"
                :open-date="referralResult.openDate"
                :result-referral-performer-data="referralResult.performerInfo"
                :status="referralResult.status"
            >
            </referral-result-main-info>
            <v-row v-if="referralResult.diagnosis" dense> <v-card-title> Основна диагноза </v-card-title></v-row>
            <v-row v-if="referralResult.diagnosis" no-gutters>
                <v-col cols="6" class="pa-1"
                    ><icd-picker v-model="referralResult.diagnosis.icdCode" :disabled="true"
                /></v-col>
                <v-col cols="6" class="pa-1"
                    ><icd-picker
                        v-model="referralResult.diagnosis.icdCodeAdd"
                        :disabled="true"
                        label="Допълнително МКБ"
                /></v-col>
            </v-row>
            <div v-if="referralResult.comorbiditiesNames">
                <v-row dense><v-card-title> Придружаващи заболявания и усложнения </v-card-title></v-row>
                <v-row v-for="(item, index) in referralResult.comorbiditiesNames" :key="index" no-gutters>
                    <v-col cols="6" class="pa-1"><icd-picker :value="item.icdCode" :disabled="true" /></v-col>
                    <v-col cols="6" class="pa-1"
                        ><icd-picker :value="item.icdCodeAdd" :disabled="true" label="Допълнително МКБ"
                    /></v-col>
                </v-row>
            </div>
            <v-textarea v-model="referralResult.medicalHistory" readonly label="Анамнеза"></v-textarea>
            <v-textarea v-model="referralResult.objectiveCondition" readonly label="Обективно състояние"></v-textarea>
            <v-textarea v-model="referralResult.assessment" readonly label="Изследвания"></v-textarea>
            <v-textarea v-model="referralResult.therapy" readonly label="Терапия"></v-textarea>
            <data-table
                v-if="referralResult.procedures && referralResult.procedures.length > 0"
                title="Резултати от извършена специализирана дейност"
                :headers="tableHeaders"
                :items="referralResult.procedures"
                item-key="code"
                disable-pagination
            ></data-table>
            <div v-if="referralResult.sickLeaveNumber">
                <v-row dense><v-card-title> Болничен </v-card-title></v-row>
                <v-row>
                    <v-col cols="3"
                        ><text-field
                            v-model="referralResult.sickLeaveNumber"
                            readonly
                            label="Номер на болнияния лист"
                        ></text-field
                    ></v-col>
                    <v-col cols="3"
                        ><text-field
                            v-model="referralResult.sickLeaveDays"
                            readonly
                            label="Брой дни на болничния"
                        ></text-field
                    ></v-col>
                    <v-col cols="3"
                        ><date-field :value="referralResult.sickLeaveFrom" readonly label="От дата" />
                    </v-col>
                    <v-col cols="3"><date-field :value="referralResult.sickLeaveTo" readonly label="До дата" /> </v-col>
                </v-row>
            </div>
        </v-col>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReferralResultMainInfo from '@/component/Exam/ReferralResultMainInfo.vue';
    import IcdPicker from '@/component/Picker/IcdPicker.vue';
    import { ReferralTypeCode } from '@/enum/Exam/ReferralTypeCode';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { ResultReferralDto } from '@/model/Exam/Referral/ResultReferral/ResultReferralDto';
    import { ReferralResultService } from '@/service/Exam/Referral/ReferralResultService';
    import { nhisReferralService } from '@/service/Nhis/NhisReferralService';
    import { nhisService } from '@/service/Nhis/NhisService';
    import { formatters } from '@/util/Formatters';

    @Component({ components: { IcdPicker, ReferralResultMainInfo } })
    export default class ReferralResult extends Vue {
        @Prop()
        private nrn!: string;

        @Prop()
        private examId!: number;

        private referralResult: ResultReferralDto = new ResultReferralDto();

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Код:', value: 'code' },
                { text: 'Име:', value: 'name' },
                { text: 'Резултат:', value: 'result' },
                { text: 'Мерна единица:', value: 'resultUnit' },
                { text: 'Дата:', value: 'resultDate', formatter: formatters.date },
                { text: 'Долна референтна стойност:', value: 'referenceLowRange' },
                { text: 'Горна референтна стойност:', value: 'referenceHighRange' },
                { text: 'Текстова референтна стойност:', value: 'referenceTextRange' }
            ];
        }

        private async mounted() {
            await this.load();
        }

        private async load() {
            this.referralResult = (await ReferralResultService.getResultData(this.examId, this.nrn)).data;
        }

        private print() {
            if (this.examId) {
                this.$router.push(
                    `/Print/Exam.Referral.ReferralResultConsultation/ReferralResultConsultation/${this.examId}/${this.nrn}`
                );
            }
        }

        private get routeToReferral() {
            switch (this.referralResult.referralTypeCode) {
                case ReferralTypeCode.Referral3:
                    return `/Exam/Referral3/Edit/${this.referralResult.documentId}`;
                case ReferralTypeCode.Referral3a:
                    return `/Exam/Referral3a/Edit/${this.referralResult.documentId}`;
                case ReferralTypeCode.Referral6:
                    return `/Exam/Referral6/Edit/${this.referralResult.documentId}`;
                default:
                    return '';
            }
        }

        private async checkReferral() {
            this.$loading.show();
            try {
                const response = await nhisReferralService.checkReferral(
                    nhisService.requestData(this.referralResult.documentId, this.referralResult.referralTypeCode)
                );
                if (response) {
                    await this.load();
                }
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
