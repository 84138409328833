<template>
    <v-row>
        <v-col cols="12">
            <v-card :style="`background-color: ${backgroundColor}`">
                <div v-if="!examDto.primaryExamId && isPrimaryTypeVisit">
                    <v-row no-gutters>
                        <v-col cols="12" md="4"><h4>Данни от входящо направление</h4></v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" md="3">
                            <text-field
                                v-model="value.practiceNumber"
                                label="РЦЗ"
                                required
                                :rules="[$validator.practiceNumber]"
                            />
                        </v-col>
                        <v-col cols="12" md="3">
                            <text-field v-model="value.uin" label="УИН" required :rules="[$validator.uin]" />
                        </v-col>
                        <v-col cols="12" md="3">
                            <text-field v-model="value.deputyUin" label="УИН заместващ" />
                        </v-col>
                        <v-col cols="12" md="3">
                            <text-field v-model="value.specialtyNhifCode" label="Специалност" required />
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" md="3">
                            <dropdown
                                v-model="value.referralSubtypeId"
                                :items="referralSubtypes"
                                return-object
                                required
                                label="Напр. тип"
                                @input="setReferralSubtype"
                            ></dropdown>
                        </v-col>
                        <v-col cols="12" md="3">
                            <text-field v-model="value.nrn" required label="НРН" :rules="[$validator.nrn]" />
                        </v-col>
                        <v-col cols="12" md="3">
                            <text-field v-model="value.number" label="Напр. номер" />
                        </v-col>
                        <v-col cols="12" md="3">
                            <date-picker
                                v-model="value.issueDate"
                                required
                                label="Напр. дата"
                                @input="checkReferralDate"
                            />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <NhisReferralExtraction @referral-selected="onReferralSelect" />
                    </v-row>
                </div>
                <div v-else>
                    <dropdown
                        v-model="selectedExamId"
                        class="required"
                        :items="primaryExams"
                        required
                        label="Първичен преглед"
                        @input="changeSelectedExam"
                    />
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { NhifVisitReasonCode } from '@/enum/Exam/NhifVisitReasonCode';
    import { ReferralTypeCode } from '@/enum/Exam/ReferralTypeCode';
    import { DiagnosisDto } from '@/model/Common/DiagnosisDto';
    import { ExamDto } from '@/model/Exam/ExamDto';
    import { IncomingReferralDto } from '@/model/Exam/IncomingReferralDto';
    import { PrimaryExamDropdownDto } from '@/model/Exam/PrimaryExam/PrimaryExamDropdownDto';
    import { NhisExtractedReferralDto } from '@/model/Nhis/Referral/NhisExtractedReferralDto';
    import { MedicalProcedureDto } from '@/model/Nomenclature/MedicalProcedureDto';
    import { ReferralSubtypeDto } from '@/model/Nomenclature/ReferralSubtypeDto';
    import { primaryExamService } from '@/service/Exam/PrimaryExamService';
    import { referralSubtypeService } from '@/service/Nomenclature/ReferralSubtypeService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import NhisReferralExtraction from '@/view/Exam/Referral/ReferralExtraction/NhisReferralExtraction.vue';

    //Това са посещения, които нямат първичен/вторичен и за тях задължително трябва да се въведе входящо направление
    const primaryVisitReasons = [
        NhifVisitReasonCode.HighSpecialized,
        NhifVisitReasonCode.Prescription,
        NhifVisitReasonCode.Telk
    ];

    @Component({
        components: { NhisReferralExtraction }
    })
    export default class AmbulatorySheetIncomingReferralCard extends Vue {
        // Този компонент може да получи undefined v-model. В този случай се bind-ва default стойността, която е празен обект.
        @Prop({ default: () => new IncomingReferralDto() })
        private value!: IncomingReferralDto;

        @Prop()
        private examDto!: ExamDto;

        @Prop({ default: 'transparent' })
        private backgroundColor!: string;

        private referralSubtypes: ReferralSubtypeDto[] = [];
        private primaryExams: PrimaryExamDropdownDto[] = [];
        private selectedExamId: number | null = null;

        private get isPrimaryTypeVisit() {
            return (
                primaryVisitReasons.includes(this.examDto.nhifVisitReasonCode) ||
                (!primaryVisitReasons.includes(this.examDto.nhifVisitReasonCode) && !this.examDto.isSecondary)
            );
        }

        private onReferralSelect(item: NhisExtractedReferralDto) {
            this.$emit('input', item.incomingReferral);
            this.fillMedicalProcedure(item.medicalProcedure);
            this.fillDiagnoses(item.diagnoses);
            this.setVisitReason(item.incomingReferral.referralTypeCode);
        }

        private setVisitReason(referralTypeCode: string) {
            this.examDto.nhifVisitReasonCode =
                referralTypeCode === ReferralTypeCode.Referral3a
                    ? NhifVisitReasonCode.HighSpecialized
                    : referralTypeCode === ReferralTypeCode.Referral6
                    ? NhifVisitReasonCode.Expertise
                    : NhifVisitReasonCode.Consultation;

            //Ако направлението е от тип ВСД или Експертиза, то не искаме при проверка на диагнозите да се смени типа, така както е по принцип,
            //затова само при тип консултация оставяме маханизма да продължи да си работи
            this.$emit('watch-diagnosis', this.examDto.nhifVisitReasonCode === NhifVisitReasonCode.Consultation);
        }

        private fillMedicalProcedure(item: MedicalProcedureDto | null) {
            this.examDto.medicalProcedures[0].procedureId = item?.id ?? null;
        }

        private fillDiagnoses(diagnoses: DiagnosisDto[]) {
            let index = 0;
            for (const diagnosis of diagnoses) {
                this.examDto.diagnoses[index].icdCode = diagnosis.icdCode;
                this.examDto.diagnoses[index].icdCodeAdd = diagnosis.icdCodeAdd;
                index++;
            }
        }

        private async loadReferralSubtypes() {
            const referralSubtypesResponse = await referralSubtypeService.getIncomingReferralSubTypes();
            this.referralSubtypes = referralSubtypesResponse.data;
        }

        private async getPrimaryExams() {
            const result = await primaryExamService.getPrimaryExams(
                currentPatientCache.value.key,
                this.examDto.startDateTime
            );

            if (result) {
                this.primaryExams = result;
            }
        }

        private setReferralSubtype(item: ReferralSubtypeDto) {
            this.value.referralSubtypeNhisCode = item.nhisCode;
            this.value.referralTypeCode = item.referralTypeCode;
            this.value.referralSubtypeId = item.id;
        }

        private changeSelectedExam(id: number | null) {
            this.$emit('primary-exam-change', id);
        }

        private checkReferralDate() {
            const referralValidityDays = 30;
            const referralExpirationDate = new Date(this.value.issueDate);
            referralExpirationDate.setDate(referralExpirationDate.getDate() + referralValidityDays);
            const today = new Date();
            if (today.getTime() > referralExpirationDate.getTime()) {
                this.$notifier.showWarning('Внимание', 'Направлението е по-старо от 30 дни!');
            }
        }

        @Watch('examDto.startDateTime')
        async onExamDateChange(newVal: Date) {
            if (newVal) {
                await this.getPrimaryExams();
            }
        }

        @Watch('examDto.primaryExamId', { immediate: true })
        onPrimaryExamChange(newVal: number) {
            if (newVal) {
                this.selectedExamId = this.examDto.primaryExamId;
            }
        }

        private async mounted() {
            await this.loadReferralSubtypes();
            await this.getPrimaryExams();
        }
    }
</script>
