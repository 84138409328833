import { render, staticRenderFns } from "./NhisQuestion.vue?vue&type=template&id=415d7535&scoped=true"
import script from "./NhisQuestion.vue?vue&type=script&lang=ts"
export * from "./NhisQuestion.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "415d7535",
  null
  
)

export default component.exports