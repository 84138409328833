<template>
    <exam-layout
        :can-delete="document.id !== 0"
        :can-print="document.id !== 0"
        custom-button-text="Печат - копие"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler('Original')"
        @printed-without-preview="printWithoutPreviewHandler('Original')"
        @customButtonClick="printWithoutPreviewHandler('Copy')"
    >
        <v-card>
            <main-title>{{ title }} {{ document.number }}</main-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-card-text>
                            <invoice-recipient-search
                                v-model="document.invoiceRecipient"
                                :practice-id="practiceId ?? document.practiceId"
                            />
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="6" cols="12">
                        <v-card-title> Клиент </v-card-title>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <text-field v-model="document.invoiceRecipient.name" label="Име" required />
                                </v-col>
                                <v-col cols="12">
                                    <text-field v-model="document.invoiceRecipient.cityName" label="Град" required />
                                </v-col>
                                <v-col cols="12">
                                    <text-field v-model="document.invoiceRecipient.address" label="Адрес" required />
                                </v-col>
                                <v-col cols="12">
                                    <text-field v-model="document.invoiceRecipient.managerName" label="МОЛ" required />
                                </v-col>
                                <v-col cols="6">
                                    <text-field v-model="document.invoiceRecipient.eik" label="ЕИК" required />
                                </v-col>
                                <v-col cols="6">
                                    <text-field v-model="document.invoiceRecipient.vat" label="ДДС номер" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                    <v-col md="6" cols="12">
                        <v-card-title> Издател </v-card-title>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <text-field v-model="document.practice.name" label="Име" disabled />
                                </v-col>
                                <v-col cols="12">
                                    <city-picker v-model="document.practice.cityId" label="Град" disabled />
                                </v-col>
                                <v-col cols="12">
                                    <text-field v-model="document.practice.address" label="Адрес" disabled />
                                </v-col>
                                <v-col cols="12">
                                    <text-field v-model="document.practice.managerName" label="МОЛ" disabled />
                                </v-col>
                                <v-col cols="6">
                                    <text-field v-model="document.practice.eik" label="ЕИК" disabled />
                                </v-col>
                                <v-col cols="6">
                                    <text-field v-model="document.practice.vatNumber" label="ДДС номер" disabled />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-row>
                    <v-card-title> Банкова сметка </v-card-title>
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col cols="3">
                                <text-field v-model="document.practice.bankInformation.iban" label="IBAN" disabled />
                            </v-col>
                            <v-col cols="2">
                                <text-field v-model="document.practice.bankInformation.bic" label="BIC" disabled />
                            </v-col>
                            <v-col cols="7">
                                <text-field v-model="document.practice.bankInformation.name" label="Име" disabled />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-row>
                <v-row class="no-gutters">
                    <v-col cols="3">
                        <text-field
                            v-model="document.number"
                            label="Номер на фактура"
                            required
                            :rules="[$validator.maxLength(10), $validator.digitsOnly]"
                        />
                    </v-col>
                    <v-col cols="3">
                        <date-picker v-model="document.invoiceDate" label="Дата на издаване" required />
                    </v-col>
                    <v-col cols="3">
                        <dropdown
                            v-model="document.invoiceTypeCode"
                            item-value="code"
                            :items="invoiceTypes"
                            label="Тип"
                            required
                            disabled
                        />
                    </v-col>
                    <v-col cols="3">
                        <date-picker v-model="document.taxDate" label="Дата на данъчно събитие" required />
                    </v-col>
                    <v-col cols="3">
                        <date-picker v-model="document.paymentDate" label="Дата на падеж" required />
                    </v-col>
                    <v-col cols="3">
                        <dropdown
                            v-model="document.paymentMethodCode"
                            item-value="code"
                            :items="paymentMethodTypes"
                            label="Начин на плащане"
                            required
                        />
                    </v-col>
                    <v-col cols="6">
                        <dropdown
                            v-model="document.vatChargeTypeId"
                            :items="vatChargeTypes"
                            label="Основание за неначисляване на ДДС"
                            required
                        />
                    </v-col>
                    <v-col cols="6">
                        <text-field v-model="document.transactionPlace" label="Място на сделката" />
                    </v-col>
                    <v-col cols="3">
                        <text-field v-model="document.parentInvoiceNumber" label="Към фактура" readonly />
                    </v-col>
                    <v-col cols="3">
                        <date-field v-model="document.parentInvoiceDate" label="Дата фактура" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <invoice-lines v-model="document" :is-vat-register="isVatRated" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </exam-layout>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DateField from '@/component/Date/DateField.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import CityPicker from '@/component/Picker/CityPicker.vue';
    import { VatTypeCode } from '@/enum/Nomenclature/VatTypeCode';
    import { InvoiceDto } from '@/model/Finance/Invoice/InvoiceDto';
    import { InvoiceTypeDto } from '@/model/Nomenclature/InvoiceTypeDto';
    import { PaymentMethodDto } from '@/model/Nomenclature/PaymentMethodDto';
    import { VatChargeTypeDto } from '@/model/Nomenclature/VatChargeTypeDto';
    import { invoiceService } from '@/service/Invoice/InvoiceService';
    import { invoiceTypeService } from '@/service/Nomenclature/InvoiceTypeService';
    import { paymentMethodService } from '@/service/Nomenclature/PaymentMethodService';
    import { vatChargeTypeService } from '@/service/Nomenclature/VatChargeTypeService';
    import { printService } from '@/service/PrintService';
    import InvoiceLines from '@/view/Finance/InvoiceLines.vue';
    import InvoiceRecipientSearch from '@/view/Finance/InvoiceRecipientSearch.vue';

    @Component({
        components: { CityPicker, DateField, InvoiceRecipientSearch, ExamLayout, InvoiceLines }
    })
    export default class Invoice extends Vue {
        @Prop()
        practiceId!: number;

        @Prop()
        id!: number;

        @Prop()
        documentType!: string;

        private document: InvoiceDto = new InvoiceDto();
        private invoiceTypes: InvoiceTypeDto[] = [];
        private paymentMethodTypes: PaymentMethodDto[] = [];
        private vatChargeTypes: VatChargeTypeDto[] = [];

        private async mounted() {
            await this.load();
        }

        private get title() {
            return this.documentType === 'CreditNote'
                ? 'Кредитно известие'
                : this.documentType === 'DebitNote'
                  ? 'Дебитно известие'
                  : 'Фактура';
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                if (!this.validateLines()) {
                    if (this.document.id) {
                        await invoiceService.update(this.document);
                    } else {
                        const id = await invoiceService.create(this.document);
                        this.$router.push(`/Finance/Invoice/Edit/${id}`);
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private validateLines() {
            return this.document?.invoiceLines.some((line) => {
                if (!line.description) {
                    this.$notifier.showWarning(
                        this.$t('notifications.validationErrors').toString(),
                        this.$t('notifications.pleaseFixValidationErrors').toString()
                    );
                    return true;
                }
                return false;
            });
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                await invoiceService.delete(this.id);
                this.$router.push(`/Finance/Invoice/${this.document.practiceId}`);
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler(invoiceType: string) {
            if (this.id) {
                this.$router.push(`/Print/Invoice.Invoice/Invoice/${invoiceType}/${this.id}`);
            }
        }

        private async printWithoutPreviewHandler(invoiceType: string) {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.id.toString();
                parameters.invoiceType = invoiceType;
                const report = 'Invoice.Invoice';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async load() {
            this.$loading.show();
            try {
                const invoiceNumberLength = 10;
                await this.getVatChargeTypes();
                await this.getPaymentMethods();
                await this.getInvoiceTypes();
                if (this.documentType && this.id) {
                    await this.getCreditDebitNoteById();
                } else if (this.id) {
                    await this.getInvoiceById();
                    this.documentType = this.document.invoiceTypeCode ?? 'Invoice';
                } else {
                    await this.getInvoiceInfo();
                }
                this.document.number = String(this.document.number).padStart(invoiceNumberLength, '0');
            } finally {
                this.$loading.hide();
            }
        }

        private get isVatRated() {
            const vatType = this.vatChargeTypes.find((vatItem) => vatItem.id === (this.document?.vatChargeTypeId ?? 0));
            return !(
                // Чл.39 от ЗДДС - доставката е освободена; свързана е със здравеопазване.
                (
                    vatType?.code === VatTypeCode.DefaultIsVat ||
                    // Чл.113, ал.9 от ЗДДС - лицето не е регистрирано по ЗДДС.
                    vatType?.code === VatTypeCode.DefaultNoVat ||
                    // Чл.113, ал.9 от ЗДДС- лицето е регистрирано само на основание чл.97а (услуги) от ЗДДС и няма право да начислява ДДС.
                    (vatType !== null && vatType?.code === null)
                )
            );
        }

        private async getInvoiceTypes() {
            this.invoiceTypes = await invoiceTypeService.getInvoiceTypes();
        }

        private async getPaymentMethods() {
            this.paymentMethodTypes = await paymentMethodService.getPaymentMethods();
        }

        private async getVatChargeTypes() {
            this.vatChargeTypes = await vatChargeTypeService.getVatChargeTypes();
        }

        private async getInvoiceInfo() {
            this.document = await invoiceService.getInvoiceInfoByPractice(this.practiceId);
        }

        private async getInvoiceById() {
            this.document = await invoiceService.getById(this.id);
        }

        private async getCreditDebitNoteById() {
            this.document = await invoiceService.getCreditDebitNoteById(this.documentType, this.id);
        }
    }
</script>

<style scoped></style>
