<template>
    <div>
        <v-card>
            <v-card-title>
                {{
                    model.doctorDeputyKey.deputyDoctorIsHired
                        ? 'Нает лекар'
                        : model.deputyDoctorIsEmployee
                        ? 'Заместващ служител'
                        : 'Заместващ лекар'
                }}: {{ model.deputyDoctorName }}, УИН {{ model.deputyDoctorUin }}<br />
                Титуляр: {{ model.primaryDoctorEmployee.employeeTitle }}
            </v-card-title>

            <v-card-actions class="pt-0 px-4">
                <btn action="Back" :to="backRoute" />

                <template v-if="allowWriteSelf">
                    <!-- Когато заместващият лекар се е саморегистрирал, първоначално потребителят му очаква одобрение.
                        В този случай надписът на бутона е по-конкретен, но извиква същото API за запис. -->
                    <btn
                        v-if="selectedUserId && selectedUserId == model.deputyUserId && model.deputyUserIsPending"
                        action="Success"
                        @click="updateDoctorDeputy"
                    >
                        Одобряване на потребителя
                    </btn>
                    <!-- Бутон "Запис" е активен, ако е избран редовен radio-бутон, а не за търсене на потребител,
                        както и ако при търсенето е открит потребител, дори да е с кратко потребителско име. -->
                    <btn
                        v-else-if="!userSearchIsVisible || (userSearch.result && userSearch.result.userExists)"
                        action="Save"
                        @click="updateDoctorDeputy"
                    ></btn>
                    <!-- По време на търсенето или при невалиден имейл адрес се показва неактивен бутон "Запис". -->
                    <btn v-else-if="!userSearch.result || !userSearch.isValidEmail" action="Save" disabled></btn>
                    <!-- Трябва да се изпрати покана, но ако е надвишен лимитът за изпращане, бутонът е неактивен. -->
                    <btn
                        v-else
                        action="Save"
                        :disabled="Boolean(userSearch.result.sendInvitationWarning)"
                        @click="inviteDeputyUser"
                    >
                        Изпращане на покана за регистриране в Хипократ
                    </btn>
                    <v-spacer></v-spacer>

                    <btn
                        v-if="model.schedules.length === 0 && model.visitCountAsDeputy === 0"
                        action="Delete"
                        @click="deleteUnusedDoctorDeputy"
                    />
                </template>

                <alert :value="model.visitCountAsDeputy > 0" type="info" class="mb-0">
                    {{ model.doctorDeputyKey.deputyDoctorIsHired ? 'Наетият' : 'Заместващият' }}
                    лекар е направил {{ model.visitCountAsDeputy }}
                    {{ model.schedules.length === 0 ? 'прегледа, затова не може да бъде изтрит.' : 'прегледа.' }}
                </alert>
            </v-card-actions>

            <v-card-title v-if="model.doctorDeputyKey.deputyDoctorIsHired">
                Потребител на наетия лекар, който има право да работи
            </v-card-title>
            <v-card-title v-else-if="model.deputyDoctorIsEmployee">
                Потребител на заместващия служител, който има право да замества
            </v-card-title>
            <v-card-title v-else>Потребител на заместващия лекар</v-card-title>

            <v-card-text>
                <alert :value="!$loading.isVisible && !model.usersRelatedToDoctor.length" type="info">
                    {{
                        model.doctorDeputyKey.deputyDoctorIsHired
                            ? 'Наетият лекар'
                            : model.deputyDoctorIsEmployee
                            ? 'Заместващият служител'
                            : 'Заместващият лекар'
                    }}
                    все още не се е регистрирал в Хипократ (няма потребител).
                </alert>

                <v-radio-group v-if="!$loading.isVisible" v-model="selectedUserId" class="mt-0">
                    <!-- Опция за стойност null, т.е. не е конкретизиран потребителят. -->
                    <v-radio v-if="model.usersRelatedToDoctor.length" :value="null">
                        <template v-if="model.deputyDoctorIsEmployee" #label>
                            Всеки потребител(имейл адрес), упълномощен от
                            {{ model.doctorDeputyKey.deputyDoctorIsHired ? 'наетия лекар' : 'зам. служителя' }}, има
                            право да работи.
                        </template>
                        <template v-else #label>
                            Заместващият лекар сам ще се регистрира в Хипократ с друг потребител(имейл адрес).
                        </template>
                    </v-radio>
                    <v-radio v-else :value="null">
                        <template v-if="model.deputyDoctorIsEmployee" #label>
                            Когато се регистрира, всеки потребител(имейл адрес), упълномощен от
                            {{ model.doctorDeputyKey.deputyDoctorIsHired ? 'наетия лекар' : 'зам. служителя' }}, ще има
                            право да работи.
                        </template>
                        <template v-else #label>Заместващият лекар сам ще се регистрира в Хипократ</template>
                    </v-radio>

                    <v-radio
                        v-for="user in model.usersRelatedToDoctor"
                        :key="user.userId"
                        :value="user.userId"
                        :label="user.personAndUserName"
                    ></v-radio>

                    <!-- Опция за търсене и избор на друг потребител. -->
                    <v-radio
                        :value="-1"
                        :label="`Искам да избера или да поканя ${
                            model.usersRelatedToDoctor.length + model.invitedEmails.length ? 'друг ' : ''
                        }потребител(имейл адрес)...`"
                    ></v-radio>
                </v-radio-group>

                <user-search v-if="allowWriteSelf && userSearchIsVisible" v-model="userSearch" />

                <div v-for="email in model.invitedEmails" :key="email" :value="email">
                    Потребител <strong>{{ email }}</strong> е поканен. Трябва да се регистрира.
                    <btn icon action="Delete" @click="deleteDoctorDeputyInvitation(email)">Изтриване на поканата</btn>
                </div>
            </v-card-text>
        </v-card>

        <doctor-deputy-schedule v-model="model" class="mt-4"></doctor-deputy-schedule>
    </div>
</template>

<script lang="ts">
    import { Component, Prop } from 'vue-property-decorator';

    import { DoctorDeputyEditDto } from '@/model/Employee/Deputy/DoctorDeputyEditDto';
    import { DoctorDeputyKey } from '@/model/Employee/Deputy/DoctorDeputyKey';
    import { DoctorDeputyUpdateCommand } from '@/model/Employee/Deputy/DoctorDeputyUpdateCommand';
    import { EmployeeUserDto } from '@/model/Employee/EmployeeUserDto';
    import { UserSearchModel } from '@/model/Employee/Invitation/UserSearchModel';
    import { doctorDeputyService } from '@/service/Employee/Deputy/DoctorDeputyService';
    import { EmployeeBase } from '@/view/Employee/EmployeeBase';
    import UserSearch from '@/view/Employee/UserSearch.vue';

    import DoctorDeputySchedule from './DoctorDeputySchedule.vue';

    @Component({
        components: { DoctorDeputySchedule, UserSearch }
    })
    export default class DoctorDeputyEdit extends EmployeeBase {
        @Prop()
        private doctorDeputyKey!: DoctorDeputyKey;

        private model: DoctorDeputyEditDto = new DoctorDeputyEditDto();

        private selectedUserId: number | null = null;
        private usersRelatedToDoctor: EmployeeUserDto[] = [];
        private userSearch: UserSearchModel = new UserSearchModel(this.checkDoctorDeputyExistance);

        private mounted() {
            return this.loadDoctorDeputyForEdit();
        }

        private async loadDoctorDeputyForEdit() {
            this.$loading.show();
            try {
                this.model = await doctorDeputyService.getDoctorDeputyForEdit(this.doctorDeputyKey);
                this.selectedUserId = this.model.deputyUserId;
            } finally {
                this.$loading.hide();
            }
        }

        private get allowWriteSelf() {
            return this.allowWrite(this.model.doctorDeputyKey.primaryDoctorEmployeeSeqNumber);
        }

        private get userSearchIsVisible() {
            const anotherUser = -1;
            return this.selectedUserId === anotherUser;
        }

        private checkDoctorDeputyExistance() {
            return doctorDeputyService.checkDoctorDeputyExistance(
                this.doctorDeputyKey,
                this.userSearch.emailOrUserName.trim()
            );
        }

        private async updateDoctorDeputy() {
            this.$loading.show();
            try {
                const command = new DoctorDeputyUpdateCommand();
                command.doctorDeputyKey = this.doctorDeputyKey;
                if (this.userSearchIsVisible) {
                    command.otherEmailOrUserName = this.userSearch.emailOrUserName.trim();
                } else {
                    command.deputyUserId = this.selectedUserId;
                }

                await doctorDeputyService.updateDoctorDeputy(command);

                this.$router.push(this.backRoute);
            } finally {
                this.$loading.hide();
            }
        }

        private async inviteDeputyUser() {
            this.$loading.show();
            try {
                const command = new DoctorDeputyUpdateCommand();
                command.doctorDeputyKey = this.doctorDeputyKey;
                command.otherEmailOrUserName = this.userSearch.emailOrUserName;

                await doctorDeputyService.updateDoctorDeputy(command);

                this.userSearch.emailOrUserName = '';
            } finally {
                this.$loading.hide();
            }
            await this.loadDoctorDeputyForEdit();
        }

        private async deleteDoctorDeputyInvitation(email: string) {
            this.$loading.show();
            try {
                await doctorDeputyService.deleteDoctorDeputyInvitation(this.doctorDeputyKey, email);
            } finally {
                this.$loading.hide();
            }
            await this.loadDoctorDeputyForEdit();
        }

        private async deleteUnusedDoctorDeputy() {
            this.$loading.show();
            try {
                await doctorDeputyService.deleteUnusedDoctorDeputy(this.doctorDeputyKey);
            } finally {
                this.$loading.hide();
            }
            this.$router.push(this.backRoute);
        }

        private get backRoute() {
            const ddKey = this.model.doctorDeputyKey;
            return `/Employee/Edit/${ddKey.practiceId}/${ddKey.primaryDoctorEmployeeSeqNumber}`;
        }
    }
</script>
