import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
import { currentPatientCache } from '@/store/CurrentPatientCache';
import { userContextCache } from '@/store/User/UserContextCache';

import { MenuItemModel } from './MenuItemModel';

const addDeclarationMenuItems = (menuItems: MenuItemModel[], patientId: number) => {
    const currentPatient = currentPatientCache.value;
    menuItems.push(
        new MenuItemModel(
            'GDPR декларация',
            'mdi-shield-account-outline',
            `/Print/Patient.GdprDeclaration/${patientId}`,
            { class: currentPatient.isDeclarationGdprPrinted ? 'success2' : '' }
        ),
        new MenuItemModel(
            'Декларация е-подпис',
            'mdi-shield-account-outline',
            `/Print/Patient.DeclarationESign/DeclarationESign/${null}/${patientId}`,
            { class: currentPatient.isDeclarationEsignPrinted ? 'success2' : '' }
        )
    );
};

const addSpecialMenuItems = (menuItems: MenuItemModel[], patientId: number, doctorEmployeeSeqNumber: number) => {
    menuItems.push(
        new MenuItemModel(
            'Изтеглени входящи направления',
            'mdi-book-account-outline',
            `/Patient/IncomingReferralPatient/${patientId}/${doctorEmployeeSeqNumber}`
        )
    );
};

export const createPatientMenuItems = () => {
    const { current } = userContextCache;
    if (!current) {
        return [];
    }
    const { practiceId, employeeSeqNumber, specialtyCode } = current;
    const currentPatient = currentPatientCache.value;
    const { patientId } = currentPatient.key;

    const menuItems = [
        new MenuItemModel('Посещения', 'mdi-stethoscope', `/Patient/VisitSummary/${patientId}`),
        new MenuItemModel('Пациент', '', ''),
        new MenuItemModel('Основни данни', 'mdi-badge-account-outline', `/Patient/PersonalData/${patientId}`),
        new MenuItemModel('Месторабота', 'mdi-briefcase-outline', `/Patient/Workplace/${patientId}`),
        new MenuItemModel('Е-рецептурна книжка', 'mdi-book-open-outline', `/Patient/NhifPrescriptionBook/${patientId}`),
        new MenuItemModel(
            'Рец. книжки ветерани|военноинв.',
            'mdi-book-open-page-variant-outline',
            `/Patient/PrescriptionBook/${patientId}`
        ),
        new MenuItemModel('Предишен лекар', 'mdi-doctor', `/Patient/PreviousGp/${patientId}`),
        new MenuItemModel('Рискови групи', 'mdi-account-alert', `/Patient/RiskGroup/${patientId}`),
        new MenuItemModel('Алергии', 'mdi-allergy', `/Patient/Allergy/${patientId}`),
        new MenuItemModel('Фамилна обремененост', 'mdi-human-male-female-child', `/Patient/FamilyHistory/${patientId}`),
        new MenuItemModel('Диспансеризации', 'mdi-human-cane', `/Patient/Dispensary/${practiceId}/${patientId}`),
        new MenuItemModel(
            'Освоб. от потребителска такса',
            'mdi-cash-lock-open',
            `/Patient/FeeDisengagement/${patientId}`
        ),
        new MenuItemModel('Частен фонд', 'mdi-cash', `/Patient/PatientFund/${patientId}`),
        new MenuItemModel('Имунизации', 'mdi-bottle-tonic-outline', `/Patient/Immunization/${patientId}`),
        new MenuItemModel('Е-Имунизации', 'mdi-bottle-tonic-plus-outline', `/Patient/EImmunizations/${patientId}`),
        new MenuItemModel('История на заболявания', 'mdi-hospital-box-outline', `/Patient/ExternalVisit/${patientId}`),
        new MenuItemModel('Прикачени документи', 'mdi-clipboard-file-outline', `/Patient/FileUpload/${patientId}`, {
            class: currentPatient.hasFiles ? 'success2' : ''
        }),
        new MenuItemModel('Съобщение за смърт', 'mdi-script-outline', `/Patient/DeathNotice/${patientId}`)
    ];

    addDeclarationMenuItems(menuItems, patientId);
    if (specialtyCode !== SpecialtyCode.Gp) {
        addSpecialMenuItems(menuItems, patientId, employeeSeqNumber);
    }
    return menuItems;
};
