import { Type } from 'class-transformer';

import { NhisPatientImmunizationKey } from './NhisPatientImmunizationKey';

export class NhisPatientImmunizationGetXmlCommand {
    @Type(() => NhisPatientImmunizationKey)
    public key: NhisPatientImmunizationKey;

    constructor(key: NhisPatientImmunizationKey, public doctorUin: string, public deputyDoctorUin: string | null) {
        this.key = key;
    }
}
