<template>
    <v-card>
        <v-card-title>Данни за електронната имунизация</v-card-title>
        <v-card-text>
            <v-row v-if="value">
                <v-col md="3">
                    <v-text-field
                        v-model="value.nrn"
                        dense
                        :class="nrnBackground"
                        label="НРН номер"
                        readonly
                    ></v-text-field>
                </v-col>
                <v-col md="3">
                    <text-field v-model="value.statusName" dense label="Статус на имунизацията" readonly></text-field>
                </v-col>
                <v-col md="3">
                    <date-field v-model="value.statusDate" dense label="Дата на последна проверка" disabled />
                </v-col>
            </v-row>
            <v-card-actions>
                <v-row>
                    <v-col v-if="canPost" md="3">
                        <btn action="Send" block :disabled="disable || isLoading" @click="postImmunization">
                            Изпращане
                        </btn>
                    </v-col>
                    <v-col v-if="canCancel" md="3">
                        <btn action="Delete" block :disabled="disable || isLoading" @click="cancelImmunization">
                            Анулиране
                        </btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { NhisPrescriptionStatusCode } from '@/enum/Nhis/NhisPrescriptionStatusCode';
    import { NhisPatientImmunizationKey } from '@/model/Nhis/Immunization/NhisPatientImmunizationKey';
    import { NhisImmunizationDto } from '@/model/Patient/Immunization/NhisImmunizationDto';
    import { nhisImmunizationService } from '@/service/Nhis/NhisImmunizationService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';

    @Component
    export default class NhisImmunization extends Vue {
        @Prop({ type: Object })
        private value!: NhisImmunizationDto;

        @Prop()
        private immunizationKey!: NhisPatientImmunizationKey;

        @Prop()
        private isNhisNew!: boolean;

        @Prop()
        private disable!: boolean;

        private get isLoading() {
            return this.$loading.isVisible;
        }

        private async postImmunization() {
            const response = await nhisImmunizationService.postImmunization(this.immunizationKey, this.isNhisNew);
            if (response) {
                this.change(response);
            }
        }

        private async cancelImmunization() {
            const response = await nhisImmunizationService.cancelImmunization(this.immunizationKey);
            if (response) {
                this.change(response);
            }
        }

        private get canPost() {
            return (
                this.immunizationKey.immunizationSeqNumber > 0 &&
                !this.value.statusCode &&
                currentVisitCache.value.exam?.nrn &&
                currentVisitCache.value.exam.statusCode !== NhisExamStatusCode.Canceled
            );
        }

        private get canCancel() {
            return this.value?.statusCode?.length > 0 && this.value?.statusCode === NhisPrescriptionStatusCode.Active;
        }

        private change(data: NhisImmunizationDto) {
            this.$emit('input', data);
        }

        private get nrnBackground() {
            return this.value.statusCode === NhisPrescriptionStatusCode.Active
                ? 'text-field-success'
                : this.value.statusCode === NhisPrescriptionStatusCode.Canceled
                ? 'text-field-error'
                : '';
        }
    }
</script>
