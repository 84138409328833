<template>
    <div>
        <v-tabs class="mt-5">
            <v-tab>Имунизации</v-tab>
            <v-tab v-if="immunizationKey && showReactions && canCreate">Нежелани реакции</v-tab>
            <v-tab-item>
                <NhisImmunizationCreateEditor
                    ref="immunizationEditor"
                    :immunization-key="immunizationKey"
                    :exam-id="examId"
                    @input="changeNrn"
                    @hook:mounted="onImmunizationEditorMounted"
                />
            </v-tab-item>
            <v-tab-item v-if="immunizationKey && showReactions && canCreate">
                <NhisImmunizationReactionView :immunization-key="immunizationKey" />
            </v-tab-item>
        </v-tabs>
        <UnsavedChanges ref="leaveDialog" />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import { NhisPatientImmunizationKey } from '@/model/Nhis/Immunization/NhisPatientImmunizationKey';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';
    import NhisImmunizationCreateEditor from '@/view/Nhis/Immunization/NhisImmunizationCreateEditor.vue';
    import NhisImmunizationReactionView from '@/view/Nhis/Immunization/NhisImmunizationReactionView.vue';

    @Component({
        components: { UnsavedChanges, NhisImmunizationCreateEditor, NhisImmunizationReactionView },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(
                this.$data.immunizationEditorRef.initialValue,
                this.$data.immunizationEditorRef.model,
                next
            );
        }
    })
    export default class NhisImmunizationEditor extends Vue {
        @Ref()
        readonly immunizationEditor!: NhisImmunizationCreateEditor;

        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private examId!: number;

        @Prop()
        private immunizationKey!: NhisPatientImmunizationKey;

        private showReactions: boolean = false;
        private immunizationEditorRef: NhisImmunizationCreateEditor = new NhisImmunizationCreateEditor();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private changeNrn(): void {
            this.showReactions = true;
        }

        private get canCreate() {
            return this.$route.path.includes('Exam');
        }

        private onImmunizationEditorMounted() {
            this.immunizationEditorRef = this.immunizationEditor;
        }

        private mounted() {
            this.leaveDialogRef = this.leaveDialog;
        }
    }
</script>
