import { Type } from 'class-transformer';

import { NhisPatientImmunizationKey } from '@/model/Nhis/Immunization/NhisPatientImmunizationKey';
import { SubmitCommand } from '@/model/Nhis/SubmitCommand';

export class NhisPatientImmunizationSubmitCommand extends SubmitCommand {
    @Type(() => NhisPatientImmunizationKey)
    public immunizationKey: NhisPatientImmunizationKey;

    constructor(xmlString: string, accessTokenValue: string, immunizationKey: NhisPatientImmunizationKey) {
        super(xmlString, accessTokenValue);
        this.immunizationKey = immunizationKey;
    }
}
