import { plainToInstance } from 'class-transformer';

import { RuleFilterDto } from '@/model/Exam/RuleFilterDto';
import { PatientKey } from '@/model/Patient/PatientKey';
import { DispensaryRegisterDto } from '@/model/Register/Dispensary/DispensaryRegisterDto';
import { DispensaryRuleDto } from '@/model/Register/Dispensary/DispensaryRuleDto';
import { PatientRegisterKey } from '@/model/Register/PatientRegisterKey';
import { httpService } from '@/service/Infrastructure/HttpService';

export const dispensaryRegisterService = {
    async getByDoctorEmployee(practiceId: number, doctorEmployeeSeqNumber: number): Promise<DispensaryRegisterDto[]> {
        const response = await httpService.get<DispensaryRegisterDto[]>('/DispensaryRegister/GetByDoctorEmployee', {
            params: { practiceId, doctorEmployeeSeqNumber }
        });
        return plainToInstance(DispensaryRegisterDto, response.data);
    },

    async getByPatient(
        patientKey: PatientKey,
        currentDoctorEmployeeSeqNumber: number
    ): Promise<DispensaryRegisterDto[]> {
        const response = await httpService.get<DispensaryRegisterDto[]>('/DispensaryRegister/GetByPatient', {
            params: { ...patientKey, currentDoctorEmployeeSeqNumber }
        });
        return plainToInstance(DispensaryRegisterDto, response.data);
    },

    exportToExcel(practiceId: number, doctorEmployeeSeqNumber: number): Promise<void> {
        return httpService.download('/DispensaryRegister/ExportToExcel', {
            params: { practiceId, doctorEmployeeSeqNumber }
        });
    },

    async getDispensaryRegister(key: PatientRegisterKey): Promise<DispensaryRegisterDto> {
        const response = await httpService.get<DispensaryRegisterDto>('/DispensaryRegister/GetDispensaryRegister', {
            params: key
        });
        return plainToInstance(DispensaryRegisterDto, response.data);
    },

    async createDispensaryRegister(data: DispensaryRegisterDto): Promise<number> {
        const response = await httpService.post<number>('/DispensaryRegister/CreateDispensaryRegister', data);
        return response.data;
    },

    async updateDispensaryRegister(data: DispensaryRegisterDto): Promise<void> {
        await httpService.put('/DispensaryRegister/UpdateDispensaryRegister', data);
    },

    async deleteDispensaryRegister(key: PatientRegisterKey): Promise<void> {
        await httpService.delete('/DispensaryRegister/DeleteDispensaryRegister', { params: key });
    },

    async searchDispensaryRule(data: RuleFilterDto): Promise<DispensaryRuleDto[]> {
        const response = await httpService.post<DispensaryRuleDto[]>('/DispensaryRegister/SearchDispensaryRule', data);
        return plainToInstance(DispensaryRuleDto, response.data);
    },

    async getNextDispensaryNumber(practiceId: number, doctorEmployeeSeqNumber: number): Promise<number> {
        const response = await httpService.get<number>('/DispensaryRegister/GetNextDispensaryNumber', {
            params: { practiceId, doctorEmployeeSeqNumber }
        });
        return response.data;
    }
};
