<template>
    <data-table
        title="Диспансерен регистър"
        :headers="headers"
        :items="dispensaryRegisters"
        show-search-box
        :item-class="itemRowBackground"
    >
        <template #actions>
            <btn
                v-if="$allowWrite"
                action="New"
                :to="`/Register/DispensaryRegister/Create/${practiceId}/${doctorEmployeeSeqNumber}`"
            >
            </btn>
            <btn action="Export" @click="exportToExcel()">Експорт</btn>
            <btn v-if="isDevOrTest" action="Refresh" @click="fetchLongtermCare()">Извличане НЗИС</btn>
        </template>
        <template #rowActions="{ item }">
            <btn
                v-if="$allowWrite"
                icon
                action="Edit"
                :to="`/Register/DispensaryRegister/Edit/${practiceId}/${item.key.patientId}/${item.key.seqNumber}`"
            />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { config } from '@/Config';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NhisLongtermCareXmlRequestDto } from '@/model/Nhis/LongtermCare/NhisLongtermCareXmlRequestDto';
    import { DispensaryRegisterDto } from '@/model/Register/Dispensary/DispensaryRegisterDto';
    import { nhisLongtermCareService } from '@/service/Nhis/NhisLongtermCareService';
    import { dispensaryRegisterService } from '@/service/Register/DispensaryRegisterService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class DispensaryRegister extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private dispensaryRegisters: DispensaryRegisterDto[] = [];

        private headers: IDataTableHeader[] = [
            { text: 'ЕГН', value: 'patientIdentifier' },
            { text: 'Име', value: 'patientName' },
            { text: 'Регистрация', value: 'registerDate', filterable: false, formatter: formatters.date },
            { text: 'Досие', value: 'dossierNumber' },
            { text: 'МКБ', value: 'icdCode' },
            { text: 'МКБ 2', value: 'icdCodeAdd' },
            { text: 'Възраст', value: 'ageBracket' },
            { text: 'Отписване', value: 'deregisterDate', filterable: false, formatter: formatters.date },
            { text: 'Предложения за предстоящи прегледи', value: 'suggestedFutureExams', filterable: false },
            { text: '', value: 'actionButtons', sortable: false }
        ];

        private get isDevOrTest() {
            return config.isDevOrTest;
        }

        private async load() {
            this.$loading.show();
            try {
                this.dispensaryRegisters = await dispensaryRegisterService.getByDoctorEmployee(
                    this.practiceId,
                    this.doctorEmployeeSeqNumber
                );
            } finally {
                this.$loading.hide();
            }
        }

        private async mounted() {
            await this.load();
        }

        private async exportToExcel() {
            if (this.doctorEmployeeSeqNumber) {
                this.$loading.show();
                try {
                    await dispensaryRegisterService.exportToExcel(this.practiceId, this.doctorEmployeeSeqNumber);
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private itemRowBackground(item: DispensaryRegisterDto): string {
            if (item.deregisterDate !== null) {
                return 'style-deregistered';
            }
            return 'style-main';
        }

        private async fetchLongtermCare() {
            const response = await nhisLongtermCareService.postFetchLongtermCare(this.createCommand());
            if (response) {
                await this.load();
            }
        }

        private createCommand() {
            const command = new NhisLongtermCareXmlRequestDto();
            command.deputyDoctorIsHired = userContextCache.current?.deputyDoctorIsHired ?? null;
            command.deputyDoctorUin = userContextCache.current?.deputyDoctorUin ?? null;
            command.doctorUin = userContextCache.current?.doctorUin ?? '';
            command.doctorSpecialtyCode = userContextCache.current?.specialtyCode ?? null;
            command.doctorEmployeeId = userContextCache.currentDoctorEmployeeId;
            command.doctorEmployeeSeqNumber = userContextCache.current?.employeeSeqNumber ?? 0;
            command.patientId = currentPatientCache.value.key.patientId;
            command.practiceId = userContextCache.currentPracticeId ?? 0;

            return command;
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../asset/Css/Register.css';
    /*По някаква причина долният импорт на по-новия формат css не работи. Трябва да търсим причината.*/
    /*@import '../../asset/Css/Register.scss'; */
</style>
