import { plainToInstance } from 'class-transformer';

import { PatientPreventiveDataDto } from '@/model/Patient/PatientPreventiveDataDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const patientPreventiveVisitService = {
    async getPreventiveActivities(
        patientId: number,
        practiceId: number,
        doctorEmployeeSeqNumber: number
    ): Promise<PatientPreventiveDataDto> {
        const response = await httpService.get<PatientPreventiveDataDto>(
            `/PatientPreventiveVisit/GetPreventiveActivities`,
            {
                params: { patientId, practiceId, doctorEmployeeSeqNumber }
            }
        );
        return plainToInstance(PatientPreventiveDataDto, response.data);
    },
    async save(patientData: PatientPreventiveDataDto): Promise<number> {
        const response = await httpService.post<number>(`/PatientPreventiveVisit/Save`, patientData);
        return response.data;
    }
};
