import { Type } from 'class-transformer';

import { BankInformationDto } from '@/model/Common/BankInformationDto';
import { DealerContactDto } from '@/model/Licensing/DealerContactDto';

import { FiscalMemoryDto } from './Fiscal/FiscalMemoryDto';

export class PracticeEditDto {
    public id: number = 0;
    public name: string = '';
    public eik: string = '';
    public oldEik: string = '';
    public practiceNumber: string = '';
    public oldPracticeNumber: string = '';
    public companyTypeId: number | null = null;
    // Не се записва в базата. Само подпомага избора на здравен район.
    public lookupRhifId: number | null = null;
    public rhifAreaId: number | null = null;
    public nhifCode: string = '';
    public nraAuthorityCode: string = '';
    public nssiAuthorityCode: string = '';
    public vatNumber: string = '';
    public cityId: number | null = null;
    public address: string = '';
    public managerName: string = '';
    public managerIdentifier: string = '';
    public geoLocation: string | null = '';
    public isInDisadvantagedRegion: boolean = false;

    @Type(() => FiscalMemoryDto)
    public fiscalMemories: FiscalMemoryDto[] = [];

    @Type(() => BankInformationDto)
    public bankInformation: BankInformationDto | null = new BankInformationDto();

    // Настройки.
    public hasReception: boolean = false;
    public supportedFundCodes: string[] = [];

    // Дилър, ако има.
    @Type(() => DealerContactDto)
    public dealerContact: DealerContactDto | null = null;
}
