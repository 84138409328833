<template>
    <ExamLayout
        :can-save="!disableEdit"
        :can-delete="!disableEdit && document.id > 0"
        :can-print="document.id > 0"
        title="Направление за Медико-Диагностична дейност"
        :custom-button-text="customButtonText"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @customButtonClick="quickAddHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <div v-if="limitRegStandards">
            <v-slide-y-transition mode="out-in">
                <regulatory-standard-progress
                    :title="'Стойност на бл.МЗ-НЗОК №4 ОБЩО'"
                    :used-reg-standards="usedRegStandards.worthReferral4AllTypes"
                    :max-reg-standards="limitRegStandards.worthReferral4AllTypes"
                />
            </v-slide-y-transition>
        </div>
        <div v-if="isSpecialist && mriUsedValue >= 0 && mriMaxValue > 0">
            <v-slide-y-transition mode="out-in">
                <regulatory-standard-progress
                    :title="'Стойност на бл.МЗ-НЗОК №4 ЯМР'"
                    :used-reg-standards="mriUsedValue"
                    :max-reg-standards="mriMaxValue"
                />
            </v-slide-y-transition>
        </div>
        <nhis-referral
            v-if="showNhisReferral"
            v-model="document.nhisReferral"
            :referral-id="document.id"
            :form-type="'Referral4'"
            @input="refreshRegulatoryStandardData"
        />
        <v-card class="mb-md-4" :disabled="disableEdit" :style="`background-color: ${backgroundColor}`">
            <main-title>Направление за Медико-Диагностична дейност</main-title>
            <v-card-text>
                <AmbulatorySheetHeaderInfoCard v-once :background-color="backgroundColor" />
            </v-card-text>
            <v-card-text>
                <v-row>
                    <v-col cols="3"> <text-field v-model="document.number" label="№" /></v-col>
                    <!--Направление за медико-диагностика №-->
                    <v-col cols="3">
                        <text-field v-model.number="ambulatorySheetNumber" disabled label="амбулаторен лист №" />
                    </v-col>
                    <v-col md="3">
                        <date-picker v-model="document.issueDate" label="Дата" required />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-col md="8">
                <v-select
                    v-model="document.referral4SubtypeId"
                    class="required"
                    :rules="[$validator.required]"
                    :items="referralSubtypes"
                    item-text="name"
                    item-value="id"
                    label="Тип посещение"
                />
            </v-col>
        </v-card>

        <v-card class="mb-md-4" :disabled="disableEdit" :style="`background-color: ${backgroundColor}`">
            <v-card-title>Изпраща се за</v-card-title>
            <v-card-text>
                <ExamDiagnosisPicker v-model="document.examDiagnosis" use-main-icd required />
                <Referral4FundService :value="document.fundServices" :date="document.issueDate" :ref4="document" />
            </v-card-text>
        </v-card>
        <v-spacer></v-spacer>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import ExamDiagnosisPicker from '@/component/Exam/ExamDiagnosisPicker.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import RegulatoryStandardProgress from '@/component/Exam/RegulatoryStandardProgress.vue';
    import { NhifVisitReasonCode } from '@/enum/Exam/NhifVisitReasonCode';
    import { ReferralTypeCode } from '@/enum/Exam/ReferralTypeCode';
    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { Referral4Dto } from '@/model/Exam/Referral/Referral4Dto';
    import { ReferralSubtypeDto } from '@/model/Nomenclature/ReferralSubtypeDto';
    import { CurrentRegulatoryStandardDoctorDto } from '@/model/Practice/RegulatoryStandard/CurrentRegulatoryStandardDoctorDto';
    import { RegulatoryStandardDoctorDto } from '@/model/Practice/RegulatoryStandard/RegulatoryStandardDoctorDto';
    import { examService } from '@/service/Exam/ExamService';
    import { referral4Service } from '@/service/Exam/Referral/Referral4Service';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { referralSubtypeService } from '@/service/Nomenclature/ReferralSubtypeService';
    import { regulatoryStandardService } from '@/service/Practice/RegulatoryStandardService';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { userSettingsState } from '@/store/User/UserSettingsState';
    import AmbulatorySheetHeaderInfoCard from '@/view/Exam/AmbulatorySheet/AmbulatorySheetHeaderInfoCard.vue';
    import NhisReferral from '@/view/Exam/Referral/NhisReferral.vue';
    import Referral4FundService from '@/view/Exam/Referral/Referral4/Referral4FundService.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: {
            AmbulatorySheetHeaderInfoCard,
            UnsavedChanges,
            NhisReferral,
            ExamDiagnosisPicker,
            ExamLayout,
            RegulatoryStandardProgress,
            Referral4FundService
        },
        async beforeRouteLeave(to, from, next) {
            if (this?.$data?.leaveDialogRef) {
                await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
            } else {
                next();
            }
        }
    })
    export default class Referral4 extends Vue {
        @Ref() readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private documentId!: number;

        @Prop()
        private examId!: number;

        private get ambulatorySheetNumber() {
            return currentVisitCache.isLoaded ? currentVisitCache.value.exam?.ambulatorySheetNumber : 'изчакайте...';
        }

        private referralSubtypes: ReferralSubtypeDto[] = [];
        private document: Referral4Dto = new Referral4Dto();
        private initialValue: Referral4Dto = new Referral4Dto();
        private limitRegStandards: CurrentRegulatoryStandardDoctorDto | null = null;
        private usedRegStandards: RegulatoryStandardDoctorDto = new RegulatoryStandardDoctorDto();
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private get mriUsedValue() {
            return this.document.fundServices.some((fs) => fs.code === '10.02' || fs.code === '10.92')
                ? this.usedRegStandards?.worthReferral4Mri
                : null;
        }

        private get mriMaxValue() {
            return this.document.fundServices.some((fs) => fs.code === '10.02' || fs.code === '10.92')
                ? this.limitRegStandards?.worthReferral4Mri
                : null;
        }

        private get backgroundColor() {
            return !userSettingsState.userSettings.ui.hasReferralsBackground
                ? 'transparent'
                : userSettingsState.userSettings.ui.isDarkTheme
                ? '#8f948e'
                : '#d5dcd4';
        }

        private get disableEdit() {
            return this.document?.nhisReferral?.nrn?.length > 0;
        }

        private get showNhisReferral() {
            return this.document.id > 0 && this.document.nhisReferral;
        }

        private get customButtonText() {
            return !this.document.id ? 'Бързо добавяне' : '';
        }

        private get isSpecialist() {
            return userContextCache.current?.specialtyCode !== SpecialtyCode.Gp;
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                await this.getReferral4Subtypes();
                await this.getRegulatoryStandards();
                if (!this.documentId) {
                    this.document = await referral4Service.getReferralInfoByExamId(this.examId);
                    await this.getCorrectReferralSubtype();
                } else {
                    this.document = await referral4Service.getReferral4(this.documentId);
                }
                await currentVisitCache.load(this.document.examId);
                this.getExamDiagnosisOnLoad();
            } finally {
                this.$loading.hide();
            }
        }

        private async getCorrectReferralSubtype() {
            const visitReasonCode = await examService.getExamVisitReason(this.examId);
            let subtypeName = '';
            switch (visitReasonCode) {
                case NhifVisitReasonCode.Dispensary: {
                    subtypeName = 'Диспансерно интердисциплинарно наблюдение';
                    break;
                }
                case NhifVisitReasonCode.ChildPrevention: {
                    subtypeName = 'Детско здравеопазване';
                    break;
                }
                case NhifVisitReasonCode.MotherPrevention: {
                    subtypeName = 'Майчино здравеопазване';
                    break;
                }
                case NhifVisitReasonCode.AdultPrevention: {
                    subtypeName = 'Профилактика на ЗОЛ над 18';
                    break;
                }
                default: {
                    subtypeName = 'Остро';
                }
            }
            this.document.referral4SubtypeId =
                this.referralSubtypes.find((type) => type.name.includes(subtypeName))?.id ??
                this.referralSubtypes[0].id;
        }

        private async getRegulatoryStandards() {
            if (currentVisitCache.isLoaded) {
                this.limitRegStandards = await regulatoryStandardService.getCurrentRegStandardForDoctor(
                    currentVisitCache.value.patientVersionKey.practiceId,
                    currentVisitCache.value.doctorEmployeeSeqNumber
                );
                if (this.limitRegStandards) {
                    this.usedRegStandards = await regulatoryStandardService.getUsedRegStandardByDoctor(
                        currentVisitCache.value.patientVersionKey.practiceId,
                        currentVisitCache.value.doctorEmployeeSeqNumber,
                        this.limitRegStandards.startDate ?? new Date(),
                        this.limitRegStandards.endDate ?? new Date()
                    );
                }
            }
        }

        private async updateEventHandler() {
            if (this.document.fundServices.length === 0) {
                this.$notifier.showWarning('Грешка при запис', 'Моля изберете поне едно изследване');
                return;
            }

            this.$loading.show();
            try {
                let documentId = this.document.id;
                let title = `№ ${this.document.number};`;
                for (const fundService of this.document.fundServices) {
                    if (fundService.name) {
                        title += ` ${fundService.code};`;
                    }
                }
                if (documentId === 0) {
                    const refResponse = await referral4Service.createReferral4(this.document);
                    documentId = Number(refResponse.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', documentId, ReferralTypeCode.Referral4, title);
                    this.$router.push(`/Exam/Referral4/Edit/${documentId}`);
                } else {
                    await referral4Service.updateReferral4(this.document);
                    eventBus.$emit('update-referral-event', title);
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const documentId = this.document.id;
                await referral4Service.deleteReferral4(documentId);
                this.copyObject();
                this.$router.push(`/Exam/Referral4/Create/${currentVisitCache.value.id}`);
                eventBus.$emit(
                    'delete-referral-event',
                    documentId,
                    ReferralTypeCode.Referral4,
                    `Бланка 4 - №${this.document.number}`
                );
            } finally {
                this.$loading.hide();
            }
        }

        private quickAddHandler() {
            this.$router.push(`/Exam/Referral4/Referral4QuickAdd/${this.examId}`);
        }

        private async getReferral4Subtypes() {
            const response = await referralSubtypeService.getReferral4Subtypes();
            this.referralSubtypes = response.data;
        }

        private getExamDiagnosisOnLoad() {
            const examDiagnosis = currentVisitCache.value.exam?.examDiagnoses.find(
                (diagnosis) => diagnosis.id === this.document.examDiagnosis?.id
            );
            this.document.examDiagnosis = examDiagnosis ?? null;
        }

        private async refreshRegulatoryStandardData() {
            this.copyObject();
            await this.getRegulatoryStandards();
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }

        private printEventHandler() {
            if (this.document.id) {
                this.$router.push(`/Print/Exam.Referral.Referral4/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.Referral.Referral4';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>
