<template>
    <div>
        <v-card-title>
            <div v-if="register.id < 1">Добавяне на запис в регистър Детско здравеопазване</div>
            <div v-else>Преглед/редактиране на запис в регистър Детско здравеопазване</div>
        </v-card-title>
        <PatientSearch v-if="register.id < 1" />
        <nhis-longterm-care
            v-if="register.id > 0"
            v-model="register.nhisLongtermCare"
            register-type="Children"
            :register-id="register.id"
        />
        <v-card v-if="register.id < 1 || changeDiagnosis">
            <v-card-title>Данни за регистрация</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3"><text-field v-model="ruleFilter.Icd" label="МКБ" /></v-col>
                    <v-col cols="12" md="7"><text-field v-model="ruleFilter.IcdName" label="Диагноза" /></v-col>
                    <v-col cols="12" md="2"><btn action="Search" @click="search" /></v-col>
                </v-row>
                <div v-if="childrenHealthRules.length > 0">
                    <v-row>
                        <v-col>
                            <h3>Правила за регистрация в регистър Детско здравеопазване</h3>
                            <data-table :headers="headers" :items="childrenHealthRules" class="elevation-1">
                                <template #rowActions="{ item }">
                                    <btn icon action="Select" @click="selectChildrenHealthRule(item.id)" />
                                    <btn icon action="Regulation" @click="openDialog(item.id)" />
                                </template>
                            </data-table>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
        <v-card>
            <v-card-title>Данни за регистрация</v-card-title>
            <v-card-text>
                <v-form ref="documentForm" v-model="isValid">
                    <v-row>
                        <v-col cols="12" md="4">
                            <text-field v-model="register.icdCode" label="Основна диагноза" disabled />
                        </v-col>
                        <v-col cols="12" md="4">
                            <text-field v-model="register.icdCodeAdd" label="Допълнителна диагноза" />
                        </v-col>
                        <v-col cols="12" md="4">
                            <text-field v-model="register.ambulatorySheetNumber" label="Номер на АЛ" type="number" />
                        </v-col>
                    </v-row>
                </v-form>
                <v-row>
                    <v-col cols="12" md="3">
                        <date-picker v-model="register.registerDate" label="Дата на регистрация" required />
                    </v-col>
                    <v-col mcols="12" md="3">
                        <date-picker v-model="register.referralDate" label="Дата на направление" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field v-model="register.referralNumber" type="number" label="Номер на направление" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <btn
                            v-if="register.id > 0 && !changeDiagnosis"
                            action="Edit"
                            @click="changeChildrenHealthRule()"
                        >
                            Редактиране на диагноза
                        </btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-textarea v-model="register.notes" dense label="Бележки" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <date-picker v-model="register.deregisterDate" label="Дата на отписване" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <dropdown
                            v-model="register.childrenHealthDeregisterReasonId"
                            :items="deregisterReasons"
                            label="Причина за отписване"
                            clearable
                        >
                        </dropdown>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <btn action="Back" :to="backRoute" />
                <v-spacer />
                <btn action="Save" @click="updateChildrenHealthRegister" />
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialogVisible" transition="dialog-bottom-transition" max-width="900">
            <v-card>
                <v-card-title>
                    Наредба за водене на регистъра
                    <v-spacer />
                    <btn icon action="Close" @click="dialogVisible = false" />
                </v-card-title>
                <v-card-text>
                    <pre class="text-h6 pt-8">{{ selectedChildrenHealthOrdinanceText }}</pre>
                </v-card-text>
            </v-card>
        </v-dialog>
        <NecessaryActivities
            :fund-lab-tests="register.childrenHealthLabTests"
            :consultations="register.childrenHealthConsultations"
        />
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { RuleFilterDto } from '@/model/Exam/RuleFilterDto';
    import { ChildrenHealthDeregisterReasonDto } from '@/model/Nomenclature/ChildrenHealthDeregisterReasonDto';
    import { ChildrenHealthRegisterDto } from '@/model/Register/ChildrenHealth/ChildrenHealthRegisterDto';
    import { ChildrenHealthRuleDto } from '@/model/Register/ChildrenHealth/ChildrenHealthRuleDto';
    import { childrenHealthDeregisterReasonService } from '@/service/Nomenclature/ChildrenHealthDeregisterReasonService';
    import { childrenHealthRegisterService } from '@/service/Register/ChildrenHealthRegisterService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import NhisLongtermCare from '@/view/Nhis/LongtermCare/NhisLongtermCare.vue';
    import NecessaryActivities from '@/view/Register/NecessaryActivities.vue';
    import PatientSearch from '@/view/Register/PatientSearch.vue';

    @Component({
        components: { NhisLongtermCare, PatientSearch, NecessaryActivities }
    })
    export default class ChildrenHealthRegisterEdit extends Vue {
        @Prop()
        private doctorEmployeeId!: number;

        @Prop()
        private id!: number;

        private isValid: boolean = false;
        private dialogVisible: boolean = false;
        private changeDiagnosis: boolean = false;
        private selectedChildrenHealthOrdinanceText: string = '';
        private register: ChildrenHealthRegisterDto = new ChildrenHealthRegisterDto();
        private deregisterReasons: ChildrenHealthDeregisterReasonDto[] = [];
        private ruleFilter: RuleFilterDto = new RuleFilterDto();
        private childrenHealthRules: ChildrenHealthRuleDto[] = [];
        private headers: IDataTableHeader[] = [
            { text: 'Диагноза', value: 'name' },
            { text: 'МКБ', value: 'icdCode' },
            { text: 'Допълнително МКБ', value: 'icdCodeAdd' },
            { text: '', value: 'id' }
        ];

        private async load() {
            this.$loading.show();
            await this.loadDeregisterReasons();
            if (this.id) {
                await this.getChildrenHealthRegister(this.id);
            }
            this.$loading.hide();
        }

        private openDialog(id: number) {
            this.dialogVisible = true;
            this.selectChildrenHealthRule(id);
        }

        private selectChildrenHealthRule(id: number) {
            this.register.childrenHealthRuleId = id;
            const childrenHealthRule = this.childrenHealthRules.find((rule) => rule.id === id);
            if (childrenHealthRule) {
                this.register.icdCode = childrenHealthRule.icdCode;
                this.register.icdCodeAdd = childrenHealthRule.icdCodeAdd;
                this.selectedChildrenHealthOrdinanceText = childrenHealthRule.ordinanceText;
                this.childrenHealthRules = [];
            }
        }

        private async loadDeregisterReasons() {
            const response = await childrenHealthDeregisterReasonService.getChildrenHealthDeregisterReasons();
            this.deregisterReasons = response.data;
        }

        private async getChildrenHealthRegister(id: number) {
            const response = await childrenHealthRegisterService.get(id);
            this.register = response.data;
            if (this.register.patientId) {
                currentPatientCache.loadLastVersion(this.register.patientId);
            }
        }

        private async updateChildrenHealthRegister() {
            if (!currentPatientCache.isLoaded) {
                return;
            }
            this.register.patientId = currentPatientCache.value.key.patientId;
            this.register.doctorEmployeeId = this.doctorEmployeeId ?? userContextCache.currentDoctorEmployeeId;
            (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();

            if (this.isValid) {
                if (!this.id) {
                    await childrenHealthRegisterService.create(this.register);
                } else {
                    this.register.id = this.id;
                    await childrenHealthRegisterService.update(this.register);
                }
                this.$router.push(this.backRoute);
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        private async search() {
            this.$loading.show();
            try {
                const response = await childrenHealthRegisterService.searchChildrenHealthRule(this.ruleFilter);
                this.childrenHealthRules = response.data;
            } finally {
                this.$loading.hide();
            }
        }

        private changeChildrenHealthRule() {
            this.changeDiagnosis = true;
        }

        private get backRoute() {
            return `/Register/ChildrenHealthRegister/${
                this.doctorEmployeeId ?? userContextCache.currentDoctorEmployeeId
            }`;
        }

        private mounted() {
            return this.load();
        }
    }
</script>

<style scoped>
    pre {
        overflow-x: auto;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
    }
</style>
