import { Route, RouteConfig } from 'vue-router';

const registerChildRoutes: RouteConfig[] = [
    {
        path: 'PatientSearch/:practiceId',
        props: {
            register: (route: Route) => ({ practiceId: Number(route.params.practiceId) })
        },
        components: {
            register: () => import('@/view/Patient/PatientSearch.vue')
        }
    },
    {
        path: 'ChildrenHealthRegister/:doctorEmployeeId',
        props: {
            register: (route: Route) => ({ doctorEmployeeId: Number(route.params.doctorEmployeeId) })
        },
        components: {
            register: () => import('@/view/Register/ChildrenHealth/ChildrenHealthRegister.vue')
        }
    },
    {
        path: 'ChildrenHealthRegister/Create/:doctorEmployeeId',
        props: {
            register: (route: Route) => ({ doctorEmployeeId: Number(route.params.doctorEmployeeId) })
        },
        components: {
            register: () => import('@/view/Register/ChildrenHealth/ChildrenHealthRegisterEdit.vue')
        }
    },
    {
        path: 'ChildrenHealthRegister/Edit/:id',
        props: {
            register: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            register: () => import('@/view/Register/ChildrenHealth/ChildrenHealthRegisterEdit.vue')
        }
    },
    {
        path: 'RiskGroupRegister',
        components: {
            register: () => import('@/view/Register/RiskGroup/RiskGroupRegister.vue')
        }
    },
    {
        path: 'RiskGroupRegister/Create',
        components: {
            register: () => import('@/view/Register/RiskGroup/RiskGroupRegisterEdit.vue')
        }
    },
    {
        path: 'RiskGroupRegister/Edit/:id',
        props: {
            register: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            register: () => import('@/view/Register/RiskGroup/RiskGroupRegisterEdit.vue')
        }
    },
    {
        path: 'DispensaryRegister/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            register: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            register: () => import('@/view/Register/Dispensary/DispensaryRegister.vue')
        }
    },
    {
        path: 'DispensaryRegister/Create/:practiceId/:doctorEmployeeSeqNumber',
        props: {
            register: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            register: () => import('@/view/Register/Dispensary/DispensaryRegisterEdit.vue')
        }
    },
    {
        path: 'DispensaryRegister/Edit/:practiceId/:patientId/:seqNumber',
        props: {
            register: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                patientId: Number(route.params.patientId),
                seqNumber: Number(route.params.seqNumber)
            })
        },
        components: {
            register: () => import('@/view/Register/Dispensary/DispensaryRegisterEdit.vue')
        }
    },
    {
        path: 'PregnancyRegister/:doctorEmployeeId',
        props: {
            register: (route: Route) => ({ doctorEmployeeId: Number(route.params.doctorEmployeeId) })
        },
        components: {
            register: () => import('@/view/Register/Pregnancy/PregnancyRegister.vue')
        }
    },
    {
        path: 'PregnancyRegister/Create/:doctorEmployeeId',
        props: {
            register: (route: Route) => ({ doctorEmployeeId: Number(route.params.doctorEmployeeId) })
        },
        components: {
            register: () => import('@/view/Register/Pregnancy/PregnancyRegisterEdit.vue')
        }
    },
    {
        path: 'PregnancyRegister/Edit/:id',
        props: {
            register: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            register: () => import('@/view/Register/Pregnancy/PregnancyRegisterEdit.vue')
        }
    },
    {
        path: 'PregnancyRegister/PregnancyCard/Create/:pregnancyId',
        props: {
            register: (route: Route) => ({ pregnancyId: Number(route.params.pregnancyId) })
        },
        components: {
            register: () => import('@/view/Register/Pregnancy/PregnancyCard/PregnancyCard.vue')
        }
    },
    {
        path: 'PregnancyRegister/PregnancyCard/Edit/:documentId',
        props: {
            register: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            register: () => import('@/view/Register/Pregnancy/PregnancyCard/PregnancyCard.vue')
        }
    },
    {
        path: 'PregnancyRegister/PregnancyReferral/Create/:pregnancyId',
        props: {
            register: (route: Route) => ({ pregnancyId: Number(route.params.pregnancyId) })
        },
        components: {
            register: () => import('@/view/Register/Pregnancy/PregnancyReferral/PregnancyReferral.vue')
        }
    },
    {
        path: 'PregnancyRegister/PregnancyReferral/Edit/:documentId',
        props: {
            register: (route: Route) => ({ documentId: Number(route.params.documentId) })
        },
        components: {
            register: () => import('@/view/Register/Pregnancy/PregnancyReferral/PregnancyReferral.vue')
        }
    }
];

export const registerRoutes: RouteConfig = {
    path: '/Register',
    component: () => import('@/view/Register/RegisterMenu.vue'),
    children: registerChildRoutes
};
