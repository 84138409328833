import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
import { NhisPatientImmunizationKey } from '@/model/Nhis/Immunization/NhisPatientImmunizationKey';

export class NhisGroupSummaryDto {
    public examIdsToOpen: number[] = [];
    public referral3Ids: number[] = [];
    public referral3aIds: number[] = [];
    public referral4Ids: number[] = [];
    public referral6Ids: number[] = [];
    public referral7Ids: number[] = [];
    public referralTelkIds: number[] = [];
    public medicalNoteIds: number[] = [];
    public immunizationKeys: NhisPatientImmunizationKey[] = [];
    public prescriptionKeys: PrescriptionKey[] = [];
    public examIdsToClose: number[] = [];
    public examIdsToEdit: number[] = [];
}
