import { render, staticRenderFns } from "./CurrentUserContextTitle.vue?vue&type=template&id=4af8bf54&scoped=true"
import script from "./CurrentUserContextTitle.vue?vue&type=script&lang=ts"
export * from "./CurrentUserContextTitle.vue?vue&type=script&lang=ts"
import style0 from "./CurrentUserContextTitle.vue?vue&type=style&index=0&id=4af8bf54&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4af8bf54",
  null
  
)

export default component.exports