<template>
    <v-card>
        <v-card-title>Данни за електронното направление</v-card-title>
        <v-card-text>
            <v-row v-if="value" dense>
                <v-col md="3">
                    <text-field v-model="value.nrn" :class="nrnBackground" label="НРН номер" readonly></text-field>
                </v-col>
                <v-col md="3">
                    <text-field v-model="value.statusName" label="Статус на направлението" readonly></text-field>
                </v-col>
                <v-col md="3">
                    <date-field v-model="value.statusDate" label="Дата на последна проверка" />
                </v-col>
            </v-row>
            <v-card-actions>
                <v-row dense>
                    <v-col v-if="canPost" md="3">
                        <btn action="Send" block :disabled="disable || isLoading" @click="post">Изпращане</btn>
                    </v-col>
                    <v-col v-if="canCancel" md="3">
                        <btn action="Delete" block :disabled="disable || isLoading" @click="cancel">Анулиране</btn>
                    </v-col>
                    <v-col v-if="value.nrn && value.nrn.length > 0" md="4" xl="3">
                        <btn action="CheckDocument" block :disabled="disable || isLoading" @click="checkReferral">
                            Проверка на статус
                        </btn>
                    </v-col>
                    <v-col v-if="value.nrn && value.nrn.length > 0" md="3">
                        <btn action="Primary" block :disabled="disable || isLoading" :to="clickPreview()">
                            Резултат
                        </btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ReferralTypeCode } from '@/enum/Exam/ReferralTypeCode';
    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { NhisPrescriptionStatusCode } from '@/enum/Nhis/NhisPrescriptionStatusCode';
    import { NhisReferralDto } from '@/model/Nhis/Referral/NhisReferralDto';
    import { nhisReferralService } from '@/service/Nhis/NhisReferralService';
    import { nhisService } from '@/service/Nhis/NhisService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';

    @Component
    export default class NhisReferral extends Vue {
        @Prop({ type: Object })
        private value!: NhisReferralDto;

        @Prop()
        private referralId!: number;

        @Prop()
        private formType!: string;

        @Prop()
        private disable!: boolean;

        private get isLoading() {
            return this.$loading.isVisible;
        }

        public async post() {
            const response = await nhisReferralService.postReferral(this.requestData());
            if (response) {
                this.change(response);
            }
        }

        private async cancel() {
            const response = await nhisReferralService.cancelReferral(this.requestData());
            if (response) {
                this.change(response);
            }
        }

        private async checkReferral() {
            const response = await nhisReferralService.checkReferral(this.requestData());
            if (response) {
                this.change(response);
            }
        }

        private clickPreview() {
            switch (this.formType) {
                case ReferralTypeCode.Referral3:
                case ReferralTypeCode.Referral3a:
                case ReferralTypeCode.Referral6:
                    return `/Exam/ReferralResult/${this.value.nrn}/${currentVisitCache.value.id}`;
                case ReferralTypeCode.Referral4:
                    return `/Exam/ReferralResult4/${this.value.nrn}/${currentVisitCache.value.id}`;
                case ReferralTypeCode.Referral7:
                    return `/Exam/ReferralResult7/${this.value.nrn}/${currentVisitCache.value.id}`;
                default:
                    return '';
            }
        }

        private get canPost() {
            return (
                ((this.referralId > 0 && !this.value?.statusCode) ||
                    (this.value?.statusCode?.length > 0 &&
                        this.value?.statusCode === NhisPrescriptionStatusCode.Rejected)) &&
                currentVisitCache.value.exam?.nrn &&
                currentVisitCache.value.exam.statusCode !== NhisExamStatusCode.Canceled
            );
        }

        private get canCancel() {
            return this.value?.statusCode?.length > 0 && this.value?.statusCode === NhisPrescriptionStatusCode.Active;
        }

        private change(data: NhisReferralDto) {
            this.$emit('input', data);
        }

        private requestData() {
            return nhisService.requestData(this.referralId, this.formType);
        }

        private get nrnBackground() {
            return this.value.statusCode === NhisPrescriptionStatusCode.Active
                ? 'text-field-success'
                : this.value.statusCode === NhisPrescriptionStatusCode.Canceled
                ? 'text-field-error'
                : '';
        }
    }
</script>
