import { Type } from 'class-transformer';

import { SubmitCommand } from '@/model/Nhis/SubmitCommand';
import { PatientKey } from '@/model/Patient/PatientKey';

export class NhisPatientImmunizationExtractionSubmitCommand extends SubmitCommand {
    @Type(() => PatientKey)
    public patientKey: PatientKey;

    constructor(xmlString: string, accessTokenValue: string, patientKey: PatientKey) {
        super(xmlString, accessTokenValue);
        this.patientKey = patientKey;
    }
}
