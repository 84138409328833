import { NhisLaboratoryTestDto } from '@/model/Nhis/Exam/PreventiveActivities/NhisLaboratoryTestDto';
import { NhisMedicalProcedureDto } from '@/model/Nhis/Exam/PreventiveActivities/NhisMedicalProcedureDto';
import { NhisQuestionnaireDto } from '@/model/Nhis/Exam/PreventiveActivities/Questionnaire/NhisQuestionnaireDto';

export class NhisMedicalHistoryDto {
    public questionnaries: NhisQuestionnaireDto[] = [];
    public nrnAllergies: string[] = [];
    public nrnFamilyHistories: string[] = [];
    public procedures: NhisMedicalProcedureDto[] = [];
    public labTests: NhisLaboratoryTestDto[] = [];
    public nhisPreventiveExamTypeCode: string | null = null;
}
