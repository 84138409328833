import { plainToInstance } from 'class-transformer';

import { DispensaryExternalEditDto } from '@/model/Patient/Dispensary/DispensaryExternalEditDto';
import { DispensaryExternalRowDto } from '@/model/Patient/Dispensary/DispensaryExternalRowDto';
import { PatientKey } from '@/model/Patient/PatientKey';
import { PatientRegisterKey } from '@/model/Register/PatientRegisterKey';
import { httpService } from '@/service/Infrastructure/HttpService';

export const dispensaryExternalService = {
    async getDispensaryExternals(patientKey: PatientKey): Promise<DispensaryExternalRowDto[]> {
        const response = await httpService.get<DispensaryExternalRowDto[]>(
            '/DispensaryExternal/GetDispensaryExternals',
            { params: patientKey }
        );
        return plainToInstance(DispensaryExternalRowDto, response.data);
    },

    async getDispensaryExternal(key: PatientRegisterKey): Promise<DispensaryExternalEditDto> {
        const response = await httpService.get<DispensaryExternalEditDto>('/DispensaryExternal/GetDispensaryExternal', {
            params: key
        });
        return plainToInstance(DispensaryExternalEditDto, response.data);
    },

    async createDispensaryExternal(data: DispensaryExternalEditDto): Promise<number> {
        const response = await httpService.post<number>('/DispensaryExternal/CreateDispensaryExternal', data);
        return response.data;
    },

    async updateDispensaryExternal(data: DispensaryExternalEditDto): Promise<void> {
        await httpService.put('/DispensaryExternal/UpdateDispensaryExternal', data);
    },

    async deleteDispensaryExternal(key: PatientRegisterKey): Promise<void> {
        await httpService.delete('/DispensaryExternal/DeleteDispensaryExternal', { params: key });
    }
};
