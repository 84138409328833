<template>
    <data-table
        title="Опис към месечния отчет на новозаписани пациенти на ПИМП"
        :headers="tableHeaders"
        :items="items.lines"
        disable-pagination
    >
        <template #actions>
            <btn action="Export" @click="exportExcel" />
            <btn
                action="Print"
                :to="`/Print/Report.Pis.PatientListReport/PatientListReport/${filter.practiceId}/${filter.doctorEmployeeSeqNumber}/${printStartDate}/${printEndDate}`"
            />
            &nbsp;&nbsp;Всичко общо: {{ items.total }} бр.
            <v-spacer />
            <btn icon action="Close" @click="$emit('close-dialog')" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { PatientListReportDto } from '@/model/Report/Pis/PatientList/PatientListReportDto';
    import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
    import { pisService } from '@/service/Report/PisService';
    import { loadingState } from '@/store/LoadingState';
    import { dateUtil } from '@/util/DateUtil';
    import { formatters } from '@/util/Formatters';

    @Component
    export default class PatientListReportLines extends Vue {
        @Prop()
        items!: PatientListReportDto;

        @Prop()
        filter!: ReportIntervalFilterDto;

        private get printStartDate() {
            return dateUtil.formatBgDate(this.filter.startDate);
        }

        private get printEndDate() {
            return dateUtil.formatBgDate(this.filter.endDate);
        }

        private get tableHeaders(): IDataTableHeader[] {
            return [
                { text: 'Идентификатор:', value: 'identifier' },
                { text: 'Имена:', value: 'name' },
                { text: 'Дата на раждане:', value: 'birthDate', formatter: formatters.date },
                { text: 'Дата на записване:', value: 'registerDate', formatter: formatters.date }
            ];
        }

        private async exportExcel() {
            loadingState.show();
            try {
                await pisService.exportExcelPatientListReport(this.filter);
            } finally {
                loadingState.hide();
            }
        }
    }
</script>
