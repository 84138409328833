<template>
    <div>
        <main-title>Протокол: {{ value.nrn }}</main-title>
        <v-card-text>
            <v-row>
                <v-col md="3" cols="6"><text-field v-model="value.nrn" label="НРН" readonly /></v-col>
                <v-col md="3" cols="6"><text-field v-model="value.status" label="Статус" readonly /></v-col>
                <v-col md="3" cols="6"><text-field v-model="value.protocolType" label="Тип протокол" readonly /></v-col>
                <v-col md="3" cols="6"><text-field v-model="value.mkb" label="Мкб" readonly /></v-col>
                <v-col md="3" cols="6"><date-field v-model="value.startDate" label="Начало" readonly /></v-col>
                <v-col md="3" cols="6"><date-field v-model="value.endDate" label="Край" readonly /></v-col>
                <v-col md="3" cols="6"><text-field v-model="value.validityPeriod" label="Период" readonly /></v-col>
            </v-row>
            <v-row v-for="(medication, index) in value.medications" :key="index">
                <v-col cols="12" class="mb-1">
                    <h4>Лекарство: {{ index + 1 }}</h4></v-col
                >
                <v-col md="3" cols="6"><text-field v-model="medication.nhifCode" label="Лекарство" readonly /></v-col>
                <v-col md="3" cols="6"
                    ><text-field v-model="medication.activeSubstance" label="Количество активно вещество" readonly
                /></v-col>
                <v-col md="3" cols="6"
                    ><text-field v-model="medication.medicationCode" label="Код по  НСЦРЛП" readonly
                /></v-col>
                <v-col md="3" cols="6"
                    ><text-field v-model="medication.quantity" label="Предписано количество" readonly
                /></v-col>
                <v-col md="3" cols="6"
                    ><text-field v-model="medication.totalAmount" label="Общо количество за целия период" readonly
                /></v-col>
                <v-col md="3" cols="6"
                    ><text-field v-model="medication.prescribedDays" label="Брой дни на единичната доза" readonly
                /></v-col>
                <v-col md="3" cols="6">
                    Начин на предписване
                    <v-radio-group v-model="medication.isQuantityByForm" row readonly>
                        <v-radio label="На брой" :value="true"></v-radio>
                        <v-radio label="На опаковки" :value="false"></v-radio> </v-radio-group
                ></v-col>
                <v-col md="3" cols="6">
                    Позволена замяна
                    <v-radio-group v-model="medication.substitutionAllowed" row readonly>
                        <v-radio label="Да" :value="true"></v-radio>
                        <v-radio label="Не" :value="false"></v-radio> </v-radio-group
                ></v-col>
                <v-row v-for="(instruction, innerIndex) in medication.instructions" :key="innerIndex">
                    <v-col cols="12" class="mb-1"
                        ><h4>Инструкции за дозировка и прием: {{ innerIndex + 1 }}</h4></v-col
                    >
                    <v-col md="1" cols="3"><text-field v-model="instruction.frequency" label="Пъти" readonly /></v-col>
                    <v-col md="1" cols="3"
                        ><text-field v-model="instruction.doseQuantity" label="Количество" readonly
                    /></v-col>
                    <v-col md="1" cols="3"><text-field v-model="instruction.period" label="На" readonly /></v-col>
                    <v-col md="1" cols="3"><text-field v-model="instruction.periodUnit" readonly /></v-col>
                    <v-col md="1" cols="3"
                        ><text-field v-model="instruction.therapyDuration" label="За" readonly
                    /></v-col>
                    <v-col md="1" cols="3">
                        <span>Дни</span>
                    </v-col>
                    <v-col md="3" cols="6">
                        Прием по необходимост
                        <v-radio-group v-model="medication.substitutionAllowed" row readonly>
                            <v-radio label="Да" :value="true"></v-radio>
                            <v-radio label="Не" :value="false"></v-radio> </v-radio-group
                    ></v-col>
                    <v-col cols="6">
                        <v-textarea v-model="instruction.interpretation" rows="3" readonly label="Текстови указания" />
                    </v-col>
                    <v-col cols="6">
                        <v-textarea v-model="instruction.notes" rows="3" readonly label="Допълнителни указания" />
                    </v-col>
                </v-row>
            </v-row>
        </v-card-text>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import MainTitle from '@/component/Common/MainTitle.vue';
    import DateField from '@/component/Date/DateField.vue';
    import { ProtocolPreviewDto } from '@/model/Exam/DrugProtocol/Preview/ProtocolPreviewDto';

    @Component({
        components: { DateField, MainTitle }
    })
    export default class ProtocolPreview extends Vue {
        @Prop()
        value!: ProtocolPreviewDto;

        private isQuntityByForm(isQuantityByForm: boolean): string {
            return isQuantityByForm ? 'На брой' : 'На опаковка';
        }
    }
</script>

<style scoped></style>
