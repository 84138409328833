<template>
    <v-row>
        <v-col cols="auto">
            <v-row dense>
                <v-col cols="auto">
                    <v-icon :disabled="disabled" @click="decrease">mdi-arrow-left-thick</v-icon>
                </v-col>
                <v-col cols="auto">
                    <date-picker v-model="value.startDate" :disabled="disabled" label="Начална дата" @input="input" />
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="auto">
            <v-row dense>
                <v-col cols="auto">
                    <date-picker v-model="value.endDate" :disabled="disabled" label="Крайна дата" @input="input" />
                </v-col>
                <v-col cols="auto">
                    <v-icon :disabled="disabled" @click="increase">mdi-arrow-right-thick</v-icon>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

    import { ReportIntervalFilterDto } from '@/model/Report/ReportIntervalFilterDto';
    import { reportIntervalCache } from '@/store/ReportIntervalCache';

    @Component
    //TODO: Начален етап на компонент за лесно избиране на дати в отчетите
    export default class ReportDatePicker extends Vue {
        @Prop()
        private value!: ReportIntervalFilterDto;

        @Prop()
        private disabled!: boolean;

        @Prop({ default: false })
        private byDay!: boolean;

        @Emit()
        private input() {
            reportIntervalCache.set(this.value);
            return this.value;
        }

        private increase() {
            if (this.value.startDate) {
                this.value.startDate = this.byDay
                    ? this.increaseDateByDay(this.value.startDate)
                    : this.increaseDateByMonth(this.value.startDate);
            }
            if (this.value.endDate) {
                this.value.endDate = this.byDay
                    ? this.increaseDateByDay(this.value.endDate)
                    : this.increaseDateByMonth(this.value.endDate);
            }
            this.input();
        }

        private decrease() {
            if (this.value.startDate) {
                this.value.startDate = this.byDay
                    ? this.decreaseDateByDay(this.value.startDate)
                    : this.decreaseDateByMonth(this.value.startDate);
            }
            if (this.value.endDate) {
                this.value.endDate = this.byDay
                    ? this.decreaseDateByDay(this.value.endDate)
                    : this.decreaseDateByMonth(this.value.endDate);
            }
            this.input();
        }

        private decreaseDateByDay(date: Date) {
            return new Date(date.setDate(date.getDate() - 1));
        }

        private decreaseDateByMonth(date: Date) {
            let day = date.getDate();
            let month = date.getMonth();
            let year = date.getFullYear();

            if (this.isFirstMonth(month)) {
                year -= 1;
            }

            month = this.getMonthDecreased(month);

            if (this.isLastDay(date)) {
                day = this.getLastDayOfMonth(month, year);
            }

            return new Date(year, month, day);
        }

        private increaseDateByDay(date: Date) {
            return new Date(date.setDate(date.getDate() + 1));
        }

        private increaseDateByMonth(date: Date) {
            let day = date.getDate();
            let month = date.getMonth();
            let year = date.getFullYear();

            if (this.isLastMonth(month)) {
                year += 1;
            }

            month = this.getMonthIncreased(month);

            if (this.isLastDay(date)) {
                day = this.getLastDayOfMonth(month, year);
            }

            return new Date(year, month, day);
        }

        private getMonthDecreased(month: number) {
            const lastMonthIndex = 11;
            return this.isFirstMonth(month) ? lastMonthIndex : month - 1;
        }

        private getMonthIncreased(month: number) {
            const firstMonthIndex = 0;
            return this.isLastMonth(month) ? firstMonthIndex : month + 1;
        }

        private isLastDay(dt: Date) {
            const test = new Date(dt.getTime()),
                month = test.getMonth();

            test.setDate(test.getDate() + 1);
            return test.getMonth() !== month;
        }

        private isFirstMonth(currentMonth: number) {
            const firstMonthIndex = 0;
            return currentMonth === firstMonthIndex;
        }

        private isLastMonth(currentMonth: number) {
            const lastMonthIndex = 11;
            return currentMonth === lastMonthIndex;
        }

        private getLastDayOfMonth(month: number, year: number) {
            const date = new Date(year, month + 1, 0);
            return date.getDate();
        }

        private tryGetHistoryParams() {
            reportIntervalCache.loadFromLocalStorage();
            if (reportIntervalCache.value?.startDate || reportIntervalCache.value?.endDate) {
                this.value.startDate = reportIntervalCache.value.startDate;
                this.value.endDate = reportIntervalCache.value.endDate;
            }
            this.input();
        }

        private restoreDate() {
            window.onpopstate = () => {
                this.tryGetHistoryParams();
            };
        }

        private mounted() {
            this.restoreDate();
        }
    }
</script>

<style scoped>
    .v-icon.v-icon::after {
        width: 0;
    }
</style>
