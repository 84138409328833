import { plainToInstance } from 'class-transformer';

import { AdvertisementDto } from '@/model/Advertisement/AdvertisementDto';
import { AdvertisementFilterDto } from '@/model/Advertisement/AdvertisementFilterDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const advertisementService = {
    async getValidAdvertisements(filter: AdvertisementFilterDto): Promise<AdvertisementDto[]> {
        const response = await httpService.post<AdvertisementDto[]>('/Advertisement/GetValidAdvertisements', filter);
        return plainToInstance(AdvertisementDto, response?.data);
    }
};
