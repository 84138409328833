<template>
    <v-app>
        <!--
            Внимание: Скрива се при mdAndDown без да е указано нищо - вградена логика на drawer-а.
            Може да се влияе с property–та permanent, temporary, fixed и disable-resize-watcher.
        -->
        <v-navigation-drawer
            v-model="mainMenuIsVisible"
            :expand-on-hover="mainMenuIsCollapsed && !mdAndDownResolution"
            :mini-variant="mainMenuIsCollapsed && !mdAndDownResolution"
            clipped
            app
            color="mainMenu"
        >
            <main-menu></main-menu>
        </v-navigation-drawer>

        <v-app-bar app clipped-left color="titleBar" dark>
            <v-app-bar-nav-icon v-if="mdAndDownResolution" @click.stop="mainMenuIsVisible = !mainMenuIsVisible" />
            <v-btn class="d-none d-lg-flex" icon @click.stop="toggleMainMenuIsCollapsed">
                <v-icon>{{ mainMenuIsCollapsed ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
            </v-btn>
            <v-btn v-if="showSecondaryMenuAppBarIcon" icon @click="toggleSecondaryMenu">
                <v-icon>{{ secondaryMenuAppBarIcon }}</v-icon>
            </v-btn>

            <v-toolbar-title class="hidden-sm-and-down">Хипократ</v-toolbar-title>
            <v-toolbar-title v-if="isDevOrTest || isStaging" class="hidden-sm-and-down pl-5 red--text"
                >Тестова Среда</v-toolbar-title
            >
            <!-- v-spacer-ите разпределят елементите равномерно по цялата ширина и избутват последните вдясно. -->
            <v-spacer />
            <template v-if="isAuthenticated">
                <current-patient-title />
                <v-spacer />
                <current-visit-title />
                <v-spacer />
                <licensing-status-icon />
                <current-user-context-title />
                <user-menu-item />
                <notifications-badge />
            </template>
            <signin-icon v-else />
        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>

        <v-footer app> Хипократ {{ appVersionText }} </v-footer>

        <!-- Място, на което излизат toast съобщенията. -->
        <notifications group="notifications-group" position="bottom right" width="25%" />
        <!-- Десен панел с натрупаните нотификации. -->
        <notifications-drawer v-if="notificationsDrawerIsVisible" />
        <!-- Модален диалог за конкретно съобщение, най-често съобщение за грешка. -->
        <notification-dialog />
        <user-agreement-dialog />
    </v-app>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { config } from '@/Config';
    import { appUpdateService } from '@/service/Infrastructure/AppUpdateService';
    import { pushNotificationService } from '@/service/User/PushNotificationService';
    import { menuState } from '@/store/MenuState';
    import { notifications } from '@/store/Notifications';
    import { currentUser } from '@/store/User/CurrentUser';
    import CurrentVisitTitle from '@/view/Exam/CurrentVisitTitle.vue';
    import LicensingStatusIcon from '@/view/Licensing/LicensingStatusIcon.vue';
    import NotificationDialog from '@/view/Notification/NotificationDialog.vue';
    import NotificationsBadge from '@/view/Notification/NotificationsBadge.vue';
    import NotificationsDrawer from '@/view/Notification/NotificationsDrawer.vue';
    import CurrentPatientTitle from '@/view/Patient/CurrentPatientTitle.vue';
    import CurrentUserContextTitle from '@/view/User/CurrentUserContextTitle.vue';
    import UserMenuItem from '@/view/User/DropdownMenu/UserMenuItem.vue';
    import SigninIcon from '@/view/User/SigninIcon.vue';
    import UserAgreementDialog from '@/view/User/UserAgreementDialog.vue';

    import MainMenu from './MainMenu.vue';

    @Component({
        components: {
            MainMenu,
            CurrentPatientTitle,
            CurrentVisitTitle,
            LicensingStatusIcon,
            CurrentUserContextTitle,
            UserMenuItem,
            SigninIcon,
            UserAgreementDialog,
            NotificationsBadge,
            NotificationDialog,
            NotificationsDrawer
        }
    })
    export default class App extends Vue {
        private mounted() {
            // Следните функции могат да изведат нотификации (обновяване, новини, задачи), затова трябва да се изпълнят:
            // - след use(vueNotificationPlugin), за да добави нотификацията;
            // - след vueApp.$mount('#app'), за да изобрази нотификацията;
            // - след notifications.loadFromLocalStorage(), за да не замаже съхранените нотификации.
            appUpdateService.validateAutoUpdate();

            // Известията се проверяват периодично, но също и след презареждане на SPA, и след sign in, без await.
            pushNotificationService.showPushNotifications();
        }

        private get notificationsDrawerIsVisible() {
            return notifications.drawerIsVisible;
        }

        private get mdAndDownResolution() {
            return this.$vuetify.breakpoint.mdAndDown;
        }

        private mainMenuIsVisible: boolean = true;

        private get mainMenuIsCollapsed() {
            return menuState.mainMenuIsCollapsed;
        }

        private get showSecondaryMenuAppBarIcon() {
            return menuState.showSecondaryMenuAppBarIcon;
        }

        private get secondaryMenuAppBarIcon() {
            return menuState.secondaryMenuAppBarIcon;
        }

        private toggleSecondaryMenu() {
            menuState.showSecondaryMenu = !menuState.showSecondaryMenu;
        }

        private toggleMainMenuIsCollapsed() {
            menuState.mainMenuIsCollapsed = !menuState.mainMenuIsCollapsed;
        }

        private get isAuthenticated() {
            return currentUser.isAuthenticated;
        }

        private get appVersionText() {
            return appUpdateService.appVersionText;
        }

        private get privacyPolicyUrl() {
            return `${config.identityServerBaseUrl}/Home/Privacy`;
        }

        private get isDevOrTest() {
            return config.isDevOrTest;
        }

        private get isStaging() {
            return config.isStaging;
        }
    }
</script>

<!-- Глобални стилове за цялото приложение. Нарочно няма атрибут scoped. -->
<style lang="scss">
    @import '@/asset/Css/App.scss';
</style>
