import { base64Service } from '@/service/File/Base64Service';
import { printHttpService } from '@/service/Infrastructure/PrintHttpService';

export const printService = {
    async printReport(report: string, parameters: unknown): Promise<void> {
        const response = await printHttpService.post<string>('Print/GenerateReportPDF', {
            report,
            parameters
        });
        if (response?.data) {
            base64Service.printFile(response.data);
        }
    },

    async printGroupReport(report: string, parameters: unknown): Promise<void> {
        const response = await printHttpService.post<string>('Print/GenerateGroupReportPDF', {
            report,
            parameters
        });
        if (response?.data) {
            base64Service.printFile(response.data);
        }
    }
};
