<template>
    <!--
        ReportViewer-ът на Telerik е базиран на jQuery, не поддържа Vue и има някои изисквания:
        - ReportViewer обектът трябва да се създаде по DOM елемент. Това е причината да има ref.
        - Елементът трябва да има стил за позиция, иначе не се показва. Това е причината да има id.
    -->
    <div id="reportViewerElementId" ref="reportViewerElement">
        <v-card flat>
            <v-card-title>
                {{ isAuthenticated ? 'Печатният образец се зарежда...' : 'Моля, влезте в системата.' }}
            </v-card-title>
        </v-card>
    </div>
</template>

<script lang="ts">
    // Съществуват няколко npm пакета с различни теми за Kendo компонентите.
    // Те са бъгави по отношение на report viewer-а, но Telerik изпратиха кръпки (виж style най-долу) и по-късно оправиха някои бъгове.
    // Тези пакети съдържат sass-based теми. Това било по-модерно от less-based темите, но и двата вида се поддържат актуални от Telerik.
    // През 2021-01 махнахме less-based css-ите от index.html и включихме темата чрез следния пакет (и кръпките най-долу).
    // През 2021-07 и 2021-12 обновихме пакета с темата. Част от кръпките станаха излишни, други все още са нужни.
    import '@progress/kendo-theme-material';
    // Дефинира символите $ и jQuery и зарежда telerikReportViewer.kendo.min.js, за да сработи telerikReportViewer.js.
    import '@progress/telerik-angular-report-viewer/dist/dependencies/initExtDeps';

    // В проекта е добавен npm пакет с Angular компонент, който опакова jQuery-based report viewer-а.
    // Следният ред зарежда директно базовия telerikReportViewer.js без да ползва Angular компонента.
    // Това е обсолютно същият скрипт, който би се ползвал с jQuery.
    import {
        ReportViewer,
        TelerikReportViewerOptions
    } from '@progress/telerik-angular-report-viewer/dist/dependencies/telerikReportViewer';
    import jQuery from 'jquery';
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import { config } from '@/Config';
    // Превод на български език на повечето текстове в Telerik report viewer-а.
    import { StringResources } from '@/model/Print/TelerikReportViewerStringResources';
    import { currentUser } from '@/store/User/CurrentUser';

    interface IReportViewer {
        stringResources: Record<string, string>;
    }

    // Включва поддръжката на cookie-та за ajax заявките, изпращани от Telerik ReportViewer-а.
    // https://docs.telerik.com/reporting/knowledge-base/custom-headers-with-reportviewer-rest-api-calls
    // Когато има няколко instance-а на сървъра за печат в Azure, сесията на всеки клиент трябва да се фиксира към един instance,
    // защото storage-ът на REST услугата се пази в RAM паметта на конкрентния instance, защото когато се пази в базата данни печатането става много бавно.
    // Фиксирането на сесията към един instance става чрез ARR affinity cookie-та, генерирани от Azure App Service-а.
    jQuery.ajaxSetup({ xhrFields: { withCredentials: true } });

    @Component
    export default class PrintComponent extends Vue {
        // Указва пътя до *PrintService класа и до .trdp файла спрямо .dll-ката на печатния web проект. Подпапките са разделени с точка вместо с /.
        @Prop({ required: true })
        private template!: string;

        @Prop({ required: true })
        private parameters!: unknown;

        @Ref()
        private reportViewerElement!: Element;

        private get isAuthenticated() {
            return currentUser.isAuthenticated;
        }

        private mounted() {
            if (!currentUser.isAuthenticated) {
                return;
            }

            const options: TelerikReportViewerOptions = {
                serviceUrl: config.printApiBaseUrl,
                reportSource: {
                    report: this.template,
                    parameters: this.parameters
                },
                // Добавя authorization header с access token-а в заявките към печатния сървър.
                authenticationToken: currentUser.user?.access_token,
                // По подразбиране scaleMode е FIT_PAGE.
                scaleMode: 'SPECIFIC',
                sendEmail: { enabled: false }
            };

            const reportViewer = new ReportViewer(this.reportViewerElement, options);

            // TypeScript описанието на клас ReportViewer е непълно - дефинира конструктор,
            // но не и полетата, затова .stringResources се достъпва насляпо през наш интерфейс.
            const sr = (reportViewer as IReportViewer).stringResources;
            // Повечето текстове се подменят с български вариант. Идеята е взета от:
            // https://docs.telerik.com/reporting/angular-report-viewer-localization
            Object.assign(sr, StringResources.bulgarian);
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* Ако не се укажат всички тези стилови параметри, report viewer-ът изобщо не се показва. */
    #reportViewerElementId {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        /*overflow: hidden;
        clear: both;*/
    }
</style>

<!--
    Следните кръпки поправят визуални дефекти в report viewer-а при използване на material sass темата от пакета @progress/kendo-theme-material.
    Някои от кръпките вече не са нужни, защото са оправени в по-късна версия на темата. Секцията е глобална, защото няма ефект ако е scoped.
    Кръпките са изпратени от Telerik по наш сигнал: https://www.telerik.com/forums/telerik-report-toolbar-missing-with-kendo-sass-themes
-->
<style>
    /*
        align values
        Клас .k-splitter по подразбиране задава line-height: 2; Това измества данните в report viewer-а много надолу.
        Предложението от Telerik беше класът да се override-не така: .k-splitter { line-height: inherit; }
        Това върши работа в изолиран проект, но в този проект Vuetify задава централно .v-application { line-height: 1.5; }.
        Това също измества данните, макар и по-малко. Крайното решение е line-height да се върне на default-ното за browser-а.
    */
    .k-splitter {
        line-height: normal;
    }

    /* aligns icons - Вече не е нужно; към 2021-07 е оправено от Telerik. */
    /*.k-menu-link {
        display: inherit;
    }*/

    /* fixes the dropdown popup of the export button - Вече не е нужно; към 2021-07 е оправено от Telerik. */
    /*#trv-main-menu-export-command {
        position: relative;
    }*/

    /* loading message background - Към 2022-12 не е оправено от Telerik. */
    .trv-pages-area > .trv-error-pane > .trv-centered {
        background-color: white;
        border: 1px solid lightgrey;
    }
</style>
