export class DeputyDoctorDropdownDto {
    // Положително число = нает, отрицетелно число = заместващ, 0 = титуляр.
    public deputyDoctorIdAndRole: number = 0;
    public deputyDoctorName: string = '';
    public deputyDoctorUin: string = '';

    public get title() {
        const maxValue = 2147483647;
        const minValue = -2147483648;
        return `${this.deputyDoctorName}${
            this.deputyDoctorIdAndRole === 0
                ? ', без зам./нает'
                : this.deputyDoctorIdAndRole === minValue
                ? ''
                : this.deputyDoctorIdAndRole === maxValue
                ? ', вкл. от зам./нает'
                : this.deputyDoctorIdAndRole > 0
                ? ', Нает'
                : ', Заместващ'
        }`;
    }
}
