import { Type } from 'class-transformer';

import { NhisPatientImmunizationKey } from '@/model/Nhis/Immunization/NhisPatientImmunizationKey';
import { ImmunizationDto } from '@/model/Nomenclature/ImmunizationDto';
import { NhisVaccineDoseDto } from '@/model/Nomenclature/NhisVaccineDoseDto';
import { NhisVaccineDto } from '@/model/Nomenclature/NhisVaccineDto';

import { NhisImmunizationDto } from './NhisImmunizationDto';

export class NhisPatientImmunizationDto {
    @Type(() => NhisPatientImmunizationKey)
    public key: NhisPatientImmunizationKey = new NhisPatientImmunizationKey();

    public doctorEmployeeSeqNumber: number | null = null;

    @Type(() => NhisVaccineDto)
    public nhisVaccine: NhisVaccineDto | null = null;

    @Type(() => ImmunizationDto)
    public immunization: ImmunizationDto | null = null;

    @Type(() => NhisVaccineDoseDto)
    public nhisVaccineDose: NhisVaccineDoseDto | null = null;

    public nhisVaccineLot: string = '';
    public lotExpirationDate: Date | null = null;
    public examId: number | null = null;
    public occurrenceDate: Date = new Date();
    public isFromPrimarySource: boolean = true;
    public icdCode: string = '';

    public doseNumber: number = 1;
    public doseQuantity: number = 0;
    public isSpecialCase: boolean = false;
    public nextDoseDate: Date | null = null;
    public previousImmunizationNrn: string | null = null;
    public socialGroupId: number | null = null;

    @Type(() => NhisImmunizationDto)
    public nhisImmunization: NhisImmunizationDto = new NhisImmunizationDto();

    public mantuResult: boolean | null = null;

    // Ако съществува ваксина поставена преди НЗИС.
    public isExternalImmunization: boolean = false;
    public externalImmunizationOccurrenceDate: Date | null = null;
    public externalImmunizationVaccineName: string = '';
    public externalImmunizationVaccineLot: string = '';
    public externalImmunizationCountryId: string | null = null;
    public externalImmunizationAuthority: string = '';
    public applicationBodyPart: string | null = null;
    public applicationRoute: string | null = null;
}
