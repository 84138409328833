<template>
    <div>
        <v-card-text>
            <v-row v-for="(doc, index) in value" :key="index" class="my-2">
                <v-col cols="12">
                    <v-row class="align-baseline">
                        <v-col cols="12" md="3"><text-field v-model.trim="doc.name" required label="Име:" /></v-col>
                        <v-col xs="12" md="3" cols="12">
                            <dropdown
                                v-model="doc.externalDocumentTypeCode"
                                class="required"
                                item-value="code"
                                :items="documentTypes"
                                required
                                label="Вид документ:"
                        /></v-col>
                        <v-col cols="12" md="3"
                            ><text-field v-model.trim="doc.nrn" label="НРН:" :rules="[$validator.nrn]"
                        /></v-col>
                        <btn v-if="value.length > 1" icon action="Delete" @click="removeDocument(index)" />
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <btn action="New" @click="addDocument">Добавяне на документ</btn>
        </v-card-actions>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NhifProtocolDocumentDto } from '@/model/Exam/DrugProtocol/NhifProtocolDocumentDto';
    import { ExternalDocumentTypeDto } from '@/model/Nomenclature/ExternalDocumentTypeDto';
    import { externalDocumentTypeService } from '@/service/Nomenclature/ExternalDocumentTypeService';
    import { arrayUtil } from '@/util/ArrayUtil';

    @Component
    export default class NhifProtocolDocument extends Vue {
        @Prop()
        public value!: NhifProtocolDocumentDto[];

        private documentTypes: ExternalDocumentTypeDto[] = [];

        private addDocument() {
            this.value.push(new NhifProtocolDocumentDto());
        }

        private removeDocument(index: number) {
            arrayUtil.removeAt(this.value, index);
        }

        private async getDocumentTypes() {
            this.documentTypes = await externalDocumentTypeService.getExternalDocumentTypes();
        }

        private async mounted() {
            await this.getDocumentTypes();
        }
    }
</script>
