<template>
    <v-card>
        <v-row>
            <v-col cols="12" md="6">
                <v-card-title>Връзка с Контракс</v-card-title>
                <v-card-text>
                    <h2>
                        <v-icon>mdi-at</v-icon>&nbsp;
                        <a href="mailto:hippocrates@kontrax.bg">hippocrates@kontrax.bg</a>
                        <br />
                        <br />
                        <v-icon>mdi-cellphone</v-icon>&nbsp; 02 / 960 97 63
                    </h2>
                </v-card-text>
            </v-col>
            <v-col cols="12" md="6">
                <dealer-contact v-model="dealerContact"></dealer-contact>
            </v-col>
        </v-row>
        <v-card-title class="mt-8">Поддръжка на място</v-card-title>
        <v-card-text>
            <v-icon>mdi-teamviewer</v-icon>&nbsp; Понякога се налага да видим проблема през Вашите очи. Ако случаят е
            такъв, моля щракнете по-долу.
            <br />
            <br />
            <h2>
                <a href="https://download.kontrax.bg/hippocrates/download/Tools/TeamViewerQS-idc6t22732.exe">
                    Временно давам достъп до моя компютър
                </a>
            </h2>
            <br />
            <ul>
                <li>
                    <v-row>
                        <v-col cols="12" md="6">Ще се изтегли файл.<br />Ако се наложи, потвърдете изтеглянето.</v-col>
                        <v-col cols="12" md="6">
                            Microsoft Edge<br />
                            <v-img
                                class="mr-auto"
                                src="@/asset/Help/TeamViewer/DownloadEdge.png"
                                alt="Изтеглен от Edge"
                                max-width="389px"
                            />
                            Google Chrome<br />
                            <v-img
                                class="mr-auto"
                                src="@/asset/Help/TeamViewer/DownloadChrome.png"
                                alt="Изтеглен от Chrome"
                                max-width="505px"
                            />
                        </v-col>
                    </v-row>
                </li>
                <li>
                    <v-row>
                        <v-col cols="12" md="6">Стартирайте файла.<br />Потвърдете стартирането.</v-col>
                        <v-col cols="12" md="6">
                            <v-img
                                class="mr-auto"
                                src="@/asset/Help/TeamViewer/Uac.png"
                                alt="UAC prompt"
                                max-width="456px"
                            />
                        </v-col>
                    </v-row>
                </li>
                <li>
                    <v-row>
                        <v-col cols="12" md="6">
                            Продиктувайте по телефона или изпратете по e-mail номера от зеленото прозорче.
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-img
                                class="mr-auto"
                                src="@/asset/Help/TeamViewer/BrandedExe.png"
                                alt="TeamViewerQS Kontrax"
                                max-width="272px"
                            />
                        </v-col>
                    </v-row>
                </li>
                <li>
                    Ще се свържем към Вашия компютър, всеки ще вижда какво прави другият и ще работим заедно по
                    отстраняване на проблема.
                </li>
                <li>Нашият достъп спира в момента, в който затворите зеленото прозорче.</li>
            </ul>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { config } from '@/Config';
    import { DealerContactDto } from '@/model/Licensing/DealerContactDto';
    import { practiceService } from '@/service/Practice/PracticeService';
    import DealerContact from '@/view/Licensing/DealerContact.vue';

    @Component({ components: { DealerContact } })
    export default class Contact extends Vue {
        @Prop()
        private practiceId!: number;

        private dealerContact: DealerContactDto = new DealerContactDto();

        private async mounted() {
            if (this.practiceId) {
                const response = await practiceService.getPracticeDealerContact(this.practiceId);
                this.dealerContact = response.data;
            }
        }

        private get privacyPolicyUrl() {
            return `${config.identityServerBaseUrl}/Home/Privacy`;
        }
    }
</script>
