export const PrescriptionDrugConfig = {
    insulin: 'INSULIN',
    // Това са лекарства, който се изписват по схемата колко пъти за ден(капсули, таблетки и подобни)
    itemsGivingByQuantityPerDay: [
        'PROLONGED-RELEASE TABLET',
        'Film coated tablet',
        'DISPERSIBLE TABLET',
        'ORODISPERSIBLE TABLET',
        'CHEWABLE TABLET',
        'GASTRO-RESISTANT TABLET',
        'TABLET',
        'MODIFIED-RELEASE TABLET',
        'FILM-COATED TABLET',
        'COATED TABLET',
        'CAPSULES',
        'TABL. FILM.',
        'CAPS. PROLONGED RELEASE, HARD',
        'TABL. FILM. COAT.',
        'TABLETS FILM COATED',
        'CAPS. MODIF.',
        'TABL. MODIF.',
        'CAPS. GASTR. RES.',
        'MODIFIED RELEASE TABL.',
        //Сашета, който се приемат на бройка
        'GASTRO RESISTANT PROLONGED RELEASE GRANULES, SACHETS',
        'MODIFIED RELEASE TABL.',
        // Това са пак сашета, който се приемат на бройка
        'ЖЕЛИРАЩИ ЧРЕВНОТО СЪДЪРЖИМО И КОНТРОЛИРАЩИ МИРИЗМАТА САШЕТА',
        'CAPSULE, HARD',
        'SOLUBLE TABLET',
        'ORODISPERSIBLE TABL',
        'TABL. VAG.',
        'TABLETS FILM-COATED, BOTTLE',
        'GRANULES',
        'GRAN. FOR ORAL SOLUTION',
        'GASTRO RESISTANT CAPSULES, HARD',
        'CAPS. SOFT',
        'TABL. COAT.',
        'TABLETS SUBLINGUAL',
        'TABL. RELEASE PROLONG',
        'TABL',
        'TABLET',
        'GASTRO-RESISTANT CAPSULE',
        // LOZENGE => подсладена таблетка
        'LOZENGE',
        // Ефервесцентни таблетки, които потопени във вода се разтварят като отделят мехурчета и газ
        'EFFERV. TABL.',
        // Супозитории приемат се на бройка
        'SUPP',
        'CAPSULE, SOFT',
        // Диспергиращи се таблетки
        'TABL.DISP.',
        // Дъвчащи таблетки
        'TABL. CHEW.',
        'TABL.PROLONG',
        'CAPS.PROLONG',
        // Предлагат се в сашета и се изписват по брой
        'GASTRO-RES. GRAN. FOR ORAL SUSP.',
        'TABL.GASTR.RES',
        'POWDER FOR ORAL SOLUTION, SACHETS',
        'SACH.',
        'COMPRESSED LOZENGES',
        'GRAN. FOR ORAL SUSP.',
        // Ампули, които се използват на бройка
        'NEBULISER SOLUTION',
        'POWD FOR ORAL SOLUTION',
        'ПРЕВРЪЗКИ АБСОРБИРАЩИ',
        // Това са патрони с дългодействащ инсулин в опаковка по 5 са и лекарят определя по колко на ден се използват
        'SOLUTION FOR INJECTION, CARTRIDGE FOR SUREPAL 5',
        'INHALATION POWDER, HARD CAPSULE',
        'SUSP. FOR NEBULIZATION',
        'GASTRO-RESISTANT PROLONGED-RELEASE GRANULES',
        'GRANULES FOR ORAL SUSPENSION',
        'FILM',
        'GRANULES FOR ORAL SOLUTION',
        'SUPPOSITORY',
        'GASTRO-RESISTANT CAPSULE, HARD',
        'MODIFIED-RELEASE CAPSULE, HARD',
        'PROLONGED-RELEASE CAPSULE, HARD',
        'NEBULISER SUSPENSION',
        'GASTRO-RESISTANT GRANULES',
        'GASTRO-RESISTANT CAPSULE, SOFT'
    ],
    earAndEyeDrops: [
        'EAR DROPS',
        'EYE AND EAR DROPS, SOLUTION',
        'EYE DROPS',
        'EYE DROPS, SUSPENSION',
        'ORAL DROPS',
        'EYE DROPS,SOLUTION',
        'NASAL DROPS SOLUTION',
        'EYE DROPS, SOLUTION'
    ],
    itemsForOneTimeUse: [
        'ИГЛА ЗА ИЗТЕГЛЯНЕ И АСПИРИРАНЕ НА РАЗТВОРИ И ЛЕКАРСТВА;СПРИНЦОВКА ;ИГЛА ЗА СЕТ ЗА СУБКУТАННО ВЛИВА',
        //Това са лекарства тип 'Изделия за стомирано болни' пише,че могат да се носят 2-3 дни препоръчва се да се сменя всеки ден
        'ИЛЕО- И КОЛО- ПЛОЧКА СТОМАХЕЗИВ',
        // Същото като горния коментар ,но на друга фирма
        'ИЛЕО- КОЛО- ПЛОЧКА',
        'ИЛЕО- КОЛО- ПЛОЧКА ДУОФЛЕКС',
        'ИЛЕО- КОЛО- ПЛОЧКА ФЛЕКСИБЪЛ',
        'ИЛЕО ТОРБИ',
        'ИЛЕО-/КОЛО ТОРБА С ФИЛТЪР',
        'ИЛЕО-/КОЛО ТОРБИЧКИ',
        'ИЛЕО-/КОЛОСТОМНА ТОРБ., ЗАТВ.',
        'ИЛЕО-/КОЛОСТОМНА ТОРБ., ОТВ.',
        'ИЛЕО-КОЛО-ПЛОЧКА КОНВЕКС',
        'ИЛЕОСТОМНА ТОРБ., БЕЖ. И МАЛКА',
        'ИЛЕОСТОМНА ТОРБИЧКА С ФИЛТЪР',
        'ИЛЕОСТОМНА ТОРБИЧКА, ДЕТСКА',
        'ИЛЕОСТОМНА ТОРБИЧКА, ПРОЗРАЧНА',
        'ИЛЕОТОРБ. С ИЗТОЧВАНЕ, ДЕТСКА',
        'КОЛОСТОМНА ТОРБА',
        'КОЛОСТОМНА ТОРБИЧКА ЗАТВОРЕНА',
        'КОЛОСТОМНА ТОРБИЧКА ОТВОРЕНА',
        'КОЛОСТОМНА ТОРБИЧКА С ФИЛТЪР',
        'КОЛОСТОМНА ТОРБИЧКА, ДЕТСКА',
        'ОТВ.ТОРБ;СФИЛТ.ПРОЗР;ФИЛТ.БЖ',
        'ОТВОРЕНА ТОРБИЧКА, ДЕТСКА',
        // Част от двукомпонентната система за 'Стомирано Болни' обикновенно се продават в пакети по 10, 5 или 4
        'ПЛОЧКА',
        'ПЛОЧКА, ПРЪСТЕН',
        // Сет от инжексия, разтвор и т.н. за еднократна употреба
        'POWD. AND SOLV. FOR SOL. FOR INJECTION/INFUSION',
        // Част от принадлежностите за стома
        'ФИЛТЪР',
        'ФИКСАТОР',
        'УРОСТОМНА ТОРБИЧКА',
        'УРОСТОМНА ТОРБ. ЗА ДЕЦА',
        'УРИНАТОРНИ ТОРБИ ЗА НЕФРОСТОМА',
        'УРИНАТОРНИ ТОРБИЧКИ',
        'УРО ТОРБА С АЛОЕ',
        'УРО.ТОР;БЕЖ;ПР,М-КА;БЕЖ М-КА',
        'УРОСТОМНА ДВУКОМПОНЕНТНА ТОРБИЧКА',
        'УРОСТОМНА ПЛОЧКА КОНВЕКС',
        'УРОСТОМНА ПЛОЧКА СТОМАХЕЗИВ',
        'УРОСТОМНА ПЛОЧКА ФЛЕКСИБЪЛ',
        // Бинт, който се използва и за придържане един вид предлага се по един в опаковка
        'ТУБУЛАРНИ БИНТОВЕ',
        'ТОРБИЧКА С ИЗТОЧВАНЕ',
        'ТОРБ, ЗАТ.;М-К, СТАНД, ГОЛ.',
        'ПЛОЧКА, ПРЪСТЕН',
        'ДВУКОМПОНЕНТНИ, ДЕТСКИ ТОРБИ',
        'ДОПЪЛНИТЕЛЕН ЗАЛЕПВАЩ РИНГ',
        'ЗАТВОРЕНА ТОРБИЧКА',
        'ЗАТВОРЕНА ТОРБИЧКА ДЕТСКА',
        'ВЪНШНИ КАТЕТРИ',
        'ИЛЕОТОРБ. С ИЗТОЧВАНЕ, ДЕТСКА',
        'ИНСУЛИНОВ РЕЗЕРВОАР',
        // Инф. сет и помпата добре ли е да се смятат за еднократна употреба
        'ИНФУЗИОНЕН СЕТ',
        'ПОМПА ЗА ПОДКОЖНО ВЛИВАНЕ НА ИМУНОГЛОБУЛИН',
        'КОЛАНЧЕ',
        'КОЛО КАПАЧЕ',
        'КОЛОСТОМНА МИНИТОРБ., КАПАЧЕ',
        'КОМП. ПРОТЕЗА ИЗКУСТВЕН АНУС',
        'КОМПЛЕКТ ИРИГАЦИОННА СИСТЕМА',
        'СТРИП ПАСТА',
        'SUSP.RECT.',
        'ЗАЩИТНИ ЛЕНТИ',
        'ИЛЕОТОРБ. С ИЗТОЧВАНЕ, ДЕТСКА',
        'КЪРП. ЗА ПОЧ. НА АДХЕЗИВ. ОСТ.',
        'ПЛАСТЕЛИН',
        'ПРЕВРЪЗКИ АБСОРБИРАЩИ',
        'ПРЕДПАЗЕН ОКОЛОСТОМЕН ПРЪСТЕН/ ЛЕНТА',
        'EYE OINTMENT',
        'IMPLANT',
        'IMPL.',
        'POWD. FOR SOL. FOR INFUSION',
        'SOL. FOR INJ/INF IN PRE-FILLED SYRINGE',
        'ПОЧИСТВАЩИ КЪРПИЧКИ',
        'ПРОТЕКТИВНИ КЪРПИЧКИ',
        'INTRAVITREAL IMPLANT IN APPLICATOR',
        'IMPLANT IN PRE-FILLED SYRINGE',
        'blood glucose sensors'
    ],
    // За момента спираме да го ползваме
    // Това са лекарства като инсулин и т.н., които се смятат чрез доза или мг/кг
    itemsCalcMgToKg: [
        'SUSP.INJ',
        'SOL.INJ',
        'SOL. INJ. PENFILL',
        'SOL. FOR INF.',
        'SOL. FOR INJ/INF',
        'SOL. INF. VIAL',
        'SOLUTION FOR INJECTION',
        'SOLUTION FOR INJECTION 1 PREFILLED SYRINGES + 1 ALCOHOL PADS',
        'SOLUTION FOR INJECTION 2 PREFILLED SYRINGES + 2 ALCOHOL PADS',
        'SOLUTION FOR INJECTION IN 2 PRE-FILLED PENS',
        'SOLUTION FOR INJECTION INITIATION PACK: 1 PRE-FILLED PEN (63 MCG) + 1 PRE-FILLED PEN (94 MCG)',
        'SOLUTION FOR INJECTION PREFILLED SYRINGE WITH NEEDLE GUARD',
        'SOLUTION FOR INJECTION, CARTRIDGE FOR SUREPAL 10',
        'SOLUTION FOR INJECTION, CARTRIDGE FOR SUREPAL 5',
        'POWDER AND SOLVENT FOR INJECTION FOR INFUSION',
        'POWDER AND SOLVENT FOR PROLONGED-RELEASE SUSPENSION FOR INJECTION',
        'POWDER AND SOLVENT FOR SOLUTION FOR INJECTION',
        'POWDER AND SOLVENT FOR SOLUTION FOR INJECTION (1 VIAL + 1 SYRINGE + 1 VIAL ADAPTER)',
        'POWDER FOR CONCENTRATE SOLUTION FOR INFUSION',
        'POWDER FOR INJ+SOLV FOR SOL+TRANSFER SET',
        'POWDER FOR SOLUTION FOR INJECTION',
        'POWDER FOR SOLUTION FOR INJECTION/INFUSION OR INHALATION',
        'POWDER FOR SUSPENSION FOR INJECTION + SOLVENT',
        'POWDER+SOLV. FOR SOL. FOR INJ.',
        'PROLONGED RELEASE SUSP. FOR INJ. IN PRE-FILLED SYRINGE',
        'ORAL SOL.',
        'POWD.FOR SOL. FOR INJ/INF',
        'POWD.INF.',
        'SOL.INJ. PREFILLED PEN',
        'POWDER AND SOLVENT FOR SOLUTION FOR INJECTION/INFUSION',
        'POWDER AND SOLVENT FOR SUSPENSION FOR INJECTION',
        'POWDER FOR ORAL SUSPENSION',
        'POWDER FOR CONCENTRATE FOR SOLUTION FOR INFUSION',
        'POWDER FOR ORAL SOLUTION',
        'POWDER FOR SOLUTION FOR INJECTION/INFUSION AND INHALATION',
        'POWDER FOR SOLUTION FOR INFUSION',
        'ORAL SUSPENSION',
        'ORAL SOLUTION',
        'SOLUTION FOR INFUSION',
        'SOLUTION FOR INJECTION IN PRE-FILLED SYRINGE',
        'SOLUTION FOR INJECTION/INFUSION',
        'SUSPENSION FOR INJECTION',
        'SOLUTION FOR INJECTION IN PRE-FILLED PEN',
        'INHALATION SOLUTION',
        'PROLONGED-RELEASE SUSPENSION FOR INJECTION',
        'SYRUP'
    ],
    gelAndOintmentItems: [
        'NASAL GEL',
        'NASAL OINTMENT',
        'GEL',
        'CREAM',
        'EYE OINTMENT',
        // Мисля че е сбъркана категорията, би трябвало да е CUTANEOUS SOLUTION със 'C'
        'GUTANEOUS SOLUTION',
        'CUTANEOUS EMULSION',
        'OINTMENT',
        'ГЕЛ',
        'КРЕМ ЗА ТЯЛО',
        'ПАСТА, ЗАПЪЛВАЩА',
        'ПАСТА ЛЕЧЕБНА',
        'CUTANEOUS EMULSION',
        'RECTAL CREAM',
        'RECTAL SUSPENSION'
    ],
    itemsWithDoseInName: [
        'SPRAY',
        'SUSP.INH.PRES',
        'INHALATION POWDER',
        'INHALATION POWDER, PRE-DISPENSED',
        'OROMUCOSAL SPRAY',
        'POWDER INH',
        'PRESSURIZED INHALATION, SUSPENSION',
        'SOL.INH.PRES',
        'OROMUCOSAL SPRAY, SOLUTION',
        'SUBLINGUAL SPRAY, SOLUTION',
        'PRESSURISED INHALATION, SUSPENSION'
    ],
    coronaryDrugs: ['BF241', 'BF316']
};
