import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { FundCode } from '@/enum/Nomenclature/FundCode';
import { DeleteUndeleteCommand } from '@/model/Common/DeleteUndeleteCommand';
import { VisitCacheDto } from '@/model/Exam/Cache/VisitCacheDto';
import { ExamDto } from '@/model/Exam/ExamDto';
import { GroupPrintExamCommand } from '@/model/Exam/GroupPrintExamCommand';
import { UpdateExamConditionCommand } from '@/model/Exam/UpdateExamConditionCommand';
import { httpService } from '@/service/Infrastructure/HttpService';
import { currentVisitCache } from '@/store/CurrentVisitCache';
import { userContextCache } from '@/store/User/UserContextCache';

export const examService = {
    async getExamById(examId: number): Promise<ExamDto> {
        const response = await httpService.get<ExamDto>(`/Exam/GetExam/${examId}`);
        return plainToInstance(ExamDto, response.data);
    },

    async getCopiedExamById(examId: number): Promise<ExamDto> {
        return (await httpService.get<ExamDto>(`/Exam/GetCopiedExamById/${examId}`)).data;
    },

    async getNextAmbulatorySheetNumberForCurrentEmployee(): Promise<number> {
        const response = await httpService.get<number>('/Exam/GetNextAmbulatorySheetNumber', {
            params: {
                practiceId: userContextCache.currentPracticeId,
                doctorEmployeeSeqNumber: userContextCache.currentDoctorEmployeeSeqNumber
            }
        });
        return response.data;
    },

    async createExam(dto: ExamDto): Promise<VisitCacheDto> {
        const response = await httpService.post<VisitCacheDto>('/Exam/CreateExam', dto);
        return plainToInstance(VisitCacheDto, response.data);
    },

    async updateExam(dto: ExamDto): Promise<ExamDto> {
        const response = await httpService.post<ExamDto>('/Exam/UpdateExam/', dto);
        return plainToInstance(ExamDto, response.data);
    },

    updateExamConditionFromRiskAssessmentCard(data: string): Promise<AxiosResponse> | null {
        if (currentVisitCache.isLoaded) {
            const command = new UpdateExamConditionCommand(currentVisitCache.value.id, data);
            return httpService.post('/Exam/UpdateExamCondition/', command);
        }
        return null;
    },

    deleteUndeleteExam(examId: number, isDeleted: boolean): Promise<AxiosResponse> {
        const data = new DeleteUndeleteCommand(examId, isDeleted);
        return httpService.delete('/Exam/DeleteUndeleteExam', { data });
    },

    async getExamDate(examId: number): Promise<Date> {
        return (await httpService.get<Date>(`/Exam/GetExamDateById/${examId}`)).data;
    },

    async setExamIsPrinted(command: GroupPrintExamCommand): Promise<void> {
        await httpService.post(`/Exam/SetExamIsPrinted`, command);
    },

    async clearExamIsDigitallySigned(examId: number): Promise<void> {
        await httpService.get(`/Exam/ClearExamIsDigitallySigned/${examId}`);
    },

    async getDefaultFundForVisit(visitId: number): Promise<FundCode> {
        const response = await httpService.get<FundCode>(`/Exam/GetDefaultFundForVisit/${visitId}`);
        return response.data;
    },

    async getExamVisitReason(examId: number): Promise<string> {
        const response = await httpService.get<string>(`/Exam/GetExamVisitReason/${examId}`);
        return response.data;
    }
};
