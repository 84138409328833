export class PisFileUploadRequestDto {
    public purpose: string = '';
    public uin: string | null = null;
    public contractNumber: string = '';
    public contractDate: string = '';
    public contractType: number = 0;
    public startDate: string | null = null;
    public endDate: string | null = null;
    public docDate: string = '';
    public specCode: string | null = null;
    public fileName: string | null = null;

    public constructor(public contents: string, public lzNumber: string, public userId: string) {}
}
