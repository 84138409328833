<template>
    <!--
      Въведеният api-key е взет оттук: https://www.tiny.cloud/my-account/integrate/#vue,
      а тук hipokrat.bg е добавен като разрешен домейн: https://www.tiny.cloud/my-account/domains/
      Влиза се чрез Google с hippcloud@gmail.com. Този account се използва и за настройването на Google Login в Identity Server.
      Активирани са само безплатни plugin-и и функции на tiny-mce.
    -->
    <editor
        api-key="ff8zr986935993ejcjoeh26pbkczd20klg8tvlxh8pmx48mz"
        :value="value"
        :init="{
            height: 300,
            menubar: true,
            readonly: 1,
            plugins: [
                'advlist',
                'autolink',
                'lists',
                'charmap',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'insertdatetime',
                'wordcount'
            ],

            toolbar:
                'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat'
        }"
        @input="$emit('input', ...arguments)"
    />
</template>

<script lang="ts">
    import Editor from '@tinymce/tinymce-vue';
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component({
        components: { Editor }
    })
    export default class RichText extends Vue {
        @Prop()
        private value!: string;
    }
</script>
