<template>
    <div>
        <v-row>
            <v-col cols="12"
                ><h3 class="mb-2">
                    Регистрирани заболявания в амбулаториите на лечебните заведения по вид на заболяването на лица на
                    възраст над 18 години
                </h3></v-col
            >
        </v-row>
        <v-row>
            <v-col cols="12"><DataTableComponent v-model="value.adultDiseases" :headers="headers" /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Annex56AdultDiseaseDto } from '@/model/Report/Rhi/Annex56/Annex56AdultDiseaseDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class AdultDisease extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Annex56AdultDiseaseDto;

        private timer: number = 0;

        private headers: IDataTableHeader[] = [
            { text: 'НАИМЕНОВАНИЕ НА БОЛЕСТИТЕ ПО МКБ–10', value: 'name' },
            { text: 'Шифър', value: 'code' },
            { text: 'Заболявания - всичко', value: 'total' },
            { text: 'Новооткрити заболявания - всичко', value: 'countNewFound' }
        ];

        @Watch('value.adultDiseases', { deep: true })
        private onValueChanged() {
            const timeout = 3000;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => this.calculateSectionRow(), timeout);
        }

        private calculateSectionRow() {
            for (let idx = 0; idx < this.value.adultDiseases.length; idx++) {
                if (this.value.adultDiseases[idx].isSection) {
                    this.value.adultDiseases[idx].countNewFound = 0;
                    this.value.adultDiseases[idx].total = 0;

                    for (let kidx = 0; kidx < this.value.adultDiseases.length; kidx++) {
                        if (this.value.adultDiseases[kidx].parentCode === this.value.adultDiseases[idx].code) {
                            this.value.adultDiseases[idx].countNewFound =
                                (this.value.adultDiseases[idx].countNewFound ?? 0) +
                                (this.value.adultDiseases[kidx].countNewFound ?? 0);
                            this.value.adultDiseases[idx].total =
                                (this.value.adultDiseases[idx].total ?? 0) +
                                (this.value.adultDiseases[kidx].total ?? 0);
                        }
                    }
                }
            }
        }
    }
</script>
