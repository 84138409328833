<template>
    <div>
        <v-row>
            <v-col cols="12"
                ><h3 class="mb-2">
                    Фактори, влияещи върху здравното състояние на населението и контакта със здравните служби - ОБЩО
                </h3></v-col
            >
        </v-row>
        <v-row>
            <v-col cols="12"
                ><DataTableComponent v-model="value.factorForHealthStatusesTotal" :headers="headers"
            /></v-col>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { Annex56FactorForHealthStatusTotalDto } from '@/model/Report/Rhi/Annex56/Annex56FactorForHealthStatusTotalDto';
    import DataTableComponent from '@/view/Report/Rhi/Report365/DataTableComponent.vue';

    @Component({
        components: {
            DataTableComponent
        }
    })
    export default class FactorForHealthStatusTotal extends Vue {
        @Prop({ required: true, type: Object })
        private value!: Annex56FactorForHealthStatusTotalDto;

        private timer: number = 0;

        private headers: IDataTableHeader[] = [
            { text: 'НАИМЕНОВАНИЕ НА БОЛЕСТИТЕ ПО МКБ–10', value: 'name' },
            { text: 'Шифър', value: 'code' },
            { text: 'Всичко', value: 'total' },
            { text: 'Новооткрити заболявания', value: 'countNewFound' }
        ];
    }
</script>
