import { Route, RouteConfig } from 'vue-router';

import { NhisPatientImmunizationKey } from '@/model/Nhis/Immunization/NhisPatientImmunizationKey';

const patientChildRoutes: RouteConfig[] = [
    {
        path: 'PersonalData/:id?/:patientVersionSeqNumber?',
        props: {
            patient: (route: Route) => ({
                id: route.params.id ? Number(route.params.id) : null,
                patientVersionSeqNumber: route.params.patientVersionSeqNumber
                    ? Number(route.params.patientVersionSeqNumber)
                    : null
            })
        },
        components: {
            patient: () => import('@/view/Patient/PatientEdit/PatientEdit.vue')
        }
    },
    {
        path: 'Workplace/:id?',
        props: {
            patient: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Patient/PatientEdit/PatientWorkplaceView.vue')
        }
    },
    {
        path: 'NhifPrescriptionBook/:id',
        props: {
            patient: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Nhif/NhifPrescriptionBook.vue')
        }
    },
    {
        path: 'NhifPrescriptionBookDiagnosis/Create/:id',
        props: {
            patient: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Nhif/NhifPrescriptionBookDiagnosisEditor.vue')
        }
    },
    {
        path: 'NhifPrescriptionBookDiagnosis/Edit/:bookDiagnosisId',
        props: {
            patient: (route: Route) => ({
                bookDiagnosisId: Number(route.params.bookDiagnosisId)
            })
        },
        components: {
            patient: () => import('@/view/Nhif/NhifPrescriptionBookDiagnosisEditor.vue')
        }
    },
    {
        path: 'PrescriptionBook/:id',
        props: {
            patient: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Patient/PrescriptionBook/PrescriptionBook.vue')
        }
    },
    {
        path: 'PreviousGp/:id/:patientVersionSeqNumber?',
        props: {
            patient: (route: Route) => ({
                id: Number(route.params.id),
                patientVersionSeqNumber: route.params.patientVersionSeqNumber
                    ? Number(route.params.patientVersionSeqNumber)
                    : null
            })
        },
        components: {
            patient: () => import('@/view/Patient/PreviousGp.vue')
        }
    },
    {
        path: 'RiskGroup/:id',
        props: {
            patient: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Patient/RiskGroup/RiskGroup.vue')
        }
    },
    {
        path: 'FeeDisengagement/:id',
        props: {
            patient: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Patient/FeeDisengagement/FeeDisengagement.vue')
        }
    },
    {
        path: 'Immunization/:id',
        props: {
            patient: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Patient/Immunization/Immunization.vue')
        }
    },
    {
        path: 'DeathNotice/:id',
        props: {
            patient: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Patient/Gp/DeathNotice.vue')
        }
    },
    {
        path: 'VisitSummary/:id',
        props: {
            patient: (route: Route) => ({ patientId: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/VisitList/PatientVisitSummary.vue')
        }
    },
    {
        path: 'EImmunizations/:id',
        props: {
            patient: (route: Route) => ({ patientId: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Nhis/Immunization/NhisImmunizationView.vue')
        }
    },
    {
        path: 'EImmunization/Preview/:practiceId/:patientId/:immunizationSeqNumber',
        props: {
            patient: (route: Route) => {
                const immunizationKey = new NhisPatientImmunizationKey();
                immunizationKey.patientKey.practiceId = Number(route.params.practiceId);
                immunizationKey.patientKey.patientId = Number(route.params.patientId);
                immunizationKey.immunizationSeqNumber = Number(route.params.immunizationSeqNumber);
                return { immunizationKey };
            }
        },
        components: {
            patient: () => import('@/view/Nhis/Immunization/NhisImmunizationEditor.vue')
        }
    },
    {
        path: 'FileUpload/:id',
        props: {
            patient: (route: Route) => ({ patientId: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/File/PatientFileView.vue')
        }
    },
    {
        path: 'ExternalVisit/:id',
        props: {
            patient: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Patient/PatientExternalVisit.vue')
        }
    },
    {
        path: 'ExternalVisit/Create/:patientId',
        props: {
            patient: (route: Route) => ({ patientId: Number(route.params.patientId) })
        },
        components: {
            patient: () => import('@/view/Exam/Results/ExternalVisit.vue')
        }
    },
    {
        path: 'ExternalVisit/Edit/:practiceId/:patientId/:seqNumber',
        props: {
            patient: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                patientId: Number(route.params.patientId),
                seqNumber: Number(route.params.seqNumber)
            })
        },
        components: {
            patient: () => import('@/view/Exam/Results/ExternalVisit.vue')
        }
    },
    {
        path: 'Dispensary/:practiceId/:id',
        props: {
            patient: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                patientId: Number(route.params.id)
            })
        },
        components: {
            patient: () => import('@/view/Patient/Dispensary/DispensaryList.vue')
        }
    },
    {
        path: 'DispensaryExternal/Create/:practiceId/:id',
        props: {
            patient: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                patientId: Number(route.params.id)
            })
        },
        components: {
            patient: () => import('@/view/Patient/Dispensary/DispensaryExternalEdit.vue')
        }
    },
    {
        path: 'DispensaryExternal/Edit/:practiceId/:id/:seqNumber',
        props: {
            patient: (route: Route) => ({
                practiceId: Number(route.params.practiceId),
                patientId: Number(route.params.id),
                seqNumber: Number(route.params.seqNumber)
            })
        },
        components: {
            patient: () => import('@/view/Patient/Dispensary/DispensaryExternalEdit.vue')
        }
    },
    {
        path: 'Allergy/:id',
        props: {
            patient: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Patient/Allergy/PatientAllergy.vue')
        }
    },
    {
        path: 'Allergy/Create/:id',
        props: {
            patient: (route: Route) => ({ patientId: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Patient/Allergy/PatientAllergyEditor.vue')
        }
    },
    {
        path: 'Allergy/Edit/:documentId/:id',
        props: {
            patient: (route: Route) => ({
                patientId: Number(route.params.id),
                documentId: Number(route.params.documentId)
            })
        },
        components: {
            patient: () => import('@/view/Patient/Allergy/PatientAllergyEditor.vue')
        }
    },
    {
        path: 'FamilyHistory/:id',
        props: {
            patient: (route: Route) => ({ id: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Patient/FamilyHistory/FamilyHistory.vue')
        }
    },
    {
        path: 'FamilyHistory/Create/:id',
        props: {
            patient: (route: Route) => ({ patientId: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Patient/FamilyHistory/FamilyHistoryEditor.vue')
        }
    },
    {
        path: 'FamilyHistory/Edit/:documentId/:id',
        props: {
            patient: (route: Route) => ({
                patientId: Number(route.params.id),
                documentId: Number(route.params.documentId)
            })
        },
        components: {
            patient: () => import('@/view/Patient/FamilyHistory/FamilyHistoryEditor.vue')
        }
    },
    {
        path: 'IncomingReferralPatient/:id/:doctorEmployeeSeqNumber',
        props: {
            patient: (route: Route) => ({
                id: Number(route.params.id),
                doctorEmployeeSeqNumber: Number(route.params.doctorEmployeeSeqNumber)
            })
        },
        components: {
            patient: () => import('@/view/Query/Patient/IncomingReferralPatientReport.vue')
        }
    },
    {
        path: 'PreventiveVisit/:id',
        props: {
            patient: (route: Route) => ({ patientId: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Patient/PatientPreventiveVisit.vue')
        }
    },
    {
        path: 'DispensaryVisit/:id',
        props: {
            patient: (route: Route) => ({ patientId: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Patient/Dispensary/PatientDispensaryVisit.vue')
        }
    },
    {
        path: 'PatientFund/:id',
        props: {
            patient: (route: Route) => ({ patientId: Number(route.params.id) })
        },
        components: {
            patient: () => import('@/view/Patient/PatientEdit/PatientFund.vue')
        }
    }
];

export const patientRoutes: RouteConfig = {
    path: '/Patient',
    props: (route: Route) => ({ id: Number(route.params.id) }),
    component: () => import('@/view/Patient/PatientMenu.vue'),
    children: patientChildRoutes
};
