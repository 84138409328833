import { plainToInstance } from 'class-transformer';

import { PatientKey } from '@/model/Patient/PatientKey';
import { PatientPrescriptionDrugDto } from '@/model/Query/Patient/PatientPrescriptionDrugDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const patientPrescriptionDrugService = {
    async getPatientPrescriptionDrugs(patientKey: PatientKey): Promise<PatientPrescriptionDrugDto[]> {
        const response = await httpService.get<PatientPrescriptionDrugDto[]>(
            'PatientPrescriptionDrug/GetPatientPrescriptionDrugs',
            { params: patientKey }
        );
        return plainToInstance(PatientPrescriptionDrugDto, response.data);
    },

    async getPatientNhisPrescriptionDrugs(patientKey: PatientKey): Promise<PatientPrescriptionDrugDto[]> {
        const response = await httpService.get<PatientPrescriptionDrugDto[]>(
            'PatientPrescriptionDrug/GetPatientNhisPrescriptionDrugs',
            { params: patientKey }
        );
        return plainToInstance(PatientPrescriptionDrugDto, response.data);
    },

    async checkForSpirivaPrescribing(patientId: number, unitCode: string): Promise<string> {
        const response = await httpService.get<string>(
            `PatientPrescriptionDrug/CheckForSpirivaPrescribing?patientId=${patientId}&unitCode=${unitCode}`
        );
        return response.data;
    }
};
