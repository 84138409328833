<template>
    <div>
        <data-table
            title="Нежелани реакции"
            :headers="tableHeaders"
            :loading="$loading.isVisible"
            :items="items"
            disable-pagination
            sort-by="startDate"
        >
            <template #actions>
                <btn action="New" @click="createReaction" />
            </template>
            <template #rowActions="{ item }">
                <btn icon action="Edit" @click="editReaction(item)" />
            </template>
        </data-table>

        <v-dialog v-model="dialogIsVisible" persistent max-width="75%">
            <nhis-immunization-reaction-editor
                v-model="cloneObject"
                :immunization-key="immunizationKey"
                @change="updateGrid"
                @close="closeDialog"
            />
        </v-dialog>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { NhisImmunizationReactionDto } from '@/model/Nhis/Immunization/NhisImmunizationReactionDto';
    import { NhisPatientImmunizationKey } from '@/model/Nhis/Immunization/NhisPatientImmunizationKey';
    import { nhisImmunizationService } from '@/service/Nhis/NhisImmunizationService';
    import { formatters } from '@/util/Formatters';
    import NhisImmunizationReactionEditor from '@/view/Nhis/Immunization/NhisImmunizationReactionEditor.vue';

    @Component({
        components: { NhisImmunizationReactionEditor }
    })
    export default class NhisImmunizationReactionView extends Vue {
        @Prop()
        immunizationKey!: NhisPatientImmunizationKey;

        private items: NhisImmunizationReactionDto[] = [];
        private cloneObject: NhisImmunizationReactionDto = new NhisImmunizationReactionDto();
        private dialogIsVisible: boolean = false;

        private tableHeaders: IDataTableHeader[] = [
            { text: 'Начало:', value: 'startDate', formatter: formatters.date },
            { text: 'Край:', value: 'endDate', formatter: formatters.date },
            { text: 'Забелязано от пациента:', value: 'isSelfReported', formatter: formatters.boolean },
            { text: 'НРН на реакцията:', value: 'nrn' },
            { text: 'Дата на изпращане:', value: 'uploadDate', formatter: formatters.date },
            { text: 'Описание', value: 'description' }
        ];

        private async mounted() {
            if (this.immunizationKey) {
                await this.getTableItems();
            }
        }

        private createReaction() {
            Object.assign(this.cloneObject, new NhisImmunizationReactionDto());
            this.openDialog();
        }

        private editReaction(item: NhisImmunizationReactionDto) {
            Object.assign(this.cloneObject, item);
            this.openDialog();
        }

        private async getTableItems() {
            this.$loading.show();
            try {
                const response = await nhisImmunizationService.getImmunizationReactions(this.immunizationKey);
                if (response?.data) {
                    this.items = response.data;
                }
            } finally {
                this.$loading.hide();
            }
        }

        closeDialog(): void {
            this.cloneObject = new NhisImmunizationReactionDto();
            this.dialogIsVisible = false;
        }

        openDialog(): void {
            this.dialogIsVisible = true;
        }

        private updateGrid(model: NhisImmunizationReactionDto) {
            const immunization = this.items.find((item) => item.key.reactionSeqNumber === model.key.reactionSeqNumber);
            if (immunization) {
                Object.assign(immunization, model);
            } else {
                this.items.push(model);
            }
            this.closeDialog();
        }
    }
</script>
