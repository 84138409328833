<template>
    <ExamLayout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        :is-custom-header-slot="!isNone"
        title="Болничен лист - Приложение № 3 към чл. 13, ап. 1"
        help-action="BL"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <!--Ако е актив значи е изпратен и трябва да се вижда custom меню-то-->
        <div v-if="document.id && !isNone" slot="customHeader">
            <SickSheetCustomHeader
                v-model="document"
                :disabled="isCanceled"
                :can-print="document.id > 0"
                @copy="copySickSheet(document.id)"
                @printed="printEventHandler"
                @canceled="cancelingEventHandler"
            />
        </div>
        <sick-sheet-upload
            v-if="documentId"
            class="mt-3"
            :document-id="documentId"
            :status="document.statusCodeOfSend"
            :canceled-sick-sheet-id="document.canceledSickSheetId"
            @status-change="statusChange"
        />
        <sick-sheet-patient-report />
        <counter-warning v-if="!document.id" :left-counters="document.leftSickCounterNumbers" />
        <total-days-warning :total-days="document.sickSheetTotalDaysPatient" />

        <v-card class="my-md-2" :disabled="isCanceled || isActive" :style="`background-color: ${backgroundColor}`">
            <main-title class="mb-2">Болничен лист - Приложение № 3 към чл. 13, ап. 1</main-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4" class="pb-0">
                        <text-field v-model="document.number" disabled label="Болничен лист №" />
                    </v-col>
                    <v-col cols="12" md="4" class="pb-0">
                        <v-select
                            v-model="isCanceled"
                            dense
                            :items="[
                                { id: false, name: 'Издаване' },
                                { id: true, name: 'Анулиране' }
                            ]"
                            item-text="name"
                            item-value="id"
                            label="Вид на болничен лист"
                            disabled
                        />
                    </v-col>
                    <v-col cols="12" md="4" class="pb-0">
                        <date-picker v-model="document.issueDate" label="Издаден на"></date-picker>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mb-md-2" :disabled="isCanceled || isActive" :style="`background-color: ${backgroundColor}`">
            <v-card-text>
                <v-row>
                    <v-col cols="6" md="3" class="pb-0">
                        <text-field v-model="currentPatient.lakNumber" disabled label="ЛАК №" />
                    </v-col>
                    <v-col cols="6" md="3" class="pb-0">
                        <text-field v-model="document.ambulatorySheetNumber" disabled label="№ на амб. лист" />
                    </v-col>
                    <v-col cols="6" md="3" class="pb-0">
                        <text-field v-model="document.historyOfSickNumber" disabled label="Ист. забол. №" />
                    </v-col>
                    <v-col cols="6" md="3" class="pb-0">
                        <v-select
                            v-model="document.isContinuation"
                            dense
                            :items="[
                                { id: false, name: 'Първичен' },
                                { id: true, name: 'Продължение' }
                            ]"
                            item-text="name"
                            item-value="id"
                            label="Тип на Болничен лист"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mb-md-2" :disabled="isCanceled || isActive" :style="`background-color: ${backgroundColor}`">
            <v-card-title>Адрес на лечебното заведение</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6" md="3">
                        <text-field v-model="document.nhifCode" disabled label="Рег. № на л.з." />
                    </v-col>
                    <v-col cols="12" md="5">
                        <text-field
                            v-model="document.nameOfHospital"
                            disabled
                            label="Издаден от"
                            hint="лечебно заведение по ЗЛЗ /вид, име/"
                        />
                    </v-col>
                    <v-col cols="12" md="4">
                        <text-field
                            v-model="currentVisit.doctorNames"
                            disabled
                            hint="име на лекаря /вид ЛКК, клиника/ отделение"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="3" class="pb-0">
                        <text-field v-model="document.cityOfHospital" disabled label="гр." />
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                        <text-field v-model="document.addressOfHospital" disabled label="адрес" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mb-md-2" :disabled="isCanceled || isActive" :style="`background-color: ${backgroundColor}`">
            <btn action="Edit" class="float-right" @click="onFamilyModeChange">Придружаващо лице</btn>
            <sick-sheet-patient-info
                :background-color="backgroundColor"
                :family-member="document.familyMember"
                :choice-maker="isFamilyMemberMode"
                :sick-sheet="document"
                @family-member-change="familyMemberChange"
                @update-patient-workplace="updatePatientWorkplace"
            />
        </v-card>

        <v-card class="mb-md-2 col" :disabled="isCanceled || isActive" :style="`background-color: ${backgroundColor}`">
            <v-card-title class="pt-0">Диагноза</v-card-title>
            <v-card-text class="pb-0">
                <v-row v-if="visitHasExam">
                    <v-col cols="12" md="6" class="py-0">
                        <icd-picker v-if="useOtherIcdCode" v-model="document.icdCode" required />
                        <exam-diagnosis-picker v-else v-model="selectedExamDiagnosis" required use-main-icd />
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                        <v-checkbox v-model="useOtherIcdCode" label="Искам да използвам друга диагноза" />
                    </v-col>
                </v-row>
                <icd-picker v-else v-model="document.icdCode" required />

                <alert :value="isIcdCodeInvalid" type="error">
                    Системата на НОИ не приема МКБ кодове с повече от 2 знака след точката. Моля променете.
                </alert>
            </v-card-text>
        </v-card>

        <v-card class="mb-md-2" :disabled="isCanceled || isActive" :style="`background-color: ${backgroundColor}`">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6" class="pb-0">
                        <dropdown
                            v-model="document.reasonOfDiseaseCode"
                            :items="reasonOfDiseaseTypeFiltered"
                            item-text="name"
                            item-value="code"
                            label="Причина за неработоспособност"
                            required
                        />
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                        <v-select
                            v-model="document.regimenDaysSubtypeCodeArray"
                            class="required"
                            :rules="[$validator.required]"
                            :items="regimenDaysSubtype"
                            multiple
                            dense
                            deletable-chips
                            item-text="name"
                            item-value="code"
                            label="Режим на лечение"
                            :menu-props="{ closeOnContentClick: true }"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                <v-row>
                    <v-col cols="4" md="3" class="py-0">
                        <date-picker v-model="document.sickLeaveStartDate" label="В отпуск от"></date-picker>
                    </v-col>
                    <v-col cols="4" md="3" class="py-0">
                        <date-field v-model="document.sickLeaveEndDate" label="В отпуск до" />
                    </v-col>
                    <v-col cols="4" md="3" class="py-0">
                        <text-field
                            label="Всичко отпуск в календарни дни"
                            readonly
                            :min="0"
                            :max="999"
                            type="number"
                            :value="
                                (document.sickLeaveLegalDays =
                                    document.hospitalRegimenDays +
                                    document.homeRegimenDays +
                                    document.sanatoriumRegimenDays +
                                    document.unrestrictedRegimenDays)
                            "
                            :rules="minDaysSickSheet"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-title>Брой на дните при режим</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col class="py-1">
                        <text-field
                            v-model.number="document.hospitalRegimenDays"
                            label="a/ болничен"
                            :disabled="detectRegimenDayIsDisabled(['1'])"
                            :min="0"
                            :max="999"
                            type="number"
                            :required="!detectRegimenDayIsDisabled(['1'])"
                            :rules="minAndMaxValue"
                        />
                    </v-col>
                    <v-col class="py-1">
                        <text-field
                            v-model.number="document.homeRegimenDays"
                            label="б/ домашен"
                            :disabled="detectRegimenDayIsDisabled(['2', '3', '4'])"
                            :min="0"
                            :max="999"
                            type="number"
                            :required="!detectRegimenDayIsDisabled(['2', '3', '4'])"
                            :rules="minAndMaxValue"
                        />
                    </v-col>
                    <v-col class="py-1">
                        <text-field
                            v-model.number="document.sanatoriumRegimenDays"
                            label="в/
                            сан.-курортен"
                            :disabled="detectRegimenDayIsDisabled(['5'])"
                            :min="0"
                            :max="999"
                            type="number"
                            :required="!detectRegimenDayIsDisabled(['5'])"
                            :rules="minAndMaxValue"
                        />
                    </v-col>
                    <v-col class="py-1">
                        <text-field
                            v-model.number="document.unrestrictedRegimenDays"
                            label="г/ свободен"
                            :disabled="detectRegimenDayIsDisabled(['6', '7'])"
                            :min="0"
                            :max="999"
                            type="number"
                            :required="!detectRegimenDayIsDisabled(['6', '7'])"
                            :rules="minAndMaxValue"
                        />
                    </v-col>
                    <v-col class="py-1">
                        <text-field
                            label="Общо дни"
                            :min="0"
                            :max="999"
                            disabled
                            :value="
                                document.hospitalRegimenDays +
                                document.homeRegimenDays +
                                document.sanatoriumRegimenDays +
                                document.unrestrictedRegimenDays
                            "
                            type="number"
                        />
                    </v-col>
                    <v-col class="py-1">
                        <text-field
                            v-model="document.regimenDaysWord"
                            disabled
                            label="Дни /словом/"
                            :value="
                                numberInWords(
                                    document.hospitalRegimenDays +
                                        document.homeRegimenDays +
                                        document.sanatoriumRegimenDays +
                                        document.unrestrictedRegimenDays
                                )
                            "
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mb-md-2" :disabled="isCanceled || isActive" :style="`background-color: ${backgroundColor}`">
            <v-card-text>
                <v-row>
                    <v-card-title dense>Бременност</v-card-title>
                    <v-col cols="12" md="3" class="pb-0">
                        <date-picker v-model="document.pregnancyTermDate" label="Термин" />
                    </v-col>
                    <v-col cols="12" md="3" class="pb-0">
                        <date-picker v-model="document.pregnancyDeliveryDate" label="Раждане" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card
            v-if="isFamilyMemberMode"
            class="mb-md-2"
            :disabled="isCanceled || isActive"
            :style="`background-color: ${backgroundColor}`"
        >
            <v-card-title>Придружаване и гледане на болен член от семейството</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col class="py-0">
                        <dropdown
                            v-if="document.familyMember"
                            v-model="document.familyMember.familyMemberTypeId"
                            :required="isFamilyMemberMode"
                            :items="sickFamilyType"
                            clearable
                            label="Роднинска връзка"
                        />
                    </v-col>
                    <v-col class="py-0">
                        <text-field hint="Име" :value="isFamilyMemberMode ? currentPatient.firstName : ''" />
                    </v-col>
                    <v-col class="py-0">
                        <text-field hint="Презиме" :value="isFamilyMemberMode ? currentPatient.middleName : ''" />
                    </v-col>
                    <v-col class="py-0">
                        <text-field hint="Фамилия" :value="isFamilyMemberMode ? currentPatient.lastName : ''" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0">
                        <PidTypePicker
                            v-if="isFamilyMemberMode"
                            v-model="currentPatient.personIdentification.pidTypeCode"
                            disabled
                        />
                        <PidTypePicker v-else disabled />
                    </v-col>
                    <v-col class="py-0">
                        <text-field
                            hint="ЕГН/ЛНЧ на болния"
                            :value="isFamilyMemberMode ? currentPatient.personIdentification.identifier : ''"
                        />
                    </v-col>
                    <v-col class="py-0"></v-col>
                    <v-col class="py-0"></v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mb-md-2" :disabled="isCanceled || isActive" :style="`background-color: ${backgroundColor}`">
            <v-card-text>
                <v-row>
                    <v-card-title>Да се яви на преглед на</v-card-title>
                    <v-col cols="3" class="mt-4 py-0">
                        <date-picker v-model="document.dateMedicalCheck" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card :disabled="isCanceled || isActive" :style="`background-color: ${backgroundColor}`">
            <v-card-text>
                <v-row>
                    <v-col cols="6" md="3">
                        <text-field v-model="document.lkkDecisionNumber" type="number" label="Решение ЛКК №" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <date-picker v-model="document.lkkDecisionDate" label="Дата ЛКК"></date-picker>
                    </v-col>

                    <v-col cols="6" md="3">
                        <text-field v-model="document.telkDecisionNumber" type="number" label="Решение ТЕЛК/НЕЛК №" />
                    </v-col>
                    <v-col cols="6" md="3">
                        <date-picker v-model="document.telkDecisionDate" label="Дата ТЕЛК"></date-picker>
                    </v-col>
                </v-row>
                <v-textarea :value="generateNotesMessage()" label="Бележки" disabled outlined />
                <btn action="List" @click="notesDialogModel.dialogIsVisible = true">Бележки</btn>
                <SickSheetNotes
                    v-model="notesDialogModel"
                    :sick-sheet="document"
                    @sick-sheet-notes-updated="applyUpdatedNotes"
                />
            </v-card-text>
            <sick-sheet-doctor v-model="document" />
        </v-card>

        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import ExamDiagnosisPicker from '@/component/Exam/ExamDiagnosisPicker.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import IcdPicker from '@/component/Picker/IcdPicker.vue';
    import PidTypePicker from '@/component/Picker/PidTypePicker.vue';
    import { SickSheetStatusCode } from '@/enum/Exam/SickSheetStatusCode';
    import { FamilyMemberDto } from '@/model/Exam/SickSheet/FamilyMemberDto';
    import { SickSheetDto } from '@/model/Exam/SickSheet/SickSheetDto';
    import { SickSheetNotesDialogModel } from '@/model/Exam/SickSheet/SickSheetNotesDialogModel';
    import { ReasonOfDiseaseDto } from '@/model/Nomenclature/ReasonOfDiseaseDto';
    import { RegimenDaysSubtypeDto } from '@/model/Nomenclature/RegimenDaysSubtypeDto';
    import { SickFamilyMemberTypeDto } from '@/model/Nomenclature/SickFamilyMemberTypeDto';
    import { sickSheetService } from '@/service/Exam/SickSheetService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { userSettingsState } from '@/store/User/UserSettingsState';
    import { formatters } from '@/util/Formatters';
    import CounterWarning from '@/view/Exam/SickSheet/SickSheetCounterWarning.vue';
    import SickSheetCustomHeader from '@/view/Exam/SickSheet/SickSheetCustomHeader.vue';
    import SickSheetDoctor from '@/view/Exam/SickSheet/SickSheetDoctor.vue';
    import SickSheetNotes from '@/view/Exam/SickSheet/SickSheetNotes.vue';
    import SickSheetPatientReport from '@/view/Exam/SickSheet/SickSheetPatientReport.vue';
    import TotalDaysWarning from '@/view/Exam/SickSheet/SickSheetTotalDaysSickWarning.vue';
    import SickSheetUpload from '@/view/Exam/SickSheet/SickSheetUpload.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    import SickSheetPatientInfo from './SickSheetPatientInfo.vue';

    type ruleType<T> = ((val: T) => void)[] | boolean[] | string[];

    const sickConfig = {
        maxDays: 999,
        maxAge: 120,
        egnCountDigit: 10,
        maxChars: 200,
        diseaseTypeWithFamilyMember: ['15', '25', '26', '27']
    };

    @Component({
        components: {
            SickSheetPatientReport,
            SickSheetDoctor,
            UnsavedChanges,
            SickSheetUpload,
            ExamDiagnosisPicker,
            ExamLayout,
            IcdPicker,
            PidTypePicker,
            SickSheetPatientInfo,
            SickSheetNotes,
            SickSheetCustomHeader,
            CounterWarning,
            TotalDaysWarning
        },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class SickSheet extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private documentId!: number;

        @Prop()
        private visitId!: number;

        @Prop()
        private copySickSheetId!: number;

        private get currentPatient() {
            return currentPatientCache.value;
        }

        private get currentVisit() {
            return currentVisitCache.value;
        }

        private document: SickSheetDto = new SickSheetDto();
        private initialValue: SickSheetDto = new SickSheetDto();
        private reasonOfDiseaseType: ReasonOfDiseaseDto[] = [];
        private reasonOfDiseaseTypeFiltered: ReasonOfDiseaseDto[] = [];
        private regimenDaysSubtype: RegimenDaysSubtypeDto[] = [];
        private sickFamilyType: SickFamilyMemberTypeDto[] = [];
        private isFamilyMemberMode: boolean = false;
        private notesDialogModel: SickSheetNotesDialogModel = new SickSheetNotesDialogModel();
        private regimenDaysTypesArray: string[] = [];
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();
        private useOtherIcdCode: boolean = false;

        private get backgroundColor() {
            return !userSettingsState.userSettings.ui.hasReferralsBackground
                ? 'transparent'
                : userSettingsState.userSettings.ui.isDarkTheme
                ? '#949463'
                : '#e7e79d';
        }

        private get isIcdCodeInvalid() {
            const validIcdCodeLength = 5;
            return this.document?.icdCode?.length > validIcdCodeLength;
        }

        private copySickSheet(id: number) {
            this.$router.push(`/Exam/SickSheet/Copy/${id}`);
        }

        private cancelingEventHandler() {
            this.document.statusCodeOfSend = SickSheetStatusCode.ActiveToBeCanceled;
        }

        private familyMemberChange(item: FamilyMemberDto) {
            this.document.familyMember = item;
        }

        private minAndMaxValue: ruleType<number> = [
            (val: number) => (val && val > 0) || val === 0 || 'Броят на дните трябва да е по голям от 0',
            (val: number) =>
                val <= sickConfig.maxDays || `Броят на дните не може да бъде по-голям от ${sickConfig.maxDays}`
        ];

        private minDaysSickSheet: ruleType<number> = [
            (val: number) =>
                (val && val >= 0) ||
                `Броят дни във Болничният лист не може да е число по-малко или равно на 0.
            Моля попълнете някой от полетата за режим на лечение`
        ];

        private minAndMaxCanceledChars: ruleType<string> = [
            (val: string) =>
                val.length <= sickConfig.maxChars ||
                `Дължината на текста не може да е повече от ${sickConfig.maxChars} символа`
        ];

        private get isChangeForCanceletion() {
            if (this.document?.statusCodeOfSend === SickSheetStatusCode.ActiveToBeCanceled && !this.copySickSheetId) {
                return true;
            }
            return false;
        }

        private get isNone() {
            return this.document?.statusCodeOfSend === SickSheetStatusCode.None || !this.document.statusCodeOfSend;
        }

        private get isCanceled() {
            return (
                this.document?.statusCodeOfSend === SickSheetStatusCode.Canceled ||
                this.document?.statusCodeOfSend === SickSheetStatusCode.ActiveToBeCanceled
            );
        }

        private get isActive() {
            return this.document?.statusCodeOfSend === SickSheetStatusCode.Active && !this.copySickSheetId;
        }

        private get isThereFreeSickCounter() {
            return Boolean(
                this.document.leftSickCounterNumbers && this.document.id > 0 && this.document.leftSickCounterNumbers > 0
            );
        }

        private get visitHasExam() {
            return Boolean(currentVisitCache.value.exam);
        }

        private get selectedExamDiagnosis() {
            return currentVisitCache.value.exam?.examDiagnoses.find(
                (diagnosis) => diagnosis.icdCode === this.document.icdCode
            );
        }

        private set selectedExamDiagnosis(examDiagnosis) {
            this.document.icdCode = examDiagnosis?.icdCode ?? '';
        }

        private daysInWords: string = '';

        private async mounted() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                if (this.documentId) {
                    this.document = await sickSheetService.getSickSheetById(this.documentId);
                    await currentVisitCache.load(this.document.visitId);
                } else if (this.copySickSheetId) {
                    this.document = await sickSheetService.getCopiedSickSheetById(this.copySickSheetId);
                    await currentVisitCache.load(this.document.visitId);
                } else if (this.visitId) {
                    this.document = await sickSheetService.getSickSheetInfoByVisit(this.visitId);
                } else {
                    const doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber;
                    if (doctorEmployeeSeqNumber) {
                        this.document = await sickSheetService.getSickSheetInfoByPatientAndDoctor(
                            currentPatientCache.value.key.patientId,
                            doctorEmployeeSeqNumber
                        );
                    }
                }

                if (!this.selectedExamDiagnosis) {
                    this.useOtherIcdCode = true;
                }

                this.isFamilyMemberChoesed();
                await this.loadDropdowns();
                this.configureWatch();
                this.document.dateMedicalCheck = this.document.dateMedicalCheck || this.document.sickLeaveEndDate;
                this.copyObject();
            } finally {
                this.$loading.hide();
            }
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private updatePatientWorkplace(patientWorkplaceId: number) {
            this.document.patientWorkplaceId = patientWorkplaceId;
        }

        private applyUpdatedNotes() {
            this.document.generatedNotes = this.notesDialogModel.generatedNotes;
            this.document.additionalNotes = this.notesDialogModel.additionalNotes;
            this.document.noteNssiCodes = this.notesDialogModel.selectedNssiCodes;
        }

        private generateNotesMessage() {
            const generatedNotes = this.document.generatedNotes || '';
            const additionalNotes = this.document.additionalNotes || '';

            return `${generatedNotes}\r\n${additionalNotes}`;
        }

        private isFamilyMemberChoesed() {
            this.isFamilyMemberMode =
                this.document.familyMember !== null &&
                this.document.familyMember.familyMemberFirstName !== null &&
                this.document.id > 0;
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                if (!this.document.id) {
                    const newId =
                        this.visitId || this.copySickSheetId
                            ? await sickSheetService.createSickSheet(this.document)
                            : await sickSheetService.createSickSheetVisit(
                                  currentPatientCache.value.key.patientId,
                                  userContextCache.currentDoctorEmployeeSeqNumber ?? 0,
                                  this.document
                              );

                    const title = `Болничен лист №${newId}`;
                    if (!this.copySickSheetId) {
                        eventBus.$emit('create-referral-event', newId, 'SickSheet', title);
                    } else {
                        this.$notifier.showSuccess(
                            'Редактиране с анулиране',
                            `Успешно създаден запис на редактиране с анулиране: Болничен лист ${newId}`
                        );
                    }
                    this.copyObject();
                    this.$router.push(`/Exam/SickSheet/Edit/${newId}`);
                } else {
                    await sickSheetService.updateSickSheet(this.document);
                    eventBus.$emit('update-referral-event', 'Болничен лист');
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const documentId = this.document.id;
                const title = `Болничен лист №${documentId}`;
                const visitIsDeleted = await sickSheetService.deleteSickSheet(documentId);
                this.copyObject();
                this.$router.push(
                    visitIsDeleted
                        ? `/Patient/VisitSummary/${currentPatientCache.value.key.patientId}`
                        : `/Exam/SickSheet/Create/${currentVisitCache.value.id}`
                );
                eventBus.$emit('delete-referral-event', documentId, 'SickSheet', title);
            } finally {
                this.$loading.hide();
            }
        }

        private onFamilyModeChange() {
            this.isFamilyMemberMode = !this.isFamilyMemberMode;
            this.filterReasonOfDisease();
        }

        private filterReasonOfDisease() {
            if (this.isFamilyMemberMode) {
                const familyDiseaseCodes = ['15', '25', '26', '27'];
                this.reasonOfDiseaseTypeFiltered = this.reasonOfDiseaseType.filter((element) =>
                    familyDiseaseCodes.includes(element.code)
                );
            } else {
                this.reasonOfDiseaseTypeFiltered = this.reasonOfDiseaseType;
            }
        }

        private async loadDropdowns() {
            this.reasonOfDiseaseType = await sickSheetService.getReasonOfDiseaseTypes();
            this.filterReasonOfDisease();
            this.regimenDaysSubtype = await sickSheetService.getRegimenDaysSubtypes();
            this.sickFamilyType = await sickSheetService.getSickFamilyMemberType();
        }

        private configureWatch() {
            this.$watch(() => this.document.hospitalRegimenDays, this.changeSickEndDate);
            this.$watch(() => this.document.homeRegimenDays, this.changeSickEndDate);
            this.$watch(() => this.document.sanatoriumRegimenDays, this.changeSickEndDate);
            this.$watch(() => this.document.unrestrictedRegimenDays, this.changeSickEndDate);
        }

        private changeSickEndDate() {
            const defaultHour = 12;
            const days = this.document.sickLeaveLegalDays;
            if (days && this.document.sickLeaveEndDate && this.document.sickLeaveStartDate) {
                const date = new Date(this.document.sickLeaveStartDate);
                date.setDate(date.getDate() + days - 1);
                date.setHours(defaultHour);
                this.document.sickLeaveEndDate = date;
                this.document.dateMedicalCheck = date;
            }
        }

        @Watch('document.sickLeaveStartDate')
        private onSickLeaveStartDateChange() {
            this.changeSickEndDate();
        }

        @Watch('document.regimenDaysSubtypeCodeArray')
        private onRegimenDaysSubtypeCodeArrayChange(value: string[] | null) {
            if (value) {
                this.regimenDaysTypesArray = [...value];
            }
        }

        private detectRegimenDayIsDisabled(codes: string[]): boolean {
            if (codes.length && this.regimenDaysTypesArray.length) {
                for (const code of codes) {
                    if (this.regimenDaysTypesArray.includes(code)) {
                        return false;
                    }
                }
            }
            return true;
        }

        @Watch('document.regimenDaysSubtypeCodeArray')
        private changeRegimenDaysValue(codeArray: string[]) {
            if (codeArray.length) {
                if (this.detectRegimenDayIsDisabled(['1'])) {
                    this.document.hospitalRegimenDays = 0;
                }
                if (this.detectRegimenDayIsDisabled(['2', '3', '4'])) {
                    this.document.homeRegimenDays = 0;
                }
                if (this.detectRegimenDayIsDisabled(['5'])) {
                    this.document.sanatoriumRegimenDays = 0;
                }
                if (this.detectRegimenDayIsDisabled(['6', '7'])) {
                    this.document.unrestrictedRegimenDays = 0;
                }
            }
        }

        @Watch('currentPatient.firstName')
        private onFirstNameChanged() {
            this.currentPatient.firstName = formatters.capitalizeFirstLetter(this.currentPatient.firstName);
        }

        @Watch('currentPatient.middleName')
        private onMiddleNameChanged() {
            this.currentPatient.middleName = formatters.capitalizeFirstLetter(this.currentPatient.middleName);
        }

        @Watch('currentPatient.lastName')
        private onLastNameChanged() {
            this.currentPatient.lastName = formatters.capitalizeFirstLetter(this.currentPatient.lastName);
        }

        // Следният алгоритъм изписва число с думи и е най-четим, когато директно използва magic numbers.
        /* eslint-disable @typescript-eslint/no-magic-numbers */
        private numberInWords(days: number): string {
            const digitInWords: string[] = [
                'нула',
                'един',
                'два',
                'три',
                'четири',
                'пет',
                'шест',
                'седем',
                'осем',
                'девет',
                'десет'
            ];
            if (days >= 0 && days <= 10) {
                this.daysInWords = digitInWords[days];
            } else if (days <= 19) {
                this.daysInWords = days === 11 ? 'единадесет' : `${digitInWords[days - 10]}надесет`;
            } else if (days <= 99) {
                const firstDigit = days % 10;
                const secondDigit = (days - firstDigit) / 10;
                this.daysInWords = `${digitInWords[secondDigit]}десет${
                    firstDigit > 0 ? ` и ${digitInWords[firstDigit]}` : ''
                }`;
            } else if (days <= sickConfig.maxDays) {
                this.daysInWords = SickSheet.numberInWordsHundreds(days, digitInWords);
            }
            this.document.regimenDaysWord = this.daysInWords;
            return this.daysInWords;
        }

        private static numberInWordsHundreds(days: number, digitInWords: string[]) {
            const firstDigit = days % 10;
            const secondDigit = ((days - firstDigit) / 10) % 10;
            const thirdDigit = (days - firstDigit - secondDigit * 10) / 100;
            const prefix =
                thirdDigit === 1
                    ? 'сто'
                    : thirdDigit === 2
                    ? 'двеста'
                    : thirdDigit === 3
                    ? 'триста'
                    : `${digitInWords[thirdDigit]}стотин`;

            let result = prefix;
            if (firstDigit > 0 || secondDigit > 0) {
                if (secondDigit === 1 && firstDigit === 0) {
                    result += ' и десет';
                } else if (secondDigit === 1 && firstDigit === 1) {
                    result += ' и единадесет';
                } else if (secondDigit === 1 && firstDigit > 1) {
                    result += ` и ${digitInWords[firstDigit]}надесет`;
                } else if (firstDigit === 0) {
                    result += ` и ${digitInWords[secondDigit]}десет`;
                } else if (secondDigit === 0) {
                    result += ` и ${digitInWords[firstDigit]}`;
                } else {
                    result += ` ${digitInWords[secondDigit]}десет и ${digitInWords[firstDigit]}`;
                }
            }
            return result;
        }
        /* eslint-enable @typescript-eslint/no-magic-numbers */

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.SickSheet/${this.documentId}`);
            } else {
                // Todo: notification
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.SickSheet';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private statusChange(statusCode: string) {
            this.document.statusCodeOfSend = statusCode;
        }
    }
</script>
