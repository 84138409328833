<template>
    <div class="caption text--disabled">{{ practiceTypeName }}</div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { practiceTypeCache } from '@/store/Nomenclature/PracticeTypeCache';

    @Component
    export default class PracticeTypeLabel extends Vue {
        @Prop()
        practiceNumber!: string | null;

        private get practiceTypeName() {
            return practiceTypeCache.getPracticeTypeNameOrEmptyString(this.practiceNumber);
        }
    }
</script>
