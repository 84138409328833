import { NhisExamDto } from '@/model/Nhis/Exam/NhisExamDto';
import { NhisExamExtractionCommand } from '@/model/Nhis/Exam/NhisExamExtractionCommand';
import { NhisExamExtractionResultDto } from '@/model/Nhis/Exam/NhisExamExtractionResultDto';
import { NhisXmlRequestDto } from '@/model/Nhis/NhisXmlRequestDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { nhisService } from '@/service/Nhis/NhisService';
import { signXmlService } from '@/service/Report/SignXmlService';
import { loadingState } from '@/store/LoadingState';
import { nhisKey } from '@/store/NhisNhifAccessTokenCache';

const getOpeningExamXml = async function getOpeningExamXml(requestData: NhisXmlRequestDto): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetOpeningExamXml`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getClosingExamXml = async function getClosingExamXml(requestData: NhisXmlRequestDto): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetClosingExamXml`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getCorrectionExamXml = async function getCorrectionExamXml(requestData: NhisXmlRequestDto): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetCorrectionExamXml`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getXmlCancellationExam = async function getXmlCancellationExam(requestData: NhisXmlRequestDto): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetCancellationExamXml`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getSickSheetsXml = async function getSickSheetsXml(requestData: NhisXmlRequestDto): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetSickSheets`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getExamExtractionRequestXml = async function getExamExtractionRequestXml(
    requestData: NhisExamExtractionCommand
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetExamExtractionRequestXml`, requestData);
    console.log(response);
    return response?.data ?? '';
};

const getConsultationExtractionRequestXml = async function getConsultationExtractionRequestXml(
    requestData: NhisExamExtractionCommand
): Promise<string> {
    const response = await httpService.post<string>(`/${nhisKey}/GetConsultationExtractionRequestXml`, requestData);
    console.log(response);
    return response?.data ?? '';
};

export const nhisExamService = {
    async postOpeningExam(requestData: NhisXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await getOpeningExamXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const examResult = await nhisService.postXmlToNhisWithId<NhisExamDto>(
                    'PostOpenExamRequest',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(examResult?.response);
                if (examResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${examResult.error}`);
                } else {
                    if (examResult?.warnings?.length > 0) {
                        nhisService.showWarnings(examResult.warnings);
                    }
                    return examResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postClosingExam(requestData: NhisXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await getClosingExamXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const examResult = await nhisService.postXmlToNhisWithId<NhisExamDto>(
                    'PostClosingExamRequest',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(examResult?.response);
                if (examResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${examResult.error}`);
                } else {
                    if (examResult?.warnings?.length > 0) {
                        nhisService.showWarnings(examResult.warnings);
                    }
                    return examResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postCorrectionExam(requestData: NhisXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await getCorrectionExamXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const examResult = await nhisService.postXmlToNhisWithId<NhisExamDto>(
                    'PostCorrectionExamRequest',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(examResult?.response);
                if (examResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${examResult.error}`);
                } else {
                    await notifierService.showSuccess('Резултат', `${'Успешно редактиране'}`);
                    if (examResult?.warnings?.length > 0) {
                        nhisService.showWarnings(examResult.warnings);
                    }
                    return examResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postSickSheets(requestData: NhisXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await getSickSheetsXml(requestData);
            if (xmlString === '') {
                await notifierService.showWarning(
                    '',
                    'Не са намерени активни/анулирани болнични листове! Моля проверете дали бланките са изпретени към НОИ'
                );
            }
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const examResult = await nhisService.postXmlToNhisWithId<NhisExamDto>(
                    'PostSickSheetsRequest',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(examResult?.response);
                if (examResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${examResult.error}`);
                } else {
                    await notifierService.showSuccess('Резултат', `${'Успешно изпращане'}`);
                    if (examResult?.warnings?.length > 0) {
                        nhisService.showWarnings(examResult.warnings);
                    }
                    return examResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postCancelExam(requestData: NhisXmlRequestDto) {
        loadingState.show();
        try {
            const xmlString = await getXmlCancellationExam(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const examResult = await nhisService.postXmlToNhisWithId<NhisExamDto>(
                    'PostCancellationExamRequest',
                    signResponse.contents,
                    requestData.entityId
                );
                console.log(examResult?.response);
                if (examResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${examResult.error}`);
                } else {
                    return examResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postExamExtraction(requestData: NhisExamExtractionCommand) {
        loadingState.show();
        try {
            const xmlString = await getExamExtractionRequestXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const extractionResult = await nhisService.postXmlToNhis<NhisExamExtractionResultDto>(
                    'PostExamExtraction',
                    signResponse.contents
                );
                console.log(extractionResult?.response);

                if (extractionResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${extractionResult.error}`);
                } else {
                    return extractionResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postConsultationExtraction(requestData: NhisExamExtractionCommand) {
        loadingState.show();
        try {
            const xmlString = await getConsultationExtractionRequestXml(requestData);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const extractionResult = await nhisService.postXmlToNhis<NhisExamExtractionResultDto>(
                    'PostConsultationExtraction',
                    signResponse.contents
                );
                console.log(extractionResult?.response);

                if (extractionResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${extractionResult.error}`);
                } else {
                    return extractionResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    //Флагът setNewSignature се използва за да се сетне датата на подписване, но когато се прави проверка на подписа
    // тази дата трябва да остане същата
    async getExamDataToSign(examId: number, setNewSignature: boolean): Promise<string> {
        const response = await httpService.get<string>(`/${nhisKey}/GetExamDataToSign`, {
            params: {
                examId,
                setNewSignature
            }
        });
        return response?.data;
    },

    async getExamDataCorrectionToSign(examId: number, setSignatureDate: boolean): Promise<string> {
        const response = await httpService.get<string>(`/${nhisKey}/GetExamDataCorrectionToSign`, {
            params: {
                examId,
                setSignatureDate
            }
        });
        return response?.data;
    }
};
