import axios from 'axios';
import { plainToInstance } from 'class-transformer';
import Vue from 'vue';

import { config } from '@/Config';
import { AppInfoModel } from '@/model/Infrastructure/AppInfoModel';

import { notifierService } from './NotifierService';

// VUE_APP_APP_INFO не се задава чрез .env файла, а се генерира във vue.config.js по време на serve/build/lint.
const builtInInfoRaw = JSON.parse(process.env.VUE_APP_APP_INFO);
if (!builtInInfoRaw) {
    throw new Error('Липсва инфомация за приложението.');
}

const builtInInfo = plainToInstance(AppInfoModel, builtInInfoRaw);
// Мнението на сървъра за това каква е версията идва от AppInfo.json по-късно и трябва да бъде реактивно.
const downloadedInfo = Vue.observable(new AppInfoModel());

// По време на build, версията на този проект се записва в json файл - виж vue.config.js.
// Този файл може да се прочете и така да се засече кога е качена нова версия.
const downloadAppInfo = async () => {
    downloadedInfo.clear();

    // Сглобява се уникален URI, за да се заобиколи кеширането на файла.
    // Баланс между кратко, уникално и проследимо: от 1669190901073 маха последните 3 и първите 2 цифри и взима 69190901.
    const msInSecond = 1000;
    const secondsIn3Years = 100000000;
    const antiCache = Math.floor(Date.now() / msInSecond) % secondsIn3Years;
    const response = await axios.get<AppInfoModel>(`${config.spaBaseUrlAbsolute}AppInfo.json?t=${antiCache}`);

    Object.assign(downloadedInfo, plainToInstance(AppInfoModel, response.data));
};

// Проверява за нова версия при навигиране, но не по-често от през 1 минута (60 * 1000 ms).
// TODO: В по-натоварените среди интервалът да бъде например 10 минути и да се чете от .env файловете.
const minPeriodForUpdateCheck = 60000;
let lastUpdateCheckTick = 0;

const isTimeForUpdate = () => {
    const now = Date.now();
    const elapsedTime = now - lastUpdateCheckTick;
    if (elapsedTime < minPeriodForUpdateCheck) {
        return false;
    }
    lastUpdateCheckTick = now;
    return true;
};

const expectedAppInfoKey = 'expectedAppInfo';

const cleanupAutoUpdate = () => {
    localStorage.removeItem(expectedAppInfoKey);
};

const loadExpectedInfoJson = () => localStorage.getItem(expectedAppInfoKey);

const saveExpectedInfo = (appInfo: AppInfoModel) => {
    // За всеки случай информацията за приложението се записва в local storage със същата индентация, като във vue.config.js.
    localStorage.setItem(expectedAppInfoKey, JSON.stringify(appInfo, null, '    '));
};

const updateIfVersionChanged = () => {
    // Ако вече се очаква нова версия, значи предишото автоматично обновяване е било неуспшно.
    // В такъв случай не се правят повторни опити, за да не изпадне приложението в безкрайно презареждане.
    // Повторен опит се прави едва след като версията на сървъра стане още по-нова от неуспешно обновената.
    const expectedInfoJson = loadExpectedInfoJson();
    let expectedInfo = null;
    if (expectedInfoJson) {
        expectedInfo = plainToInstance(AppInfoModel, JSON.parse(expectedInfoJson));

        // Ако междувременно версиите са се изравнили, флагът за неуспех се сваля.
        if (downloadedInfo.equals(builtInInfo)) {
            console.log(
                `Отпадна нуждата от обновяване до ${expectedInfo.format(true)}. Актуална е ${builtInInfo.format(true)}.`
            );
            cleanupAutoUpdate();
            expectedInfo = null;
        }
    }

    // По време на разработка AppInfo.json може да се промени, например в следствие на lint-ването преди commit.
    // Това кара dev сървъра да презареди и обнови приложението, но в него остава вграден старият номер на версия,
    // защото VUE_APP_APP_INFO се изчислява при изпълнението на npm run serve и не се променя след това.
    // За да не излизат грешки за неуспшно обновяване, по време на разработка не се правят опити за обновяване.
    if (!config.isDevelopment && !downloadedInfo.equals(builtInInfo)) {
        if (downloadedInfo.equals(expectedInfo)) {
            console.log(
                `Приложението няма да се обновява от ${builtInInfo.format(true)} до ${downloadedInfo.format(
                    true
                )}, защото последният опит е бил неуспешен.`
            );
        } else {
            console.log(`Начало на обновяване от ${builtInInfo.format(true)} до ${downloadedInfo.format(true)}...`);
            saveExpectedInfo(downloadedInfo);
            // Просто презарежда страницата. Разчита на това, че build-натите chunk-ове са със случайни имена
            // (съдържанието на index.html е променено) и че web сървърът ще засече новите дати на файловете.
            window.location.reload();
        }
    }
};

const appUpdateService = {
    async tryAutoUpdate() {
        if (isTimeForUpdate()) {
            await downloadAppInfo();
            if (!downloadedInfo.isEmpty) {
                updateIfVersionChanged();
            } else {
                // Lint-ването преди commit променя файла, dev сървърът презарежда приложението, но файлът е заключен и идва празен response.
                console.log(
                    'Версията на приложението не може да се прочете от сървъра. AppInfo.json е заключен или празен.'
                );
            }
        }
    },

    // Проверка дали е имало автоматично обновяване и дали е завършило успешно.
    validateAutoUpdate() {
        const expectedInfoJson = loadExpectedInfoJson();
        if (expectedInfoJson !== null) {
            const expectedInfo = plainToInstance(AppInfoModel, JSON.parse(expectedInfoJson));
            if (expectedInfo) {
                if (expectedInfo.equals(builtInInfo)) {
                    const successMessage = `Успешно обновяване до ${builtInInfo.format(true)}.`;
                    console.log(successMessage);
                    cleanupAutoUpdate();
                    notifierService.showSuccess('', successMessage);
                } else {
                    const warningMessage = `Неуспешно обновяване: очаквана ${expectedInfo.format(
                        true
                    )}, заредена ${builtInInfo.format(true)}.`;
                    console.warn(warningMessage);
                    notifierService.showWarning('', warningMessage);
                    // Нарочно не се изпълнява cleanupAutoUpdate(), за да не се правят повече опити за обновяване до тази версия.
                }
            } else {
                console.warn(
                    'Bug: Празна или счупена очаквана инфомация за приложението в local storage:',
                    expectedInfoJson
                );
                cleanupAutoUpdate();
            }
        }
    },

    // През повечето време показва версията без build номера, т.е. с точност до ден, а не до минута, за по-прегледно.
    // Когато приложението се обновява или има проблем с обновяването, се показват старата/вградената и новата/очакваната версия.
    // Примери:
    // - v58 rc от 24.11 = приложението е обновено, всичко е нормално.
    // - v58 rc от 24.11 13:17 ... = тече проверка за нова версия.
    // - v58 rc от 24.11 13:17 / v58 rc от 25.11 10:46 = Неуспшено обновяване до друга версия.
    get appVersionText() {
        return builtInInfo.equals(downloadedInfo)
            ? builtInInfo.format(false)
            : downloadedInfo.isEmpty
            ? `${builtInInfo.format(true)} ...`
            : `${builtInInfo.format(true)} / ${downloadedInfo.format(true)}`;
    }
};

export { appUpdateService };
