<!--
    В този компонент е важен само кодът. Template-ът не се вижда, защото се рендира в невидим iframe в следствие на redirect от IdentityServer обратно към този SPA.
    Може да се види само ако ръчно се зареди адрес /SigninSilentCallback, което обаче няма смисъл и не прави нищо.
    Template-ът не може да се изтрие изцяло, защото това води го грешка "Failed to mount component: template or render function not defined".
-->
<template>
    <v-card flat>
        <v-card-title>Сесията се подновява...</v-card-title>
    </v-card>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import { oidcClient } from '@/service/Infrastructure/OidcClient';

    @Component
    export default class SigninSilentCallback extends Vue {
        private created() {
            oidcClient.signinSilentCallback();
        }
    }
</script>
