import { plainToInstance } from 'class-transformer';

import { PatientKey } from '@/model/Patient/PatientKey';
import { PatientVisitSummaryCommand } from '@/model/VisitList/PatientVisitSummary/PatientVisitSummaryCommand';
import { PatientVisitSummaryDto } from '@/model/VisitList/PatientVisitSummary/PatientVisitSummaryDto';
import { httpService } from '@/service/Infrastructure/HttpService';
import { userContextCache } from '@/store/User/UserContextCache';

export const patientVisitSummaryService = {
    async getPatientVisitSummary(patientKey: PatientKey, allVisits: boolean): Promise<PatientVisitSummaryDto> {
        const { current } = userContextCache;
        if (!current) {
            throw Error('Не е избрана месторабота.');
        }
        const command = new PatientVisitSummaryCommand(patientKey, current.employeeSeqNumber, allVisits);

        const response = await httpService.post<PatientVisitSummaryDto>(
            'PatientVisitSummary/GetPatientVisitSummary',
            command
        );
        return plainToInstance(PatientVisitSummaryDto, response.data);
    }
};
