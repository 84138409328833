<template>
    <div
        :style="
            showScrollbars ? `overflow-x: scroll !important; overflow-y: scroll;height:${scaledWindowHeight}px` : ''
        "
    >
        <slot name="header">
            <div ref="appBarParent">
                <v-toolbar
                    v-if="!hideToolbar"
                    :width="appBarWidth"
                    :min-width="appBarWidth"
                    :max-width="appBarWidth"
                    :height="$vuetify.breakpoint.smAndDown ? appBarHeight * 2 : appBarHeight"
                    class="fixToolbarAtTheTop"
                    :style="scaledView"
                >
                    <slot name="customHeader"></slot>
                    <v-col v-if="!isCustomHeaderSlot" class="px-0" cols="12">
                        <h4>{{ title }}</h4>
                        <v-row v-if="$allowWrite">
                            <v-col v-if="canSave" cols="4" s="4" md="2">
                                <btn
                                    action="Save"
                                    :disabled="!$allowWrite || disableButtons"
                                    block
                                    @click="updateDocument()"
                                />
                            </v-col>
                            <v-col v-if="canPrint" cols="4" s="4" md="2">
                                <v-menu open-on-hover bottom offset-y pointer>
                                    <template #activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on">
                                            <btn action="Print" :disabled="disableButtons" block />
                                        </div>
                                    </template>

                                    <v-list>
                                        <v-list-item @click="printWithoutPreview">
                                            <v-list-item-title class="pointer">Печат</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="printDocument">
                                            <v-list-item-title class="pointer">Печат - преглед</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                            <v-col cols="4" s="4" md="2">
                                <help-popup :action="helpAction"></help-popup>
                            </v-col>
                            <v-col v-if="customButtonText" cols="4" s="4" md="2">
                                <btn action="Primary" block @click="customButtonClick()">
                                    {{ customButtonText }}
                                </btn>
                            </v-col>
                            <v-spacer />
                            <v-col v-if="canDelete" class="mx-md-auto" cols="4" s="4" md="2">
                                <btn action="Delete" :disabled="disableButtons" block @click="deleteDocument()" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-toolbar>
            </div>
        </slot>
        <main class="pt-15" :style="scaledView">
            <v-form
                ref="documentForm"
                v-model="isValid"
                class="mt-5"
                :disabled="!$allowWrite"
                :style="$vuetify.breakpoint.smAndDown ? { 'padding-top': `${appBarHeight}px` } : ''"
            >
                <slot></slot>
            </v-form>
        </main>
        <footer>
            <slot name="footer"> </slot>
        </footer>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { HelpActionCode } from '@/model/Help/HelpAction';
    import { loadingState } from '@/store/LoadingState';
    import { userSettingsState } from '@/store/User/UserSettingsState';

    @Component
    export default class ExamLayout extends Vue {
        @Prop({ required: true })
        private canDelete!: boolean;

        @Prop()
        private canPrint!: boolean;

        @Prop({ default: true })
        private canSave!: boolean;

        @Prop()
        private disabled!: boolean;

        @Prop()
        private isCustomHeaderSlot!: boolean;

        @Prop()
        private hideToolbar!: boolean;

        @Prop({ default: '' })
        private helpAction!: HelpActionCode;

        @Prop({ default: '' })
        private title!: string;

        @Prop()
        private customButtonText!: string;

        private get disableButtons() {
            return loadingState.isVisible;
        }

        private isValid: boolean = false;

        private appBarWidth: number = 0;
        // Трябва да декларирам още 1 променлива / константа ли?
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        private appBarHeight: number = 60;

        private updateDocument() {
            (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();

            if (this.isValid) {
                this.$emit('updated');
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        private printDocument() {
            this.$emit('printed');
        }

        private printWithoutPreview() {
            this.$emit('printed-without-preview');
        }

        private deleteDocument() {
            this.$emit('deleted');
        }

        private customButtonClick() {
            this.$emit('customButtonClick');
        }

        private mounted() {
            this.setAppBarWidth();
            window.addEventListener('resize', this.onResize);
        }

        private setAppBarWidth() {
            this.appBarWidth = Number(
                (this.$refs.appBarParent as HTMLElement)?.clientWidth /
                    (this.showScrollbars ? userSettingsState.userSettings.ui.scaleRatio : 1)
            );
            this.$emit('app-bar-width', this.appBarWidth);
        }

        onResize(): void {
            this.setAppBarWidth();
        }

        private get scaledView() {
            return {
                transform: `scale(${userSettingsState.userSettings.ui.scaleRatio})`,
                'transform-origin': 'left top'
            };
        }

        private get scaledWindowHeight() {
            const doubleHeight = 2;
            //От височината на екрана изваждаме височината на toolbar-a, който може да е двоен в зависимост от размера и изваждаме падинга,
            // който имаме до формата, за да получим големината която имаме на действителния екран без да се налага да скролиме основния екран
            return (
                window.innerHeight -
                (this.$vuetify.breakpoint.smAndDown ? this.appBarHeight * doubleHeight : this.appBarHeight) -
                this.appBarHeight
            );
        }

        private get showScrollbars() {
            return userSettingsState.userSettings.ui.scaleRatio > 1;
        }
    }
</script>

<style scoped>
    .fixToolbarAtTheTop {
        position: fixed;
        z-index: 2;
    }

    .deleteButton {
        float: right;
    }
</style>
