<template>
    <exam-layout
        :can-delete="document.id > 0"
        :can-print="document.id > 0"
        title="Протокол за предписване на лекарства, заплащани от НЗОК/РЗОК"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <v-card v-if="previousProtocols.length > 0 && !documentId" class="mb-3">
            <v-card-text>
                <v-col cols="6">
                    <dropdown label="Копиране от протокол" :items="previousProtocols" @input="copyProtocol" />
                </v-col>
            </v-card-text>
        </v-card>
        <v-card>
            <main-title>Протокол за предписване на лекарства, заплащани от НЗОК/РЗОК</main-title>
            <v-card-text>
                <v-row class="my-2">
                    <v-col cols="12" md="6"><PatientInfo /></v-col>
                    <v-col cols="12" md="6"><DoctorInfo /></v-col>
                </v-row>
                <v-row class="my-2">
                    <v-col cols="12" md="3">
                        <text-field v-model.trim="document.number" required label="Номер:" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <date-picker v-model="document.issueDate" required label="Дата на издаване" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field v-model.trim="document.days" type="number" required label="За срок в дни:" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field v-model.trim="document.statementNumber" label="Номер на решение:" />
                    </v-col>
                </v-row>
                <v-row class="my-2">
                    <v-col cols="12">
                        <ExamDiagnosisPicker
                            v-model="document.examDiagnosis"
                            use-main-icd
                            required
                            @input="resetDrugs"
                        />
                    </v-col>
                </v-row>
                <v-row v-for="(drug, index) in document.drugs" :key="index" class="my-2">
                    <h3>Лекарство {{ index + 1 }}</h3>
                    <v-col cols="12">
                        <v-row class="align-baseline">
                            <ProtocolDrugPicker
                                v-model="document.drugs[index]"
                                :icd-code="document.examDiagnosis ? document.examDiagnosis.icdCode : ''"
                                label="Код на лекарствен продукт:"
                                :position="index + 1"
                                required
                                @input="document.drugs[index].position = index + 1"
                            />
                            <btn v-if="document.drugs.length > 1" icon action="Delete" @click="removeDrug(index)" />
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field v-model.trim="document.drugs[index].quantityPerDay" required label="Дневна доза:" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field
                            v-model.trim="document.drugs[index].quantityPerWeek"
                            required
                            label="Седмична доза:"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field
                            v-model.trim="document.drugs[index].quantityPerMonth"
                            required
                            label="Месечна доза:"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <text-field
                            v-model.trim="document.drugs[index].sessionDays"
                            type="number"
                            required
                            label="За срок в дни:"
                        />
                    </v-col>
                    <alert
                        v-if="!allDrugsSameProtocol && document.drugs && document.drugs[index].nhifCode"
                        value
                        type="warning"
                    >
                        Лекарствата в протокола са от различни групи.
                        {{ document.drugs[index].nhifCode }} е от група
                        {{ document.drugs[index].protocolTypeCode }}
                    </alert>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <text-field
                            v-model.trim="document.totalQuantity"
                            label="Общо количество за срока на действие на протокола:"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <text-field
                            v-model.trim="document.controlVisits"
                            label="Предложение за срокове за контролни прегледи:"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <btn v-if="document.drugs.length < 3" action="New" @click="addDrug">Добавяне на лекарство</btn>
            </v-card-actions>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </exam-layout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import DoctorInfo from '@/component/Exam/DoctorInfo.vue';
    import ExamDiagnosisPicker from '@/component/Exam/ExamDiagnosisPicker.vue';
    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PatientInfo from '@/component/Exam/PatientInfo.vue';
    import { DrugProtocolDto } from '@/model/Exam/DrugProtocol/DrugProtocolDto';
    import { NhifProtocolDropdownDto } from '@/model/Exam/DrugProtocol/NhifProtocolDropdownDto';
    import { ExamDiagnosisDto } from '@/model/Exam/ExamDiagnosisDto';
    import { DrugProtocolDrug } from '@/model/Nomenclature/DrugProtocolDrug';
    import { drugProtocolPaperService } from '@/service/Exam/Protocol/DrugProtocolPaperService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { arrayUtil } from '@/util/ArrayUtil';
    import ProtocolDrugPicker from '@/view/Exam/DrugProtocol/ProtocolDrugPicker.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';

    @Component({
        components: {
            UnsavedChanges,
            ExamLayout,
            ProtocolDrugPicker,
            ExamDiagnosisPicker,
            DoctorInfo,
            PatientInfo
        },
        async beforeRouteLeave(to, from, next) {
            await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
        }
    })
    export default class DrugProtocol extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private examId!: number;

        @Prop()
        private documentId!: number;

        private document: DrugProtocolDto = new DrugProtocolDto();
        private initialValue: DrugProtocolDto = new DrugProtocolDto();
        private previousProtocols: NhifProtocolDropdownDto[] = [];
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();

        private get allDrugsSameProtocol() {
            //Правим проверка дали всички лекарства, ако са повече от 1, са от една и съща група
            if (this.document.drugs.length > 1) {
                //Вземаме първото лекарство да бъде шаблон, по който да сравняваме останалите
                const [firstDrug] = this.document.drugs;
                return this.document.drugs.every(
                    (drug) => {
                        if (drug?.nhifCode && firstDrug?.nhifCode) {
                            return drug.protocolTypeCode === firstDrug.protocolTypeCode;
                        }
                        return true;
                    }
                    //Сравняваме само ако има избрано лекарство, ако няма връщаме true
                );
            }
            //Ако лекарствата са по-малко от 1, тогава връщаме true
            return true;
        }

        private addDrug() {
            this.document.drugs.push(new DrugProtocolDrug());
        }

        private removeDrug(index: number) {
            arrayUtil.removeAt(this.document.drugs, index);
        }

        private resetDrugs() {
            this.document.drugs = [];
            this.document.drugs.push(new DrugProtocolDrug());
        }

        private setDrugInitialValue(index: number) {
            if (this.document?.drugs[index] === null) {
                this.document.drugs[index] = new DrugProtocolDrug();
                this.document.drugs[index].position = index + 1;
            }
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let documentId = this.document.id;
                if (this.document.id === 0) {
                    const response = await drugProtocolPaperService.createDrugProtocolPaper(this.document);
                    documentId = Number(response.data);
                    this.copyObject();
                    eventBus.$emit('create-referral-event', documentId, 'DrugProtocol', 'Протокол за НЗОК лекарства');
                    this.$router.push(`/Exam/DrugProtocol/Edit/${documentId}`);
                } else {
                    await drugProtocolPaperService.updateDrugProtocolPaper(this.document);
                    eventBus.$emit('update-referral-event', 'Протокол за НЗОК лекарства');
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async deleteEventHandler() {
            this.$loading.show();
            try {
                const documentId = this.document.id;
                await drugProtocolPaperService.deleteDrugProtocolPaper(documentId);
                this.copyObject();
                this.$router.push(`/Exam/DrugProtocol/Create/${this.document.examId}`);
                eventBus.$emit('delete-referral-event', this.documentId, 'DrugProtocol', 'Протокол за НЗОК лекарства');
            } finally {
                this.$loading.hide();
            }
        }

        private printEventHandler() {
            if (this.documentId) {
                this.$router.push(`/Print/Exam.DrugProtocol.DrugProtocol/${this.documentId}`);
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters: { [key: string]: string } = {};
                parameters.id = this.documentId.toString();
                const report = 'Exam.DrugProtocol.DrugProtocol';
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async load() {
            this.$loading.show();
            try {
                if (this.documentId) {
                    this.document = await drugProtocolPaperService.getDrugProtocolPaper(this.documentId, null);
                    this.getExamDiagnosisOnLoad();
                } else {
                    this.document = await drugProtocolPaperService.getDrugProtocolInfoByExamId(this.examId);
                }
                await this.loadPreviousProtocols();
            } finally {
                this.$loading.hide();
            }
        }

        private async loadPreviousProtocols() {
            if (currentPatientCache?.value.key.patientId) {
                const response = await drugProtocolPaperService.getPreviousProtocolsForPatient(
                    currentPatientCache.value.key
                );
                this.previousProtocols = response?.data ? response.data : [];
            }
        }

        private getExamDiagnosisOnLoad() {
            const examDiagnosis = currentVisitCache.value.exam?.examDiagnoses.find(
                (diagnosis) => diagnosis.id === this.document.examDiagnosis?.id
            );
            this.document.examDiagnosis = examDiagnosis ?? new ExamDiagnosisDto();
        }

        private async copyProtocol(id: number) {
            const previousProtocol = await drugProtocolPaperService.getDrugProtocolPaper(id, this.document.issueDate);
            if (previousProtocol) {
                this.populateDocument(previousProtocol);
                this.getExamDiagnosisOnLoad();
            }
        }

        private populateDocument(previousProtocol: DrugProtocolDto) {
            this.document.drugs = previousProtocol.drugs;
            this.document.examDiagnosis = previousProtocol.examDiagnosis;
            this.document.days = previousProtocol.days;
            this.document.controlVisits = previousProtocol.controlVisits;
            this.document.statementNumber = previousProtocol.statementNumber;
            this.document.totalQuantity = previousProtocol.totalQuantity;
        }

        private async mounted() {
            await this.load();

            if (this.document.drugs.length < 1) {
                this.document.drugs.push(new DrugProtocolDrug());
            }

            this.copyObject();
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        @Watch('document.nhifProtocolDrugs')
        private onDrugChange() {
            if (this.document?.drugs) {
                const drugs = this.document?.drugs;
                for (let index = 0; index < drugs.length; index++) {
                    if (drugs[index] === null) {
                        this.setDrugInitialValue(index);
                    }
                }
            }
        }
    }
</script>
