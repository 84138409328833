<template>
    <div>
        <v-row
            v-if="showAdvertisement"
            style="position: fixed; z-index: 3"
            :style="`width:${appBarWidth}px`"
            no-gutters
        >
            <v-col cols="12">
                <v-img :src="advertisementImg" :style="`background-color: ${backgroundColor}`" />
            </v-col>
        </v-row>
        <ExamLayout
            :can-delete="examDto.id !== 0 && !examDto.isDeleted"
            :can-print="examDto.id !== 0"
            title="Амбулаторен лист"
            help-action="AL"
            @updated="updateEventHandler"
            @deleted="deleteEventHandler"
            @printed="printEventHandler"
            @printed-without-preview="printWithoutPreviewHandler"
            @app-bar-width="changeAppBarWidth"
        >
            <nhis-exam
                v-if="examDto.id"
                v-model="examDto.nhisExam"
                class="mb-3"
                :exam-id="examDto.id"
                :exam-date="examDto.startDateTime"
                @input="copyExamObject"
                @reload-patient-condition="reloadPatientCondition"
            ></nhis-exam>
            <div v-if="currentPatientIsLoaded">
                <v-card :style="`background-color: ${backgroundColor}`">
                    <AmbulatorySheetTitleDoctorPatient
                        :visit-id="visitId ?? null"
                        :exam-dto="examDto"
                        :background-color="backgroundColor"
                    />
                </v-card>
                <v-row
                    v-if="isSpecialist && isNhifPaid && !isUninsuredPregnant && !isScreening"
                    class="mb-1"
                    no-gutters
                >
                    <v-col cols="12"
                        ><AmbulatorySheetIncomingReferralCard
                            v-model="examDto.incomingReferral"
                            :background-color="backgroundColor"
                            :exam-dto="examDto"
                            @watch-diagnosis="onIncomingReferralChange"
                            @primary-exam-change="onPrimaryExamChange"
                        ></AmbulatorySheetIncomingReferralCard
                    ></v-col>
                </v-row>
                <v-row class="my-1" no-gutters>
                    <v-col cols="12">
                        <AmbulatorySheetTypeMainIcdCard
                            :background-color="backgroundColor"
                            :exam-dto="examDto"
                            :exam-type-changed="examTypeChanged"
                            :watch-diagnosis="watchDiagnosis"
                            @visit-type-change="onVisitTypeChange"
                            @icd-change="onIcdChange"
                            @visit-reasons-loaded="getVisitReasons"
                            @register-include="addToRegisterMark"
                            @remove-register-mark="removeAddToRegisterMark"
                        />
                    </v-col>
                </v-row>
                <v-row class="my-1" no-gutters dense
                    ><v-col cols="12">
                        <AmbulatorySheetComorbiditiyDiagnoses
                            :background-color="backgroundColor"
                            :exam-dto="examDto"
                            :exam-type-changed="examTypeChanged"
                            :watch-diagnosis="watchDiagnosis"
                            @register-include="addToRegisterMark"
                            @icd-change="onIcdChange"
                        />
                    </v-col>
                </v-row>
                <v-row class="my-1" no-gutters
                    ><v-col cols="12">
                        <v-row align="stretch" no-gutters>
                            <v-col md="4" sm="12" :class="{ 'pr-1': $vuetify.breakpoint.mdAndUp }">
                                <v-card :style="`background-color: ${backgroundColor}`" class="fill-height">
                                    <v-row dense no-gutters>
                                        <v-col md="12"
                                            ><v-checkbox
                                                :input-value="examDto.hasReferral4"
                                                dense
                                                disabled
                                                label="направления за МДД"
                                            ></v-checkbox
                                        ></v-col>
                                    </v-row>
                                    <v-col cols="12" class="no-margin-no-padding"
                                        ><AmbulatorySheetReferral4Card v-model="examDto.referral4[0]"
                                    /></v-col>
                                    <v-col cols="12" class="no-margin-no-padding"
                                        ><AmbulatorySheetReferral4Card v-model="examDto.referral4[1]"
                                    /></v-col>
                                    <v-col cols="12" class="no-margin-no-padding"
                                        ><AmbulatorySheetReferral4Card v-model="examDto.referral4[2]"
                                    /></v-col>
                                    <v-col cols="12" class="no-margin-no-padding"
                                        ><AmbulatorySheetReferral4Card v-model="examDto.referral4[3]"
                                    /></v-col>
                                    <v-col cols="12" class="no-margin-no-padding"
                                        ><AmbulatorySheetReferral4Card v-model="examDto.referral4[4]"
                                    /></v-col>
                                </v-card>
                            </v-col>
                            <v-col md="8" sm="12" :class="{ 'pl-1': $vuetify.breakpoint.mdAndUp }">
                                <v-card :style="`background-color: ${backgroundColor}`" class="fill-height">
                                    <AmbulatorySheetPatientCondition
                                        ref="patientCondition"
                                        v-model="examDto"
                                    ></AmbulatorySheetPatientCondition>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col cols="12">
                        <AmbulatorySheetProcedures
                            :exam-dto="examDto"
                            :background-color="backgroundColor"
                            @save-fiscal-receipt="updateEventHandler"
                        />
                    </v-col>
                </v-row>
                <AmbulatorySheetCopyDialog v-model="copyDialogModel" @closed="onCopyDialogClosed" />
                <UnsavedChanges ref="leaveDialog" />
            </div>
        </ExamLayout>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import { NhifVisitReasonCode } from '@/enum/Exam/NhifVisitReasonCode';
    import { FundCode } from '@/enum/Nomenclature/FundCode';
    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { AdvertisementFilterDto } from '@/model/Advertisement/AdvertisementFilterDto';
    import { AmbulatorySheetCopyDialogModel } from '@/model/Exam/AmbulatorySheetCopyDialogModel';
    import { AmbulatorySheetMedicalProcedureDto } from '@/model/Exam/AmbulatorySheetMedicalProcedureDto';
    import { ExamDto } from '@/model/Exam/ExamDto';
    import { GroupPrintExamCommand } from '@/model/Exam/GroupPrintExamCommand';
    import { MedicalProcedureDto } from '@/model/Nomenclature/MedicalProcedureDto';
    import { NhifVisitReasonDto } from '@/model/Nomenclature/NhifVisitReasonDto';
    import { advertisementService } from '@/service/AdvertisementService';
    import { examService } from '@/service/Exam/ExamService';
    import { medicalProcedureService } from '@/service/Nomenclature/MedicalProcedureService';
    import { insuranceStatusService } from '@/service/Patient/InsuranceStatusService';
    import { practiceFundService } from '@/service/Practice/PracticeFundService';
    import { printService } from '@/service/PrintService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { userSettingsState } from '@/store/User/UserSettingsState';
    import AmbulatorySheetComorbiditiyDiagnoses from '@/view/Exam/AmbulatorySheet/AmbulatorySheetComorbiditiyDiagnoses.vue';
    import AmbulatorySheetProcedures from '@/view/Exam/AmbulatorySheet/AmbulatorySheetProcedures.vue';
    import AmbulatorySheetTitleDoctorPatient from '@/view/Exam/AmbulatorySheet/AmbulatorySheetTitleDoctorPatient.vue';
    import AmbulatorySheetTypeMainIcdCard from '@/view/Exam/AmbulatorySheet/AmbulatorySheetTypeMainIcdCard.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';
    import NhisExam from '@/view/Nhis/Exam/NhisExam.vue';

    import AmbulatorySheetCopyDialog from './AmbulatorySheetCopyDialog.vue';
    import AmbulatorySheetIncomingReferralCard from './AmbulatorySheetIncomingReferralCard.vue';
    import AmbulatorySheetPatientCondition from './AmbulatorySheetPatientCondition.vue';
    // eslint-disable-next-line import/max-dependencies
    import AmbulatorySheetReferral4Card from './AmbulatorySheetReferral4Card.vue';

    @Component({
        components: {
            AmbulatorySheetTitleDoctorPatient,
            AmbulatorySheetProcedures,
            UnsavedChanges,
            AmbulatorySheetComorbiditiyDiagnoses,
            AmbulatorySheetTypeMainIcdCard,
            NhisExam,
            AmbulatorySheetPatientCondition,
            AmbulatorySheetCopyDialog,
            AmbulatorySheetReferral4Card,
            AmbulatorySheetIncomingReferralCard,
            ExamLayout
        },

        //Правим проверка дали има разлика между обектите при зареждане на страницата и при напускане,
        //ако има показваме уведомителен прозорец, за да може потребителя да реши дали иска да излезе или ще запази промените
        async beforeRouteLeave(to, from, next) {
            if (this.$data.leaveDialogRef) {
                await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.examDto, next);
            } else {
                next();
            }
        }
    })
    export default class AmbulatorySheet extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop()
        private visitId!: number;

        @Prop()
        private copyExamId!: number;

        private get currentPatientIsLoaded() {
            return currentPatientCache.isLoaded;
        }

        private get backgroundColor() {
            return !userSettingsState.userSettings.ui.hasReferralsBackground
                ? 'transparent'
                : userSettingsState.userSettings.ui.isDarkTheme
                ? '#969693'
                : '#ddded8';
        }

        //Таз променлива се декларира, за да се използва в beforeRouteLeave, защото това е единственият начин
        // да достъпим метода check в UnsavedChanges компонентата
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();
        private procedures: MedicalProcedureDto[] = [];
        private examDto: ExamDto = new ExamDto();
        private initialValue: ExamDto = new ExamDto();
        private copyDialogModel: AmbulatorySheetCopyDialogModel = new AmbulatorySheetCopyDialogModel();
        private visitReasons: NhifVisitReasonDto[] = [];
        private incomingReferralChange: boolean = false;
        private advertisementImg: string | null = null;
        private showAdvertisement: boolean = false;
        private timer: number = 0;
        private appBarWidth: number = 0;

        //dkaraminov - Добавям този флаг, защото ми трябва да знам кога типа на прегледа е сменен от потребителя,
        // за да правим проверка по въведените диагнози
        private examTypeChanged: boolean = false;

        private get isScreening() {
            return this.examDto.nhifVisitReasonCode === NhifVisitReasonCode.Screening;
        }

        private get watchDiagnosis(): boolean {
            return Boolean(this.examDto.copiedExamId) || Boolean(this.incomingReferralChange);
        }

        private get isUninsuredPregnant() {
            return (
                userContextCache.current?.specialtyCode === SpecialtyCode.Gynecology &&
                !currentPatientCache.value.insuranceStatus.isInsured
            );
        }

        private get isSpecialist() {
            return userContextCache.current?.specialtyCode !== SpecialtyCode.Gp;
        }

        private get isNhifPaid() {
            return this.examDto.fundCode === FundCode.Nhif;
        }

        private async mounted() {
            this.leaveDialogRef = this.leaveDialog;

            if (this.visitId) {
                await this.loadVisit();
            }
            // Ако е подадено copyExamId, ще се извърши копиране на АЛ.
            else {
                // При ново посещение трябва да се изчисти кешираното.
                // При копиране също се изчиства кешираното посещение, защото пречи на проверките по МКБ.
                currentVisitCache.clear();
                if (this.copyExamId) {
                    await this.copyExam(this.copyExamId);
                    if (this.examDto.existingPrescriptions) {
                        this.copyDialogModel.dialogIsVisible = true;
                    }
                } else {
                    await this.setFundToNhifIfSupported();
                }
                await currentPatientCache.loadLastVersion(
                    currentPatientCache.value.key.patientId,
                    userContextCache.currentDoctorEmployeeSeqNumber
                );
                this.checkPatientRegistrationStatus();
                await this.checkInsuranceStatus();
            }

            // При започване на нов преглед се взима поредният номер на АЛ от брояча.
            if (this.examDto.ambulatorySheetNumber === null) {
                await this.getNextAmbulatorySheetNumber();
            }

            const proceduresResponse = await medicalProcedureService.getMedicalProcedures();
            this.procedures = proceduresResponse.data;
        }

        private async reloadPatientCondition() {
            await (this.$refs.patientCondition as AmbulatorySheetPatientCondition).getFromBlob();
        }

        private checkPatientRegistrationStatus() {
            if (currentPatientCache.value.isDeregistered()) {
                this.$notifier.showWarning('Внимание', 'Пациентът е отписан!');
            }

            if (currentPatientCache.value.isDeceased()) {
                this.$notifier.showWarning('Внимание', 'Пациентът е починал!');
            }
        }

        private async setFundToNhifIfSupported() {
            const response = await practiceFundService.getActiveFunds(
                userContextCache.currentPracticeId ?? 0,
                this.examDto.fundCode,
                false
            );
            if (response?.data && response.data.length > 0 && response.data.some((pf) => pf.code === FundCode.Nhif)) {
                this.examDto.fundCode = FundCode.Nhif;
            }
        }

        //Това се прави ако направлението идва с конкретен тип ВСД или Експертиза, то да не се променя тип на направлението автоматично
        //Ако е тип консултация оставяме механизма за проверка на типа на прегледа по мкб да работи
        private onIncomingReferralChange(watchDiagnosis: boolean) {
            this.incomingReferralChange = watchDiagnosis;
        }

        private async getNextAmbulatorySheetNumber() {
            this.examDto.ambulatorySheetNumber = await examService.getNextAmbulatorySheetNumberForCurrentEmployee();
        }

        private async loadVisit() {
            if (this.visitId) {
                this.$loading.show();
                try {
                    if (currentVisitCache.value.exam) {
                        this.examDto = await examService.getExamById(this.visitId);
                        this.copyExamObject();
                    } else {
                        this.examDto.fundCode = await examService.getDefaultFundForVisit(this.visitId);
                    }
                } finally {
                    this.$loading.hide();
                }
            }
        }

        private copyExamObject() {
            this.initialValue = this.$lodash.cloneDeep(this.examDto);
        }

        private async copyExam(copyExamId: number) {
            this.$loading.show();
            try {
                this.examDto = await examService.getCopiedExamById(copyExamId);
            } finally {
                this.$loading.hide();
            }
        }

        private async printEventHandler() {
            if (this.visitId) {
                if (this.examDto.fundCode === 'Patient') {
                    await this.setExamIsPrinted();
                    this.$router.push(`/Print/Exam.AmbulatorySheetPatient/${this.visitId}`);
                } else {
                    await this.setExamIsPrinted();
                    this.$router.push(`/Print/Exam.AmbulatorySheet/${this.visitId}`);
                }
            }
        }

        // Бързият/директният печат отпечатва само първата страница, независимо дали има нужда от втора.
        // Ако лекарят иска да отпечата дълъг АЛ на две страници, задължително трябва да мине през preview.
        // Ако обаче лекарят мине през preview на дълъг АЛ, но иска да отпечата само първата страница,
        // трябва да ограничи печата през диалога на browser-а или да се върне назад и да изпълни бърз печат.
        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                const parameters = { id: this.visitId, page1Only: true };
                const report =
                    this.examDto.fundCode === 'Patient' ? 'Exam.AmbulatorySheetPatient' : 'Exam.AmbulatorySheet';
                await this.setExamIsPrinted();
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private async setExamIsPrinted() {
            await examService.setExamIsPrinted(
                new GroupPrintExamCommand(userContextCache.currentPracticeId ?? 0, [this.visitId])
            );
            this.examDto.isPrinted = true;
        }

        //Обединяваме медицинските процедури пристигнали от нзис (ако има такива) и тези, които лекарят сам е добавил
        private mergeMedicalProcedures() {
            if ((this.examDto?.nhisMedicalHistory?.procedures?.length ?? 0) > 0) {
                const currentProcedures = this.examDto.medicalProcedures.filter(
                    (procedure) => procedure.procedureId !== null
                );
                this.examDto.nhisMedicalHistory?.procedures.forEach((item) => {
                    const medicalProcedure = new AmbulatorySheetMedicalProcedureDto();
                    medicalProcedure.procedureId = item.procedureId;
                    medicalProcedure.count = item.executionCount;
                    if (!currentProcedures.includes(medicalProcedure)) {
                        currentProcedures.push(medicalProcedure);
                    }
                });
                this.examDto.medicalProcedures = currentProcedures;
            }
        }

        private changeAppBarWidth(width: number) {
            this.appBarWidth = width;
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                this.mergeMedicalProcedures();
                //Когато създаваме преглед от опашка
                if (this.visitId && this.examDto.id === 0) {
                    await this.createQueueExam();
                } else if (this.visitId) {
                    await this.updateExam();
                } else if (currentPatientCache.isLoaded && userContextCache.currentDoctorEmployeeSeqNumber) {
                    const newExamId = await this.createVisit();
                    this.$router.push(`/Exam/AmbulatorySheet/Edit/${newExamId}`);
                } else {
                    this.$notifier.showError('', 'Не е избран пациент или месторабота.');
                }
                this.checkForPayment();
            } finally {
                this.$loading.hide();
            }
        }

        private async createQueueExam() {
            if (this.visitId) {
                this.examDto.id = this.visitId;
                await this.createVisit();
                // Правим го защото вече сме в Edit и трябва да презаредим страницата, за да се заредят менютата.
                this.$router.go(0);
            }
        }

        private async updateExam() {
            // След запис добавените диагнози получават id-та, може да не са на същите позиции, без дубликати са,
            // терапията е допълнена, НЗИС статусът може да е променен и т.н. Целият преглед се презарежда.
            this.examDto = await examService.updateExam(this.examDto);
            this.copyExamObject();
            this.$notifier.showSuccess('Запис', 'Промените са записани успешно');

            // Прегледът е със същото id, но вече е променен, затова се кешира наново, асинхронно.
            // Презареждането се force-ва с подмяна на id-то вместо с currentVisitCache.clear(), за оптимизация.
            currentVisitCache.value.id = 0;
            currentVisitCache.load(this.visitId);
        }

        private setExamMainData() {
            // Слагаме patientVersionKey от кеширания пациент на exam.patientVersionKey, но само при операция Create
            // Слагаме и двамата лекари - титуляр и заместващ/нает.
            this.examDto.patientVersionKey = currentPatientCache.value.key;
            this.examDto.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
            this.examDto.deputyDoctorId = userContextCache.current?.deputyDoctorId ?? null;
            this.examDto.deputyDoctorIsHired = userContextCache.current?.deputyDoctorIsHired ?? null;
        }

        private async createVisit(): Promise<number> {
            this.setExamMainData();
            const updatedVisitCache = await examService.createExam(this.examDto);
            await currentVisitCache.set(updatedVisitCache);
            this.copyExamObject();
            return updatedVisitCache.id;
        }

        private checkForPayment() {
            if (
                currentPatientCache.value.isRetired &&
                !currentPatientCache.haveActiveFeeDisengagement &&
                !this.examDto.fiscalReceiptNumber
            ) {
                this.$notifier.showWarning('', 'Не е въведен номер на платежен документ!');
            }
        }

        private async deleteEventHandler() {
            await examService.deleteUndeleteExam(this.examDto.id, true);
            this.copyExamObject();
            this.$router.push(`/Patient/VisitSummary/${this.examDto.patientVersionKey.patientId}`);
        }

        private onCopyDialogClosed() {
            this.examDto.copyFreePrescriptions = this.copyDialogModel.copyFreePrescriptions;
            this.examDto.copyWhitePrescriptions = this.copyDialogModel.copyWhitePrescriptions;
            this.examDto.copyGreenPrescriptions = this.copyDialogModel.copyGreenPrescriptions;
            this.examDto.copyYellowPrescriptions = this.copyDialogModel.copyYellowPrescriptions;
        }

        private onVisitTypeChange(nhifVisitReasonCode: NhifVisitReasonCode) {
            this.examTypeChanged = true;
            this.removeAddToRegisterMark();
            if (this.examDto.nhifVisitReasonCode !== nhifVisitReasonCode) {
                this.examDto.nhifVisitReasonCode = nhifVisitReasonCode;
                const visitReason = this.visitReasons.find((type) => type.code === nhifVisitReasonCode);
                this.$notifier.showWarning('Внимание', `Типът на прегледа е: ${visitReason?.name ?? ''}`);
            }
            this.populatePregnancyData();
        }

        private async onIcdChange(icdCode: string) {
            if (!this.visitId && !this.isSpecialist) {
                const filter = new AdvertisementFilterDto('Exam', icdCode, null);
                const advertisements = await advertisementService.getValidAdvertisements(filter);
                const [examAdvertisement] = advertisements;

                if (examAdvertisement) {
                    this.advertisementImg = `data:image/png;base64,${examAdvertisement.fileContent}`;
                    if (this.showAdvertisement === false) {
                        this.showAdvertisement = true;
                    }
                } else {
                    this.advertisementImg = null;
                }
                const timeout = 5000;
                this.timer = setTimeout(() => {
                    this.showAdvertisement = false;
                }, timeout);
            }
        }

        private populatePregnancyData() {
            const [mainDiag] = this.examDto.diagnoses;
            if (
                this.examDto.nhifVisitReasonCode === NhifVisitReasonCode.MotherPrevention &&
                !this.visitId &&
                mainDiag.pregnancyRegister.gestationalWeek
            ) {
                this.examDto.lastRegularMenstruation = mainDiag.pregnancyRegister.lastRegularMenstruation;
                this.examDto.gestationalWeek = mainDiag.pregnancyRegister.gestationalWeek;
                this.examDto.pregnancyEndDate = mainDiag.pregnancyRegister.pregnancyEndDate;
                this.calculateProbableBirthTerm();
            }
        }

        private calculateProbableBirthTerm() {
            const thousand: number = 1000;
            const sixty: number = 60;
            const days: number = 280;
            const hours: number = 24;
            if (this.examDto.lastRegularMenstruation !== null) {
                this.examDto.probableBirthTerm = new Date(
                    this.examDto.lastRegularMenstruation.getTime() + days * (thousand * sixty * sixty * hours)
                );
            }
        }

        private removeAddToRegisterMark() {
            this.examDto.isInRegister = false;
        }

        private addToRegisterMark() {
            this.examDto.isInRegister = true;
        }

        private getVisitReasons(data: NhifVisitReasonDto[]) {
            this.visitReasons = data;
        }

        private onPrimaryExamChange(data: number | null) {
            this.examDto.primaryExamId = data;
        }

        private checkInsuranceStatus() {
            if (currentPatientCache.isLoaded) {
                insuranceStatusService
                    .checkStatus(
                        currentPatientCache.value.key.patientId,
                        currentPatientCache.value.personIdentification.pidTypeCode ?? '',
                        currentPatientCache.value.personIdentification.identifier,
                        currentPatientCache.value.insuranceStatus
                    )
                    .then((response) => {
                        if (response) {
                            currentPatientCache.value.insuranceStatus = response;
                        }
                    });
            }
        }
    }
</script>
