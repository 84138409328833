import { plainToInstance } from 'class-transformer';

import { ExamSignatureEditDto } from '@/model/Exam/TabletSignature/ExamSignatureEditDto';
import { ExamSignerDto } from '@/model/Exam/TabletSignature/ExamSignerDto';
import { PatientKey } from '@/model/Patient/PatientKey';
import { httpService } from '@/service/Infrastructure/HttpService';

export const tabletSignatureService = {
    async getExamSignersByPatient(key: PatientKey): Promise<ExamSignerDto[]> {
        const response = await httpService.post<ExamSignerDto[]>('TabletSignature/GetExamSignersByPatient', key);
        return plainToInstance(ExamSignerDto, response.data);
    },

    async getExamSignatureForEdit(visitId: number): Promise<ExamSignatureEditDto> {
        const response = await httpService.get<ExamSignatureEditDto>('TabletSignature/GetExamSignatureForEdit', {
            params: { visitId }
        });
        return plainToInstance(ExamSignatureEditDto, response.data);
    },

    async uploadExamSignature(visitId: number, command: ExamSignatureEditDto): Promise<void> {
        await httpService.post('TabletSignature/UploadExamSignature', command, { params: { visitId } });
    },

    async deleteExamSignature(visitId: number, eTag: string | null): Promise<void> {
        await httpService.delete('TabletSignature/DeleteExamSignature', { params: { visitId, eTag } });
    }
};
