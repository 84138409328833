<template>
    <data-table :headers="headers" :items="items" :loading="$loading.isVisible" title="Амбулаторни листове">
        <template #actions>
            <v-row>
                <v-col cols="8">
                    <report-date-picker v-model="filter" :disabled="$loading.isVisible" @input="getTableItems" />
                </v-col>
                <v-col cols="4">
                    <text-field v-model="filter.timeMinutes" label="Минути между прегледи" />
                </v-col>
                <v-col cols="6" md="3">
                    <btn action="Refresh" block @click="getTableItems">Зареждане</btn>
                </v-col>
                <v-col cols="6" md="3">
                    <btn action="Export" block @click="exportExcel" />
                </v-col>
            </v-row>
        </template>
        <template #rowActions="{ item }">
            <btn icon action="Edit" :to="`/Exam/AmbulatorySheet/Edit/${item.id}`" />
            <btn icon action="Print" @click="printAction(item)" />
        </template>
    </data-table>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import ReportDatePicker from '@/component/Date/ReportDatePicker.vue';
    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { GroupPrintExamCommand } from '@/model/Exam/GroupPrintExamCommand';
    import { TimeBetweenExamsCommand } from '@/model/Query/Exam/TimeBetweenExamsCommand';
    import { TimeBetweenExamsDto } from '@/model/Query/Exam/TimeBetweenExamsDto';
    import { examService } from '@/service/Exam/ExamService';
    import { timeBetweenExamsService } from '@/service/Query/Exam/TimeBetweenExamsService';
    import { loadingState } from '@/store/LoadingState';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { formatters } from '@/util/Formatters';

    @Component({
        components: { ReportDatePicker }
    })
    export default class TimeBetweenExams extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        @Prop({ required: true })
        private doctorEmployeeSeqNumber!: number;

        private items: TimeBetweenExamsDto[] = [];
        private filter: TimeBetweenExamsCommand = new TimeBetweenExamsCommand();

        private headers: IDataTableHeader[] = [
            { text: 'АЛ номер', value: 'number' },
            { text: 'АЛ НРН', value: 'nrn' },
            { text: 'Дата', value: 'startDateTime', formatter: formatters.dateTime },
            { text: 'Посещение за', value: 'visitReason' },
            { text: 'Идентификатор', value: 'patientIdentifier' },
            { text: 'Пациент', value: 'patientName' },
            { text: 'Следващ АЛ', value: 'nextExamNumber' },
            { text: 'Следващ АЛ НРН', value: 'nextExamNrn' },
            { text: 'Следващ АЛ Дата', value: 'nextExamStartDate', formatter: formatters.dateTime }
        ];

        private async getTableItems() {
            this.$loading.show();
            try {
                this.items = [];
                this.fillDoctorEmployee();
                const response = await timeBetweenExamsService.getExams(this.filter);
                this.items = response.data;
            } finally {
                this.$loading.hide();
            }
        }

        private async exportExcel() {
            loadingState.show();
            try {
                this.fillDoctorEmployee();
                await timeBetweenExamsService.exportExams(this.filter);
            } finally {
                loadingState.hide();
            }
        }

        private fillDoctorEmployee() {
            this.filter.practiceId = this.practiceId;
            this.filter.doctorEmployeeSeqNumber = this.doctorEmployeeSeqNumber;
        }

        private async printAction(item: TimeBetweenExamsDto) {
            await this.setExamIsPrinted(item.id);
            this.$router.push(`/Print/Exam.AmbulatorySheet/${item.id}`);
        }

        private async setExamIsPrinted(id: number) {
            await examService.setExamIsPrinted(
                new GroupPrintExamCommand(userContextCache.currentPracticeId ?? 0, Array(1).fill(id) as [])
            );
        }

        private async mounted() {
            if (!this.filter?.startDate || !this.filter?.endDate) {
                this.filter.fillCurrentMonth();
            }
            await this.getTableItems();
        }
    }
</script>
