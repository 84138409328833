<template>
    <v-container fluid>
        <v-slide-y-transition mode="out-in">
            <v-card>
                <v-card-title v-if="isNew">Създаване на лиценз</v-card-title>
                <v-card-title v-else>Продължаване на лиценз</v-card-title>
                <v-card-actions>
                    <btn action="Back" :to="licensingRoute" />
                    <v-spacer />
                    <btn v-if="isNew" action="New" @click="create">Създаване на лиценз и платежно нареждане</btn>
                    <btn v-else action="Save" @click="create">
                        Продължаване на лиценз и създаване на платежно нареждане
                    </btn>
                </v-card-actions>
                <v-card-text>
                    <v-form ref="documentForm" v-model="isValid">
                        <v-row>
                            <v-col xs="12" md="3">
                                <date-picker
                                    v-model="practiceLicense.validFrom"
                                    label="Начало"
                                    required
                                    :disabled="!isNew"
                                />
                            </v-col>
                            <v-col md="3">
                                <v-select
                                    v-model="practiceLicense.months"
                                    class="required"
                                    :rules="[$validator.required]"
                                    :items="monthsChoice"
                                    item-text="text"
                                    dense
                                    item-value="value"
                                    label="Месеци"
                                    @change="calculateLicensePrice"
                                />
                            </v-col>
                            <v-col xs="12" md="3">
                                <date-picker :value="practiceLicense.validTo" disabled label="Край" />
                            </v-col>
                            <v-col md="3">
                                <v-tooltip bottom>
                                    <template #activator="{ on, attrs }">
                                        <text-field
                                            v-model="practiceLicense.employeeCount"
                                            class="required"
                                            required
                                            type="number"
                                            v-bind="attrs"
                                            label="Брой служители"
                                            :rules="[minEmployeesRule]"
                                            @change="calculateLicensePrice"
                                            v-on="on"
                                        />
                                    </template>
                                    <span>Брой служители в практиката: {{ practiceFeatures.employeeCount }}</span>
                                </v-tooltip>
                            </v-col>
                            <!--<v-col md="12">
                                <v-textarea v-model="practiceLicense.publicNotes" outlined dense label="Бележки" rows="2" />
                            </v-col>-->
                        </v-row>
                        <alert type="info" prominent>
                            <v-row>
                                <v-col cols="12" md="3" lg="2">Цена с ДДС:</v-col>
                                <v-col cols="12" md="9" lg="10">{{ priceWithVatText }}</v-col>
                                <v-col cols="12" md="3" lg="2">Цена без ДДС:</v-col>
                                <v-col cols="12" md="9" lg="10">{{ priceWithDescription }}</v-col>
                            </v-row>
                        </alert>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-slide-y-transition>
    </v-container>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import { SelectListNumberValueItem } from '@/model/Common/SelectListNumberValueItem';
    import { PracticeLicenseDto } from '@/model/Licensing/PracticeLicenseDto';
    import { practiceLicenseService } from '@/service/Licensing/PracticeLicenseService';
    import { licensingCache } from '@/store/LicensingCache';

    const monthsHalfYear = 6;
    const monthsPerYear = 12;

    @Component
    export default class PracticeLicenseCreate extends Vue {
        @Prop()
        private practiceId!: number;

        @Prop({ required: true })
        private isNew!: boolean;

        @Prop({ default: monthsPerYear })
        private months!: number;

        private isValid: boolean = false;
        private practiceLicense: PracticeLicenseDto = new PracticeLicenseDto();
        private monthsChoice: SelectListNumberValueItem[] = [
            { text: `${monthsHalfYear} месеца`, value: monthsHalfYear },
            { text: `${monthsPerYear} месеца`, value: monthsPerYear }
        ];

        private priceWithVatText: string = '';
        private priceWithDescription: string = '';

        private async mounted() {
            this.$loading.show();
            try {
                if (this.practiceId) {
                    this.practiceLicense.practiceId = this.practiceId;
                    if (!this.isNew) {
                        const next = await practiceLicenseService.suggestNextPracticeLicense(this.practiceId);
                        if (next.months < monthsHalfYear) {
                            next.months = monthsHalfYear;
                        } else if (next.months !== monthsPerYear) {
                            next.months = monthsPerYear;
                        }
                        this.practiceLicense = next;
                    }
                    this.setPracticeLicenseDefaults();
                    await this.calculateLicensePrice();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private get practiceFeatures() {
            return licensingCache.status.practiceFeatures;
        }

        // Ако браузърът се зареди директно на тази страница, статусът на лиценза идва по-късно.
        @Watch('practiceFeatures')
        private onPracticeFeaturesLoaded() {
            this.setPracticeLicenseDefaults();
        }

        private setPracticeLicenseDefaults() {
            this.practiceLicense.employeeCount = licensingCache.status.practiceFeatures.employeeCount;
        }

        private minEmployeesRule(value: number) {
            return (
                value >= this.practiceFeatures.employeeCount ||
                'За да бъде лицензът валиден, броят трябва да покрива текущия броя лекари в практиката.'
            );
        }

        private async calculateLicensePrice() {
            if (!this.practiceLicense.months) {
                return;
            }

            const priceModel = await practiceLicenseService.calculateLicensePrice(
                this.practiceLicense.months,
                this.practiceLicense.employeeCount
            );

            const centsPrecision = 2;
            this.priceWithVatText = `${priceModel.priceWithVat.toFixed(centsPrecision)} ${priceModel.currencySymbol}`;
            this.priceWithDescription = `${priceModel.price.toFixed(centsPrecision)} ${priceModel.currencySymbol} = ${
                priceModel.description
            }`;
        }

        private async create() {
            (this.$refs.documentForm as Vue & { validate: () => boolean }).validate();
            if (this.isValid) {
                await practiceLicenseService.createPracticeLicenseAndAdjustPendingPayments(this.practiceLicense);
                this.$router.push(this.licensingRoute);
            } else {
                this.$notifier.showWarning(
                    this.$t('notifications.validationErrors').toString(),
                    this.$t('notifications.pleaseFixValidationErrors').toString()
                );
            }
        }

        private get licensingRoute() {
            return `/Practice/Licensing/${this.practiceId}`;
        }
    }
</script>
