import { AxiosResponse } from 'axios';
import { plainToInstance } from 'class-transformer';

import { NhisImmunizationReactionDto } from '@/model/Nhis/Immunization/NhisImmunizationReactionDto';
import { NhisPatientImmunizationExtractionCommand } from '@/model/Nhis/Immunization/NhisPatientImmunizationExtractionCommand';
import { NhisPatientImmunizationExtractionSubmitCommand } from '@/model/Nhis/Immunization/NhisPatientImmunizationExtractionSubmitCommand';
import { NhisPatientImmunizationGetXmlCommand } from '@/model/Nhis/Immunization/NhisPatientImmunizationGetXmlCommand';
import { NhisPatientImmunizationKey } from '@/model/Nhis/Immunization/NhisPatientImmunizationKey';
import { NhisPatientImmunizationReactionKey } from '@/model/Nhis/Immunization/NhisPatientImmunizationReactionKey';
import { NhisPatientImmunizationReactionSubmitCommand } from '@/model/Nhis/Immunization/NhisPatientImmunizationReactionSubmitCommand';
import { NhisPatientImmunizationSubmitCommand } from '@/model/Nhis/Immunization/NhisPatientImmunizationSubmitCommand';
import { NhisImmunizationDto } from '@/model/Patient/Immunization/NhisImmunizationDto';
import { NhisImmunizationViewDto } from '@/model/Patient/Immunization/NhisImmunizationViewDto';
import { NhisPatientImmunizationDto } from '@/model/Patient/Immunization/NhisPatientImmunizationDto';
import { PatientKey } from '@/model/Patient/PatientKey';
import { httpService } from '@/service/Infrastructure/HttpService';
import { notifierService } from '@/service/Infrastructure/NotifierService';
import { nhisService } from '@/service/Nhis/NhisService';
import { nhisVaccineLotService } from '@/service/Nhis/NhisVaccineLotService';
import { signXmlService } from '@/service/Report/SignXmlService';
import { loadingState } from '@/store/LoadingState';
import { accessTokenCache, nhisKey } from '@/store/NhisNhifAccessTokenCache';
import { userContextCache } from '@/store/User/UserContextCache';

const createGetXmlCommand = (immunizationKey: NhisPatientImmunizationKey) => {
    const { current } = userContextCache;
    if (!current || !current.doctorUin) {
        throw Error('Не е избрана месторабота.');
    }
    return new NhisPatientImmunizationGetXmlCommand(immunizationKey, current.doctorUin, current.deputyDoctorUin);
};

const getImmunizationCreateXml = async (immunizationKey: NhisPatientImmunizationKey) => {
    const command = createGetXmlCommand(immunizationKey);
    const response = await httpService.post<string>(`/${nhisKey}/GetImmunizationCreateXml`, command);
    console.log(response);
    return response?.data ?? '';
};

const getImmunizationCancellationXml = async (immunizationKey: NhisPatientImmunizationKey) => {
    const command = createGetXmlCommand(immunizationKey);
    const response = await httpService.post<string>(`/${nhisKey}/GetImmunizationCancellationXml`, command);
    return response?.data ?? '';
};

const getImmunizationCertificateRequestXml = async (immunizationKey: NhisPatientImmunizationKey) => {
    const command = createGetXmlCommand(immunizationKey);
    const response = await httpService.post<string>(`/${nhisKey}/GetImmunizationCertificateRequestXml`, command);
    return response?.data ?? '';
};

const getImmunizationReactionRequestXml = async (key: NhisPatientImmunizationReactionKey) => {
    const response = await httpService.post<string>(`/${nhisKey}/GetImmunizationReactionRequestXml`, key);
    return response?.data ?? '';
};

const postImmunizationToNhis = async (
    action: string,
    xmlString: string,
    immunizationKey: NhisPatientImmunizationKey
) => {
    const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
    const response = await httpService.post<NhisImmunizationDto>(
        `${nhisKey}/${action}`,
        new NhisPatientImmunizationSubmitCommand(xmlString, tokenInfo?.accessToken ?? '', immunizationKey)
    );
    return response.data;
};

const postReactionRequest = async function postReactionRequest(
    xmlString: string,
    immunizationReactionKey: NhisPatientImmunizationReactionKey
) {
    const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
    const tokenValue = tokenInfo?.accessToken ?? '';
    const response = await httpService.post<NhisImmunizationDto>(
        `${nhisKey}/PostImmunizationReactionRequest`,
        new NhisPatientImmunizationReactionSubmitCommand(xmlString, tokenValue, immunizationReactionKey)
    );
    return response.data;
};

const postExtractionRequest = async function postExtractionRequest(xmlString: string, patientKey: PatientKey) {
    const tokenInfo = await accessTokenCache.ensureValidToken(nhisKey);
    const tokenValue = tokenInfo?.accessToken ?? '';
    const response = await httpService.post<NhisImmunizationDto>(
        `${nhisKey}/PostImmunizationExtractionRequest`,
        new NhisPatientImmunizationExtractionSubmitCommand(xmlString, tokenValue, patientKey)
    );
    return response.data;
};

export const nhisImmunizationService = {
    async getCovidBatches() {
        loadingState.show();
        try {
            const response = await nhisVaccineLotService.getCovidBatches(
                userContextCache.currentPracticeId ?? 0,
                userContextCache.currentDoctorEmployeeSeqNumber ?? 0
            );
            //console.log(response.response);
            if (response?.error?.length > 0) {
                await notifierService.showWarning('Внимание', response.error);
            } else {
                await notifierService.showSuccess('', `Номерата на партидите са обновени.`);
            }
        } finally {
            loadingState.hide();
        }
    },

    async postImmunization(immunizationKey: NhisPatientImmunizationKey, isNhisNew: boolean) {
        loadingState.show();
        try {
            const xmlString = await getImmunizationCreateXml(immunizationKey);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const action = isNhisNew ? 'PostCreateImmunization' : 'PostSecondaryImmunization';
                const immunizationResult = await postImmunizationToNhis(action, signResponse.contents, immunizationKey);
                //console.log(immunizationResult?.response);
                if (immunizationResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${immunizationResult.error}`);
                } else {
                    return immunizationResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async cancelImmunization(immunizationKey: NhisPatientImmunizationKey) {
        loadingState.show();
        try {
            const xmlString = await getImmunizationCancellationXml(immunizationKey);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const immunizationResult = await postImmunizationToNhis(
                    'PostCancellationImmunization',
                    signResponse.contents,
                    immunizationKey
                );
                //console.log(immunizationResult?.response);
                if (immunizationResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${immunizationResult.error}`);
                } else {
                    return immunizationResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    async postImmunizationCertificateRequest(immunizationKey: NhisPatientImmunizationKey) {
        loadingState.show();
        try {
            const xmlString = await getImmunizationCertificateRequestXml(immunizationKey);
            console.log(xmlString);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const certificateResult = await nhisService.postXmlToNhis<NhisImmunizationDto>(
                    'PostImmunizationCertificateRequest',
                    signResponse.contents
                );
                console.log(certificateResult?.response);
                if (certificateResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${certificateResult.error}`);
                } else {
                    return certificateResult.certificateBase64;
                }
            }
            return '';
        } finally {
            loadingState.hide();
        }
    },

    async postReaction(key: NhisPatientImmunizationReactionKey) {
        loadingState.show();
        try {
            const xmlString = await getImmunizationReactionRequestXml(key);
            const signResponse = await signXmlService.signXml(xmlString);
            if (!signResponse.isError && signResponse.contents) {
                const reactionResult = await postReactionRequest(signResponse.contents, key);
                //console.log(reactionResult?.response);
                if (reactionResult?.error?.length > 0) {
                    await notifierService.showWarning('Внимание', `${reactionResult.error}`);
                } else {
                    return reactionResult;
                }
            }
            return null;
        } finally {
            loadingState.hide();
        }
    },

    createPatientImmunization(dto: NhisPatientImmunizationDto): Promise<AxiosResponse<number>> {
        return httpService.post(`${nhisKey}/CreateImmunization`, dto);
    },

    getPreviousImmunization(data: NhisPatientImmunizationDto): Promise<AxiosResponse<NhisPatientImmunizationDto>> {
        return httpService.post<NhisPatientImmunizationDto>(`${nhisKey}/CheckForPreviousImmunization/`, data);
    },

    updatePatientImmunization(dto: NhisPatientImmunizationDto): Promise<AxiosResponse<void>> {
        return httpService.put(`${nhisKey}/UpdateImmunization`, dto);
    },

    async getImmunization(immunizationKey: NhisPatientImmunizationKey): Promise<NhisPatientImmunizationDto> {
        const response = await httpService.post(`${nhisKey}/GetImmunization`, immunizationKey);
        return plainToInstance(NhisPatientImmunizationDto, response?.data);
    },

    getImmunizationsByExam(id: number): Promise<AxiosResponse<NhisImmunizationViewDto[]>> {
        return httpService.get<NhisImmunizationViewDto[]>(`${nhisKey}/GetImmunizationsByExamId/${id}`);
    },

    getImmunizationsByPatient(id: number): Promise<AxiosResponse<NhisImmunizationViewDto[]>> {
        return httpService.get<NhisImmunizationViewDto[]>(`${nhisKey}/GetImmunizationsByPatientId/${id}`);
    },

    getImmunizationReactions(
        immunizationKey: NhisPatientImmunizationKey
    ): Promise<AxiosResponse<NhisImmunizationReactionDto[]>> {
        return httpService.post(`${nhisKey}/GetImmunizationReactions`, immunizationKey);
    },

    createImmunizationReaction(data: NhisImmunizationReactionDto): Promise<AxiosResponse<number>> {
        return httpService.post(`${nhisKey}/CreateImmunizationReaction`, data);
    },

    updateImmunizationReaction(data: NhisImmunizationReactionDto): Promise<AxiosResponse<void>> {
        return httpService.put(`${nhisKey}/UpdateImmunizationReaction`, data);
    },

    getExtractionRequestXml(command: NhisPatientImmunizationExtractionCommand): Promise<AxiosResponse<string>> {
        return httpService.post(`/${nhisKey}/GetImmunizationExtractionRequestXml`, command);
    },

    postExtractionRequest(xmlString: string, patientKey: PatientKey): Promise<NhisImmunizationDto> {
        return postExtractionRequest(xmlString, patientKey);
    },

    deleteImmunization(immunizationKey: NhisPatientImmunizationKey): Promise<AxiosResponse<void>> {
        return httpService.delete(`${nhisKey}/DeleteImmunization`, { data: immunizationKey });
    }
};
