import { Type } from 'class-transformer';

import { ExternalVisitLabTestDto } from './ExternalVisitLabTestDto';

export class ExternalVisitDto {
    public practiceId: number = 0;
    public patientId: number = 0;
    public seqNumber: number = 0;
    public visitId: number | null = null;
    public documentType: string = '';
    public date: Date = new Date();
    public documentNumber: string = '';
    public practiceName: string = '';
    public doctorName: string = '';
    public specialty: string = '';
    public isCoronary: boolean = false;
    public mainIcdCode: string = '';
    public mainIcdCodeAdd: string = '';
    public otherIcdCodes: string = '';
    public symptoms: string = '';
    public condition: string = '';
    public tests: string = '';
    public treatment: string = '';
    public notes: string = '';

    @Type(() => ExternalVisitLabTestDto)
    public labTests: ExternalVisitLabTestDto[] = [];
}
