<template>
    <ExamLayout
        :can-save="!disableEdit"
        :can-delete="!disableEdit && document.key.prescriptionId > 0"
        :can-print="document.key.prescriptionId > 0"
        title="Рецептурна бланка - бл.МЗ-НЗОК №5A"
        help-action="Form5A"
        @updated="updateEventHandler"
        @deleted="deleteEventHandler"
        @printed="printEventHandler"
        @printed-without-preview="printWithoutPreviewHandler"
    >
        <nhis-prescription
            v-if="document.key.prescriptionId > 0 && document.nhisPrescription"
            v-model="document.nhisPrescription"
            :prescription-key="prescriptionKey"
            :form-type="document.formTypeCode"
            @input="copyObject"
            @reload-prescription="load"
        />
        <validation-alert
            :nhif-drugs="[document.nhifDrug, document.nhifDrug2, document.nhifDrug3]"
            :exam-diagnosis="[examDiagnosis1, examDiagnosis2, examDiagnosis3]"
            :protocol-type-code="document.protocolTypeCode"
            :issue-date="document.issueDate"
            :exam-id="document.key.examId"
        />
        <v-card class="mb-md-4" :disabled="disableEdit" :style="`background-color: ${backgroundColor}`">
            <main-title>Рецептурна бланка - бл.МЗ-НЗОК №5A</main-title>

            <v-card-text>
                <v-row>
                    <v-col cols="6" md="3"><text-field disabled label="Рецепта №(аптека)" /></v-col>
                    <v-col cols="6" md="3"><text-field disabled label="Изпълнила аптека №" /></v-col>
                    <v-col cols="12" md="3">
                        <dropdown
                            v-model="document.protocolTypeCode"
                            :items="prescriptionProtocolTypes"
                            item-text="code"
                            item-value="code"
                            required
                            label="Начин на предписание"
                            @input="clearAllDrugs"
                        />
                    </v-col>
                </v-row>

                <v-row v-if="document.protocolTypeCode !== 'Без протокол'">
                    <v-col cols="6" md="3"
                        ><text-field v-model="document.protocolNumber" required label="Протокол №"
                    /></v-col>
                    <v-col cols="6" md="3"
                        ><date-picker v-model="document.protocolDateTime" required label="Протокол Дата:"
                    /></v-col>
                    <v-col xs="6" md="3" cols="6">
                        <text-field v-model="document.protocolDays" required label="Валидност (дни):"
                    /></v-col>
                </v-row>
            </v-card-text>

            <v-card-text> <AmbulatorySheetHeaderInfoCard v-once :background-color="backgroundColor" /> </v-card-text>
        </v-card>

        <prescription-info
            v-model="document"
            :exam-diagnoses="examDiagnoses"
            :disable-edit="disableEdit"
            :background-color="backgroundColor"
        />

        <v-card class="mb-4" :style="`background-color: ${backgroundColor}`">
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-tabs v-model="segmentCode" grow slider-color="accent" active-class="accent2 contrast--text">
                            <!-- Тук е описано защо се ползва href: https://github.com/vuetifyjs/vuetify/issues/10540#issuecomment-643731321 -->
                            <v-tab class="segment-title" :href="'#' + NhifPrescriptionSegmentCode.A">Отрязък A</v-tab>
                            <v-tab
                                class="segment-title"
                                :href="'#' + NhifPrescriptionSegmentCode.B"
                                :disabled="prescriptionIsEmpty"
                            >
                                Отрязък B
                            </v-tab>
                            <v-tab
                                class="segment-title"
                                :href="'#' + NhifPrescriptionSegmentCode.C"
                                :disabled="prescriptionIsEmpty"
                            >
                                Отрязък C
                            </v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="showCopyBtn" cols="2">
                        <btn action="Copy" :disabled="disableEdit" @click="copySegment(segmentCode)">
                            Копиране на отрязък {{ segmentCode === NhifPrescriptionSegmentCode.B ? 'А' : 'B' }}
                        </btn>
                    </v-col>
                    <v-spacer />
                    <v-col cols="2">
                        <btn action="Delete" :disabled="disableEdit" @click="clearAllDrugs(segmentCode)">
                            Изчистване
                        </btn>
                    </v-col>
                    <v-col cols="2">
                        <btn action="Refresh" :disabled="disableEdit" @click="reorderDrugs(segmentCode)"
                            >Пренареждане</btn
                        >
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mb-4" :disabled="disableEdit" :style="`background-color: ${backgroundColor}`">
            <v-card-text class="no-margin-no-padding">
                <v-tabs-items v-model="segmentCode">
                    <v-tab-item :value="NhifPrescriptionSegmentCode.A">
                        <!-- Начало първо лекарство от отрязък А -->
                        <DrugComponent
                            v-model="document.nhifDrug"
                            :exam-diagnosis="examDiagnosis1"
                            :protocol-type-code="document.protocolTypeCode"
                            :exam-id="document.key.examId"
                            :segment-code="NhifPrescriptionSegmentCode.A"
                            :position="1"
                            :form-type="document.formTypeCode"
                            :days-summit="calculateAccumulation(document.nhifDrug)"
                            :exam-diagnoses="examDiagnoses"
                            :background-color="backgroundColor"
                            :advertisements="advertisements[examDiagnosis1?.icdCode ?? '']"
                            required-drug
                            @update-nhif="updateNhif"
                            @exam-diagnosis-selected="onExamDiagnosisSelected"
                        />
                        <!-- Край първо лекарство от отрязък А -->
                        <!-- Начало второ лекарство от отрязък А -->
                        <DrugComponent
                            v-model="document.nhifDrug2"
                            :exam-diagnosis="examDiagnosis2"
                            :protocol-type-code="document.protocolTypeCode"
                            :exam-id="document.key.examId"
                            :segment-code="NhifPrescriptionSegmentCode.A"
                            :position="2"
                            :form-type="document.formTypeCode"
                            :days-summit="calculateAccumulation(document.nhifDrug2)"
                            :exam-diagnoses="examDiagnoses"
                            :background-color="backgroundColor"
                            :advertisements="advertisements[examDiagnosis2?.icdCode ?? '']"
                            @update-nhif="updateNhif"
                            @exam-diagnosis-selected="onExamDiagnosisSelected"
                        />
                        <!-- Край второ лекарство от отрязък А -->
                        <!-- Начало трето лекарство от отрязък А -->
                        <DrugComponent
                            v-model="document.nhifDrug3"
                            :exam-diagnosis="examDiagnosis3"
                            :protocol-type-code="document.protocolTypeCode"
                            :exam-id="document.key.examId"
                            :segment-code="NhifPrescriptionSegmentCode.A"
                            :position="3"
                            :form-type="document.formTypeCode"
                            :days-summit="calculateAccumulation(document.nhifDrug3)"
                            :exam-diagnoses="examDiagnoses"
                            :background-color="backgroundColor"
                            :advertisements="advertisements[examDiagnosis3?.icdCode ?? '']"
                            @update-nhif="updateNhif"
                            @exam-diagnosis-selected="onExamDiagnosisSelected"
                        />
                        <!-- Край трето лекарство от отрязък А -->
                        <v-row :style="`background-color: ${backgroundColor}`">
                            <!-- Всичко, което трябва да плати пациентът -->
                            <v-col md="3" offset-md="9">
                                <text-field
                                    label="Всичко:"
                                    disabled
                                    :value="totalCostPatient(document.nhifDrug, document.nhifDrug2, document.nhifDrug3)"
                                />
                            </v-col>
                        </v-row>
                    </v-tab-item>

                    <v-tab-item :value="NhifPrescriptionSegmentCode.B">
                        <!-- Начало първо лекарство от отрязък B -->
                        <PrescriptionNhifAlert
                            :selected-drug="document.nhifDrugB"
                            :segment-a-drugs="[document.nhifDrug, document.nhifDrug2, document.nhifDrug3]"
                        />
                        <DrugComponent
                            v-model="document.nhifDrugB"
                            :exam-diagnosis="examDiagnosis1"
                            :protocol-type-code="document.protocolTypeCode"
                            :exam-id="document.key.examId"
                            :segment-code="NhifPrescriptionSegmentCode.B"
                            :position="1"
                            :form-type="document.formTypeCode"
                            required-drug
                            :days-summit="calculateAccumulation(document.nhifDrugB)"
                            :exam-diagnoses="examDiagnoses"
                            :background-color="backgroundColor"
                            @update-nhif="updateNhif"
                            @exam-diagnosis-selected="onExamDiagnosisSelected"
                        />
                        <!-- Край на първо лекарство от отрязък B -->
                        <!-- Начало второ лекарство от отрязък B -->
                        <PrescriptionNhifAlert
                            :selected-drug="document.nhifDrugB2"
                            :segment-a-drugs="[document.nhifDrug, document.nhifDrug2, document.nhifDrug3]"
                        />
                        <DrugComponent
                            v-model="document.nhifDrugB2"
                            :exam-diagnosis="examDiagnosis2"
                            :protocol-type-code="document.protocolTypeCode"
                            :exam-id="document.key.examId"
                            :segment-code="NhifPrescriptionSegmentCode.B"
                            :position="2"
                            :form-type="document.formTypeCode"
                            :days-summit="calculateAccumulation(document.nhifDrugB2)"
                            :exam-diagnoses="examDiagnoses"
                            :background-color="backgroundColor"
                            @update-nhif="updateNhif"
                            @exam-diagnosis-selected="onExamDiagnosisSelected"
                        />
                        <!-- Край на второ лекарство от отрязък B -->
                        <!-- Начало трето лекарство от отрязък B -->
                        <PrescriptionNhifAlert
                            :selected-drug="document.nhifDrugB3"
                            :segment-a-drugs="[document.nhifDrug, document.nhifDrug2, document.nhifDrug3]"
                        />
                        <DrugComponent
                            v-model="document.nhifDrugB3"
                            :exam-diagnosis="examDiagnosis3"
                            :protocol-type-code="document.protocolTypeCode"
                            :exam-id="document.key.examId"
                            :segment-code="NhifPrescriptionSegmentCode.B"
                            :position="3"
                            :form-type="document.formTypeCode"
                            :days-summit="calculateAccumulation(document.nhifDrugB3)"
                            :exam-diagnoses="examDiagnoses"
                            :background-color="backgroundColor"
                            @update-nhif="updateNhif"
                            @exam-diagnosis-selected="onExamDiagnosisSelected"
                        />
                        <!-- Край на трето лекарство от отрязък B -->
                        <v-row :style="`background-color: ${backgroundColor}`">
                            <!-- Всичко, което трябва да плати пациентът -->
                            <v-col md="3" offset-md="9">
                                <text-field
                                    label="Всичко:"
                                    disabled
                                    :value="
                                        totalCostPatient(document.nhifDrugB, document.nhifDrugB2, document.nhifDrugB3)
                                    "
                                />
                            </v-col>
                        </v-row>
                    </v-tab-item>

                    <v-tab-item :value="NhifPrescriptionSegmentCode.C">
                        <!-- Начало първо лекарство от отрязък C -->
                        <PrescriptionNhifAlert
                            :selected-drug="document.nhifDrugC"
                            :segment-a-drugs="[document.nhifDrug, document.nhifDrug2, document.nhifDrug3]"
                        />
                        <DrugComponent
                            v-model="document.nhifDrugC"
                            :exam-diagnosis="examDiagnosis1"
                            :protocol-type-code="document.protocolTypeCode"
                            :exam-id="document.key.examId"
                            :segment-code="NhifPrescriptionSegmentCode.C"
                            :position="1"
                            :form-type="document.formTypeCode"
                            required-drug
                            :days-summit="calculateAccumulation(document.nhifDrugC)"
                            :exam-diagnoses="examDiagnoses"
                            :background-color="backgroundColor"
                            @update-nhif="updateNhif"
                            @exam-diagnosis-selected="onExamDiagnosisSelected"
                        />
                        <!-- Край на първо лекарство от отрязък C -->
                        <!-- Начало второ лекарство от отрязък C -->
                        <PrescriptionNhifAlert
                            :selected-drug="document.nhifDrugC2"
                            :segment-a-drugs="[document.nhifDrug, document.nhifDrug2, document.nhifDrug3]"
                        />
                        <DrugComponent
                            v-model="document.nhifDrugC2"
                            :exam-diagnosis="examDiagnosis2"
                            :protocol-type-code="document.protocolTypeCode"
                            :exam-id="document.key.examId"
                            :segment-code="NhifPrescriptionSegmentCode.C"
                            :position="2"
                            :form-type="document.formTypeCode"
                            :days-summit="calculateAccumulation(document.nhifDrugC2)"
                            :exam-diagnoses="examDiagnoses"
                            :background-color="backgroundColor"
                            @update-nhif="updateNhif"
                            @exam-diagnosis-selected="onExamDiagnosisSelected"
                        />
                        <!-- Край на второ лекарство от отрязък C -->
                        <!-- Начало трето лекарство от отрязък C -->
                        <PrescriptionNhifAlert
                            :selected-drug="document.nhifDrugC3"
                            :segment-a-drugs="[document.nhifDrug, document.nhifDrug2, document.nhifDrug3]"
                        />
                        <DrugComponent
                            v-model="document.nhifDrugC3"
                            :exam-diagnosis="examDiagnosis3"
                            :protocol-type-code="document.protocolTypeCode"
                            :exam-id="document.key.examId"
                            :segment-code="NhifPrescriptionSegmentCode.C"
                            :position="3"
                            :form-type="document.formTypeCode"
                            :days-summit="calculateAccumulation(document.nhifDrugC3)"
                            :exam-diagnoses="examDiagnoses"
                            :background-color="backgroundColor"
                            @update-nhif="updateNhif"
                            @exam-diagnosis-selected="onExamDiagnosisSelected"
                        />
                        <!-- Край на трето лекарство от отрязък C -->
                        <v-row :style="`background-color: ${backgroundColor}`">
                            <!-- Всичко, което трябва да плати пациентът -->
                            <v-col md="3" offset-md="9">
                                <text-field
                                    label="Всичко:"
                                    disabled
                                    :value="
                                        totalCostPatient(document.nhifDrugC, document.nhifDrugC2, document.nhifDrugC3)
                                    "
                            /></v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
                <v-row>
                    <v-col md="2"><v-checkbox v-model="document.isPregnant" label="Бременна"></v-checkbox></v-col>
                    <v-col md="2"><v-checkbox v-model="document.isNursing" label="Кърмачка"></v-checkbox></v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <UnsavedChanges ref="leaveDialog" />
    </ExamLayout>
</template>

<script lang="ts">
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import ExamLayout from '@/component/Exam/ExamLayout.vue';
    import PrescriptionInfo from '@/component/Exam/PrescriptionInfo.vue';
    import { AdvertisementTypeCode } from '@/enum/AdvertisementTypeCode';
    import { NhifPrescriptionSegmentCode } from '@/enum/Exam/NhifPrescriptionSegmentCode';
    import { PrescriptionFormTypeCode } from '@/enum/Exam/PrescriptionFormTypeCode';
    import { AdvertisementDto } from '@/model/Advertisement/AdvertisementDto';
    import { AdvertisementFilterDto } from '@/model/Advertisement/AdvertisementFilterDto';
    import { InfoByExamFilter } from '@/model/Exam/InfoByExamFilter';
    import { PrescriptionKey } from '@/model/Exam/Prescription/PrescriptionKey';
    import { prescriptionNhifConfig as config } from '@/model/Exam/Prescription/PrescriptionNhifConfig';
    import { PrescriptionNhifTypeADto } from '@/model/Exam/Prescription/PrescriptionNhifTypeADto';
    import {
        PrescriptionProtocolType,
        prescriptionProtocolTypes
    } from '@/model/Exam/Prescription/PrescriptionProtocolType';
    import { PrescriptionDiagnosisDto } from '@/model/Exam/PrescriptionDiagnosisDto';
    import { NhifDrugDto } from '@/model/Nomenclature/NhifDrugDto';
    import { advertisementService } from '@/service/AdvertisementService';
    import { prescriptionService } from '@/service/Exam/PrescriptionService';
    import { eventBus } from '@/service/Infrastructure/EventBus';
    import { printService } from '@/service/PrintService';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userSettingsState } from '@/store/User/UserSettingsState';
    import { objectUtil } from '@/util/ObjectUtil';
    import AmbulatorySheetHeaderInfoCard from '@/view/Exam/AmbulatorySheet/AmbulatorySheetHeaderInfoCard.vue';
    import UnsavedChanges from '@/view/Exam/UnsavedChanges.vue';
    import NhisPrescription from '@/view/Nhis/Prescription/NhisPrescription.vue';

    import DrugComponent from './DrugComponent.vue';
    import PrescriptionNhifAlert from './PrescriptionNhifAlert.vue';
    import ValidationAlert from './PrescriptionValidationAlert.vue';

    @Component({
        components: {
            AmbulatorySheetHeaderInfoCard,
            UnsavedChanges,
            NhisPrescription,
            DrugComponent,
            PrescriptionNhifAlert,
            ValidationAlert,
            ExamLayout,
            PrescriptionInfo
        },
        async beforeRouteLeave(to, from, next) {
            if (this?.$data?.leaveDialogRef) {
                await this.$data.leaveDialogRef.check(this.$data.initialValue, this.$data.document, next);
            } else {
                next();
            }
        }
    })
    export default class PrescriptionNhifTypeA extends Vue {
        @Ref()
        readonly leaveDialog!: UnsavedChanges;

        @Prop({ required: true })
        private prescriptionKey!: PrescriptionKey;

        private document: PrescriptionNhifTypeADto = new PrescriptionNhifTypeADto();
        private initialValue: PrescriptionNhifTypeADto = new PrescriptionNhifTypeADto();
        private nhifDrugs: NhifDrugDto[] = [];
        private leaveDialogRef: UnsavedChanges = new UnsavedChanges();
        private examDiagnoses: PrescriptionDiagnosisDto[] = [];
        private advertisements: { [key: string]: AdvertisementDto[] | null } = {};

        private get backgroundColor() {
            return !userSettingsState.userSettings.ui.hasReferralsBackground
                ? 'transparent'
                : userSettingsState.userSettings.ui.isDarkTheme
                ? '#b08ba1'
                : '#ffe5f4';
        }

        private get collectAllNhifDrugs(): NhifDrugDto[] {
            return [
                this.document.nhifDrug ?? new NhifDrugDto(),
                this.document.nhifDrug2,
                this.document.nhifDrug3,
                this.document.nhifDrugB ?? new NhifDrugDto(),
                this.document.nhifDrugB2,
                this.document.nhifDrugB3,
                this.document.nhifDrugC ?? new NhifDrugDto(),
                this.document.nhifDrugC2,
                this.document.nhifDrugC3
            ];
        }

        private get disableEdit() {
            return this.document?.nhisPrescription?.nrn?.length > 0;
        }

        private copyObject() {
            this.initialValue = this.$lodash.cloneDeep(this.document);
        }

        private async mounted() {
            await this.load();
            this.copyObject();
        }

        private set segmentCode(newSegmentCode: NhifPrescriptionSegmentCode) {
            this.segmentCodeField = newSegmentCode;
        }

        private get segmentCode() {
            return this.segmentCodeField;
        }

        private copySegment(segmentCode: string) {
            if (segmentCode === NhifPrescriptionSegmentCode.B) {
                this.document.nhifDrugB = this.$lodash.cloneDeep(this.document.nhifDrug);
                this.document.nhifDrugB2 = this.$lodash.cloneDeep(this.document.nhifDrug2);
                this.document.nhifDrugB3 = this.$lodash.cloneDeep(this.document.nhifDrug3);
            } else if (segmentCode === NhifPrescriptionSegmentCode.C) {
                this.document.nhifDrugC = this.$lodash.cloneDeep(this.document.nhifDrugB);
                this.document.nhifDrugC2 = this.$lodash.cloneDeep(this.document.nhifDrugB2);
                this.document.nhifDrugC3 = this.$lodash.cloneDeep(this.document.nhifDrugB3);
            }
        }

        private async load() {
            this.$loading.show();
            try {
                this.leaveDialogRef = this.leaveDialog;
                await currentVisitCache.load(this.prescriptionKey.examId);
                await this.getDiagnoses();
                if (!this.prescriptionKey.prescriptionId) {
                    this.document = (await prescriptionService.getPrescriptionInfoByExamId(
                        new InfoByExamFilter(
                            this.prescriptionKey.examId,
                            currentVisitCache.value.startDateTime,
                            PrescriptionFormTypeCode.Nhif5a
                        )
                    )) as PrescriptionNhifTypeADto;
                } else {
                    this.document = await prescriptionService.getPrescriptionTypeA(this.prescriptionKey);
                }
            } finally {
                this.$loading.hide();
            }
        }

        // Така самият enum NhifPrescriptionSegmentCode става видим за template-а.
        private NhifPrescriptionSegmentCode: typeof NhifPrescriptionSegmentCode = NhifPrescriptionSegmentCode;

        private prescriptionProtocolTypes: PrescriptionProtocolType[] = prescriptionProtocolTypes;

        private segmentCodeField: NhifPrescriptionSegmentCode = NhifPrescriptionSegmentCode.A;

        private get showCopyBtn() {
            if (this.segmentCode === NhifPrescriptionSegmentCode.B) {
                return !(
                    objectUtil.deepEqual(
                        this.document?.nhifDrug ?? new NhifDrugDto(),
                        this.document?.nhifDrugB ?? new NhifDrugDto()
                    ) &&
                    objectUtil.deepEqual(this.document.nhifDrug2, this.document.nhifDrugB2) &&
                    objectUtil.deepEqual(this.document.nhifDrug3, this.document.nhifDrugB3)
                );
            } else if (this.segmentCode === NhifPrescriptionSegmentCode.C) {
                return !(
                    objectUtil.deepEqual(
                        this.document?.nhifDrugB ?? new NhifDrugDto(),
                        this.document?.nhifDrugC ?? new NhifDrugDto()
                    ) &&
                    objectUtil.deepEqual(this.document.nhifDrugB2, this.document.nhifDrugC2) &&
                    objectUtil.deepEqual(this.document.nhifDrugB3, this.document.nhifDrugC3)
                );
            }
            return false;
        }

        private get prescriptionIsEmpty() {
            return (
                !this.document?.nhifDrug?.tradeName &&
                !this.document?.nhifDrug2?.tradeName &&
                !this.document?.nhifDrug3?.tradeName &&
                !this.document?.nhifDrugB?.tradeName &&
                !this.document?.nhifDrugB2?.tradeName &&
                !this.document?.nhifDrugB3?.tradeName &&
                !this.document?.nhifDrugC?.tradeName &&
                !this.document?.nhifDrugC2?.tradeName &&
                !this.document?.nhifDrugC3?.tradeName
            );
        }

        private get examDiagnosis1() {
            return this.getPrescriptionDiagnosis(this.document.examDiagnosis1Id, this.document.isDiagnosis1Add);
        }

        private get examDiagnosis2() {
            return this.getPrescriptionDiagnosis(this.document.examDiagnosis2Id, this.document.isDiagnosis2Add);
        }

        private get examDiagnosis3() {
            return this.getPrescriptionDiagnosis(this.document.examDiagnosis3Id, this.document.isDiagnosis3Add);
        }

        private updateNhif(event: {
            nhifDrug: NhifDrugDto;
            segmentCode: NhifPrescriptionSegmentCode;
            position: number;
        }) {
            if (event.segmentCode === NhifPrescriptionSegmentCode.A) {
                if (event.position === config.position1) {
                    this.document.nhifDrug = event.nhifDrug;
                } else if (event.position === config.position2) {
                    this.document.nhifDrug2 = event.nhifDrug;
                } else if (event.position === config.position3) {
                    this.document.nhifDrug3 = event.nhifDrug;
                }
            } else {
                this.$forceUpdate();
                if (event.segmentCode === NhifPrescriptionSegmentCode.B) {
                    if (event.position === config.position1) {
                        this.document.nhifDrugB = event.nhifDrug;
                    } else if (event.position === config.position2) {
                        this.document.nhifDrugB2 = event.nhifDrug;
                    } else if (event.position === config.position3) {
                        this.document.nhifDrugB3 = event.nhifDrug;
                    }
                } else if (event.segmentCode === NhifPrescriptionSegmentCode.C) {
                    if (event.position === config.position1) {
                        this.document.nhifDrugC = event.nhifDrug;
                    } else if (event.position === config.position2) {
                        this.document.nhifDrugC2 = event.nhifDrug;
                    } else if (event.position === config.position3) {
                        this.document.nhifDrugC3 = event.nhifDrug;
                    }
                }
            }
        }

        private clearAllDrugs(segment: NhifPrescriptionSegmentCode) {
            if (segment === NhifPrescriptionSegmentCode.A) {
                this.document.nhifDrug = null;
                this.document.nhifDrug2 = new NhifDrugDto();
                this.document.nhifDrug3 = new NhifDrugDto();
            } else if (segment === NhifPrescriptionSegmentCode.B) {
                this.document.nhifDrugB = null;
                this.document.nhifDrugB2 = new NhifDrugDto();
                this.document.nhifDrugB3 = new NhifDrugDto();
            } else if (segment === NhifPrescriptionSegmentCode.C) {
                this.document.nhifDrugC = null;
                this.document.nhifDrugC2 = new NhifDrugDto();
                this.document.nhifDrugC3 = new NhifDrugDto();
            }
        }

        private reorderDrugs(segment: NhifPrescriptionSegmentCode) {
            if (segment === NhifPrescriptionSegmentCode.A) {
                this.reorderSegmentA();
            } else if (segment === NhifPrescriptionSegmentCode.B) {
                this.reorderSegmentB();
            } else if (segment === NhifPrescriptionSegmentCode.C) {
                this.reorderSegmentC();
            }
        }

        private reorderSegmentA() {
            if (
                (this.document.nhifDrug === null || this.document.nhifDrug?.tradeName === '') &&
                this.document.nhifDrug3.tradeName !== '' &&
                this.document.nhifDrug2.tradeName === ''
            ) {
                this.document.nhifDrug = new NhifDrugDto();
                this.document.examDiagnosis1Id = this.document.examDiagnosis3Id;
                Object.assign(this.document.nhifDrug, this.document.nhifDrug3);
                this.document.nhifDrug3 = new NhifDrugDto();
            }
            if (
                this.document.nhifDrug2.tradeName !== '' &&
                (this.document.nhifDrug === null || this.document.nhifDrug?.tradeName === '')
            ) {
                this.document.nhifDrug = new NhifDrugDto();
                this.document.examDiagnosis1Id = this.document.examDiagnosis2Id;
                Object.assign(this.document.nhifDrug, this.document.nhifDrug2);
                this.document.nhifDrug2 = new NhifDrugDto();
            }

            if (this.document.nhifDrug3.tradeName !== '' && this.document.nhifDrug2.tradeName === '') {
                this.document.nhifDrug2 = new NhifDrugDto();
                this.document.examDiagnosis2Id = this.document.examDiagnosis3Id;
                Object.assign(this.document.nhifDrug2, this.document.nhifDrug3);
                this.document.nhifDrug3 = new NhifDrugDto();
            }
        }

        private reorderSegmentB() {
            if (
                (this.document.nhifDrugB === null || this.document.nhifDrugB?.tradeName === '') &&
                this.document.nhifDrugB3.tradeName !== '' &&
                this.document.nhifDrugB2.tradeName === ''
            ) {
                this.document.nhifDrugB = new NhifDrugDto();
                Object.assign(this.document.nhifDrugB, this.document.nhifDrugB3);
                this.document.nhifDrugB3 = new NhifDrugDto();
            }
            if (
                this.document.nhifDrugB2.tradeName !== '' &&
                (this.document.nhifDrugB === null || this.document.nhifDrugB?.tradeName === '')
            ) {
                this.document.nhifDrugB = new NhifDrugDto();
                Object.assign(this.document.nhifDrugB, this.document.nhifDrugB2);
                this.document.nhifDrugB2 = new NhifDrugDto();
            }

            if (this.document.nhifDrugB3.tradeName !== '' && this.document.nhifDrugB2.tradeName === '') {
                this.document.nhifDrugB2 = new NhifDrugDto();
                Object.assign(this.document.nhifDrugB2, this.document.nhifDrugB3);
                this.document.nhifDrugB3 = new NhifDrugDto();
            }
        }

        private reorderSegmentC() {
            if (
                (this.document.nhifDrugC === null || this.document.nhifDrugC?.tradeName === '') &&
                this.document.nhifDrugC3.tradeName !== '' &&
                this.document.nhifDrugC2.tradeName === ''
            ) {
                this.document.nhifDrugC = new NhifDrugDto();
                Object.assign(this.document.nhifDrugC, this.document.nhifDrugC3);
                this.document.nhifDrugC3 = new NhifDrugDto();
            }
            if (
                this.document.nhifDrugC2.tradeName !== '' &&
                (this.document.nhifDrugC === null || this.document.nhifDrugC?.tradeName === '')
            ) {
                this.document.nhifDrugC = new NhifDrugDto();
                Object.assign(this.document.nhifDrugC, this.document.nhifDrugC2);
                this.document.nhifDrugC2 = new NhifDrugDto();
            }

            if (this.document.nhifDrugC3.tradeName !== '' && this.document.nhifDrugC2.tradeName === '') {
                this.document.nhifDrugC2 = new NhifDrugDto();
                Object.assign(this.document.nhifDrugC2, this.document.nhifDrugC3);
                this.document.nhifDrugC3 = new NhifDrugDto();
            }
        }

        private calculateAccumulation(nhifDrug: NhifDrugDto): number {
            let result = 0;
            if (this.collectAllNhifDrugs.length && nhifDrug) {
                for (const nd of this.collectAllNhifDrugs) {
                    if (nd && (nd?.nhifId ?? 0) > 1 && nd.nhifId === nhifDrug.nhifId) {
                        result += nd?.therapyDuration ?? 0;
                    }
                }
                return result;
            }
            return 0;
        }

        private onExamDiagnosisSelected(event: { examDiagnosisId: number | null; position: number; isAdd: boolean }) {
            if (event.examDiagnosisId) {
                if (event.position === config.position1) {
                    this.document.examDiagnosis1Id = event.examDiagnosisId;
                    this.document.isDiagnosis1Add = event.isAdd;
                }
                if (event.position === config.position2) {
                    this.document.examDiagnosis2Id = event.examDiagnosisId;
                    this.document.isDiagnosis2Add = event.isAdd;
                }
                if (event.position === config.position3) {
                    this.document.examDiagnosis3Id = event.examDiagnosisId;
                    this.document.isDiagnosis3Add = event.isAdd;
                }
            }
        }

        private totalCostPatient(value: NhifDrugDto, value2: NhifDrugDto, value3: NhifDrugDto): number {
            let totalCost: number = 0;
            if (value && value.tradeName) {
                totalCost = Number((value.maxPatientPrice * value.packageCount).toFixed(config.currencyPrecision));
            }
            if (value2 && value2.tradeName) {
                totalCost += Number((value2.maxPatientPrice * value2.packageCount).toFixed(config.currencyPrecision));
            }
            if (value3 && value3.tradeName) {
                totalCost += Number((value3.maxPatientPrice * value3.packageCount).toFixed(config.currencyPrecision));
            }
            if (this.segmentCodeField === NhifPrescriptionSegmentCode.A && totalCost !== 0) {
                this.document.totalCostPatient = totalCost;
            }
            if (this.segmentCodeField === NhifPrescriptionSegmentCode.B && totalCost !== 0) {
                this.document.totalCostPatientB = totalCost;
            }
            if (this.segmentCodeField === NhifPrescriptionSegmentCode.C && totalCost !== 0) {
                this.document.totalCostPatientC = totalCost;
            }

            return Number(totalCost.toFixed(config.currencyPrecision));
        }

        private async updateEventHandler() {
            this.$loading.show();
            try {
                let { prescriptionId } = this.document.key;
                let title = '';
                if (prescriptionId === 0) {
                    this.document.formTypeCode = PrescriptionFormTypeCode.Nhif5a;
                    const result = await prescriptionService.createTypeA(this.document);
                    prescriptionId = result.id;
                    title = this.createTitle();
                    this.copyObject();
                    eventBus.$emit('create-referral-event', prescriptionId, 'PrescriptionNhifTypeA', title);
                    this.$router.push(
                        `/Exam/PrescriptionNhifTypeA/Edit/${this.prescriptionKey.examId}/${prescriptionId}`
                    );
                } else {
                    await prescriptionService.updateTypeA(this.document);
                    title = this.createTitle();
                    eventBus.$emit('update-referral-event', title);
                    this.copyObject();
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async getDiagnoses() {
            const examCache = currentVisitCache.value?.exam;
            if (examCache) {
                for (const diagnosis of examCache.examDiagnoses) {
                    this.examDiagnoses.push(
                        this.createExamDiagnosis(diagnosis.id ?? 0, diagnosis.icdCode, diagnosis.icdName, false)
                    );
                    if (diagnosis.icdCodeAdd) {
                        this.examDiagnoses.push(
                            this.createExamDiagnosis(
                                diagnosis.id ?? 0,
                                diagnosis.icdCodeAdd,
                                diagnosis.icdNameAdd ?? '',
                                true
                            )
                        );
                    }
                }
            }
            await this.getAdvertisements();
        }

        private async getAdvertisements() {
            /* eslint-disable no-await-in-loop */
            for (const diagnosis of this.examDiagnoses) {
                this.advertisements[diagnosis.icdCode] = await advertisementService.getValidAdvertisements(
                    new AdvertisementFilterDto(AdvertisementTypeCode.Prescription, diagnosis.icdCode, null)
                );
            }
            /* eslint-enable no-await-in-loop */
        }

        // TODO: Да се махне този getter, @Watch и презареждането на диагнози. Няма такова в единичната рецепта. Защо е нужно?
        private get currentVisitCache() {
            return currentVisitCache.value;
        }

        @Watch('currentVisitCache')
        async onCurrentVisitCacheChange() {
            await this.getDiagnoses();
        }

        private createExamDiagnosis(id: number, icdCode: string, icdName: string, isAdd: boolean) {
            const diagnosis = new PrescriptionDiagnosisDto();
            diagnosis.id = id;
            diagnosis.icdCode = icdCode;
            diagnosis.icdName = icdName;
            diagnosis.isAdd = isAdd;
            return diagnosis;
        }

        public getPrescriptionDiagnosis(
            examDiagnosisId: number | null,
            isAdd: boolean
        ): PrescriptionDiagnosisDto | null {
            return (
                this.examDiagnoses.find(
                    (examDiagnosis) => examDiagnosis.id === examDiagnosisId && examDiagnosis.isAdd === isAdd
                ) ?? null
            );
        }

        private createTitle() {
            return `${this.document?.nhifDrug?.nhifCode ?? ''}${this.document?.nhifDrug2?.nhifCode ?? ''}${
                this.document?.nhifDrug3?.nhifCode ?? ''
            }`;
        }

        private async deleteEventHandler() {
            await prescriptionService.delete(this.prescriptionKey);
            this.copyObject();
            this.$router.push(`/Exam/PrescriptionNhifTypeA/Create/${this.prescriptionKey.examId}`);
            eventBus.$emit(
                'delete-referral-event',
                this.prescriptionKey.prescriptionId,
                'PrescriptionNhifTypeA',
                `Бланка 5А - №${this.document.prescriptionNumber}`
            );
        }

        /* eslint max-lines: off */
        private printEventHandler() {
            if (this.prescriptionKey.prescriptionId && this.document.nhisPrescription.nrn === null) {
                this.$router.push(
                    `/Print/Exam.Prescription.PrescriptionNhifTypeA/Prescription/${this.prescriptionKey.examId}/${this.prescriptionKey.prescriptionId}`
                );
            } else if (this.prescriptionKey.prescriptionId && this.document.nhisPrescription.nrn !== '') {
                this.$router.push(
                    `/Print/Exam.Prescription.PrescriptionNhifTypeATherapeuticCourse/Prescription/${this.prescriptionKey.examId}/${this.prescriptionKey.prescriptionId}`
                );
            }
        }

        private async printWithoutPreviewHandler() {
            this.$loading.show();
            try {
                let report = '';
                const parameters: { [key: string]: string } = {};
                parameters.examId = this.prescriptionKey.examId.toString();
                parameters.prescriptionId = this.prescriptionKey.prescriptionId.toString();
                if (this.prescriptionKey.prescriptionId && this.document.nhisPrescription.nrn === null) {
                    report = 'Exam.Prescription.PrescriptionNhifTypeA';
                } else if (this.document && this.document.nhisPrescription.nrn !== '') {
                    report = 'Exam.Prescription.PrescriptionNhifTypeATherapeuticCourse';
                }

                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }
    }
</script>

<style scoped>
    /* Лекарите не се ориентирали, че останалите табове (освен избрания) могат да се щракат. С лек border изглеждат по-като бутони. */
    .segment-title {
        border: dotted thin;
    }
</style>
