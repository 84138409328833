<template>
    <div>
        <!-- Копие на съобщението от иконката в заглавната лента. -->
        <alert :value="Boolean(licensingStatus.message)" :type="licensingStatus.severity" prominent>
            <h3>{{ licensingStatus.message }}</h3>
        </alert>

        <!-- Лицензи. -->
        <data-table :headers="licenseHeaders" title="Лицензи" :items="licenses" item-class="itemClass">
            <template #actions>
                <btn
                    v-if="licenses.length > 0 && licensingStatus && licensingStatus.color !== 'error'"
                    action="New"
                    :to="createRoute(false)"
                >
                    Продължаване на лиценз
                </btn>
                <btn v-else action="New" :to="createRoute(true)">Нов лиценз</btn>
                <v-spacer></v-spacer>
                <!-- Същият надпис се вижда и в края на амбулаторни лист. -->
                <div v-if="tabletTypeName" class="info--text">
                    Пациентите се подписват върху таблет от {{ tabletTypeName }}
                </div>
                <btn icon action="Refresh" @click="load">Проверка за промени</btn>
            </template>
            <template #rowActions="{ item }">
                <div class="d-flex">
                    {{ item.message }}
                    <v-menu v-if="!item.isPaid" open-on-hover bottom offset-y>
                        <template #activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <btn action="Print" icon />
                            </div>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-list-item-title class="pointer" @click="printWithoutPreviewHandler(item, false)">
                                    Печат - платежно нареждане
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title
                                    class="pointer"
                                    @click="adjustPendingPaymentsToLicenseAndPrint(item, false)"
                                >
                                    Печат - платежно нареждане (преглед)
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title class="pointer" @click="printWithoutPreviewHandler(item, true)">
                                    Печат - вносна бележка
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title
                                    class="pointer"
                                    @click="adjustPendingPaymentsToLicenseAndPrint(item, true)"
                                >
                                    Печат - вносна бележка (преглед)
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <btn
                        v-if="item.elapsedDays < 0"
                        icon
                        action="Delete"
                        @click="deletePracticeLicenseAndAdjustPendingPayments(item)"
                    />
                </div>
            </template>
        </data-table>

        <!-- Очаквани плащания. -->
        <template v-if="duePayments.length > 0">
            <!-- Когато се очаква само едно плащане и към него няма фактура, се показва кратък вариант вместо таблица. -->
            <alert v-if="duePayments.length === 1 && duePayments[0].invoices.length === 0" type="info" prominent>
                <!-- Цикълът се завърта замо веднъж поради условието length === 1. Оставен е за тестване. -->
                <v-row v-for="item in duePayments" :key="item.seqNumber" align="center">
                    <v-col cols="12" md="auto">Моля, преведете {{ formatCurrency(item.amountWithVat) }}</v-col>
                    <v-col cols="11" md="">
                        <span v-if="item.paymentOrderFileName" class="mr-4">
                            Платежно: {{ item.paymentOrderFileName }}
                        </span>
                        <span v-if="item.publicNotes">Бележки: {{ item.publicNotes }}</span>
                    </v-col>
                    <v-col cols="1" md="auto">
                        <btn
                            icon
                            action="Edit"
                            :to="`/Customer/CustomerCredit/Edit/${item.customerId}/${item.seqNumber}/${practiceId}`"
                        />
                    </v-col>
                </v-row>
            </alert>
            <!-- Когато се очакват няколко плащания или има издадена фактура, се показва таблица. -->
            <v-sheet v-else outlined class="mt-2" color="primary" rounded>
                <data-table
                    title="Моля, преведете"
                    :headers="duePaymentHeaders"
                    :items="duePayments"
                    disable-pagination
                >
                    <template #rowActions="{ item }">
                        <customer-credit-row-actions :value="item" :practice-id="practiceId" />
                    </template>
                </data-table>
            </v-sheet>
        </template>

        <!-- Получени плащания. -->
        <data-table :headers="paymentHeaders" :title="paymentTitle" :items="confirmedCredits">
            <template #rowActions="{ item }">
                <customer-credit-row-actions :value="item" :practice-id="practiceId" />
            </template>
        </data-table>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { IDataTableHeader } from '@/model/Common/IDataTableHeader';
    import { CustomerCreditDto } from '@/model/Licensing/CustomerCreditDto';
    import { CustomerLicenseDto } from '@/model/Licensing/CustomerLicenseDto';
    import { customerCreditService } from '@/service/Licensing/CustomerCreditService';
    import { practiceLicenseService } from '@/service/Licensing/PracticeLicenseService';
    import { printService } from '@/service/PrintService';
    import { licensingCache } from '@/store/LicensingCache';
    import { formatters } from '@/util/Formatters';

    import CustomerCreditRowActions from './CustomerCreditRowActions.vue';

    @Component({
        components: { CustomerCreditRowActions }
    })
    export default class Licensing extends Vue {
        @Prop({ required: true })
        private practiceId!: number;

        private get licensingStatus() {
            return licensingCache.status;
        }

        private get tabletTypeName() {
            return licensingCache.status?.practiceFeatures?.tabletTypeName;
        }

        private paymentTitle: string = 'Плащания';

        private licenses: CustomerLicenseDto[] = [];
        private duePayments: CustomerCreditDto[] = [];
        private confirmedCredits: CustomerCreditDto[] = [];

        private get licenseHeaders(): IDataTableHeader[] {
            const result = [];

            // Колоната за други практики се показва само в редките случаи, в които клиент притежава няколко практики и плаща общо.
            if (this.licenses.some((lic) => lic.isOtherPractice)) {
                result.push({ text: 'Практика', value: 'practiceNumberAndName' });
            }

            result.push(
                { text: 'Начало', value: 'validFrom', formatter: formatters.date },
                { text: 'Месеци', value: 'months' },
                { text: 'Край', value: 'validTo', formatter: formatters.date },
                { text: 'Персонал', value: 'employeeCount' },
                { text: 'Цена с ДДС', value: 'priceWithVat', formatter: formatters.currency }
            );
            return result;
        }

        private duePaymentHeaders: IDataTableHeader[] = [
            { text: 'Сума с ДДС', value: 'amountWithVat', formatter: formatters.currency },
            { text: 'Платежно', value: 'paymentOrderFileName' },
            { text: 'Бележки', value: 'publicNotes' },
            { text: 'Фактура №', value: 'invoiceNumbers' }
        ];

        private paymentHeaders: IDataTableHeader[] = [
            { text: 'Получено на', value: 'paidOn', formatter: formatters.date },
            { text: 'Сума с ДДС', value: 'amountWithVat', formatter: formatters.currency },
            { text: 'Вид', value: 'customerCreditTypeName' },
            { text: 'Платежно', value: 'paymentOrderFileName' },
            { text: 'Бележки', value: 'publicNotes' },
            { text: 'Фактура №', value: 'invoiceNumbers' }
        ];

        private createRoute(newLicense: boolean) {
            return `/Practice/PracticeLicense/Create/${this.practiceId}&${newLicense}`;
        }

        private mounted() {
            // Зареждането на всички данни е изнесено в метод, защото се извиква на още няколко места.
            this.load();
        }

        private async load() {
            this.$loading.show();
            try {
                // При всяко навигиране към екрана с лицензите, лицензионният статус се опреснява.
                // Това се случва например при click на иконката в заглавната лента и при запис на лиценз/плащане.
                await licensingCache.load(this.practiceId);

                const licenses = await practiceLicenseService.getCustomerLicenses(this.practiceId);
                // Моментното състояние на практиката се разпространява по обектите с лицензите, за да изчислят цвета си.
                for (const license of licenses) {
                    license.practiceFeatures = licensingCache.status.practiceFeatures;
                }
                this.licenses = licenses;

                const customerCredits = await customerCreditService.getCustomerCredits(this.practiceId);
                // Внимание: arr.length = 0; не се засича от Vue, затова се ползва splice(0).
                this.confirmedCredits.splice(0);
                this.duePayments.splice(0);
                let totalPaidAmount = 0;
                for (const credit of customerCredits) {
                    if (credit.isDue) {
                        this.duePayments.push(credit);
                    } else {
                        this.confirmedCredits.push(credit);
                        totalPaidAmount += credit.amountWithVat;
                    }
                }

                if (this.licenses.length > 0 && this.licenses[0].balanceWithVat > 0) {
                    this.paymentTitle = `Плащания: Общо ${formatters.currency(
                        totalPaidAmount
                    )}, от които надплатени ${formatters.currency(this.licenses[0].balanceWithVat)}`;
                }
            } finally {
                this.$loading.hide();
            }
        }

        private adjustPendingPaymentsToLicenseAndPrint(license: CustomerLicenseDto, isImportNote: boolean) {
            // Асинхронно се стартира корекция на чакащите плащания, така че да покриват посочения лиценз.
            practiceLicenseService.adjustPendingPaymentsToLicense(license.practiceId, license.seqNumber);
            if (isImportNote) {
                this.$router.push(
                    `/Print/Licensing.ImportNote/PaymentOrder/${license.practiceId}/${license.seqNumber}`
                );
            } else {
                this.$router.push(
                    `/Print/Licensing.CustomerCredit/PaymentOrder/${license.practiceId}/${license.seqNumber}`
                );
            }
        }

        private async printWithoutPreviewHandler(license: CustomerLicenseDto, isImportNote: boolean) {
            this.$loading.show();
            // Асинхронно се стартира корекция на чакащите плащания, така че да покриват посочения лиценз.
            await practiceLicenseService.adjustPendingPaymentsToLicense(license.practiceId, license.seqNumber);
            try {
                const parameters: { [key: string]: string } = {};
                parameters.practiceId = license.practiceId.toString();
                parameters.seqNumber = license.seqNumber.toString();
                let report = 'Licensing.CustomerCredit';
                if (isImportNote) {
                    report = 'Licensing.ImportNote';
                }
                await printService.printReport(report, parameters);
            } finally {
                this.$loading.hide();
            }
        }

        private formatCurrency(amount: number) {
            return formatters.currency(amount);
        }

        private async deletePracticeLicenseAndAdjustPendingPayments(license: CustomerLicenseDto) {
            await practiceLicenseService.deletePracticeLicenseAndAdjustPendingPayments(
                license.practiceId,
                license.seqNumber
            );
            this.load();
        }
    }
</script>
