<template>
    <v-row no-gutters>
        <v-col>
            <alert
                v-if="
                    isDispensary &&
                    value.dispensaryRegister &&
                    value.dispensaryRegister.key &&
                    value.dispensaryRegister.key.seqNumber
                "
                type="success"
                dense
            >
                Пациентът е
                <router-link
                    :to="`/Register/DispensaryRegister/Edit/${value.dispensaryRegister.key.practiceId}/${value.dispensaryRegister.key.patientId}/${value.dispensaryRegister.key.seqNumber}`"
                >
                    диспансеризиран с досие №{{ value.dispensaryRegister.dossierNumber ?? ' липсва' }}
                </router-link>
            </alert>
            <alert
                v-if="isMotherPreventions && value.pregnancyRegister && value.pregnancyRegister.id > 0 && !isDarkTheme"
                type="success"
                dense
            >
                Пациентът е включен в регистър "Майчино здравеопазване" по водещата диагноза! Типът на прегледа е сменен
                на 'Профилактика - Майчино здравеопазване'!
            </alert>
            <v-alert
                v-if="isMotherPreventions && value.pregnancyRegister && value.pregnancyRegister.id > 0 && isDarkTheme"
                type="success"
                dense
            >
                Пациентът е включен в регистър "Майчино здравеопазване" по водещата диагноза! Типът на прегледа е сменен
                на 'Профилактика - Майчино здравеопазване'!
            </v-alert>
            <alert
                v-if="
                    isChildHealth && value.childrenHealthRegister && value.childrenHealthRegister.id > 0 && !isDarkTheme
                "
                type="success"
                dense
            >
                Пациентът е включен в регистър "Детско здравеопазване" по водещата диагноза! Типът на прегледа е сменен
                на 'Профилактика - Детско здравеопазване'!
            </alert>
            <v-alert
                v-if="
                    isChildHealth && value.childrenHealthRegister && value.childrenHealthRegister.id > 0 && isDarkTheme
                "
                type="success"
                dense
            >
                Пациентът е включен в регистър "Детско здравеопазване" по водещата диагноза! Типът на прегледа е сменен
                на 'Профилактика - Детско здравеопазване'!
            </v-alert>
            <v-row
                v-if="
                    (value.dispensaryRegister && value.dispensaryRegister.dispensaryRuleId > 0) ||
                    (value.pregnancyRegister && value.pregnancyRegister.pregnancyRuleId > 0) ||
                    (value.childrenHealthRegister && value.childrenHealthRegister.childrenHealthRuleId > 0) ||
                    (value.preventiveRegister && value.preventiveRegister.hasPreventiveRule)
                "
                class="mb-2"
                dense
                no-gutters
            >
                <v-col cols="6">
                    <RegulationDialog
                        :dispensary-register="value.dispensaryRegister"
                        :pregnancy-register="value.pregnancyRegister"
                        :children-register="value.childrenHealthRegister"
                        :preventive-register="value.preventiveRegister"
                    />
                </v-col>
                <v-col v-if="value.dispensaryRegister && value.dispensaryRegister.dispensaryRuleId > 0" cols="6">
                    <PatientDispensaryVisitDialog
                        :patient-id="value.dispensaryRegister.key.patientId"
                        :icd-code="value.icdCode"
                    />
                </v-col>
                <v-col v-else cols="6">
                    <RegulationActivitiesDialog
                        :dispensary-register="value.dispensaryRegister"
                        :pregnancy-register="value.pregnancyRegister"
                        :children-register="value.childrenHealthRegister"
                        :preventive-register="value.preventiveRegister"
                    />
                </v-col>
            </v-row>

            <!-- Предложение за диспансеризиране, само ако има възможност и ако още не е диспансеризиран. -->
            <template
                v-if="
                    isDispensary &&
                    value.dispensaryRegister &&
                    value.dispensaryRegister.dispensaryRuleId > 0 &&
                    !value.dispensaryRegister.key.seqNumber
                "
            >
                <alert type="info" dense>Диагнозата е от наредбата за диспансеризация.</alert>
                <v-row v-if="!value.dispensaryRegister.isExternalRegistered" no-gutters>
                    <v-col cols="8">
                        <v-switch
                            v-model="value.createDispensary"
                            class="ma-0"
                            dense
                            :label="`Да диспансеризирам ли пациента?: ${value.createDispensary ? 'Да' : 'Не'}`"
                            @change="fillNextDispensaryDossierNumber"
                        />
                    </v-col>
                    <v-col cols="4">
                        <text-field
                            v-model="value.dispensaryRegister.dossierNumber"
                            :disabled="!value.createDispensary"
                            label="Дисп. номер"
                            type="number"
                        />
                    </v-col>
                </v-row>
                <alert v-else type="warning" dense>
                    <router-link
                        :to="`/Patient/Dispensary/${currentPatientKey?.practiceId}/${currentPatientKey.patientId}`"
                    >
                        Пациентът е диспансеризиран при друг.
                    </router-link>
                </alert>
            </template>

            <template v-if="isMainIcd && value.pregnancyRegister && value.pregnancyRegister.pregnancyRuleId > 0">
                <alert type="info" dense>Диагнозата е от наредбата за Майчино здравеопазване.</alert>
                <v-switch
                    v-model="value.createPregnancy"
                    dense
                    :disabled="value.pregnancyRegister.id > 0"
                    :label="`Да включа ли пациента в регистър 'Майчино здравеопазване'? След запис на амбулаторния лист, трябва да отидете в регистър Бременности и да попълните всички необходими данни за пациента!: ${
                        value.createPregnancy ? 'Да' : 'Не'
                    }`"
                />
            </template>

            <template
                v-if="
                    isMainIcd && value.childrenHealthRegister && value.childrenHealthRegister.childrenHealthRuleId > 0
                "
            >
                <alert v-if="!isDarkTheme" type="info" dense>Диагнозата е от наредбата за Детско здравеопазване.</alert>
                <v-alert v-if="isDarkTheme" type="info" dense
                    >Диагнозата е от наредбата за Детско здравеопазване.</v-alert
                >
                <v-switch
                    v-model="value.createChildrenRegister"
                    dense
                    :disabled="value.childrenHealthRegister.id > 0"
                    :label="`Да включа ли пациента в регистър 'Детско здравеопазване'?: ${
                        value.createChildrenRegister ? 'Да' : 'Не'
                    }`"
                />
            </template>

            <v-row no-gutters>
                <v-col cols="6">
                    <!--
                        TODO: Двойката МКБ-та да може да се промненя дори ако диагнозата е използвана в друг документ.
                        Диагнозата не може да се трие, когато е използвана, затова идеята е да бъде required вместо disabled.
                        Проблем: Когато другият документ е изпратен към НЗИС, редактиране на МКБ-тата не е допустимо.
                        <icd-picker
                            v-model="value.icdCode"
                            :required="required || isLocked"
                            @input="onIcdCodeInput"
                        />
                    -->
                    <icd-picker
                        v-model="value.icdCode"
                        :disabled="isLocked"
                        :required="required"
                        @input="onIcdCodeInput"
                    />
                </v-col>
                <v-col cols="6">
                    <icd-picker
                        v-model="value.icdCodeAdd"
                        :disabled="isLocked"
                        label="Допълнително МКБ"
                        @input="onIcdCodeAddInput"
                    />
                </v-col>
            </v-row>
            <alert
                v-if="isDispensary && availableDispensaryRules && availableDispensaryRules.length > 0 && !isDarkTheme"
                type="info"
                dense
            >
                Възможни придружаващи диагнози за диспансеризация на пациента: {{ availableDispensaryRules }}
            </alert>
            <alert v-if="value.isNewFound && !isDarkTheme" type="info" dense>
                Пациентът не е боледувал от това заболяване. Поставена е отметка 'Новооткрито'.
            </alert>
            <alert v-if="isLocked && !isDarkTheme" type="info" dense>{{ value.usedForms }}</alert>

            <v-alert
                v-if="isDispensary && availableDispensaryRules && availableDispensaryRules.length > 0 && isDarkTheme"
                type="info"
                dense
            >
                Възможни придружаващи диагнози за диспансеризация на пациента: {{ availableDispensaryRules }}
            </v-alert>
            <v-alert v-if="value.isNewFound && isDarkTheme" type="info" dense>
                Пациентът не е боледувал от това заболяване. Поставена е отметка 'Новооткрито'.
            </v-alert>
            <v-alert v-if="isLocked && isDarkTheme" type="info" dense>{{ value.usedForms }}</v-alert>
        </v-col>
    </v-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import IcdPicker from '@/component/Picker/IcdPicker.vue';
    import { NhifVisitReasonCode } from '@/enum/Exam/NhifVisitReasonCode';
    import { SpecialtyCode } from '@/enum/Nomenclature/SpecialtyCode';
    import { AmbulatorySheetDiagnosisDto } from '@/model/Exam/AmbulatorySheetDiagnosisDto';
    import { PatientDiagnosisCheckCommand } from '@/model/Exam/PatientDiagnosisCheckCommand';
    import { ChildrenHealthRegisterDto } from '@/model/Register/ChildrenHealth/ChildrenHealthRegisterDto';
    import { DispensaryRegisterDto } from '@/model/Register/Dispensary/DispensaryRegisterDto';
    import { PregnancyRegisterDto } from '@/model/Register/Pregnancy/PregnancyRegisterDto';
    import { examDiagnosisService } from '@/service/Exam/ExamDiagnosisService';
    import { medicalProcedureService } from '@/service/Nomenclature/MedicalProcedureService';
    import { dispensaryRegisterService } from '@/service/Register/DispensaryRegisterService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { currentVisitCache } from '@/store/CurrentVisitCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import { userSettingsState } from '@/store/User/UserSettingsState';
    import PatientDispensaryVisitDialog from '@/view/Patient/Dispensary/PatientDispensaryVisitDialog.vue';

    import RegulationActivitiesDialog from './RegulationActivitiesDialog.vue';
    import RegulationDialog from './RegulationDialog.vue';

    @Component({
        components: {
            RegulationDialog,
            IcdPicker,
            PatientDispensaryVisitDialog,
            RegulationActivitiesDialog
        }
    })
    export default class AmbulatorySheetDiagnosisCard extends Vue {
        // Този компонент може да получи undefined v-model. В този случай се bind-ва default стойността, която е празен обект.
        @Prop({ default: () => new AmbulatorySheetDiagnosisDto(), type: Object })
        private value!: AmbulatorySheetDiagnosisDto;

        @Prop()
        private examDateTime!: Date;

        @Prop()
        private isMainIcd!: boolean;

        @Prop()
        private examVisitType!: string;

        @Prop()
        private required!: boolean;

        @Prop()
        private watchDiagnosis!: boolean;

        @Prop()
        private isIncidental!: boolean;

        @Prop()
        private examTypeChange!: boolean;

        @Prop()
        private isSecondaryExam!: boolean;

        @Prop()
        private lastDispensaryNumber!: number;

        //dkaraminov
        //Използваме този флаг, както и skipDispensaryRegisterInclusion единствено при копиране на диспансерен преглед,
        // защото само при него и само при първоначалното зареждане на данните трябва да се пропусне проверката
        // за включване в регистър ако диагнозата е диспансерна, но при евентуална редакция в данните
        // проверките трябва да сработят
        @Prop({ default: false })
        private isExamCopy!: boolean;

        private skipDispensaryRegisterInclusion: boolean = false;
        private availableDispensaryRules: string[] = [];

        private get currentPatientKey() {
            return currentPatientCache.value.key;
        }

        private get isDarkTheme() {
            return userSettingsState.userSettings.ui.isDarkTheme;
        }

        private async onIcdCodeInput(icdCode: string) {
            if (this.isIncidental) {
                return;
            }
            const { icdCodeAdd } = this.value;
            let isPregnancy = false;
            let isPreventive = false;
            let isChildrenHealth = false;
            this.value.isNewFound = await this.checkForNewFoundDisease(icdCode, icdCodeAdd);
            if (icdCode) {
                //Проверка за диспансерна диагноза
                await this.checkForDispensary(icdCode, icdCodeAdd);
                //Проверка за майчино здравеопазване
                isPregnancy = await this.checkForPregnancy(icdCode);
                //Проверка за профилактичен
                isPreventive = await this.checkForPreventive(icdCode, icdCodeAdd);
                //Проверка за детско здравеопазване
                isChildrenHealth = await this.checkForChildrenHealth(icdCode);
                //Проверка дали не е необходимо да се добави конкретна процедура
                await this.checkForProcedure(this.value.icdCode);

                // Ако типът не се определи при горните проверки слагаме типът на посещението да бъде Консултация
                if (!isPregnancy && !isPreventive && !isChildrenHealth && !this.isDispensary) {
                    this.$emit('visit-type-change', NhifVisitReasonCode.Consultation);
                }
                this.$emit('icd-change', icdCode);
            }
        }

        private async onIcdCodeAddInput(icdCodeAdd: string) {
            if (this.isIncidental) {
                return;
            }
            const { icdCode } = this.value;
            this.value.isNewFound = await this.checkForNewFoundDisease(icdCode, icdCodeAdd);
            if (icdCode) {
                await this.checkForDispensary(icdCode, icdCodeAdd);
                this.$emit('icd-change', icdCodeAdd);
            }
        }

        private async checkForNewFoundDisease(icdCode: string, icdCodeAdd: string | null): Promise<boolean> {
            if (!currentPatientCache.isLoaded) {
                return false;
            }

            const command = this.createExamDiagnosisCheckCommand(icdCode, icdCodeAdd);
            const newFoundDiseaseResponse = await examDiagnosisService.isNewFoundDisease(command);

            return newFoundDiseaseResponse.data;
        }

        private async checkForDispensary(icdCode: string, icdCodeAdd: string | null): Promise<void> {
            this.availableDispensaryRules = [];
            const command = this.createExamDiagnosisCheckCommand(icdCode, icdCodeAdd);
            const response = await examDiagnosisService.checkForDispensary(command);
            if (response.data) {
                this.value.dispensaryRegister = response.data;
                this.processDispensaryResponse();
            } else {
                if (this.isDispensary) {
                    this.availableDispensaryRules = await examDiagnosisService.checkForAvailableDispensaryRules(
                        command
                    );
                }
                this.value.dispensaryRegister = new DispensaryRegisterDto();
                this.value.createDispensary = false;
            }

            if (this.isDispensary) {
                const limitationCheck = await examDiagnosisService.checkForDispensaryLimitations(command);
                if (limitationCheck.data) {
                    this.$notifier.showWarning('Внимание', limitationCheck.data);
                }
            }
        }

        private processDispensaryResponse(): void {
            // Ако dispensaryRuleId > 0 то това означава, че диагнозата е диспансерна.
            if (this.value.dispensaryRegister.dispensaryRuleId > 0) {
                // Ако има seqNumber, значи вече имаме създадено диспансерно досие за тази диагноза.
                if (this.value.dispensaryRegister.key.seqNumber) {
                    this.value.createDispensary = true;
                }
                if (this.isMainIcd && !this.isDispensary) {
                    this.$notifier.showWarning(
                        'Внимание',
                        'Избраната основна диагноза e от наредбата за диспансеризация, препоръчва се типът на прегледа да бъде диспансерен.'
                    );
                }
                // Когато диагнозата е диспансерна и прегледът е диспансерен,
                // пациентът се диспансеризира без значение дали диагнозата е основна или придружаваща.
                if (
                    !this.value.dispensaryRegister.key.seqNumber &&
                    this.isDispensary &&
                    !this.skipDispensaryRegisterInclusion &&
                    !this.value.dispensaryRegister.isExternalRegistered
                ) {
                    this.addToRegister();
                    this.value.createDispensary = true;
                    this.fillNextDispensaryDossierNumber();
                }
                this.skipDispensaryRegisterInclusion = false;
            }
        }

        private async checkForPregnancy(icdCode: string): Promise<boolean> {
            if (!currentPatientCache.isLoaded || !this.isMainIcd) {
                return false;
            }
            const isPregnancy = false;
            const command = this.createExamDiagnosisCheckCommand(icdCode, '');
            const pregnancyResponse = await examDiagnosisService.checkForPregnancy(command);
            this.value.pregnancyRegister = pregnancyResponse.data;
            //Ако пациента е регистриран в Майчино по диагнозата, поставяме типа на прегледа да бъде Майчино здравеопазване!
            if (this.value.pregnancyRegister.pregnancyRuleId > 0) {
                if (pregnancyResponse.data.id > 0) {
                    this.value.createPregnancy = true;
                }

                if (this.examVisitType !== NhifVisitReasonCode.MotherPrevention) {
                    this.$emit('visit-type-change', NhifVisitReasonCode.MotherPrevention);
                }

                if (this.value.pregnancyRegister.id === 0) {
                    this.value.createPregnancy = true;
                    this.addToRegister();
                }
                return true;
            }
            this.value.pregnancyRegister = new PregnancyRegisterDto();
            return isPregnancy;
        }

        private async checkForChildrenHealth(icdCode: string): Promise<boolean> {
            if (!currentPatientCache.isLoaded || !this.isMainIcd) {
                return false;
            }
            const isChildrenHealth = false;
            const command = this.createExamDiagnosisCheckCommand(icdCode, '');

            const childrenResponse = await examDiagnosisService.checkForChildrenHealth(command);
            this.value.childrenHealthRegister = childrenResponse.data;
            //Ако пациента е регистриран в Регистър детско по диагнозата, поставяме типа на прегледа да бъде Детско здравеопазване!
            if (this.value.childrenHealthRegister.childrenHealthRuleId > 0) {
                if (childrenResponse.data.id > 0) {
                    this.value.createChildrenRegister = true;
                }

                if (this.examVisitType !== NhifVisitReasonCode.ChildPrevention) {
                    this.$emit('visit-type-change', NhifVisitReasonCode.ChildPrevention);
                }

                if (this.value.childrenHealthRegister.id === 0) {
                    this.value.createChildrenRegister = true;
                    this.addToRegister();
                }

                return true;
            }
            this.value.childrenHealthRegister = new ChildrenHealthRegisterDto();
            return isChildrenHealth;
        }

        private async checkForPreventive(icdCode: string, icdCodeAdd: string | null): Promise<boolean> {
            if (!currentPatientCache.isLoaded || !this.isMainIcd) {
                return false;
            }
            let isPrevention = false;
            const command = this.createExamDiagnosisCheckCommand(icdCode, icdCodeAdd);
            const visitTypeResponse = (await examDiagnosisService.checkForPreventive(command)).data;
            this.value.preventiveRegister = visitTypeResponse;
            if (visitTypeResponse.preventiveType.includes('Prevention')) {
                if (visitTypeResponse.preventiveType !== NhifVisitReasonCode.MotherPrevention) {
                    await this.checkForPreventionLimitations(icdCode, icdCodeAdd);
                }
                this.$emit('visit-type-change', visitTypeResponse.preventiveType);
                this.addToRegister();
                isPrevention = true;
            }
            return isPrevention;
        }

        private async checkForPreventionLimitations(icdCode: string, icdCodeAdd: string | null) {
            const command = this.createExamDiagnosisCheckCommand(icdCode, icdCodeAdd);
            const result = await examDiagnosisService.checkForPreventionLimitations(command);
            if (result.data) {
                const correctIcdResult = this.getCorrectIcd(result.data);
                if (!correctIcdResult) {
                    this.$notifier.showWarning('Внимание', result.data);
                } else {
                    const [correctIcd] = correctIcdResult;
                    this.$notifier.showWarning('Внимание', `Диагнозата бе променена на ${correctIcd}`);
                    this.value.icdCode = correctIcd;
                }
            }
        }

        private getCorrectIcd(response: string) {
            const regex = /Z[0-9]{2}.[0-9]{1}/gu;
            return response.match(regex);
        }

        private createExamDiagnosisCheckCommand(icdCode: string, icdCodeAdd: string | null) {
            const adultAge = 18;
            const command = new PatientDiagnosisCheckCommand();
            command.patientKey = currentPatientCache.value.key;
            command.doctorSpecialtyCode = userContextCache.current?.specialtyCode ?? '';
            command.examId = currentVisitCache.value.id;
            command.doctorEmployeeSeqNumber = userContextCache.currentDoctorEmployeeSeqNumber ?? 0;
            command.icdCode = icdCode;
            command.icdCodeAdd = icdCodeAdd;
            command.examDate = this.examDateTime;
            command.isAdult = (currentPatientCache?.value?.age ?? 0) >= adultAge;
            return command;
        }

        private addToRegister() {
            this.$emit('register-include');
        }

        private get isDispensary() {
            return this.examVisitType === NhifVisitReasonCode.Dispensary;
        }

        private get isMotherPreventions() {
            return this.examVisitType === NhifVisitReasonCode.MotherPrevention;
        }

        private get isChildHealth() {
            return this.examVisitType === NhifVisitReasonCode.ChildPrevention;
        }

        private get isLocked() {
            return Boolean(this.value.usedForms);
        }

        private async checkForProcedure(newIcdCode: string) {
            // Ако диагнозата е Z00.8 - профилактика с ваксинация, трябва да се сложи процедура 99.59.
            if (
                newIcdCode === 'Z00.8' &&
                this.isMainIcd &&
                userContextCache.current?.specialtyCode === SpecialtyCode.Gp
            ) {
                // Има шест коренно различни процедури с код 99.59 (4 активни и 2 отпаднали),
                // затова търсенето е по името на процедурата, а не по кода.
                await this.findMedicalProcedure(
                    'Ваксинация профилактична',
                    `процедура "99.59 Ваксинация профилактична"`
                );
            } else if (
                userContextCache.current?.specialtyCode !== SpecialtyCode.Gp &&
                this.examVisitType !== NhifVisitReasonCode.HighSpecialized
            ) {
                if (this.isDispensary) {
                    const procedureName = 'Диспансеризация – Приложение № 8 и 9';
                    await this.findMedicalProcedure(procedureName, `процедура "89.09 ${procedureName}"`);
                } else if (!this.isSecondaryExam) {
                    const procedureName =
                        'Медицински преглед в амбулаторни или домашни условия, със снемане на анамнеза, общ и локален статус, оценка на здравословното състояние и оценка на медицински проблем';
                    await this.findMedicalProcedure(procedureName, `процедура "89.03 ${procedureName}"`);
                } else {
                    const procedureName = 'Вторични прегледи (в рамките на един и същ медицински проблем)';
                    await this.findMedicalProcedure(procedureName, `процедура "89.02 ${procedureName}"`);
                }
            }
        }

        private async findMedicalProcedure(procedureName: string, procedureDescr: string) {
            const procedure = await medicalProcedureService.getMedicalProcedureByName(procedureName);
            if (procedure) {
                this.$emit('change-procedure', procedure.id);
            } else {
                this.$notifier.showWarning(
                    '',
                    `Хипократ не откри ${procedureDescr}, а трябваше да добави такава процедура.`
                );
            }
        }

        @Watch('isSecondaryExam')
        private onExamTypeChange() {
            this.checkForProcedure(this.value.icdCode);
        }

        @Watch('examVisitType')
        private async onExamVisitTypeChange(newVal: string) {
            //dkaraminov Този флаг е необходим при първоналчното зареждане на прегледа в режим на редакция,
            // тогава не трябва да се прави тази проверка. Може би не е най-красивият начин, но само това ми дойде
            // като идея
            if (!this.examTypeChange) {
                return;
            }
            if (newVal === NhifVisitReasonCode.Dispensary) {
                this.value.createPregnancy = false;
                this.value.createChildrenRegister = false;
                if (this.value.icdCode) {
                    await this.checkForDispensary(this.value.icdCode, this.value.icdCodeAdd);
                }
            } else if (newVal === NhifVisitReasonCode.MotherPrevention) {
                this.value.createDispensary = false;
                this.value.createChildrenRegister = false;
                if (this.value.icdCode) {
                    await this.checkForPregnancy(this.value.icdCode);
                }
            } else if (newVal === NhifVisitReasonCode.ChildPrevention) {
                this.value.createDispensary = false;
                this.value.createPregnancy = false;
                if (this.value.icdCode) {
                    await this.checkForChildrenHealth(this.value.icdCode);
                }
            } else {
                this.value.createDispensary = false;
                this.value.createPregnancy = false;
                this.value.createChildrenRegister = false;
            }
            await this.checkForProcedure(this.value.icdCode);
        }

        @Watch('value.icdCode')
        private async onIcdCodeChange(icdCode: string) {
            //Използва се за копирани прегледи и входящи е-направления защото при тях не се изпълнява @input и трябва ръчно да извикаме метода
            if (this.watchDiagnosis) {
                if (this.isExamCopy) {
                    this.skipDispensaryRegisterInclusion = true;
                }
                await this.onIcdCodeInput(icdCode);
            }
        }

        @Watch('value.icdCodeAdd')
        private async onIcdCodeAddChange(icdCode: string) {
            if (this.watchDiagnosis) {
                if (this.isExamCopy) {
                    this.skipDispensaryRegisterInclusion = true;
                }
                await this.onIcdCodeAddInput(icdCode);
            }
        }

        @Watch('value.dispensaryRegister.dossierNumber')
        private onDispensaryDossierNumberChange(dossierNumber: number | null) {
            if (!dossierNumber && this.value?.dispensaryRegister) {
                this.value.dispensaryRegister.dossierNumber = null;
            }
        }

        private async fillNextDispensaryDossierNumber() {
            if (
                this.value.createDispensary &&
                userContextCache.currentPracticeId &&
                userContextCache.currentDoctorEmployeeSeqNumber
            ) {
                const dispensaryNumber = await dispensaryRegisterService.getNextDispensaryNumber(
                    userContextCache.currentPracticeId,
                    userContextCache.currentDoctorEmployeeSeqNumber
                );
                if (this.lastDispensaryNumber && this.lastDispensaryNumber >= dispensaryNumber) {
                    // Каства се към Number, защото понякога lastDispensaryNumber идва като string
                    this.value.dispensaryRegister.dossierNumber = Number(this.lastDispensaryNumber) + 1;
                } else {
                    this.value.dispensaryRegister.dossierNumber = dispensaryNumber;
                }
            } else {
                this.value.dispensaryRegister.dossierNumber = null;
            }
        }
    }
</script>
