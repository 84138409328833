import { dateUtil } from '@/util/DateUtil';

export class PatientVersionDropdownDto {
    public seqNumber: number = 0;
    public validFrom: Date | null = null;
    public validTo: Date | null = null;
    public doctorName: string = '';
    public gpEmployeeSeqNumber: number | null = null;
    // НЕ показва дали това е последната версия по дата или по seqNumber - може и да не е.
    // Показва само кой seqNumber се сочи от Patient.LastVersionSeqNumber.
    public isLast: boolean = false;

    public get validityText() {
        return `v${this.seqNumber}:${
            this.isLast
                ? ' текуща'
                : this.validFrom
                ? ` от ${dateUtil.formatBgDate(this.validFrom)} - д-р ${this.doctorName}`
                : ''
        }${this.validTo ? ` до ${dateUtil.formatBgDate(this.validTo)} - д-р ${this.doctorName}` : ''}`;
    }
}
