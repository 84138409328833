import { Type } from 'class-transformer';

import { PatientKey } from '@/model/Patient/PatientKey';
import { PatientSpecialActivityDto } from '@/model/Patient/PatientSpecialActivityDto';

export class PatientPreventiveDataDto {
    @Type(() => PatientKey)
    public patientKey: PatientKey = new PatientKey();

    @Type(() => PatientSpecialActivityDto)
    public activities: PatientSpecialActivityDto[] = [];

    @Type(() => PatientSpecialActivityDto)
    public selectedPerformedActivities: PatientSpecialActivityDto[] = [];

    @Type(() => PatientSpecialActivityDto)
    public selectedRequiredActivities: PatientSpecialActivityDto[] = [];

    public doctorEmployeeSeqNumber: number = 0;
    public patientAge: number = 0;
    public deputyDoctorId: number | null = null;
    public deputyDoctorIsHired: boolean | null = null;
}
