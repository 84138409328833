<template>
    <v-card-text v-if="hasTabletLicense">
        <!-- Същият надпис се вижда и в Управление - Лицензи. -->
        <div class="info--text">Пациентите се подписват върху таблет от {{ tabletTypeName }}</div>
        <v-checkbox
            v-if="isAdult"
            v-model="isDifferentSigner"
            label="Подписва се от придружител"
            @change="onAdultCheckboxChange"
        />
        <v-row>
            <v-col v-if="showSignerFields" cols="6" md="3">
                <dropdown
                    v-model="selectedSigner"
                    :items="signers"
                    :item-text="dropdownResultText"
                    return-object
                    label="Предходни придружители"
                    @input="onSignerSelected"
                />
            </v-col>
        </v-row>
        <v-form ref="documentForm">
            <v-row v-if="showSignerFields">
                <v-col md="3" cols="6"> <pid-type-picker v-model="model.signer.pidTypeCode" required /> </v-col>
                <v-col md="3" cols="6">
                    <text-field
                        v-model="model.signer.identifier"
                        required
                        label="Идентификатор:"
                        :rules="[$validator.identifier(model.signer.pidTypeCode ?? '', model.signer.identifier ?? '')]"
                        @blur="searchChoiceMaker"
                    />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model.trim="model.signer.firstName" required label="Име:" />
                </v-col>
                <v-col md="3" cols="6">
                    <text-field v-model.trim="model.signer.lastName" required label="Фамилия:" />
                </v-col>
            </v-row>
        </v-form>

        <v-row v-if="model.imageBase64">
            <v-col md="3" cols="6">
                <img :src="imgSrc" />
            </v-col>
        </v-row>
        <v-card-actions>
            <btn :disabled="disableBtns || isLoading || !canSign" action="Sign" @click="captureSign">
                Подпис от пациент
            </btn>
            <btn :disabled="disableBtns || isLoading || !canClearSignData" action="Delete" @click="deleteExamSignature">
                Изтриване на подпис
            </btn>
            <btn v-if="isDevOrTest && model.examXml" action="Refresh" @click="checkSignedData">
                (DevTest) Проверка подпис
            </btn>
            <btn v-if="!isDeclarationEsign" action="Print" @click="printDeclaration">Декларация е-подпис</btn>
        </v-card-actions>
        <alert v-if="!canSign" type="info"
            >Бутонът 'Подпис от пациент' ще бъде активен ако направите промяна по АЛ и запишете или ако изтриете подписа
            на пациента!</alert
        >
        <nhis-group-upload
            ref="groupUpload"
            :exam-id="exam.id"
            :exam-date="exam.startDateTime"
            hide-btn
            use-patient-signature
            @input="change"
        />
    </v-card-text>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import PidTypePicker from '@/component/Picker/PidTypePicker.vue';
    import { config } from '@/Config';
    import { NhisExamStatusCode } from '@/enum/Nhis/NhisExamStatusCode';
    import { PidTypeCode } from '@/enum/Nomenclature/PidTypeCode';
    import { ExamDto } from '@/model/Exam/ExamDto';
    import { ExamSignatureEditDto } from '@/model/Exam/TabletSignature/ExamSignatureEditDto';
    import { ExamSignerDto } from '@/model/Exam/TabletSignature/ExamSignerDto';
    import { NhisExamDto } from '@/model/Nhis/Exam/NhisExamDto';
    import { PatientKey } from '@/model/Patient/PatientKey';
    import { examService } from '@/service/Exam/ExamService';
    import { localServerSignService } from '@/service/LocalServer/LocalServerSignService';
    import { nhisExamService } from '@/service/Nhis/NhisExamService';
    import { choiceMakerService } from '@/service/Patient/ChoiceMakerService';
    import { tabletSignatureService } from '@/service/TabletSignature/TabletSignatureService';
    import { currentPatientCache } from '@/store/CurrentPatientCache';
    import { licensingCache } from '@/store/LicensingCache';
    import { userContextCache } from '@/store/User/UserContextCache';
    import NhisGroupUpload from '@/view/Query/Nhis/NhisGroupUpload.vue';

    @Component({
        components: { NhisGroupUpload, PidTypePicker }
    })
    export default class AmbulatorySheetSignature extends Vue {
        @Prop()
        exam!: ExamDto;

        private model: ExamSignatureEditDto = new ExamSignatureEditDto();
        private isDifferentSigner: boolean = false;
        private signers: ExamSignerDto[] = [];
        private selectedSigner: ExamSignerDto | null = null;
        private disableBtns: boolean = false;

        private async mounted() {
            if (this.showSignerFields) {
                await this.loadPreviousSigners();
            }
        }

        private get imgSrc() {
            return this.model.imageBase64 ? `data:image/png;base64,${this.model.imageBase64}` : '';
        }

        private async onAdultCheckboxChange(value: boolean) {
            if (value) {
                await this.loadPreviousSigners();
            }
        }

        private dropdownResultText(signer: ExamSignerDto) {
            return `Идентификатор: ${signer.identifier} Имена: ${signer.firstName} ${signer.lastName}`;
        }

        private onSignerSelected() {
            const { signer } = this.model;
            if (this.selectedSigner) {
                signer.identifier = this.selectedSigner.identifier;
                signer.pidTypeCode = this.selectedSigner.pidTypeCode;
                signer.firstName = this.selectedSigner.firstName;
                signer.lastName = this.selectedSigner.lastName;
            }
        }

        private get isSignToClose() {
            return (
                this.exam.id > 0 &&
                (this.exam?.nhisExam?.statusCode === NhisExamStatusCode.Open || !this.exam?.nhisExam?.statusCode)
            );
        }

        private get isDeclarationEsign() {
            return currentPatientCache.value.isDeclarationEsignPrinted;
        }

        private get canSign() {
            return (
                this.exam.nhisExam.statusCode !== NhisExamStatusCode.Canceled &&
                this.exam.nhisExam.statusCode !== NhisExamStatusCode.Closed
            );
        }

        private get canClearSignData() {
            return this.exam.nhisExam.statusCode === NhisExamStatusCode.Open || !this.exam.nhisExam?.statusCode;
        }

        private get isLoading() {
            return this.$loading.isVisible;
        }

        private get showSignerFields() {
            return !this.isAdult || this.isDifferentSigner;
        }

        private get isAdult() {
            const adultAge = 18;
            return currentPatientCache.value?.age !== null && currentPatientCache.value?.age >= adultAge;
        }

        private get hasTabletLicense() {
            return Boolean(this.tabletTypeName);
        }

        private get tabletTypeName() {
            return licensingCache.status?.practiceFeatures?.tabletTypeName;
        }

        private printDeclaration() {
            this.$router.push(
                `/Print/Patient.DeclarationESign/DeclarationESign/${this.exam.id}/${currentPatientCache.value?.key.patientId}`
            );
        }

        private async captureSign() {
            if (!this.hasTabletLicense || !this.isFormValid()) {
                return;
            }
            this.disableBtns = true;
            try {
                this.model.imageBase64 = null;
                this.model.rawBase64 = null;
                await this.sendOpenExamWithForms();
                this.model.examXml = await this.stringifyExamForCapture();
                const result = await localServerSignService.signCapture(
                    currentPatientCache.value.fullName,
                    'Подписване амбулаторен лист',
                    this.model.examXml
                );
                if (result.isError) {
                    this.$notifier.showWarning('', result.message ?? 'Възникна проблем при извличане на подписа');
                    await this.clearExamIsDigitallySigned();
                } else {
                    console.log(result);
                    this.model.imageBase64 = result.data;
                    this.model.rawBase64 = result.contents;
                    if (this.model.imageBase64) {
                        await tabletSignatureService.uploadExamSignature(this.exam.id, this.model);
                        await this.sendCloseEditExam();
                    }
                }
            } finally {
                this.disableBtns = false;
            }
        }

        private async deleteExamSignature() {
            this.$loading.show();
            try {
                await this.clearExamIsDigitallySigned();
                await tabletSignatureService.deleteExamSignature(this.exam.id, this.model.eTag);
                this.model = new ExamSignatureEditDto();
            } finally {
                this.$loading.hide();
            }
        }

        private async sendOpenExamWithForms() {
            await (this.$refs.groupUpload as NhisGroupUpload).sendOpenExamWithForms();
        }

        private async sendCloseEditExam() {
            await (this.$refs.groupUpload as NhisGroupUpload).sendCloseEditForms();
        }

        private change(data: NhisExamDto) {
            this.exam.nhisExam = data;
        }

        private isFormValid() {
            return (this.$refs.documentForm as Vue & { validate: () => boolean })?.validate();
        }

        private get isDevOrTest() {
            return config.isDevOrTest;
        }

        private async checkSignedData() {
            if (!this.hasTabletLicense) {
                return;
            }
            const currentObjectHashCode = await this.stringifyExamForCheck();
            const result = await localServerSignService.checkSignedData(
                currentObjectHashCode,
                this.model.rawBase64 ?? ''
            );
            console.log(result);
            if (result.isError) {
                this.$notifier.showWarning('Резултат от проверка', result.message ?? '');
            } else {
                this.$notifier.showSuccess('Резултат от проверка', result.message ?? '');
            }
            this.model.imageBase64 = result.data;
        }

        private async stringifyExamForCapture() {
            //Подписване на целия документ, както е по изискване на НЗИС, за момента го правим скрито, до момента, в който решим да го пуснем
            let examXml = this.isSignToClose
                ? await nhisExamService.getExamDataToSign(this.exam.id, true)
                : await nhisExamService.getExamDataCorrectionToSign(this.exam.id, true);
            examXml = this.clearXmlNamespaces(examXml);
            console.log(`stringifyExamForCapture:${examXml}`);
            return examXml;
        }

        private async stringifyExamForCheck() {
            //Не знаем как е затворен прегледа, дали в следствие на редакция или от съобщение за затваряне, затова използваме json-a на това,
            // което е последно подписано и проверяваме дали съдържа closeDate, ако съдържа, значи е било затваряне, ако не значи е било редакция
            const isClose = this.model.examXml?.includes('closeDate');
            //Подписване на целия документ, както е по изискване на НЗИС, за момента го правим скрито, до момента, в който решим да го пуснем
            let examXml = isClose
                ? await nhisExamService.getExamDataToSign(this.exam.id, false)
                : await nhisExamService.getExamDataCorrectionToSign(this.exam.id, false);
            examXml = this.clearXmlNamespaces(examXml);
            console.log(`stringifyExamForCheck:${examXml}`);
            return examXml;
        }

        private clearXmlNamespaces(xml: string) {
            return xml
                .replace('<?xml version="1.0" encoding="utf-8"?>', '')
                .replace(/\sxmlns(?<temp1>:[a-z]+)?="[^"]+"/gmu, '');
        }

        private async clearExamIsDigitallySigned() {
            if (this.canClearSignData) {
                await examService.clearExamIsDigitallySigned(this.exam.id);
            }
        }

        private async searchChoiceMaker() {
            this.$loading.show();
            try {
                const { signer } = this.model;
                if (signer.identifier) {
                    const response = await choiceMakerService.searchChoiceMaker(
                        signer.identifier,
                        userContextCache.currentPracticeId ?? 0
                    );
                    if (response?.length > 0) {
                        const [choiceMaker] = response;
                        signer.identifier = choiceMaker.personIdentification?.identifier;
                        signer.pidTypeCode = choiceMaker.personIdentification?.pidTypeCode ?? PidTypeCode.Egn;
                        signer.firstName = choiceMaker.firstName;
                        signer.lastName = choiceMaker.lastName;
                    }
                }
            } finally {
                this.$loading.hide();
            }
        }

        private async loadPreviousSigners() {
            this.$loading.show();
            try {
                const key = new PatientKey();
                key.practiceId = userContextCache.currentPracticeId ?? 0;
                key.patientId = currentPatientCache.value.key.patientId;
                this.signers = await tabletSignatureService.getExamSignersByPatient(key);
            } finally {
                this.$loading.hide();
            }
        }

        @Watch('examId', { immediate: true })
        private async onExamIdChange() {
            this.model = await tabletSignatureService.getExamSignatureForEdit(this.exam.id);
            if (this.model.signer.firstName && this.isAdult) {
                this.isDifferentSigner = true;
            }
        }
    }
</script>
