import { AxiosResponse } from 'axios';

import { NhisExtractedReferralDto } from '@/model/Nhis/Referral/NhisExtractedReferralDto';
import { httpService } from '@/service/Infrastructure/HttpService';

export const incomingReferralExamService = {
    createVisitOrUpdateReferral(data: NhisExtractedReferralDto): Promise<AxiosResponse<number>> {
        return httpService.post('/IncomingReferralExam/CreateVisitFromIncomingReferral', data);
    }
};
